import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { PublicLinkVerifyComponent } from './public-link-verify/public-link-verify.component';
import { EmailInviteVerifyComponent } from './email-invite-verify/email-invite-verify.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';

const routes: Routes = [
  {
    path: `:${ACCOUNT_NAME}/invite/link/:token`,
    component: PublicLinkVerifyComponent
  },
  {
    path: `:${ACCOUNT_NAME}/invite/email/:token`,
    component: EmailInviteVerifyComponent
  },
  {
    path: `:${ACCOUNT_NAME}/email/verify/:token`,
    component: EmailVerificationComponent
  },
];

@NgModule({
  declarations: [
    PublicLinkVerifyComponent,
    EmailInviteVerifyComponent,
    EmailVerificationComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
],
})
export class VerifyModule
{}
