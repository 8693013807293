export class ApiDefinition {
  login = '';
  /** /registration */
  register = '';
  /** /ages */
  ages = '';
  /** /industries */
  industries = '';
  /** /genders */
  genders = '';
  /** /countries */
  countries = '';
  /** /educations */
  educations = '';
  /** /incomes */
  incomes = '';
  /** /job-levels */
  job_levels = '';
  /**
   * /users
   */
  users = '';
  /**
   * Route for one profile /profile
   */
  profile = '';
  /**
   * Route for all profiles
   *
   * /profiles
   */
  readonly profiles = 'profiles';
  /**
   * forms/{pageSize}/{page}
   */
  type_forms = '';
  /**
   * type-forms-results/{formId}/{pageSize}/{page}
   */
  type_form_results = '';
  /**
   * /submitted-assessments/elastic
   */
  submittedAssessments = '';

  /**
   * /assessments/results/{form_id}
   */
  adminAssesmentResults = '';
  /**
   * /invites
   */
  emailInvites = '';

  /**
   * /assessments
   */
  assessmentList = '';
  /**
   * /assessment
   */
  readonly assessment = 'assessment';
  /**
   * /groups
   */
  groups = '';

  /**
   * /emailtemplates
   */
  inviteTemplates = '';

  /**
   * /accounts
   */
  accounts = '';

  /**
   * /admin/info/{id}
   */
  updateAdminRoles = '';
  /**
   * /userassessments
   */
  user_assessments = '';
  /**
   * /switch
   */
  switch = '';
  /**
   * /assessments/csv/scores
   */
  csv_scores = '';

  /**
   * Pinging route
   * scheduler/questatus
   */
  ping = '';
  /**
   * /articles
   */
  articles = '';
  /**
   * Assessment invite route for email invites
   *
   * /invites/assessment/email
   */
  readonly invitesAssessmentEmail = 'invites/assessment/email';
  /**
   * Assessment invite route for public links
   *
   * /invites/assessment/link
   */
  readonly invitesAssessmentLink = 'invites/assessment/link';
  /** invites/assessment */
  readonly invitesAssessment = 'invites/assessment';
  /**
   * List of all quiz results
   *
   * /results
   */
  readonly results = 'results';
  /**
   * /userassessments/form
   */
  readonly userassessmentsForm = 'userassessments/form';

  /**
   * /thumbs
   */
  readonly thumbs = 'thumbs';

  /**
   * /chart/templates
   */
  readonly chart_templates = 'chart/templates';
  /**
   * /assessment/report
   */
  readonly assessment_report = 'assessment/report';

  /**
   * /templates/elastic/mapping
   */
  readonly templates_elastic_mapping = 'templates/elastic/mapping';

  /**
   * /share
   */
  readonly share = 'share';

  /**
   * /report
   */
  readonly report = 'report';
  /**
   * /report/comparison
   */
  readonly report_comparison = 'report/comparison';

  /**
   * /admin
   */
  readonly admin = 'admin';

  /**
   * /anon/login
   */
  readonly anon_login = 'anon/login';
  /**
   * /user/anon
   */
  readonly user_anon = 'user/anon';

  /** `email/verify` */
  readonly emailVerify = 'email/verify';

  /** `users/email/cancel` */
  readonly changeEmailCancel = 'users/email/cancel';

  constructor() {
    this.login = 'token';
    this.register = 'registration';
    this.ages = 'ages';
    this.industries = 'industries';
    this.genders = 'genders';
    this.countries = 'countries';
    this.educations = 'educations';
    this.incomes = 'incomes';
    this.job_levels = 'job-levels';
    this.users = 'users';
    this.profile = 'profile';
    this.type_forms = 'forms';
    this.type_form_results = 'type-form-results';
    this.submittedAssessments = 'submitted-assessments/elastic';
    this.adminAssesmentResults = 'assessments/results';
    this.emailInvites = 'invites';
    this.assessmentList = 'assessments';
    this.groups = 'groups';
    this.inviteTemplates = 'emailtemplates';
    this.accounts = 'accounts';
    this.updateAdminRoles = 'admin/info';
    this.user_assessments = 'userassessments';
    this.switch = 'switch';
    this.csv_scores = 'assessments/csv/scores';
    this.ping = 'scheduler/queuestatus';
    this.articles = 'articles';
  }
}
