import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiDefinition } from '../../models/api/api.def';
import { AuthService } from '../auth/auth.service';
import { GroupModel } from '../../models/admin/groups.def';
import { API } from 'src/app/api-url/api-urls';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  api: ApiDefinition = new ApiDefinition();

  currentAccount = '';

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private helper: HelperService
  ) {
  }

  /**
   * Fetch all groups
   *
   * GET /groups
   */
  getAllGroups(indexName?: string) {
    if (indexName) {
      this.auth.httpOptions.params = new HttpParams()
        .set('account', indexName);
    }

    return this.http.get<GroupModel[]>(API.APIDOMAIN + this.api.groups, this.auth.httpOptions);
  }


  /**
   * Add users to a group
   *
   * POST /groups/adduser
   * @param user_ids
   * @param group  group id or group name if new
   * @param includeAll
   * @param index_name Account Index Name
   */
  addUsersToGroup({ user_ids, group, includeAll = false, index_name, all_known_groups = [] }: { user_ids: number[]; group: string; includeAll?: boolean; index_name?: string; all_known_groups?: any[]; }) {

    let new_group = false;

    if (all_known_groups.length) {
      const groupFound = all_known_groups.find(g => g.name === group);
      console.log(groupFound);
      if(groupFound) {
        group = groupFound.id;
      } else {
        new_group = true;
      }
    } else {
      new_group = true;
    }

    return this.http.post<{ status: 'OK' | 'Pending' }>(API.APIDOMAIN + this.api.groups + '/adduser', {
      user_id: includeAll ? [] : user_ids,
      group,
      new_group,
      includeAll,
      index_name
    }, this.auth.httpOptions);
  }

  /**
   * Add group/groups to single user
   *
   * POST /groups/add
   * @param user_id
   * @param groups
   */
  addSingleUserToMultipleGroups(user_id: number, groups: string[]) {
    // TODO: 20.02.2023 Do we use this endpoint?
    return this.http.post(API.APIDOMAIN + this.api.users + '/groups/add', {
      user_id,
      groups_name: groups
    }, this.auth.httpOptions);
  }

  /**
   * Remove users form group
   *
   * POST /groups/removeuser
   * @param user_ids
   * @param group group id or group name if new
   * @param account_name
   * @param all_known_groups all used groups
   */
  removeUserFromGroup(user_ids: number[], group: string, includeAll: boolean = false, account_name?: string, all_known_groups: any[] = []) {

    let group_id;

    if (all_known_groups.length) {
      const groupFound = all_known_groups.find(g => g.name === group);
      console.log(groupFound);
      if (groupFound) {
        group_id = groupFound.id;
      } else {
        console.error('ID not found for group: ', group);
        this.helper.showAlert(`This group does not exists in current account. Please contact administrator.`)
        return;
      }

    }
    return this.http.post<{ status: 'OK' | 'Pending' }>(API.APIDOMAIN + this.api.groups + '/removeuser', {
      user_id: includeAll ? [] : user_ids,
      includeAll,
      group_id,
      account_name
    }, this.auth.httpOptions);
  }

}
