import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { ForgottenRightComponent } from './forgotten-right.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/pages/auth/forgotten-right`,
    component: ForgottenRightComponent
  },
  {
    path: 'pages/auth/forgotten-right',
    component: ForgottenRightComponent
  }
];

@NgModule({
  declarations: [
    ForgottenRightComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})

export class ForgottenRightModule { }
