import { NgModule } from '@angular/core';
import { SingleChartComponent } from './single-chart/single-chart.component';
import { MultiLineChartComponent } from './multi-line-chart/multi-line-chart.component';
import { MultiLineSliderChartComponent } from './multi-line-slider-chart/multi-line-slider-chart.component';
import { BoxChartComponent } from './box-chart/box-chart.component';
import { SharedModule } from 'src/app/shared/shared.module';

const components = [
  SingleChartComponent,
  MultiLineChartComponent,
  MultiLineSliderChartComponent,
  BoxChartComponent
];

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [...components],
  exports: [...components]
})
export class CustomChartsModule { }
