import { Component, OnInit } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { AuthService } from '../../content/services/auth/auth.service';
import { SidenavService } from '../../main-service/sidenav.service';

@Component({
  selector: 'ds-left-sidenav',
  templateUrl: './left-sidenav.component.html',
  styleUrls: ['./left-sidenav.component.scss']
})
export class LeftSidenavComponent implements OnInit
{
  isLocal = false;

  constructor(
    public themingService: ThemingService,
    public auth: AuthService,
    public sidenavService: SidenavService
  )
  {}

  ngOnInit()
  {
    this.auth.makeNavLinks();

    if (window.location.hostname === 'localhost')
    {
      this.isLocal = true;
    }
  }

  handleLeftSidenav() {
    if (this.sidenavService.leftSidenav.mode === 'side' && this.sidenavService.canBeCollapsed) {
      this.sidenavService.isCollapsed = !this.sidenavService.isCollapsed;
      this.sidenavService.openCloseSidenav('left');
    }
    else {
      this.sidenavService.toggleLeftSidenav();
    }
  }
}
