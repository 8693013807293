import { NgModule } from '@angular/core';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CreatePasswordComponent } from './create-password.component';


const routes = [
  {
    path: `:${ACCOUNT_NAME}/create-password`,
    component: CreatePasswordComponent
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent
  }
];

@NgModule({
  declarations: [
    CreatePasswordComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class CreatePasswordModule { }
