import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {API} from 'src/app/api-url/api-urls';
import {Quiz} from '../../models/user/quiz.model';
import {GetQuizParams, UpdateQuizParams} from '../../models/quiz/quiz.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizSystemService {
  httpOptions: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
  }

  /**
   * Get a list of quizzes
   *
   * GET /quiz/quizzes
   */
  getQuizzes() {
    this.setTheQuizTokenInHeader();
    return this.http.get(API.QUIZ_API + 'quizzes', this.httpOptions);
  }

  /**
   * Get a new empty quiz result for the end user, or existing result if using result_key
   *
   * POST /quiz/get
   * @param params - quiz get params
   */
  getQuiz(params: GetQuizParams) {
    this.setTheQuizTokenInHeader();
    return this.http.post<Quiz>(API.QUIZ_API + 'get', params, this.httpOptions);
  }

  /**
   * Update quiz with results
   *
   * Remainder: Result key is retrieved from /quiz/get response
   *
   * POST /quiz/update
   * @param params
   * @param userToken optional param for usage with anon quiz
   * @param quizToken optional param for usage with anon quiz
   */
  updateQuizWithResult(params: UpdateQuizParams, userToken?: string, quizToken?: string) {
    this.setTheQuizTokenInHeader(userToken, quizToken);
    return this.http.post<Quiz>(API.QUIZ_API + 'update', params, this.httpOptions);
  }

  getQuizResults(params: any, result_key: string, userToken?: string, quizToken?: string): Observable<any> {
    this.setTheQuizTokenInHeader(userToken, quizToken);
    return this.http.post<any>(API.QUIZ_API + 'results/' + result_key, params, this.httpOptions);
  }

  /**
   * Submit quiz results
   *
   * POST/quiz/submit
   * @param params
   * @param userToken optional param for usage with anon quiz
   * @param quizToken optional param for usage with anon quiz
   */
  submitQuizResults(params: UpdateQuizParams, userToken?: string, quizToken?: string) {
    this.setTheQuizTokenInHeader(userToken, quizToken);
    return this.http.post<Quiz>(API.QUIZ_API + 'submit', params, this.httpOptions);
  }

  /**
   * Update assessment report after demographics update
   *
   * PATCH /submit
   * @param result_key assessment result key
   * @param userToken optional param for usage with anon quiz
   * @param quizToken optional param for usage with anon quiz
   */
  updateQuizResultsDemographics(result_key: string, userToken?: string, quizToken?: string) {
    this.setTheQuizTokenInHeader(userToken, quizToken);
    return this.http.patch<Quiz>(API.QUIZ_API + 'submit', {result_key: result_key}, this.httpOptions);
  }


  /**
   * -----TESTING ONLY--------
   *
   * Get the randomly generated answers for the quiz
   *
   *
   * @param userToken optional param for usage with anon quiz
   * @param quizToken optional param for usage with anon quiz
   */
  getRandomResults(quiz_id: string, locale: string, userToken?: string, quizToken?: string) {
    const httpOptions = this.auth.httpOptions;
    httpOptions.params = new HttpParams().set('locale', locale);

    this.setTheQuizTokenInHeader(userToken, quizToken);
    return this.http.get<{ [key: string]: string }>(API.QUIZ_API + 'random/' + quiz_id, httpOptions);
  }

  /**
   * We have to set this token only for quiz routes, because other ones don't allow another header
   */
  private setTheQuizTokenInHeader(userToken?: string, quizToken?: string) {
    this.httpOptions = this.auth.httpOptions;
    this.httpOptions.headers = {
      'QuizToken': quizToken || this.auth.quiz_token
    };
    if (userToken) this.httpOptions.headers['X-TOKEN'] = userToken;
  }

  getGameEmbedInfo(questionId: any, formId: any, locale?: string, userToken?: string, quizToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) options.headers = {'X-TOKEN': userToken};

    if (locale) {
      options.params = new HttpParams().set('locale', locale);
    }

    return this.http.get<any>(API.APIDOMAIN + `userassessments/form/${formId}/items/${questionId}`, options);
  }

  getGameResults(questionId: any, formId: any, locale?: string, userToken?: string, quizToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) options.headers = {'X-TOKEN': userToken};

    if (locale) {
      options.params = new HttpParams().set('locale', locale);
    }

    return this.http.put<any>(API.APIDOMAIN + `userassessments/form/${formId}/items/${questionId}`, {}, options);
  }

  getCatResults(questionId: any, formId: any, catResult: any, locale?: string, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) options.headers = {'X-TOKEN': userToken};

    if (locale) {
      options.params = new HttpParams().set('locale', locale);
    }

    return this.http.put<any>(API.APIDOMAIN + `userassessments/form/${formId}/items/${questionId}`, catResult, options);
  }

  filterGames(value: string, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) options.headers = {'X-TOKEN': userToken};

    return this.http.get<any>(API.APIDOMAIN + `skills-translator/games/${value}`, options);
  }
}

