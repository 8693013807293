import { Component, OnInit, Input } from '@angular/core';
import { SubmittedAssessment } from 'src/app/main/content/models/admin/results.def';

@Component({
  selector: 'ds-jqdiagnostic-details-card',
  templateUrl: './jqdiagnostic-details-card.component.html',
  styleUrls: ['./jqdiagnostic-details-card.component.scss']
})
export class JqdiagnosticDetailsCardComponent implements OnInit
{
  _result: SubmittedAssessment;
  @Input() set result(value: SubmittedAssessment)
  {
    this._result = value;

    console.groupCollapsed('JQ DIAGNOSTIC - DETAILS CARD');
    console.log(this.result);
    console.groupEnd();
  }
  get result() { return this._result; }

  constructor() { }

  ngOnInit()
  {
  }

}
