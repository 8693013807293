import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../main-service/sidenav.service';

@Component({
  selector: 'ds-right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss']
})
export class RightSidenavComponent implements OnInit
{

  constructor(
    private sidenavService: SidenavService
  )
  { }

  ngOnInit()
  {
  }

}
