import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseForgotPasswordComponent } from './forgot-password.component';
import { ForgotPasswordDesign1Component } from './forgot-password-design1/forgot-password-design1.component';
import { ForgotPasswordDesign2Component } from './forgot-password-design2/forgot-password-design2.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/pages/auth/forgot-password`,
    component: FuseForgotPasswordComponent
  },
  {
    path: 'pages/auth/forgot-password',
    component: FuseForgotPasswordComponent
  }
];

@NgModule({
  declarations: [
    FuseForgotPasswordComponent,
    ForgotPasswordDesign1Component,
    ForgotPasswordDesign2Component
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ]
})

export class ForgotPasswordModule {

}
