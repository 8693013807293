import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { IMSection } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-incompetent-men-user-details-card',
  templateUrl: './incompetent-men-user-details-card.component.html',
  styleUrls: ['./incompetent-men-user-details-card.component.scss']
})
export class IncompetentMenUserDetailsCardComponent implements OnInit
{
  private _user: UserModel;
  @Input() set user(v: UserModel)
  {
    this._user = v;
  }
  get user() { return this._user; }


  private _profilePicture;
  @Input() set profilePicture(v: string)
  {
    this._profilePicture = v;
  }
  get profilePicture() { return this._profilePicture; }

  private _data: IMSection;
  @Input() set data(v: IMSection)
  {
    this._data = v;
  }
  get data() { return this._data; }


  get isAnon() { return this.user.status === 'ANONYMOUS'; }

  constructor() { }

  ngOnInit()
  {
  }

}
