export function copyToClipboard(text: string): boolean {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  const isCopied = document.execCommand('copy');
  document.body.removeChild(el);

  return isCopied;
}


export function deepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}


/**
 * Create an anchor element and initiate a file download.
 *
 * @param data
 * @param fileType example: 'text/csv'
 * @param title needs to end with an extension
 */
export function downloadFile(data: any, fileType: string = 'text', title: string) {
  // check if filename starts and ends with quotes, and trim them if it does
  if(title.startsWith('"') && title.endsWith('"')){
    title = title.slice(1, -1);
  }
  // Create anchor element
  const a = window.document.createElement('a');
  // Set data object as download value
  a.href = window.URL.createObjectURL(new Blob([ data ], {type: fileType}));
  // Set title
  a.download = title;
  // Append anchor to body.
  document.body.appendChild(a);
  a.click();
  // Remove anchor from body
  document.body.removeChild(a);
}

export const isGearSubdomain = window.location.hostname.includes('gear.');
// For Gear subdomain testing purpose only
//export const isGearSubdomain = true;
