import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from 'src/app/api-url/api-urls';
import { ShareService } from '../../../../share/share-services/share.service';
import { Dimension, Report } from '../tsq-results/tsq-results.component';

@Component({
  selector: 'tsq-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit, OnDestroy {
  aggregateResponse: Dimension;
  acquiringHiringTalent: Dimension;
  engagingEvolvingTalent: Dimension;
  improvingCultureRetention: Dimension;
  chart: string;
  svg: null | string = null;
  contentLength: number = 0;


  subs: Subscription;

  @Input()
  set report(value: Report) {
    this.aggregateResponse = value.score.scores.calculate.dimension.aggregateResponse;
    this.acquiringHiringTalent = value.score.scores.calculate.dimension.acquiringHiringTalent;
    this.engagingEvolvingTalent = value.score.scores.calculate.dimension.engagingEvolvingTalent;
    this.improvingCultureRetention = value.score.scores.calculate.dimension.improvingCultureRetention;
    this.chart = this.aggregateResponse.chart;
  }

  private calculatePageContentLength() {
    this.contentLength = 0;
    this.contentLength += this.aggregateResponse.description.length;
    this.contentLength += this.acquiringHiringTalent.title.length;
    this.contentLength += this.acquiringHiringTalent.ratinglongdescription.length;
    this.contentLength += this.engagingEvolvingTalent.title.length;
    this.contentLength += this.engagingEvolvingTalent.ratinglongdescription.length;
  }

  private handle() {
    this.calculatePageContentLength();


    const MAX_NUM = 1400;

    if (this.contentLength >= MAX_NUM) return;

    const numOfSpaces = MAX_NUM - this.contentLength;
    this.engagingEvolvingTalent.ratinglongdescription += '<p>';
    for (let i = 0; i < numOfSpaces; i++) {
      this.engagingEvolvingTalent.ratinglongdescription += '&nbsp;';
      if (i % 50 === 0) {
        this.engagingEvolvingTalent.ratinglongdescription += '<br />';
      }
    }
    this.engagingEvolvingTalent.ratinglongdescription += '</p>';
  }

  constructor( public sharedService: ShareService ) {
  }

  ngOnInit() {

    this.aggregateResponse.href = this.aggregateResponse.href.replace('%media_url%', API.MEDIA_API);

    this.subs = this.sharedService.downloadSvg(this.aggregateResponse.href).pipe(
      map( svg => this.svg = svg )
    ).subscribe();
  }

  ngOnDestroy(): void {
    if(this.subs) this.subs.unsubscribe();
  }

}
