import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { environment } from 'src/environments/environment';
import { combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import {
  ConditionType,
  QuestionType,
  Quiz,
  QuizHeader,
  QuizQuestion,
  RenderType
} from 'src/app/main/content/models/user/quiz.model';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { AssesmentService } from 'src/app/main/content/services/user/assesment.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { QuizSystemService } from 'src/app/main/content/services/quiz/quiz-system.service';
import { UpdateQuizParams } from 'src/app/main/content/models/quiz/quiz.model';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ROLE_DEVELOPER } from 'src/app/constants/user.role';
import { API } from 'src/app/api-url/api-urls';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RestartAssessmentComponent } from '../../dialogs/restart-assessment/restart-assessment.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { CatGameEmbedComponent } from '../../dialogs/cat-game-embed/cat-game-embed.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslocoService } from '@ngneat/transloco';
import { DemographicsService } from 'src/app/main/content/services/user/demographics.service';
import { KeyValue } from '@angular/common';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { AppStore } from 'src/app/app.store';
import { MatSelectChange } from '@angular/material';

@Component({
  selector: 'ds-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.15s ease', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class QuizComponent extends BaseRouteParamComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroySubject: Subject<void> = new Subject<void>();
  showArrows: boolean = false;
  pending: boolean = false;
  incognito$: Observable<boolean>;
  showCatGame: boolean;
  private showResultsPageCounter: number = 0;

  assessmentWithCat: boolean =  false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected helper: HelperService,
    protected quizService: QuizSystemService,
    protected auth: AuthService,
    public themingService: ThemingService,
    protected platform: Platform,
    protected assessmentService: AssesmentService,
    protected routerParams: RouterParamsService,
    protected breakpointObserver: BreakpointObserver,
    protected errService: ErrorService,
    protected userService: UsersService,
    protected sanitizer: DomSanitizer,
    protected dialog: MatDialog,
    protected anonService: AnonUserService,
    protected translocoService: TranslocoService,
    protected demoService: DemographicsService,
    public appStore: AppStore
  ) {
    super(routerParams);

    this.translocoService.langChanges$.subscribe(lang => console.log('My language is: ', lang));
    this.isInternetExplorer = !!window.navigator.userAgent.match(/Trident/);
    this.showTestingButtons = !environment.production || (this.auth && this.auth.user && this.auth.user.roles && this.auth.user.roles.includes(ROLE_DEVELOPER));
    this.media_url = API.MEDIA_API;
    this.incognito$ = appStore.incognito$;

  }

  get currentHeader(): QuizHeader {
    return this.quiz.result.quiz.fields[this.headerIndex];
  }

  get currentQuestion(): QuizQuestion {
    return this.quiz.result.quiz.fields[this.headerIndex].fields[this.questionIndex];
  }

  get resultKey(): string {
    return localStorage.getItem('result_key');
  }

  get quizHeader() {
    return this.currentQuestion.fields;
  }


  set resultKey(value: string) {
    localStorage.setItem('result_key', value);
  }

  get destroy$() {
    return this.destroySubject.asObservable();
  }


  Num = Number;
  loading = false;
  imageLoader = true;
  submitting = false;
  isSaving = false;
  switchingHeader = false;
  /** Set to false while handling saving of the answer result */
  canChangeIndex = true;

  @HostBinding('class.page-background') pageBackground: boolean = false;

  showQuestionNumber = false;
  showFieldHeaders = true;

  isEnd = false;
  hasReachedEnd = false;
  previousQuestionDisplayed = false;

  quizId: string;

  currentQuestionNumber: number = 1;
  totalHeaders = 0;
  totalQuestions = 0;

  questionIndex: number = 0;
  headerIndex: number = 0;

  allQuestionsAnswered = true;
  unansweredIndexies: number[] = [];

  subscriptions: Subscription = new Subscription();

  private _quiz: Quiz;
  @Input()
  set quiz(value: Quiz) {
    console.log('quiz', value);
    this._quiz = value;
  }

  get quiz(): Quiz {
    return this._quiz;
  }
  questionTypes = QuestionType;
  renderTypes = RenderType;
  savedAnswers: { [key: string]: string | number | { value: string | string[], text: string, opened_at: string } | string[] } | any = {};

  /** Used to check if the value has changed so update isn't called if the value is the same */
  previousValue: string | number | string[];

  listOfUnAnsweredQuestions: {
    headerIndex: number;
    questionIndex: number;
  }[] = [];

  isLandscape = false;
  isProduction = environment.production;
  showTestingButtons = false;
  isInternetExplorer = false;
  isAnonQuiz = false;
  /** Used for anon quiz */
  showDemographics = false;

  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('gameInput', { static: false }) gameInput: ElementRef<HTMLInputElement>;

  @Output() quizSubmitted = new EventEmitter<boolean>();

  /** Timeout variable */
  questionPromptTimer;
  /** Timeout time in seconds */
  promptTime = 10;
  /** Whether or not to show the prompt */
  showPrompt = false;
  /** Whether or not to show the prompt again */
  promptDontShowAgain = false;
  media_url: string;
  selectedLanguage: boolean = false;
  locale: string;
  hiddenFields: any;
  countries: any;

  game = {
    candidateId: <string> '',
    webToken: <string> '',
    invitationId: <string> '',
    assessmentId: <string> '',
    src: <string> '',
    language: <string> ''
  };
  isGameLoaded = false;
  gameCompleted = false;
  isLoading = false;

  timerSub: Subscription;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  gamesControl = new FormControl('');
  filteredGames: string[] = [];
  selectedGames: string[] = [];
  loadingGames: boolean = false;
  stgAnswerKey: string = null;
  privacyCheck: boolean = false;

  counter = 0;


  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.quizId = this.route.snapshot.params.id;

    if (this.quiz) {
      this.initData();
    } else {

      this.helper.showAlert('Quiz not loaded correctly');
      this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/dashboard']);
    }

    const layoutChanges = this.breakpointObserver.observe(['(orientation: landscape)']);
    // Subscribe to orientation change event
    layoutChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {
      this.checkOrientation(result);
    });

    this.pageBackground = this.handleCustomAssessmentStyles();
  }


  ngAfterViewInit() {
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  protected idleTimer() {
    if (this.quiz.result.hidden_fields.conference_mode) {
      if (this.timerSub) {
        this.timerSub.unsubscribe();
      }

      const source = timer(Number(this.quiz.result.hidden_fields.conference_mode) * 1000);
      this.timerSub = source.subscribe(value => {
        this.timerSub.unsubscribe();
        const dialogRef = this.dialog.open(RestartAssessmentComponent, {
          data: Number(this.quiz.result.hidden_fields.conference_mode)
        });

        dialogRef.afterClosed().subscribe(result => {
          result ? this.idleTimer() : this.restartAssessment();
        });
      });
    }
  }


  protected restartAssessment() {
    this.savedAnswers = {};
    let userToken = '', quizToken = '';
    const updateParams: UpdateQuizParams = {
      hidden_fields: this.hiddenFields,
      quiz_key: this.quizId,
      result_key: this.resultKey,
      result: this.savedAnswers,
      locale: this.locale
    };

    if (!this.isAnonQuiz) {
      userToken = this.auth.user.token_key;
      quizToken = this.auth.quiz_token;
    } else {
      userToken = this.anonService.anonToken;
      quizToken = this.anonService.anonQuizToken;
    }

    this.quizService.updateQuizWithResult(updateParams, (this.isAnonQuiz ? userToken : null), (this.isAnonQuiz ? quizToken : null)).subscribe(res => {
      this.quiz.result = res.result;
      this.headerIndex = 0;
      this.questionIndex = 0;
      this.currentQuestionNumber = 1;
      this.initData();
      this.jumpToHeader(0);
      if (this.timerSub) {
        this.timerSub.unsubscribe();
      }
    }, err => {
      this.errService.handleHttpErrorForLogedInUser(err);
    });
  }

  /**
   * Checks the orientation of the window and sets the isLandscape accordingly
   */
  protected checkOrientation(state: BreakpointState) {
    if (window.screen && window.screen.orientation && window.screen.orientation.angle && window.screen.orientation.angle !== 0) {
      this.isLandscape = true;
    } else {
      this.isLandscape = false;
    }
  }


  get progress(): number {
    const totalQuestions = this.quiz.result.quiz.fields.length;
    return (this.questionIndex / totalQuestions) * 100;
  }

  protected initData() {
    if (!this.quizId) {
      this.quizId = this.quiz.result.quiz.quiz_key;
    }

    this.locale = this.quiz.locale;
    this.resultKey = this.quiz.result.result_key;

    this.showFieldHeaders = this.quiz.result.quiz.properties.show_field_headers;
    this.showQuestionNumber = this.quiz.result.quiz.properties.show_question_number;

    this.totalHeaders = this.quiz.result.quiz.fields.length;
    this.totalQuestions = this.quiz.result.quiz.fields.reduce((tot, h) => tot + h.fields.length, 0);

    this.hiddenFields = this.quiz.result.hidden_fields;

    // check if assessment has CAT game
    this.assessmentWithCat = this.hiddenFields.game === 'sort:95' ? true : false;

    const hasResults = Object.entries(this.quiz.result_answers).length > 0;

    this.initOtherOptionFields();

    if (hasResults) {
      console.log('hasResults: ', hasResults);
      // this.handleLanguageSelect(this.locale);
      this.preloadExistingAnswers();
    }

    this.loadUnanswerdRequiredQuestions();

    if (hasResults) {
      this.continueQuizFromLastAnsweredQuestion();
    }

    this.handlePrompTimer();

    this.getCountries();

    this.loading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  protected handlePrompTimer() {
    let currentTime: string;
    this.stgAnswerKey = null;

    currentTime = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS');

    this.currentQuestion.opened_at = currentTime;

    console.groupCollapsed(`Question key: ${ this.currentQuestion.field_key }`);

    if (this.currentQuestion.selectedAnswer && this.quiz.theme === 'stg_assessment-quiz-theme') {
      this.stgAnswerKey = this.currentQuestion.properties.labels[this.currentQuestion.selectedAnswer.toString()]['id'];
    }

    if (this.currentQuestion.type === 'embed') {
      this.isGameLoaded = false;
      this.isLoading = false;
    }

    if (this.currentQuestion.properties.parser) {
      if (!this.selectedGames[this.currentQuestion.properties.parser[0].with]) {
        this.end();
      }
    }

    if (this.currentQuestion.type === this.questionTypes.autocompleteMultiple) {
      this.gamesControl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
        if (!value) return this.loadingGames = false;
        this.loadingGames = true;

        this.quizService.filterGames(value).subscribe(res => {
          this.filteredGames = res;
          this.loadingGames = false;
        });
      });
    }

    // only while promptDontShowAgain is set to false do we continue to init new timers
    if (this.promptDontShowAgain === false && (this.currentQuestion.properties && this.currentQuestion.properties.timeout_help_popup)) {
      clearTimeout(this.questionPromptTimer);
      this.showPrompt = false;

      this.questionPromptTimer = setTimeout(() => {
        this.showPrompt = true;
      }, (this.currentQuestion.properties.timeout_help_popup.seconds || this.promptTime) * 1000);
    } else {
      this.showPrompt = false;
      clearTimeout(this.questionPromptTimer);
    }

    console.groupEnd();
  }

  handleCustomAssessmentStyles() {
    return this.quiz.theme === 'stg_assessment-quiz-theme';
  }

  getCountries() {
    this.demoService.country.getAllCountries().subscribe(res => {
      this.countries = res;
    });
  }


  remove(group: string): void {
    const index = this.selectedGames.indexOf(group);
    const g = this.selectedGames[index];

    if (index >= 0) this.selectedGames.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;

    if (this.selectedGames.includes(value)) {
      this.gameInput.nativeElement.value = '';
      return;
    }

    if (!value.length) return;
    this.selectedGames.push(value);

    this.gameInput.nativeElement.value = '';
  }

  handleGameRelatedQuestionTitle(title: string, oldValue: string, newValue: number) {
    let newStr = '';
    switch (newValue) {
      case 0:
        newStr = `<span style="color: #56bded !important;">${ this.selectedGames[newValue] }</span>`;
        break;
      case 1:
        newStr = `<span style="color: #FF9622 !important;">${ this.selectedGames[newValue] }</span>`;
        break;
      case 2:
        newStr = `<span style="color: #8fc3a9 !important;">${ this.selectedGames[newValue] }</span>`;
        break;
      default:
        break;
    }

    return this.sanitizer.bypassSecurityTrustHtml(title.replace(oldValue, newStr));
  }

  handleBorderColorStg(currentGameNun: number) {
    switch (currentGameNun) {
      case 0:
        return '2px solid #56bded';
        break;
      case 1:
        return '2px solid #FF9622';
        break;
      case 2:
        return '2px solid #8fc3a9';
        break;
      default:
        break;
    }
  }

  /**
   * Switches the question index to the next question.
   */
  protected nextQuestion() {
    this.imageLoader = true;

    if (this.canChangeIndex) {
      this.questionIndex++;
      this.currentQuestionNumber++;

      const currentQuestionValue =
        this.currentQuestion.showOther.show ?
          this.currentQuestion.showOther.otherValue :
          this.currentQuestion.selectedAnswer;
      this.previousValue = currentQuestionValue;

      this.checkIfQuestionIsConditional('next');

      this.handlePrompTimer();
    }
  }

  /**
   * Switches the question index to the previous question.
   */
  protected previousQuestion() {
    this.imageLoader = true;

    if (this.canChangeIndex) {
      this.questionIndex--;
      this.currentQuestionNumber--;

      this.previousValue = this.currentQuestion.showOther.show ?
        this.currentQuestion.showOther.otherValue :
        this.currentQuestion.selectedAnswer;

      this.checkIfQuestionIsConditional('previous');

      this.handlePrompTimer();
    }
  }

  /**
   * Switches the header index to the next header
   */
  protected nextHeader() {
    if (this.canChangeIndex) {
      this.headerIndex++;
      this.currentQuestionNumber++;
      // Once the header changes, we need to set the question index to 0 (the first question under this header)
      this.questionIndex = 0;

      const currentQuestionValue =
        this.currentQuestion.showOther.show ?
          this.currentQuestion.showOther.otherValue :
          this.currentQuestion.selectedAnswer;
      this.previousValue = currentQuestionValue;

      this.checkIfQuestionIsConditional('next');

      this.handlePrompTimer();
    }
  }

  /**
   * Switches the header index to the previous header
   */
  protected previousHeader() {
    if (this.canChangeIndex) {
      this.headerIndex--;
      this.currentQuestionNumber--;
      // Once the header changes, we need to set the question index to the last question in this header
      this.questionIndex = this.currentHeader.fields.length - 1;

      this.previousValue = this.currentQuestion.showOther.show ?
        this.currentQuestion.showOther.otherValue :
        this.currentQuestion.selectedAnswer;

      this.checkIfQuestionIsConditional('previous');

      this.handlePrompTimer();
    }
  }

  langSelectChange(event: MatSelectChange): void {

    this.handleLanguageSelect(event.value);
  }

  handleLanguageSelect(lang: string) {

    this.loading = true;

    this.locale = lang;

    this.userService.changeAppLanguage(this.locale);

      if (!this.isAnonQuiz) {

        this.assessmentService.getAssessmentForm(this.quizId, this.locale).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this.quiz = res;
          console.log('Quiz', res);
  	      this.userService.changeAppLanguage(this.locale);
          this.selectedLanguage = true;
          this.initData();
        }, err => {
          this.errService.handleHttpErrorForLogedInUser(err);
        });

      } else {
        this.assessmentService.getAnonAssessmentForm(this.anonService.assessment.assessment.form_id, this.anonService.anonToken, this.locale).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this.quiz = res;
          console.log('Quiz', res);
          this.selectedLanguage = true;
          this.translocoService.setActiveLang(this.locale);
          this.initData();
        }, err => {
          this.errService.handleHttpErrorForLogedInUser(err);
        });
      }
  }

  previousNavigation() {
    if (this.quiz.theme === 'stg_assessment-quiz-theme') return false;

    return (this.previousQuestionDisplayed || this.isGameLoaded);
  }

  nextNavigation() {
    if (this.quiz.theme === 'stg_assessment-quiz-theme') return false;

    return !this.previousQuestionDisplayed;
  }

  startQuiz(userAssessmentId: number) {
    this.assessmentService.setAssessmentStartedAtStatus(userAssessmentId).pipe(take(1)).subscribe();
    this.next();

  }

  /** Switches to next question or header */
  next() {
    if (this.quiz.theme === 'stg_assessment-quiz-theme' && !this.privacyCheck) return;
    this.previousQuestionDisplayed = false;
    this.isGameLoaded = false;
    this.isLoading = false;
    this.idleTimer();

    if (this.checkIfNextQuestionIndexIsInHeader()) {
      this.nextQuestion();
    } else if (this.checkIfNextHeaderExists()) {
      this.nextHeader();
    } else {
      if (!this.isEnd) {
        this.end();
      }
    }


    if (this.currentQuestion.type === 'embed' && this.counter === 0) {
      this.getGameEmbedInfo().pipe(
        map((res) => {
          console.log('getGameEmbedInfo', res);
          return res.hasOwnProperty('embed');
        }),
        tap((isCat) => {
          this.counter++;
          this.showCatGame = isCat;
          if (!isCat) {
            this.next();
          } else {
            this.isLoading = false;
          }
        })
      ).subscribe();
    }
  }


  /** Switches to previous question or header */
  previous() {
    this.previousQuestionDisplayed = true;
    this.idleTimer();

    if (this.isEnd) {
      this.isEnd = false;
    } else if (this.checkIfPreviousQuestionIndexIsInHeader()) {
      this.previousQuestion();
    } else if (this.checkIfPreviousHeaderExists()) {
      this.previousHeader();
    } else // Keeps the user on first question and header
    {
      this.headerIndex = 0;
      this.questionIndex = 0;
      this.currentQuestionNumber = 1;
    }
  }

  /**
   * Everything that happens when user reaches end of quiz
   */
  protected end() {
    this.loadUnanswerdRequiredQuestions();
    this.isEnd = true;
    this.hasReachedEnd = true;
    if (this.listOfUnAnsweredQuestions.length > 0) // Means that some required question are still unanswered
    {
      this.allQuestionsAnswered = false;
    } else {
      if (!this.isAnonQuiz) {
        this.submitAnswers();
      }
    }
  }

  /**
   * Check if the index of the next question is within the headers fields
   *
   * @returns true if question index is within headers fields meaning we can switch to the next question
   */
  protected checkIfNextQuestionIndexIsInHeader(): boolean {
    if ((this.questionIndex + 1) < this.currentHeader.fields.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check if the index of the prevoius question is within the headers fields
   *
   * @returns true if question index is within headers fields meaning we can switch to the prevoius question
   */
  protected checkIfPreviousQuestionIndexIsInHeader(): boolean {
    if ((this.questionIndex - 1) < this.currentHeader.fields.length && (this.questionIndex - 1) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check if the index of the next header exists
   *
   * @returns true if header index exists meaning it is possible to switch to the next header
   */
  protected checkIfNextHeaderExists(): boolean {
    if ((this.headerIndex + 1) >= this.totalHeaders) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check if the index of the prevoius header exists
   *
   * @returns true if header index exists meaning it is possible to switch to the previous header
   */
  protected checkIfPreviousHeaderExists(): boolean {
    if ((this.headerIndex - 1) < 0) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Checks if current question has a condition object
   * and takes appropriate actions
   */
  protected checkIfQuestionIsConditional(direction: 'next' | 'previous') {
    if (this.currentQuestion.properties.condition) {
      // Find the question that current question depends on
      const requiredQuestion = this.findQuestionByFieldKey(this.currentQuestion.properties.condition.condition_value);

      const skipThisQuestion = () => {
        this.canChangeIndex = true; // Enable the question changing

        // If the required question does not have a value, skip current question
        direction === 'next' ? this.next() : this.previous();
      };

      if (requiredQuestion) // check if question exists
      {
        switch (this.currentQuestion.properties.condition.condition) {
          // If the conditional question has a required question that must not be empty
          // Check if that question has value inputed
          case ConditionType.empty:
            if (requiredQuestion && !requiredQuestion.selectedAnswer) {
              skipThisQuestion();
            }
            break;
          default:
            break;
        }
      } else // skip this question if the conditional question was not found
      {
        skipThisQuestion();
      }
    }
  }

  /**
   * Finds and returns a question object by it's field_key value
   * @param key
   */
  protected findQuestionByFieldKey(key: string): QuizQuestion | null {
    const headers = this.quiz.result.quiz.fields;
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].fields.find(q => q.field_key === key)) {
        return headers[i].fields.find(q => q.field_key === key);
      }
    }

    return null;
  }

  /**
   * Changes the header index to the clicked header's index
   *
   * Also changes the question to the first one in that header.
   * @param index
   */
  jumpToHeader(index: number) {
    if (!this.switchingHeader) {
      this.switchingHeader = true;
      // We have to do it this way so the question number gets updated aswell
      while (this.headerIndex !== index) {
        if (this.headerIndex > index) {
          this.previous();
        } else {
          this.next();
        }
      }

      // We also need to reset the question to the first one for the header
      while (this.questionIndex !== 0) {
        this.previous();
      }

      // Settimeout prevents crashing if jump to header is called one too many times
      setTimeout(() => {
        this.switchingHeader = false;
      }, 250);
    }
  }

  /**
   * Does a similar thing as jumpToHeader method.
   * Except this also changes to the passed question.
   * @param headerIndex
   * @param questionIndex
   */
  jumpToQuestion(headerIndex: number, questionIndex: number) {
    if ((this.headerIndex === headerIndex && this.questionIndex === questionIndex)) {
      this.isEnd = false;
    }

    // We have to do it this way so the question number gets updated aswell
    while (this.headerIndex !== headerIndex) {
      if (this.headerIndex > headerIndex) {
        this.previous();
      } else {
        this.next();
      }
    }

    // We also need to reset the question to the first one for the header
    while (this.questionIndex !== questionIndex) {
      if (this.questionIndex > questionIndex) {
        this.previous();
      } else {
        this.next();
      }
    }
  }

  /**
   * Jump to the end screen
   */
  jumpToEnd() {
    if (this.currentQuestion.type === 'embed') {
      this.isGameLoaded = false;
      this.isLoading = false;
    }

    this.headerIndex = this.totalHeaders - 1;
    this.questionIndex = this.quiz.result.quiz.fields[this.headerIndex].fields.length - 1;
    this.currentQuestionNumber = this.totalQuestions;
  }

  moveToNextQuestionOnEnterPress(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) // If enter is pressed, move user to next question
    {
      if (this.canChangeIndex) {
        this.canChangeIndex = false;
        this.saveAnswers();
        setTimeout(() => {
          this.canChangeIndex = true;
          if (this.hasReachedEnd) {
            this.takeUserToFirstUnansweredQuestion();
          } else {
            this.next();
          }
        }, 100);
      }
    }
  }

  /**
   * Selectes the answer for the question
   * @param question
   * @param value
   * @param changeQuestionOnSelect
   */
  selectAnswer(question: QuizQuestion, value: string | number | string[], changeQuestionOnSelect: boolean = true) {

    if (this.canChangeIndex) {
      this.canChangeIndex = false;


      if (Array.isArray(value) && value.includes('option-other') && !question.showOther.show) {
        changeQuestionOnSelect = false;
        question.showOther.show = true;
        question.selectedAnswer = ['option-other'];
      } else if (value === 'option-other' && !question.showOther.show) {
        changeQuestionOnSelect = false;
        question.showOther.show = true;
        question.selectedAnswer = 'option-other';
      } else {
        if (Array.isArray(value)) {
          value = this.clearOptionOther(value);
        }

        question.showOther.show = false;
        question.selectedAnswer = value;

        if (question.type === 'multiple_choice') {
          question.properties.choices.filter(choice => {
            if (choice.label === value) {
              this.savedAnswers[question.field_key] = Array.isArray(choice.id) ? {
                value: choice.id.toString(),
                text: choice.label,
                opened_at: this.currentQuestion.opened_at
              } : { value: choice.id, text: choice.label, opened_at: this.currentQuestion.opened_at }; // Save the answer to savedAnswers
            }
          });
        } else if (question.type === 'opinion_scale_label') {
          console.log('value', value);
          console.log('id', question.properties.labels[Number(value)]);
          question.selectedAnswer = question.properties.labels[Number(value)]['id'];
          this.savedAnswers[question.field_key] = {
            value: question.properties.labels[Number(value)]['id'],
            text: question.properties.labels[Number(value)]['label'],
            opened_at: this.currentQuestion.opened_at
          }; // Save the answer to savedAnswers
        } else if (question.type === this.questionTypes.autocompleteMultiple) {
          this.savedAnswers[question.field_key] = {
            value: this.selectedGames,
            text: '',
            opened_at: this.currentQuestion.opened_at
          };
        } else {
          if (question.properties.labels[Number(value)]) {
            this.savedAnswers[question.field_key] = Array.isArray(value) ? {
              value: value.toString(),
              text: question.properties.labels[Number(value)],
              opened_at: this.currentQuestion.opened_at
            } : {
              value: value.toString(),
              text: question.properties.labels[Number(value)],
              opened_at: this.currentQuestion.opened_at
            }; // Save the answer to savedAnswers
          } else {
            this.savedAnswers[question.field_key] = Array.isArray(value) ? {
              value: value.toString(),
              text: null,
              opened_at: this.currentQuestion.opened_at
            } : { value: value.toString(), text: null, opened_at: this.currentQuestion.opened_at }; // Save the answer to savedAnswers
          }
        }
        this.removeAnswerFromUnansweredArray(this.headerIndex, this.questionIndex);
        this.saveAnswers();
      }

      setTimeout(() => {
        this.canChangeIndex = true;
        if (changeQuestionOnSelect) {
          if (this.hasReachedEnd) {
            this.takeUserToFirstUnansweredQuestion();
          } else {
            this.next();
          }
        }
      }, 500);
    }
  }

  /**
   * Handles the event for text input fields
   * @param question
   * @param event
   */
  handleTextInputAnswer(question: QuizQuestion, event: any) {
    const inputElement: HTMLInputElement = (<HTMLInputElement> event.target);

    if (question.showOther && question.showOther.show) {
      question.showOther.otherValue = inputElement.value.trim();
      this.savedAnswers[question.field_key] = inputElement.value.trim(); // Save the answer to savedAnswers

      this.removeAnswerFromUnansweredArray(this.headerIndex, this.questionIndex);
    } else if (inputElement.value.trim()) {
      question.selectedAnswer = inputElement.value.trim();
      this.savedAnswers[question.field_key] = inputElement.value.trim(); // Save the answer to savedAnswers

      this.removeAnswerFromUnansweredArray(this.headerIndex, this.questionIndex);
    }
  }

  /**
   * Handles the event for text input fields
   * @param question
   * @param event
   */
  handleNumberInputAnswer(question: QuizQuestion, event: any) {
    const inputElement: HTMLInputElement = (<HTMLInputElement> event.target);
    const value = inputElement.valueAsNumber; /* Math.round(inputElement.valueAsNumber); */

    if (this.validateNumber(value, question.properties.validations.max_value)) {
      question.selectedAnswer = value;
      this.savedAnswers[question.field_key] = value; // Save the answer to savedAnswers

      this.removeAnswerFromUnansweredArray(this.headerIndex, this.questionIndex);
    } else if (value > question.properties.validations.max_value) {
      inputElement.value = question.properties.validations.max_value.toString();

      question.selectedAnswer = question.properties.validations.max_value;
      this.savedAnswers[question.field_key] = question.properties.validations.max_value; // Save the answer to savedAnswers
      this.removeAnswerFromUnansweredArray(this.headerIndex, this.questionIndex);
    }
  }

  /**
   * Validates the number input
   *
   * @param value The value that user inputed
   * @param max_number max value allowed
   * @returns true if validation passed
   */
  protected validateNumber(value: number, max_number: number): boolean {
    const errorElement: HTMLElement = document.getElementById('numberError' + this.currentQuestionNumber);
    if (value <= max_number && value >= 0) {
      errorElement.style.setProperty('display', 'none');
      return true;
    } else {
      errorElement.style.setProperty('display', 'block');
      return false;
    }
  }


  submitAnswers(userToken?: string, userID?: number, formID?: string, quizToken?: string) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    this.submitting = true;

    const updateParams: UpdateQuizParams = {
      hidden_fields: this.hiddenFields,
      quiz_key: formID || this.quizId,
      result_key: this.resultKey,
      result: this.savedAnswers,
      locale: this.locale
    };

    const obj = {
      form_id: formID || this.quizId,
      locale: this.locale
    };

    const submitQuizResults$ = this.quizService.submitQuizResults(updateParams, userToken, quizToken);

    combineLatest([submitQuizResults$]).subscribe(res => {
      this.submitting = false;
      this.quizSubmitted.emit(true);
    }, err => {
      this.submitting = false;
      this.errService.handleHttpErrorForLogedInUser(err);
    });

  }

  /**
   * Calls the /update api to save the answers
   */
  saveAnswers(userToken?: string, userID?: number, formID?: string, quizToken?: string) {
    const currentQuestionValue =
      this.currentQuestion.showOther.show ?
        this.currentQuestion.showOther.otherValue :
        this.currentQuestion.selectedAnswer;

    if (this.previousValue === currentQuestionValue) {
      return;
    }

    if (this.currentQuestion.properties.user_profile) {
      const profileData = {
        user_id: !this.isAnonQuiz ? this.auth.user.id : this.anonService.anonUser.id
      };

      profileData[this.currentQuestion.properties.user_profile] = Number(this.currentQuestion.properties.choices.filter(e => e.label === currentQuestionValue).pop().id);

      if (!this.isAnonQuiz) {
        this.subscriptions.add(
          this.userService.updateProfile(profileData.user_id, profileData).subscribe((res: any) => {

          }, err => {

            this.errService.handleHttpErrorForLogedInUser(err);
          })
        );
      } else {
        this.subscriptions.add(
          this.userService.updateProfile(profileData.user_id, profileData, this.anonService.anonToken).subscribe((res: any) => {

          }, err => {
            this.errService.handleHttpErrorForLogedOutUser(err);
          })
        );
      }
    }

    if (!this.isSaving && currentQuestionValue) {
      this.isSaving = true;

      const updateParams: UpdateQuizParams = {
        hidden_fields: this.hiddenFields,
        quiz_key: formID || this.quizId,
        result_key: this.resultKey,
        result: this.savedAnswers,
        locale: this.locale
      };

      this.quizService.updateQuizWithResult(updateParams, userToken, quizToken).subscribe(res => {
        this.isSaving = false;
      }, err => {
        this.errService.handleHttpErrorForLogedInUser(err);
      });

    }
  }

  /**
   * Pre-selects all the question with saved answers
   */
  protected preloadExistingAnswers() {
    this.savedAnswers = this.quiz.result_answers;
    // Loop over every question
    Object.entries(this.quiz.result_answers).forEach(([key, value]) => {
      this.quiz.result.quiz.fields.forEach(header => {
        header.fields.forEach(q => {
          // If current question has an answer, set the selected answer
          if (q.field_key === key) {
            const isArray = q.properties.allow_multiple_selection;
            // check if question allows for other-types
            if (q.properties.allow_other_choice) {
              const possibleChoices: string[] = q.properties.choices.map(c => c.id);
              const selectedChoices: string[] = isArray ? value['value'].split(',') : [value['value']];
              let match = false;
              // see if the saved answer matches any of the existing answers
              for (const choice of selectedChoices) {
                if (possibleChoices.includes(choice)) {
                  match = true;
                } else {
                  match = false;
                  break;
                }
              }


              // if a match is found, that means that the saved answer is one of the choices
              if (match) {
                if (q.properties.choices) {
                  q.properties.choices.forEach(choice => {
                    if (choice.id === value['value']) {
                      q.selectedAnswer = isNaN(Number(choice.label)) ? (isArray ? choice.label.split(',') : choice.label) : Number(choice.label);
                      this.savedAnswers[key]['text'] = choice.label;
                    }
                  });
                } else {
                  q.selectedAnswer = isNaN(Number(value['value'])) ? (isArray ? value.split(',') : value) : Number(value['value']);
                }
              } else // otherwise, init the `showOther` object with the answer and set the selected answer to the other-option
              {
                q.selectedAnswer = isArray ? ['option-other'] : 'option-other';
                q.showOther = {
                  show: true,
                  otherValue: value
                };
              }
            } else {
              if (q.type === this.questionTypes.opinionScaleLabel) {
                q.selectedAnswer = value['value'];
                this.savedAnswers[key]['text'] = value['text'];
              } else if (q.properties.choices) {
                q.properties.choices.forEach(choice => {
                  if (choice.id === value['value']) {
                    q.selectedAnswer = isNaN(Number(choice.label)) ? (isArray ? choice.label.split(',') : choice.label) : Number(choice.label);
                    this.savedAnswers[key]['text'] = choice.label;
                  }
                });
              } else {
                q.selectedAnswer = isNaN(Number(value['value'])) ? (isArray ? value['value'].split(',') : value['value']) : Number(value['value']);
              }
            }
          }
        });
      });
    });
  }

  /**
   * Makes the list of required question that are still unanswered
   */
  protected loadUnanswerdRequiredQuestions() {
    this.listOfUnAnsweredQuestions = [];

    this.quiz.result.quiz.fields.forEach((header, h_index) => {
      header.fields.forEach((q, q_index) => {
        // Check if the question that is required, is unanswered
        if (
          q.properties.validations &&
          q.properties.validations.required &&
          q.type !== this.questionTypes.autocompleteMultiple &&
          (!q.selectedAnswer ||
            (q.showOther && q.showOther.show && !q.showOther.otherValue))
        ) {
          this.listOfUnAnsweredQuestions.push({
            headerIndex: h_index,
            questionIndex: q_index
          });
        } else if (q.type === this.questionTypes.embed && !this.gameCompleted) {
        } else if (q.type === this.questionTypes.embed && !this.gameCompleted) {
          this.listOfUnAnsweredQuestions.push({
            headerIndex: h_index,
            questionIndex: q_index
          });
        }
      });
    });

  }

  /**
   * Call once the answer is accepted, so it can be removed from unanswered array
   */
  protected removeAnswerFromUnansweredArray(headerIndex: number, questionIndex: number) {
    if (this.listOfUnAnsweredQuestions.length > 0) {
      this.listOfUnAnsweredQuestions = this.listOfUnAnsweredQuestions
        .filter(a => !(a.headerIndex === headerIndex && a.questionIndex === questionIndex));
    }
  }

  /**
   * Switches the question to the last one in the result answers
   */
  protected continueQuizFromLastAnsweredQuestion() {
    const savedAnswersAsArray = Object.keys(this.savedAnswers);
    // Check if last answerd question is a conditional question
    // If it is stay on the same question since it's skipped already

    if (this.savedAnswers[savedAnswersAsArray[savedAnswersAsArray.length - 1]] !== ' ') {
      while (this.currentQuestion.field_key !== savedAnswersAsArray[savedAnswersAsArray.length - 1]) {
        this.preSelectGamesForContinueAssessmnet();
        this.next();
      }
      this.next();
    } else {
      while (this.currentQuestion.field_key !== savedAnswersAsArray[savedAnswersAsArray.length - 2]) {
        this.preSelectGamesForContinueAssessmnet();
        this.next();
      }
    }

  }

  preSelectGamesForContinueAssessmnet() {
    if (this.currentQuestion.type !== this.questionTypes.autocompleteMultiple) return;

    this.selectedGames = JSON.parse(this.savedAnswers[this.currentQuestion.field_key]['value']);
  }

  /**
   * Initialized the `showOther` object on all questions
   */
  protected initOtherOptionFields() {
    this.quiz.result.quiz.fields.forEach(header => {
      header.fields.forEach(q => {
        // Init `other` option
        q.showOther = {};
      });
    });
  }

  /**
   * Once any other option is pressed in a multi-select question
   * we need to clear the other option in case it was selected
   */
  protected clearOptionOther(value: string[]) {
    if (value.includes('option-other')) {

      return value = value.filter((q: string) => q !== 'option-other');
    }
    return value;
  }

  /**
   * Switches the question to the first unanswered question in an array
   */
  takeUserToFirstUnansweredQuestion() {
    if (this.listOfUnAnsweredQuestions.length > 0) {
      const ans = this.listOfUnAnsweredQuestions[0];
      this.jumpToQuestion(ans.headerIndex, ans.questionIndex);
    } else {
      this.jumpToEnd();
    }
  }

  /**
   * Used to make the input element gain focus so the user doesn't have to click it constantly
   * in oreder to input a value
   *
   * @param inputElement
   */
  focusElementAfterAnimation(inputElement: HTMLInputElement) {
    if (!this.platform.ANDROID && !this.platform.IOS) {
      inputElement.focus();
    }
  }

  /**
   * Generates a number scale based on amount of steps needed
   * @param steps
   */
  getOpinionScale(steps: number): Array<number> {
    const array = new Array<number>(steps);

    for (let i = 0; i < array.length; i++) {
      array[i] = i + 1;
    }

    return array;
  }

  getFontSize(): string {
    if (this.currentQuestionNumber < 10) {
      return '2em';
    } else if (this.currentQuestionNumber < 100) {
      return '1.5em';
    } else {
      return '1.2em';
    }
  }


  /**
   * -----TESTING ONLY------
   *
   * Gets randomly generated answers for the quiz
   */
  fillInTheQuizResults(formID?: string, userToken?: string, quizToken?: string) {
    this.loading = true;
    this.subscriptions.add(
      this.quizService.getRandomResults(formID || this.quizId, this.locale, userToken, quizToken).subscribe(res => {
        this.quiz.result_answers = res;
        this.jumpToQuestion(0, 0);
        this.hasReachedEnd = false;
        this.isEnd = false;
        this.allQuestionsAnswered = true;
        this.gameCompleted = true;

        this.preloadExistingAnswers();
        this.loadUnanswerdRequiredQuestions();

        this.loading = false;

      }, err => {
        this.loading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
        // this.helper.showAlert(err.error.message || 'Error getting random results', 'OK', { duration: 5000 });
      })
    );
  }

  getSourceURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4200/assessment');
  }

  catEventHandler() {


    this.isGameLoaded = true;
    let usertoken = '', quizToken = '';

    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    if (!this.isAnonQuiz) {
      usertoken = this.auth.user.token_key;
      quizToken = this.auth.quiz_token;
    } else {
      usertoken = this.anonService.anonToken;
      quizToken = this.anonService.anonQuizToken;
    }


    const ref = this.dialog.open(CatGameEmbedComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        usertoken,
        fieldKey: this.currentQuestion.field_key,
        quizId: this.quizId,
        locale: this.quiz.locale,
        catId: this.currentQuestion.properties.cat_id
      },
      width: '540px',
      maxHeight: '100vh',
      maxWidth: 'unset'
    });

    ref.afterClosed().subscribe(dialog_res => {
      if (dialog_res) {
        this.isLoading = true;
        this.quizService.getCatResults(this.currentQuestion.field_key, this.quizId, dialog_res.detail, this.locale, usertoken).subscribe(result => {
          this.isGameLoaded = false;
          this.gameCompleted = true;
          this.isLoading = false;
          this.next();
        }, error => {
          this.errService.handleHttpErrorForLogedInUser(error);
        });
      }
    });
  } // end catEventHandler

  getGameEmbedInfo() {
    this.isLoading = true;
    let usertoken = '', quizToken = '';

    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    if (!this.isAnonQuiz) {
      usertoken = this.auth.user.token_key;
      quizToken = this.auth.quiz_token;
    } else {
      usertoken = this.anonService.anonToken;
      quizToken = this.anonService.anonQuizToken;
    }
    return this.quizService.getGameEmbedInfo(this.currentQuestion.field_key, this.quizId, this.locale, (this.isAnonQuiz ? usertoken : null)).pipe(
      tap({
        next: (result) => {
          this.game = result.embed;
          this.isGameLoaded = true;
          this.isLoading = false;
        },
        error: () => {
          this.isGameLoaded = true;
          this.isLoading = false;
        }
      })
    );
  }

  startTheGame() {
    this.isLoading = true;
    const self = this;
    let usertoken = '', quizToken = '';

    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    if (!this.isAnonQuiz) {
      usertoken = this.auth.user.token_key;
      quizToken = this.auth.quiz_token;
    } else {
      usertoken = this.anonService.anonToken;
      quizToken = this.anonService.anonQuizToken;
    }
    this.quizService.getGameEmbedInfo(this.currentQuestion.field_key, this.quizId, this.locale, (this.isAnonQuiz ? usertoken : null)).subscribe(result => {
      console.log('getGameEmbedInfo', result);
      this.game = result.embed;
    }, error => {
    }, () => {
      this.isGameLoaded = true;
      this.isLoading = false;
    });

    document.addEventListener('MindX', function (e: any) {
      if (e.detail.type === 'assessment_completed') {
        self.quizService.getGameResults(self.currentQuestion.field_key, self.quizId, self.locale, usertoken).subscribe(result => {
          self.isGameLoaded = false;
          self.gameCompleted = true;
          self.next();
        });
      }
    });
  }

  completionProgressBar(): number {
    const questionsPerGame = 3;
    const totalQuestions = this.selectedGames.length * questionsPerGame;
    const currentQuestion = this.currentQuestionNumber - 3;
    if (currentQuestion === 0) {
      return 0;
    }
    return (currentQuestion / totalQuestions) * 100;
  }

  demographicNext({ firstName, lastName, email, country }) {
    this.pending = true;
    const user = localStorage.getItem('anon_user');
    const currentUserLocale: string = localStorage.getItem('anon_quiz_locale') || window.navigator.language || 'en'; // 07.04.23 TODO: Handle this on FE2
    const id = JSON.parse(user).id;
    const userModel: UserModel = {
      first_name: firstName,
      last_name: lastName,
      locale: currentUserLocale,
      email,
      id
    };

    const updateAnonUser$ = this.userService.updateAnonUser(userModel);
    const updateUserProfile$ = this.userService.updateAnonProfile(id, { user_id: id, country });

    combineLatest([
      updateAnonUser$,
      updateUserProfile$
    ]).pipe(
      catchError((err) => {
        if (err.error.trans_code && err.error.trans_code === 'exception.email.taken') {
          this.loading = false;
          this.errService.handleHttpErrorForLogedOutUser(err, {}, false);
        } else {
          console.log('Error updating anon user:', err);
          this.loading = false;
          this.pending = false;
          this.errService.handleHttpErrorForLogedOutUser(err, {}, false);
        }
        return of(null);
      })
    ).subscribe((res) => {
      this.pending = false;
      if (res !== null) {
        localStorage.setItem('quizAnonForm', JSON.stringify({ firstName, lastName, email, country }));
        localStorage.removeItem('anon_quiz_locale'); // 07.04.23 TODO: Handle this on FE2

        return this.showResultsPage();
      }
      localStorage.removeItem('anon_quiz_locale'); // 07.04.23 TODO: Handle this on FE2
    });


  }

  showResultsPage() {
    this.submitAnswers();
  }

  doneButtonDisabled(policyChecked: boolean, emailRequired: boolean, emailInvalid: boolean): boolean {
    if (!policyChecked) return true;
    if (!emailInvalid) return false;
    return emailRequired ? emailInvalid : true;
  }

  skipButtonDisabled(policyChecked: boolean, emailRequired: boolean, emailInvalid: boolean, incognito: boolean): boolean {
    if (!policyChecked) return true;
    return emailRequired;
  }

  isNotIncognito(incognito: boolean) {
    if (incognito && this.showResultsPageCounter === 0) {
      this.showResultsPage();
      this.showResultsPageCounter++;
    }
    return !incognito;
  }


  handleInnerHtml(safeHtml: SafeHtml) {
    const html = safeHtml.toString();

    const words = html.split('-').join('&#8209;').split(' ');

    const wrappedWords = words.reduce(((previousValue1, currentValue) => {
      return `${ previousValue1 } <span style="white-space: nowrap;">${ currentValue }</span>&nbsp;`;
    }), '');
    console.log(wrappedWords);
    return `<div style="display: flex; flex-wrap: wrap;">${ wrappedWords }</div>`;
  }


  onNext(id: string) {
  }

  showQuestions(type: string) {
    return type === 'opinion_scale_label';
  }


  getLocalization(quiz: Quiz): { [key: string]: string; } {
    return quiz.result.quiz.properties.localizations;
  }

  onChangeLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
    this.handleLanguageSelect(lang);
  }

  showYourEmail() {
    return combineLatest([this.incognito$, of(this.isEnd)]).pipe(
      map(([incognito, isEnd]) => isEnd && !incognito)
    );
  }
}
