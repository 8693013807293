import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountModel, AccountListResponse } from 'src/app/main/content/models/admin/account.def';
import { Subscription } from 'rxjs';
import { AccountsService } from 'src/app/main/content/services/admin/accounts.service';
import { AccountSelectService } from 'src/app/main/content/services/admin/account-select.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';

@Component({
  selector: 'ds-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSelectComponent implements OnInit, OnDestroy
{
  @Output() change = new EventEmitter<AccountModel>();
  @Output() selectedAccount = new EventEmitter<string>();
  @Input() displayAll?: boolean;


  accountSelectControl = new FormControl();
  accounts: AccountModel[] = [];
  allAccount: AccountModel = {
    id: 0,
    name: 'All',
    index_name: 'all',
    created_at: new Date().toString(),
    updated_at: new Date().toString(),
    whitelabel: true
  };
  subs = new Subscription();

  constructor(
    private accountService: AccountsService,
    private accSelectService: AccountSelectService,
    private errService: ErrorService,
  )
  { }

  ngOnInit()
  {
    this.getAccountList();

    this.accountSelectControl.valueChanges.subscribe((value: number) =>
    {
      console.log('Select value: ', value);
      if (value == 0) {
        this.change.emit(this.allAccount);
        this.selectedAccount.emit(this.allAccount.index_name);
        return;
      }
      /*
        this was done due to this issue
        https://github.com/angular/components/issues/13579
      */
      const selectedAccount: AccountModel = this.findAccountByID(value);
      
      this.accSelectService.selectedAccount = selectedAccount || (this.accounts.length > 0 ? this.accounts[0] : null);
      if (this.accSelectService.selectedAccount)
      {
        this.accountSelectControl.setValue(this.accSelectService.selectedAccount.id, { emitEvent: false });
        if (!selectedAccount) console.log('New select value: ', this.accSelectService.selectedAccount.id);
        this.change.emit(this.accSelectService.selectedAccount);
        this.selectedAccount.emit(this.accSelectService.selectedAccount.index_name);
      }
    });
  }

  private getAccountList()
  {
    this.accountSelectControl.disable();
    this.accounts = [];

    this.subs.add(
      this.accountService.getAccountList().subscribe((res: AccountListResponse) =>
      {
        this.accounts = res.accounts;
        this.accounts.unshift(res.current);

        if (this.accSelectService.selectedAccount)
        {
          this.accountSelectControl.setValue(this.accSelectService.selectedAccount.id, { emitEvent: false });
        }
        else
        {
          this.accountSelectControl.setValue(this.accounts[0].id, { emitEvent: false });
        }
        this.accountSelectControl.enable();

      }, err =>
      {
        console.log('Error getting accounts', err);
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }

  private findAccountByID(id: number): AccountModel
  {
    return this.accounts.find(a => a.id === id);
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
