import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {AuthService} from 'src/app/main/content/services/auth/auth.service';
import {HelperService} from 'src/app/main/content/services/helpers/helper.service';
import {UsersService} from 'src/app/main/content/services/admin/users.service';
import {ErrorService} from 'src/app/main/content/services/helpers/error.service';
import {ProfileDefinition, UserModel} from 'src/app/main/content/models/user/user.def';
import { DemographicsService } from 'src/app/main/content/services/user/demographics.service';
import { MatSelect } from '@angular/material';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  userForm: FormGroup;

  @ViewChild('ethnicityInput', {static: false})
  ethnicityInput: ElementRef;

  @ViewChild('ethnicitySelect', {static: false})
  ethnicitySelect: MatSelect;

  @ViewChild('genderInput', {static: false})
  genderInput: ElementRef;

  @ViewChild('genderSelect', {static: false})
  genderSelect: MatSelect;

  @ViewChild('mainInfoForm', {static: false})
  mainInfoForm: ElementRef;


  @Input() formData;
  @Input() profile_id;
  @Input() assessmentType?: string;

  @HostBinding('class.stg-styles') stgStyles: boolean = false;


  loading = false;
  displayMainInfo = true;
  user: UserModel;
  countryHasStates = false;

  @Output() done = new EventEmitter<boolean>();
  privacyChecked: boolean = false;

  ethnicities$: Observable<any>;
  isEthnicitiesDisabled = false;
  isCustomEthnicities = false;

  showEthnicities = true;

  genders$: Observable<any>;
  isCustomGenders = false;

  ages$: Observable<any>;

  constructor(
    private helper: HelperService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private userService: UsersService,
    private errorService: ErrorService,
    private demographicSvc: DemographicsService
  ) {
  }

  ngAfterViewInit() {
    if (this.mainInfoForm) {
      this.clearFormValidation();
    }
  }

  ngOnInit() {
    this.createForm();
    this.loading = true;

    this.stgStyles = this.handleAssessmentType();

    this.stgStyles = this.handleAssessmentType();

    // Sort countries and states
    this.formData.country.sort((c1, c2) => c1.name.localeCompare(c2.name));

    this.ethnicities$ = this.demographicSvc.getEthnicities(`${this.auth.user.id}`)
      .pipe(
        map( val => {
          if(val === null) this.showEthnicities = false;

          return val;
        })
      );
    this.genders$ = this.demographicSvc.genders.getAllGenders(`${this.auth.user.id}`);
    this.ages$ = this.demographicSvc.age.getAllAges(`${this.auth.user.id}`);


    this.userService.getProfile(this.auth.user.id).subscribe((res: any) => {

      if (res.country) {
        const {id} = res.country;
        this.countryControl.setValue(id);
      }



      this.user = res.user;
      // First check for null values
      res.user.email = res.user.email ? res.user.email : 'N/A';
      res.user.first_name = res.user.first_name ? res.user.first_name : 'N/A';
      res.user.last_name = res.user.last_name ? res.user.last_name : 'N/A';

      res.company = res.company ? res.company : 'N/A';
      res.industry = res.industry ? res.industry : 'N/A';
      res.gender = res.gender ? res.gender : 'N/A';
      res.country = res.country ? res.country : {name: 'N/A'};
      res.state = res.state ? res.state : {name: 'N/A'};
      res.region = res.region ? res.region : 'N/A';
      res.city = res.city ? res.city : 'N/A';
      res.age = res.age ? res.age : {name: 'N/A'};
      res.income = res.income ? res.income : {name: 'N/A'};

      res.ethnicities = res.ethnicities ? res.ethnicities : [];
      res.ethnicity_input = res.ethnicity_input ? res.ethnicity_input : null;
      res.gender_input = res.gender ? res.gender_input : null;
      res.job_level = res.job_level ? res.job_level : {name: 'N/A'};
      res.education = res.education ? res.education : {name: 'N/A'};

      // Set any existing values
      this.userForm.controls.email.setValue(res.user.email === 'N/A' ? '' : res.user.email);
      this.userForm.controls.firstName.setValue(res.user.first_name === 'N/A' ? '' : res.user.first_name);
      this.userForm.controls.lastName.setValue(res.user.last_name === 'N/A' ? '' : res.user.last_name);
      this.userForm.controls.company.setValue(res.company === 'N/A' ? '' : res.company);

      this.form.controls.industry.setValue(res.industry.id);
      this.form.controls.gender.setValue(res.gender.id);
      // this.form.controls.country.setValue(res.country.id);
      this.form.controls.state.setValue(res.state.id);
      this.form.controls.region.setValue(res.region === 'N/A' ? '' : res.region);
      this.form.controls.city.setValue(res.city === 'N/A' ? '' : res.city);
      this.form.controls.age.setValue(res.age.id);
      this.form.controls.income.setValue(res.income.id);

      this.form.controls.ethnicities.setValue(res.ethnicities.map( e => { return e.id }));
      this.form.controls.ethnicity_input.setValue(res.ethnicity_input);
      this.form.controls.gender_input.setValue(res.gender_input);
      this.form.controls.job_level.setValue(res.job_level.id);
      this.form.controls.education.setValue(res.education.id);

      if (res.country.name !== '' && res.country.name !== 'N/A' && res.country.states) // If county already exists, enable states input field
      {
        // Set the states to the states from the current country
        this.formData.state = this.formData.country.find(country => country.id === this.form.controls.country.value).states;
        this.formData.state.sort((c1, c2) => c1.name.localeCompare(c2.name));
        this.form.controls.state.enable();
        this.formData.state.length === 0 ? this.countryHasStates = false : this.countryHasStates = true;
      }

      this.loading = false;

      this.form.controls.country.valueChanges.subscribe((x) => {
        const selectedCountry = this.formData.country.find(
          (country) => country.id === x
        );
        if (selectedCountry && selectedCountry.states) {
          this.formData.state = selectedCountry.states;

          this.formData.state.sort((c1, c2) =>
            c1.name.localeCompare(c2.name)
          );
          this.form.controls.state.reset();
        }

        if (this.formData.state.length !== 0) {
          this.form.controls.state.enable();
          this.form.controls.region.reset();
          this.countryHasStates = true;
        } else {
          this.form.controls.state.reset();
          this.countryHasStates = false;
        }
      });


    }, (err) => {
      this.errorService.handleHttpErrorForLogedInUser(err);
    });

    this.form.controls['gender'].valueChanges
      .pipe()
      .subscribe(
      value => {
        if (value === 103) {
          this.isCustomGenders = true;
          setTimeout(() => {
            if (this.genderInput) {
              this.genderSelect.close();
              this.genderInput.nativeElement.focus();
            }
          });
        } else {
          this.isCustomGenders = false;
          this.form.controls['gender_input'].patchValue(null);
        }
      });

    this.form.controls['ethnicities'].valueChanges
      .pipe()
      .subscribe(
      value => {
        if (value.includes(9) && value.length > 1){
          this.form.controls['ethnicities'].patchValue([9]);
          this.isEthnicitiesDisabled = true;
        }

        if (value.includes(10) && value.length > 1){
          this.form.controls['ethnicities'].patchValue([10]);
          this.isEthnicitiesDisabled = true;
          this.isCustomEthnicities = false;
        }

        if (value.includes(9)) {
          this.isCustomEthnicities = true;
          setTimeout(() => {
            if (this.ethnicityInput) {
              if (this.ethnicitySelect) this.ethnicitySelect.close();
              this.ethnicityInput.nativeElement.focus();
            }
          });
        } else {
          this.isCustomEthnicities = false;
          this.form.controls['ethnicity_input'].patchValue(null);
        }

        if (value.includes(10)) {
          this.isCustomEthnicities = false;
          this.form.controls['ethnicity_input'].patchValue(null);
          if (this.ethnicitySelect) this.ethnicitySelect.close();
        }

        if (!value.includes(9) && !value.includes(10)) {
          this.isEthnicitiesDisabled = false;
        } else {
          this.isEthnicitiesDisabled = true;
        }
      }
    );

  }

  ngOnDestroy() {
  }

  patchEthnicitiesControlValue(id: number) {
    if( (id === 9 || id === 10) && this.form.controls.ethnicities.value.length === 1) {
      this.form.controls.ethnicities.value !== id ?
        this.form.controls.ethnicities.patchValue([id])
        : this.form.controls.ethnicities.patchValue([]);
    }
  }

  handleAssessmentType() {
    if (!this.assessmentType) return false;

    return this.assessmentType === 'stg_assessment-quiz-theme';
  }

  handleDemographicModalsBasedOnAssessment(modalType: string) {
    switch (modalType) {
      case 'mainInfo':
        return this.assessmentType !== 'stg_assessment-quiz-theme';
      case 'profileInfo':
        return this.assessmentType !== 'stg_assessment-quiz-theme';
      case 'stgMainInfo':
        return this.assessmentType === 'stg_assessment-quiz-theme';
      default:
        break;
    }
  }

  createForm() {
    this.form = this.fb.group({
      industry: this.fb.control(null, []),
      gender: this.fb.control(null, []),
      country: this.fb.control(null, [Validators.required]),
      state: this.fb.control(null, []),
      region: this.fb.control(null, []),
      city: this.fb.control(null, []),
      age: this.fb.control(null, []),
      education: this.fb.control(null, []),
      income: this.fb.control(null, []),
      ethnicities: this.fb.control(null, []),
      ethnicity_input: this.fb.control(null, []),
      gender_input: this.fb.control(null, []),
      job_level: this.fb.control(null, []),
    });


    this.userForm = this.fb.group({
      email: this.fb.control(null, [Validators.required]),
      firstName: this.fb.control(null, [Validators.required]),
      lastName: this.fb.control(null, [Validators.required]),
      company: this.fb.control(null, [])
    });

    this.form.controls.state.disable();
  }

  get countryControl(): FormControl {
    return this.form.get('country') as FormControl;
  }

  get firstNameControl(): FormControl {
    return this.userForm.get('firstName') as FormControl;
  }


  get lastNameControl(): FormControl {
    return this.userForm.get('lastName') as FormControl;
  }

  get email(): FormControl {
    return this.userForm.get('email') as FormControl;
  }

  private clearFormValidation() {
    this.countryControl.clearValidators();
    this.countryControl.updateValueAndValidity();
    this.firstNameControl.clearValidators();
    this.firstNameControl.updateValueAndValidity();
    this.lastNameControl.clearValidators();
    this.lastNameControl.updateValueAndValidity();
    this.email.clearValidators();
    this.email.updateValueAndValidity();
    this.form.clearValidators();
    this.userForm.clearValidators();
    this.form.updateValueAndValidity();
    this.userForm.updateValueAndValidity();
  }

  updateDemo() {
    let allControlsEmpty = true;
    Object.entries(this.form.controls).forEach(([key, control]: [string, FormControl]) => {
      if (control.value != null && !!control.value.toString().trim()) {
        allControlsEmpty = false;
      }
    });

    if (allControlsEmpty) {
      this.skipDemo();
      return;
    }

    this.loading = true;

    const profileData = {
      user_id: this.auth.user.id,
      company: this.userForm.controls.company.value ? this.userForm.controls.company.value.trim() : '',
      industry: this.form.controls.industry.value,
      gender: this.form.controls.gender.value,
      country: this.form.controls.country.value,
      state: this.form.controls.state.value,
      region: this.form.controls.region.value ? this.form.controls.region.value.trim() : '',
      city: this.form.controls.city.value ? this.form.controls.city.value.trim() : '',
      age: this.form.controls.age.value,
      income: this.form.controls.income.value,
      ethnicities: this.form.controls.ethnicities.value,
      ethnicity_input: this.form.controls.ethnicity_input.value,
      gender_input: this.form.controls.gender_input.value,
      education: this.form.controls.education.value,
      job_level: this.form.controls.job_level.value
    };

    this.user.email = this.userForm.controls.email.value ? this.userForm.controls.email.value.trim() : '';
    this.user.first_name = this.userForm.controls.firstName.value ? this.userForm.controls.firstName.value.trim() : '';
    this.user.last_name = this.userForm.controls.lastName.value ? this.userForm.controls.lastName.value.trim() : '';

    this.user.profile.ethnicities = this.form.controls.ethnicities.value;
    this.user.profile.ethnicity_input = this.form.controls.ethnicity_input.value;
    this.user.profile.gender_input = this.form.controls.gender_input.value;
    console.log('Profile data: ', profileData);
    console.log('User data: ', this.user);

    const updateUser$: Observable<UserModel> = this.userService.updateUser(this.user);
    const updateProfile$: Observable<ProfileDefinition> = this.userService.updateProfile(this.profile_id, profileData);

    combineLatest([updateUser$, updateProfile$]).subscribe(([user, profile]) => {
      this.done.emit(true);
      // this.router.navigate([], { queryParams: { skip: false } });
    }, () => {
      // error
    });

  }

  skipDemo() {
    this.done.emit(true);
    // this.router.navigate([], { queryParams: { skip: true } });
  }
}
