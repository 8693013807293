import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboardDesign1Component } from './dashboard-design1/dashboard-design1.component';
import { DashboardDesign2Component } from './dashboard-design2/dashboard-design2.component';
import { AuthGuard } from '../../../guards/auth.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';

const routes: Routes = [
  {
    path: `:${ACCOUNT_NAME}/home`,
    component: DashboardComponent, canActivate: [AuthGuard]
  },
  { path: `:${ACCOUNT_NAME}/dashboard`, redirectTo: `:${ACCOUNT_NAME}/home` }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardDesign1Component,
    DashboardDesign2Component,
  ],
  imports: [
    SharedModule,
    TranslocoRootModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class DashboardModule {

}
