import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssesmentService } from '../../../services/user/assesment.service';
import { ScoreWhoAreYou, TemplateTypes } from '../../../models/user/user-results.def';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { ShareService } from '../../share/share-services/share.service';
import { UsersService } from '../../../services/admin/users.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { HelperService } from '../../../services/helpers/helper.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { Facebook, LinkedIN, Twitter } from 'src/app/functions/share';
import { MatDialog } from '@angular/material/dialog';
import { ThemingService } from 'src/app/theme/theming.service';
import { downloadFile } from 'src/app/functions/helpful-functions';
import html2canvas from 'html2canvas';
import { AnonUserService } from '../../../services/user/anon-user.service';
import { ShareImageComponent } from '../../shared/components/share-image/share-image.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from 'src/app/api-url/api-urls';


@Component({
  selector: 'ds-assessment-results',
  templateUrl: './assessment-results.component.html',
  styleUrls: ['./assessment-results.component.scss']
})
export class AssessmentResultsComponent extends BaseRouteParamComponent implements OnInit, OnDestroy, AfterViewChecked {
  loading = true;
  formID: string;
  socialShareInProgress = false;
  shareImageFirstLoad = false;

  provider: 'facebook' | 'twitter' | 'linkedin' | 'plain';
  report: any;
  reportTypes = TemplateTypes;
  isReport = false;
  exportPdfLoading = false;
  profilePicture: string;
  primaryDrivers: ScoreWhoAreYou[] = [];
  creatingImageInProgress = false;

  shareImage = { title: <string> '', icons: <string[]> [], text: <string> '' };

  subs = new Subscription();

  @ViewChild('shareImgCmp', { static: false }) shareImgCmp: ShareImageComponent;
  imgDetector: Observable<any>;
  chart: string;

  constructor(
    protected routerParams: RouterParamsService,
    private assessmentService: AssesmentService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private shareService: ShareService,
    private userService: UsersService,
    private errService: ErrorService,
    private helper: HelperService,
    private dialog: MatDialog,
    public themingService: ThemingService,
    private anonService: AnonUserService,
    private _http: HttpClient
  ) {
    super(routerParams);
    this.shareService.exportPdf$.subscribe(() => {
      if (this.auth.isLoggedIn()) {
        this.exportAsPdf();
      }

    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.formID = this.route.snapshot.params.id;

    this.subs.add(
      this.userService.getProfile(this.auth.user.id).subscribe(res => {
        this.profilePicture = res.image_file;
      })
    );

    this.getReport();
  }

  ngAfterViewChecked() {
    if (this.shareImgCmp && this.shareImageFirstLoad) {
      this.shareImageFirstLoad = false;
      this.shareReport(this.provider);
    }
  }



  private getReport() {
    this.loading = true;

    this.assessmentService.getResultByFormIdAndUserID(this.formID, this.auth.user.id)
      .subscribe((res: any) => {
        console.log('Results: ', res);
        this.report = res;

        const href = res.score.scores.calculate.href;
        if (!this.chart && href) {
          this.chart = href.replace('%media_url%', API.MEDIA_API);
        }


        if (this.report && !this.report.score)
          this.isReport = false;
        else
          this.isReport = true;

        if (res.user_report_template === TemplateTypes.whoAreYou) {
          this.primaryDrivers = (<ScoreWhoAreYou[]> res.score).filter(score => score.drive_type === 1);
        }

        this.loading = false;

      }, err => {
        this.loading = false;
        console.log('Error getting user results: ', err);
        this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/dashboard']);
        this.errService.handleHttpErrorForLogedInUser(err);
      });

  }

  createShareImage(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    const self = this;
    this.provider = provider;

    switch (this.report.user_report_template) {
      case this.reportTypes.lq:
        this.shareImage.title = this.report.score.scores.calculate.header.intro_title;
        const arrayOfShortTraitDescriptions = Object.keys(
          this.report.score.scores.calculate.body
        ).map(
          (key) =>
            this.report.score.scores.calculate.body[key][
              'ratingshortdescription'
              ]
        );
        arrayOfShortTraitDescriptions.forEach((trait) => {
          this.shareImage.text += trait + ' ';
        });
        Object.keys(this.report.score.scores.calculate.body).forEach(function (
          key
        )
        {
          self.shareImage.icons.push(
            `lq-report/${ self.report.score.scores.calculate.body[key].ratingicone }`
          );
        });
        break;

      case this.reportTypes.sis:
        this.shareImage.title = this.report.score.scores.calculate.header.title;
        this.shareImage.text = this.report.score.scores.calculate.header.text;
        this.report.score.scores.calculate.header.icone.forEach((icon) => {
          this.shareImage.icons.push(`skills-insight-report/${ icon }`);
        });
        break;

      case this.reportTypes.stg:
        this.shareImage.title = this.report.score.scores.calculate.header.title;
        this.shareImage.text = this.report.score.scores.calculate.header.text;
        this.report.score.scores.calculate.body.forEach((res: any) => {
          this.shareImage.icons.push(res.icone);
        });
        break;
    }

    this.socialShareInProgress = true;
    this.shareImageFirstLoad = true;
  }


  shareReport(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    this.helper.showAlert('Creating link', '', { duration: 5000 });
    const userId = this.auth.user.id ? this.auth.user.id : this.anonService.anonUser.id;
    const userTokenKey = this.auth.user.token_key ? this.auth.user.token_key : this.anonService.anonToken;
    const assessmentId = this.formID ? this.formID : this.anonService.assessment.assessment.form_id;

    const self = this;
    let shareUrl: string;
    let badgeImage: any;

    html2canvas(document.querySelector('.share-image'), {
      width: 500,
      height: 260,
      onclone: function (doc) {
        doc.getElementById('share-image').style.visibility = 'visible';
      }
    }).then(function (canvas) {
      badgeImage = canvas.toDataURL();
      self.subs.add(
        self.shareService
          .shareUserBadgeImage(badgeImage, assessmentId, userId, userTokenKey)
          .subscribe((res) => {
            shareUrl = res.page;
            self.socialShareInProgress = false;
            switch (provider) {

              case 'facebook':
                Facebook.share(shareUrl);
                break;

              case 'twitter':
                Twitter.tweet(shareUrl);
                break;

              case 'linkedin':
                LinkedIN.share(shareUrl);
                break;

              default:
                break;
            }
          })
      );
    });
  }

  exportAsPdf() {
    this.exportPdfLoading = true;
    this.shareService.exportLoading = true;
    const createShareToken$ = this.shareService.createShareToken(this.auth.user.id, this.formID);
    createShareToken$.subscribe(res => {

      this.shareService.exportAsPdf(this.auth.user.token_key, res.token).subscribe((result: any) => {
        downloadFile(
          result.body,
          result.headers.get('content-type'),
          result.headers.get('content-disposition').split('=')[1]
        );
        this.exportPdfLoading = false;
        this.shareService.exportLoading = false;

      }, err => {
        console.log('PDF export Error', err);
        this.errService.handleHttpErrorForLogedInUser(err);
        this.exportPdfLoading = false;
        this.shareService.exportLoading = false;
      });

    }, err => {
      console.log('Share Error', err);
      this.errService.handleHttpErrorForLogedInUser(err);
      this.exportPdfLoading = false;
      this.shareService.exportLoading = false;
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  goToDashboard() {
    this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + `/dashboard`]);
  }
}
