import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { InvitesService } from '../../../services/admin/email-invite.service';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { AssesmentService } from '../../../services/user/assesment.service';
import { Quiz } from '../../../models/user/quiz.model';
import { Title } from '@angular/platform-browser';
import { ThemingService } from 'src/app/theme/theming.service';
import { AnonUserService } from '../../../services/user/anon-user.service';
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-anonymous-quiz',
  templateUrl: './anonymous-quiz-page.component.html',
  styleUrls: ['./anonymous-quiz-page.component.scss']
})
export class AnonymousQuizPageComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = '';
  subs = new Subscription();

  loading = false;
  loadingMSG = '';

  // quiz variables
  inviteToken: string;
  quizToken: string;
  formID: string;
  quiz: Quiz;
  emailRequired: boolean;
  locale: string;
  quiz2: any;

  constructor(
    protected routerParams: RouterParamsService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private inviteService: InvitesService,
    private helper: HelperService,
    private errService: ErrorService,
    private assessmentService: AssesmentService,
    private title: Title,
    private themingService: ThemingService,
    public anonService: AnonUserService,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(routerParams);
    this.locale = translocoService.getActiveLang();

    if (this.locale) {
      this.document.documentElement.lang = this.locale.substr(0, 2);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.auth.isLoggedIn()) {
      this.router.navigate([this.accountNameParam + '/home']);
    }

    this.inviteToken = this.route.snapshot.params.token;
    if (!this.inviteToken) {
      this.helper.showAlert('Token is missing');
      this.router.navigate([this.accountNameParam + '/login']);
    }


    if (!this.auth.isLoggedIn() && this.inviteToken) {
      this.handleInvite();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.title.setTitle(this.themingService.title);
  }


  private handleInvite(getNewAnonUser?: boolean, counter = 0) {
    console.log('anonToken', localStorage.getItem('anon_token'));
    let anon_token = '';
    this.loadingMSG = 'Handling invite token';
    this.loading = true;

    if (localStorage.getItem('anon_token')) {
      anon_token = getNewAnonUser ? '' : localStorage.getItem('anon_token');
    }

    this.inviteService.handleInvite(this.inviteToken, anon_token).subscribe((res) => {
      console.log('Invite response:', res);

      this.anonService.anonUser = res.tokens.user;
      this.anonService.anonToken = res.tokens.token_key;
      this.anonService.anonRefreshToken = res.tokens.refresh_token;
      this.anonService.assessment = res.userassessment;
      this.anonService.anonQuizToken = this.quizToken = res.tokens.quizToken.token;
      this.anonService.emailRequired = res.emailRequired;
      this.formID = res.userassessment.assessment.form_id;
      this.emailRequired = res.emailRequired;
      this.title.setTitle(`Quiz | ${ res.userassessment.assessment.name } - ${ this.themingService.title }`);

      const token = JSON.parse(atob(this.anonService.anonToken.split('.')[1]));
      const refreshToken = JSON.parse(atob(this.anonService.anonRefreshToken.split('.')[1]));
      const currentTimestamp = new Date().getTime() / 1000;

      if (token.exp < currentTimestamp && refreshToken.exp < currentTimestamp) {
        console.log('Token and refresh token expired.');
        this.handleInvite(true);
      } else if (token.exp < currentTimestamp && refreshToken.exp > currentTimestamp) {
        console.log('Token expired.');
        this.refreshAnonToken();
      } else {
        console.log('Token and refresh token valid.');
        this.getQuiz();
      }


    }, err => {
      console.log('Invite handle error:', err);
      if (counter === 0) {
        this.auth.logoutCleanUp(true);
        this.handleInvite(false, counter + 1);
      } else {
        this.errService.handleHttpErrorForLogedOutUser(err);
      }

    });
  }

  private refreshAnonToken() {
    this.auth.refreshAnonToken(this.anonService.anonUser.id, this.anonService.anonRefreshToken).subscribe(result => {
      this.anonService.anonToken = result.token_key;
      this.anonService.anonRefreshToken = result.refresh_token;
      this.getQuiz();
    }, err => {
      console.log('Refresh token error:', err);
      this.errService.handleHttpErrorForLogedOutUser(err);
      this.loading = false;
    });
  }

  private getQuiz() {
    this.loadingMSG = 'Getting the quiz';
    this.loading = true;


    this.assessmentService.getAnonAssessmentForm(this.formID, this.anonService.anonToken, this.locale)
      .subscribe(res => {
        console.log('Quiz response:', res);
        this.quiz = res;
        this.quiz2 = { ...this.quiz, userToken: this.anonService.anonToken };
        this.loading = false;
        this.class = res.theme ? res.theme : '';

      }, err => {
        console.log('Error getting quiz', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedOutUser(err);
      });

  }


  onQuizSubmitted() {
    if (this.auth.isLoggedIn())
      this.router.navigate([this.accountNameParam + `/user/assessment/${ this.formID }/results`]);
    else
      this.router.navigate([this.accountNameParam + `/report/${ this.formID }`]);
  }

  onLocaleChanged(locale: string) {
    this.locale = locale;
    this.getQuiz();
  }
}
