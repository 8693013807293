import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ThumbsService } from 'src/app/main/content/services/user/thumbs.service';
import { Subscription } from 'rxjs';
import { ThumbType } from 'src/app/main/content/models/user/thumbs.model';

@Component({
  selector: 'ds-thumbs',
  templateUrl: './thumbs.component.html',
  styleUrls: ['./thumbs.component.scss']
})
export class ThumbsComponent implements OnInit, OnDestroy
{
  @Input() thumbID: string;
  @Input() thumbType: ThumbType;

  thumbValue: 'up' | 'down';

  subs = new Subscription();

  constructor(
    private thumbService: ThumbsService
  )
  {}

  ngOnInit()
  {
    if (!this.thumbID)
    {
      console.log('WARNING - Thumb ID not provided, component wont be rendered.');
      return;
    }
    if (!this.thumbType)
    {
      console.log('WARNING - Thumb TYPE not provided, component wont be rendered.');
      return;
    }

    this.getThumbValue();
  }

  private getThumbValue()
  {
    this.subs.add(
      this.thumbService.getThumb(this.thumbID, this.thumbType).subscribe(res =>
      {
        this.thumbValue = res ? (res.thumb != null ? (res.thumb ? 'up' : 'down') : null) : null;

      }, err =>
        {
          this.thumbValue = null;
        })
    );
  }

  vote(value: boolean)
  {
    this.thumbValue = ((this.thumbValue === 'up' && value === true) || (this.thumbValue === 'down' && value === false)) ? null : (value ? 'up' : 'down');

    this.subs.add(
      this.thumbService.setThumb(this.thumbID, this.thumbType, value).subscribe(res =>
      {
        console.log('Voted on thumb: ', res);
        // this.thumbValue = value ? 'up' : 'down';
        this.thumbValue = res ? (res.thumb != null ? (res.thumb ? 'up' : 'down') : null) : null;

      }, err =>
        {
          console.log('Error voting on thumb', err);
          this.thumbValue = null;
        })
    );
  }


  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
