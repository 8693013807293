import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { OnDestroy, OnInit } from '@angular/core';
import { VerifyResponse } from '../../../models/admin/invites.def';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitesService } from '../../../services/admin/email-invite.service';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helpers/helper.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AreYouSureDialogComponent,
  DialogOptions
} from '../../shared/dialogs/are-you-sure-dialog/are-you-sure-dialog.component';
import { AnonUserService } from '../../../services/user/anon-user.service';
import { TranslocoService } from '@ngneat/transloco';
import { first, map, mergeMap, take } from 'rxjs/operators';

export class BaseVerifyComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  inviteToken: string;
  skipVerify: string;

  isLoggedIn = false;

  loadingMSG: string;
  loading = false;
  verifyResponse: VerifyResponse;

  subs = new Subscription();

  constructor(
    protected routerParams: RouterParamsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected inviteService: InvitesService,
    protected auth: AuthService,
    protected helper: HelperService,
    protected dialog: MatDialog,
    public themingService: ThemingService,
    protected anonService: AnonUserService,
    protected translocoService: TranslocoService
  ) {
    super(routerParams);
  }

  ngOnInit() {
    super.ngOnInit();

    this.translocoService.selectTranslate('messages.verifying').pipe(take(1)).subscribe(translation => {
      this.loadingMSG = translation;
    });

    this.inviteToken = this.route.snapshot.params['token'];
    this.skipVerify = this.route.snapshot.queryParams['skipVerify'];
    this.isLoggedIn = this.auth.isLoggedIn();

    if (!this.inviteToken) {
      this.router.navigate([`${ this.accountNameParam }/login`]);
    }


  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }


  protected checkIfUserIsLoggedIn(): Promise<boolean> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      // if user is logged in to another account, log him out

      if (this.isLoggedIn && this.auth.user.account.index_name !== this.accountNameParam.replace('/', '')) {


        const invite_acc_name: string = this.accountNameParam.replace('/', '');
        AreYouSureDialogComponent.dialogClose$(
          this.translocoService,
          this.dialog,
          invite_acc_name
        ).subscribe((isProceed) => {
          this.loading = false;
          if (!isProceed) {
            this.router.navigate([`/${ this.auth.user.account.index_name }/home`]);
          } else {
            console.log('User is logged in to different account from the invite link');
            this.auth.logout();
            localStorage.setItem('user-had-account', 'true');
            const x = this.route.snapshot.url.find(url => url.path === 'link');
            this.loginOrRegisterUser('register', x && x.path === 'link' ? 'link' : 'email');

            resolve(true);
          }
        });

      } else {
        this.loading = false;
        resolve(false);
      }
    });
  }

  /**
   * Calls the invite route for all invite options
   */
  protected handleInvite() {
    this.loading = true;
    this.translocoService.selectTranslate('global.loaderInviteLink').pipe(take(1)).subscribe(translation => {
      this.loadingMSG = translation;
    });

    const userToken = this.isLoggedIn ? this.auth.token : this.anonService.anonToken;

    this.subs.add(
      this.inviteService.handleInvite(this.inviteToken, userToken).subscribe(res => {
        console.log('Invite response', res);

        if (res.action === 'login' || res.action === 'register') {
          this.loginOrRegisterUser(res.action, 'link', res.email, this.inviteToken);
        } else {
          this.router.navigate([`${ this.accountNameParam }/dashboard`]);
        }
      }, err => {
        if (err.error && err.error.code === 404) {
          this.router.navigate([`${ this.accountNameParam }/dashboard`]);
        }
        console.log('Invite error', err);
        this.helper.showAlertWithouAutoDismiss(err.error && err.error.message ? err.error.message : 'Error confirming invite');
        this.router.navigate([this.accountNameParam + '/login']);
      })
    );
  }

  /**
   * Sends the user that is not logged in to the login o register page with url parameters
   */
  loginOrRegisterUser(type: 'login' | 'register', verifyType: 'link' | 'email', email?: string, token?: string) {
    this.router.navigate([`${ this.accountNameParam }/${ type }`], {
      queryParams: {
        email,
        token
      }
    });
  }
}
