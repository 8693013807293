import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainModule } from './main/main.module';
import { PagesModule } from './main/content/pages/pages.module';
import { RouterModule, Routes } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { ACCOUNT_NAME } from './constants/router-params';
import { loadScript } from './functions/script-loader';
import { QuillModule } from 'ngx-quill';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { registerLocaleData } from '@angular/common';
import locale_en from '@angular/common/locales/en';
import locale_nl from '@angular/common/locales/nl';
import locale_es from '@angular/common/locales/es';
import locale_es_MX from '@angular/common/locales/es-MX';
import locale_it from '@angular/common/locales/it';
import locale_fr from '@angular/common/locales/fr';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import locale_cs from '@angular/common/locales/cs';
import locale_ja from '@angular/common/locales/ja';
import locale_hr from '@angular/common/locales/hr';
import locale_de from '@angular/common/locales/de';
import locale_bs from '@angular/common/locales/bs';
import locale_fi from '@angular/common/locales/fi';
import locale_bg from '@angular/common/locales/bg';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { SsoComponent } from './sso/sso.component';
import { SsoGuard } from './sso/sso.guard';
import { SsoAouthComponent } from './sso/sso-aouth/sso-aouth.component';
import { TsqAssesmentModule } from './main/content/pages/shared/tsq-assesment/tsq-assesment.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedPagesModule } from 'src/app/main/content/pages/shared/shared.pages.module';


loadScript('https://connect.facebook.net/en_US/sdk.js');


registerLocaleData(locale_en);
registerLocaleData(locale_nl);
registerLocaleData(locale_es);
registerLocaleData(locale_es_MX);
registerLocaleData(locale_it);
registerLocaleData(locale_fr);
registerLocaleData(locale_fr_CA);
registerLocaleData(locale_cs);
registerLocaleData(locale_ja);
registerLocaleData(locale_hr);
registerLocaleData(locale_de);
registerLocaleData(locale_bs);
registerLocaleData(locale_fi);
registerLocaleData(locale_bg);


const dev = [];


const routes: Routes = [
  {
    path: 'sso',
    component: SsoComponent,
    canActivate: [SsoGuard]
  },
  {
    path: 'sso/aouth',
    component: SsoAouthComponent
  },
  {
    path: `:${ ACCOUNT_NAME }/admin`,
    loadChildren: () => import('src/app/main/content/pages/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: `:${ ACCOUNT_NAME }/user`,
    loadChildren: () => import('src/app/main/content/pages/user/user.module').then((m) => m.UserModule)
  },
  {
    path: `:${ ACCOUNT_NAME }/developer-corner`,
    loadChildren: () => import('src/app/main/content/pages/developer-corner/developer-corner.module').then(m => m.DeveloperCornerModule)
  },
  {
    path: `cookie-policy`,
    loadChildren: () => import('src/app/main/content/pages/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule)
  },
  {
    path: `:${ ACCOUNT_NAME }/share`,
    loadChildren: () => import('src/app/main/content/pages/share/share.module').then(m => m.ShareModule)
  },
  { path: `privacy-policy`,
    loadChildren: () => import('./main/content/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  {
    path: `:${ ACCOUNT_NAME }`,
    redirectTo: `:${ ACCOUNT_NAME }/login`
  },
  {
    path: '', pathMatch: 'full', redirectTo: '/login'
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SsoComponent,
    SsoAouthComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(routes),
        SharedModule,
        MainModule,
        PagesModule,
        MarkdownModule.forRoot(),
        QuillModule.forRoot(),
        TranslocoRootModule,
        TsqAssesmentModule,
        FontAwesomeModule,
        SharedPagesModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ...dev,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
