import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseDashboardComponent } from '../base-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from 'src/app/animations/animations';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { AssesmentService } from 'src/app/main/content/services/user/assesment.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { UserAssessment } from 'src/app/main/content/models/admin/assesment.def';

@Component({
  selector: 'ds-dashboard-design2',
  templateUrl: './dashboard-design2.component.html',
  styleUrls: ['./dashboard-design2.component.scss'],
  animations: fuseAnimations
})
export class DashboardDesign2Component extends BaseDashboardComponent implements OnInit
{
  fontFamily;
  accent;
  newPrimary;

  userAssessment: UserAssessment;

  constructor(
    public themingService: ThemingService,
    protected auth: AuthService,
    protected assesmentService: AssesmentService,
    protected router: Router,
    protected dialog: MatDialog,
    protected errService: ErrorService,
    protected routerParams: RouterParamsService
  )
  {
    super(themingService, auth, assesmentService, router, dialog, errService, routerParams);

    this.fontFamily = this.themingService.fontFamily;
    this.accent = this.themingService.accent;
    this.newPrimary = this.themingService.getPrimaryColor_RGBA_Value(0.5);
  }

  ngOnInit()
  {
    super.ngOnInit();
    this.userAssessment = this.userAssessments[0];
  }
}
