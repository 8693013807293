import {Component, OnDestroy, OnInit} from '@angular/core';
import {SidenavService} from '../main-service/sidenav.service';
import {ThemingService} from 'src/app/theme/theming.service';
import {TranslocoService} from '@ngneat/transloco';
import {UsersService} from '../content/services/admin/users.service';
import {AuthService} from '../content/services/auth/auth.service';
import {UserDefinition} from '../content/models/user/user.def';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ds-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  languages = [];
  currentUser: UserDefinition;
  userEmail: string;

  destroySubject: Subject<void> = new Subject<void>();

  constructor(
    public sidenavService: SidenavService,
    public themingService: ThemingService,
    public translocoService: TranslocoService,
    private auth: AuthService,
    private userService: UsersService
  ) {
    this.userEmail = this.auth.user.email;
  }

  ngOnInit() {
    this.languages = this.translocoService.getAvailableLangs();
    this.currentUser = this.auth.user;
    this.translocoService.setActiveLang(this.currentUser.locale);

    this.userService.languageChanged$
      .pipe(
        takeUntil(this.destroySubject),
        map( (locale) => {
          // console.clear();
          // console.log(locale);
          this.changeLanguage(locale);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  handleLeftSidenav() {
    if (this.sidenavService.leftSidenav.mode === 'side' && this.sidenavService.canBeCollapsed) {
      this.sidenavService.isCollapsed = !this.sidenavService.isCollapsed;
      this.sidenavService.openCloseSidenav('left');
    } else {
      this.sidenavService.toggleLeftSidenav();
    }
  }

  changeLanguage(key: string) {
    this.currentUser = this.auth.user;
    this.currentUser.locale = key;
    this.userService.updateUser(this.currentUser).subscribe(res => {
      this.translocoService.setActiveLang(res.locale);
      localStorage.setItem('ds_user', JSON.stringify(res));
    });
  }

  onLogout() {
    this.auth.logoutCleanUp(false);
  }
}
