import { Injectable } from '@angular/core';
import { ComponentStore } from 'src/app/component.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaturityLevel } from 'src/app/main/content/models/admin/results.def';


export interface TsqState {
  error: Error | null;
  incognito: boolean;
  hideSocial: boolean;
  user_report_template?: string;
  localizations: { [key: string]: string; };
  maturity_level?: MaturityLevel;
  showNext?: boolean;
  showPrevious?: boolean;
  selected?: string;
  selectedFlash?: string | null;
}


const initialState: TsqState = {
  error: null,
  incognito: false,
  hideSocial: false,
  localizations: {}
};

@Injectable({ providedIn: 'root' })
export class AppStore extends ComponentStore<TsqState> {
  constructor() {
    super(initialState);
  }


  set incognito(incognito: boolean) {
    this.patch({ incognito });
  }


  get incognito$(): Observable<boolean> {
    return this.state$.pipe(
      map(state => state.incognito)
    );
  }


  set hideSocial(hideSocial: boolean) {
    this.patch({ hideSocial });
  }

  get hideSocial$(): Observable<boolean> {
    return this.state$.pipe(
      map(s => s.hideSocial)
    );
  }

  set user_report_template(user_report_template: string) {
    this.patch({ user_report_template });
  }

  get user_report_template$(): Observable<string> {
    return this.state$.pipe(
      map(state => state.user_report_template)
    );
  }

  set localizations (localizations: { [key: string]: string; }){
    this.patch({localizations});
  }

  get localizations$(): Observable<{ [key: string]: string; }> {
    return this.state$.pipe(
      map(state => state.localizations)
    );
  }

  set maturity_level(maturity_level: MaturityLevel) {
    this.patch({ maturity_level });
  }

  get maturity_level$() {
    return this.state$.pipe(
      map(state => state.maturity_level)
    )
      ;
  }


  set showNext(showNext: boolean) {
    this.patch({ showNext });
  }

  get showNext$(): Observable<boolean> {
    return this.state$.pipe(map(s => s.showNext));
  }

  set showPrevious(showPrevious: boolean) {
    this.patch({ showPrevious });
  }

  get showPrevious$(): Observable<boolean> {
    return this.state$.pipe(map(s => s.showPrevious));
  }

  get selected$(): Observable<string> {
    return this.state$.pipe(map(s => s.selected));
  }

  set selected(selected: string) {
    this.patch({ selected });
  }

  set fleshSelected(selectedFlash: string | null) {
    this.patch({ selectedFlash });
  }

  get fleshSelected$(): Observable<string> {
    return this.state$.pipe(map(s => s.selectedFlash));
  }


}
