import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Directive({
  selector: '[dsSvgInline]'
})
export class SvgInlineDirective implements AfterViewInit {
  private _path: string;
  @Input('dsSvgInline')
  set path(v: string) {
    this._path = v;
  }

  get path() {
    return this._path;
  }

  private svg: string = null;

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private ren2: Renderer2,
    private http: HttpClient,
  ) {
  }

  ngAfterViewInit() {

    this.http.get(this.path, { responseType: 'text' }).subscribe(res => {
      this.svg = res;
      if (this.svg.startsWith('<svg')) {
        this.ren2.setProperty(this.elRef.nativeElement, 'innerHTML', this.svg);
      } else {
        console.groupCollapsed('SVG Inline for ' + this.path);
        console.log(`%cExpected file to start with "<svg", instead it starts with "${ res.substr(0, 5) }"`, 'font-weight:bold;font-size:18px;color:red');
        console.error(res);
        console.groupEnd();
      }
    }, err => {
      console.error('Failed to load ', this.path);
    });
  }
}
