import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseMailConfirmComponent } from './mail-confirm.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/mail-confirm`,
    component: FuseMailConfirmComponent
  },

  {
    path: 'mail-confirm',
    component: FuseMailConfirmComponent
  },
];

@NgModule({
  declarations: [
    FuseMailConfirmComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ]
})

export class MailConfirmModule
{

}
