import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserModel} from 'src/app/main/content/models/user/user.def';
import {AnonUserService} from 'src/app/main/content/services/user/anon-user.service';
import {ErrorService} from 'src/app/main/content/services/helpers/error.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'src/app/functions/validators';
import {of, Subscription} from 'rxjs';
import {AuthService} from 'src/app/main/content/services/auth/auth.service';
import {ThemingService} from 'src/app/theme/theming.service';
import {catchError, tap} from 'rxjs/operators';
import {AccountIndexClass} from '../../../../../../constants/router-params';
import {Router} from '@angular/router';
import { HelperService } from '../../../../services/helpers/helper.service';

@Component({
  selector: 'ds-anon-register',
  templateUrl: './anon-register.component.html',
  styleUrls: ['./anon-register.component.scss']
})
export class AnonRegisterComponent implements OnInit, OnDestroy, AfterViewInit {
  subs = new Subscription();

  private _user: UserModel;
  @Input()
  set user(v: UserModel) {
    this._user = v;
  }

  get user() {
    return this._user;
  }

  @Output() userRegistered = new EventEmitter<UserModel>();
  @Output() switchToLogin = new EventEmitter<string>();
  @Output() submit = new EventEmitter<void>();

  loading = false;
  showLoginButton = false;
  showTerms = true;

  form: FormGroup;

  get firstNameControl() {
    return this.form.controls.first_name as FormControl;
  }

  get lastNameControl() {
    return this.form.controls.last_name as FormControl;
  }

  get passwordControl() {
    return this.form.controls.password as FormControl;
  }

  get emailControl() {
    return this.form.controls.email as FormControl;
  }

  get termsControl() {
    return this.form.controls.terms as FormControl;
  }

  constructor(
    private helper: HelperService,
    private anonService: AnonUserService,
    private errService: ErrorService,
    private fb: FormBuilder,
    private auth: AuthService,
    public themingService: ThemingService,
    private router: Router,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }


  private createForm() {
    this.form = this.fb.group({
      first_name: [null, Validators.compose([Validators.required, CustomValidators.noWhitespaceValidator])],
      last_name: [null, Validators.compose([Validators.required, CustomValidators.noWhitespaceValidator])],
      password: [null, Validators.compose([Validators.required, CustomValidators.passwordPattern])],
      email: [null, Validators.compose([Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)])],
      terms: [false, Validators.requiredTrue]
    });
  }

  updateUser() {
    if (this.form.invalid) return;

    this.loading = true;

    this.user.first_name = this.firstNameControl.value;
    this.user.last_name = this.lastNameControl.value;
    this.user.password = this.passwordControl.value;
    this.user.email = this.emailControl.value;



    const userModel: UserModel = {
      first_name: this.firstNameControl.value,
      last_name: this.lastNameControl.value,
      email: this.emailControl.value,
      id: this.user.id,
      password: this.passwordControl.value
    };

    const updateAnonUser$ = this.anonService.updateAnonUser(userModel);
    updateAnonUser$.pipe(
      tap({
        next: async () => {
          this.loading = false;
          this.submit.emit();
          // this.helper.showAlert('Registration is successful, please check your email');
          localStorage.setItem('verify_email_msg', 'true');
          await this.router.navigate([ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/login' ]);
        }
      }),
      catchError((err) => {
        this.loading = false;
        if (err.error.trans_code && err.error.trans_code === 'exception.email.taken') {
          this.loading = false;
          this.errService.handleHttpErrorForLogedOutUser(err, {}, false);
          this.showLoginButton = true;
        } else {
          console.log('Error updating anon user:', err);
          this.loading = false;
          this.errService.handleHttpErrorForLogedOutUser(err, {}, false);
        }
        return of(null);
      })
    ).subscribe();

  }


  emitLoginEvent() {
    this.switchToLogin.emit(this.user.email);
  }


  /**
   * Opens the brazenx T&C
   */
  openTerms() {
    window.open('https://www.manpowergroup.com/terms-of-use');
  }

  openPrivacyPolicy() {
    window.open('https://www.manpowergroup.com/privacy-policy');
  }

  ngAfterViewInit(): void {


  }
}
