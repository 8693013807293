import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterParamsService } from '../main/main-service/router-params.service';
import { ACCOUNT_NAME, AccountIndexClass } from '../constants/router-params';

export class BaseRouteParamComponent implements OnInit, OnDestroy {
  /** Starts with '/' */
  accountNameParam = '';

  paramSub = new Subscription();

  constructor(
    protected routerParams: RouterParamsService
  ) {
  }

  ngOnInit() {
    this.paramSub.add(
      this.routerParams.params.subscribe(params => {
        const p = params[ACCOUNT_NAME];

        if (p !== this.accountNameParam.replace('/', '')) {
          console.groupCollapsed('Base Router Params Changed');
          console.log('Account name: ', p);

          if (p) {
            this.accountNameParam = '/' + p;
            AccountIndexClass.ACCOUNT_INDEX = p;
            AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + p;
          } else {
            this.accountNameParam = '';
            AccountIndexClass.ACCOUNT_INDEX = '';
            AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '';
          }

          console.log('Account name param: ', this.accountNameParam);
          console.groupEnd();
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }
}

