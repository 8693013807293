import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { UsersService } from '../../../services/admin/users.service';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/functions/validators';

export class BaseResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;

  user_id;
  token;

  accountNameParam = '';

  subs = new Subscription();

  constructor(
    protected userService: UsersService,
    protected helper: HelperService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected errService: ErrorService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService
  ) {
  }

  ngOnInit() {
    this.subs.add(
      this.routerParams.params.subscribe(param => {
        const p = param[ACCOUNT_NAME];
        if (p) {
          this.accountNameParam = '/' + p;
        } else {
          this.accountNameParam = '';
        }
      })
    );


    this.resetPasswordForm = this.formBuilder.group({
      password: [ '', Validators.compose([ Validators.required, CustomValidators.passwordPattern ]) ]
    });

    this.user_id = this.route.snapshot.queryParams['id'];
    this.token = this.route.snapshot.queryParams['token'];

    if (!this.user_id || !this.token) {
      this.router.navigate([ `${ this.accountNameParam }/login` ]);
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  get passwordControl() {
    return this.resetPasswordForm.controls.password as FormControl;
  }

  resetPassword() {
    this.userService.resetPassword(this.user_id, this.passwordControl.value, this.token).subscribe(res => {
      this.helper.showAlertWithouAutoDismiss(res.message);
      this.router.navigate([ `${ this.accountNameParam }/login` ]);

    }, (err) => {
      console.log(err);
      this.errService.handleHttpErrorForLogedOutUser(err, {msg: 'This token has already been used.'});
    });
  }
}
