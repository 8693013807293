import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'gear-definition-dialog',
  templateUrl: './gear-definition-dialog.component.html',
  styleUrls: ['./gear-definition-dialog.component.scss']
})
export class GearDefinitionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GearDefinitionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
  }

  public static openDialog(matDialog: MatDialog, data: string): Observable<void> {
    return matDialog.open(GearDefinitionDialogComponent, { data, maxWidth: '400px' }).afterClosed();
  }

  ngOnInit() {
    console.log(this.data);
  }

}
