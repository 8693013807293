import { Component, OnInit, Input } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { CoreDriver } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-core-drivers-user-details-card',
  templateUrl: './core-drivers-user-details-card.component.html',
  styleUrls: ['./core-drivers-user-details-card.component.scss']
})
export class CoreDriversUserDetailsCardComponent implements OnInit
{

  private _user: UserModel;
  @Input() set user(v: UserModel)
  {
    this._user = v;
  }
  get user() { return this._user; }


  private _profilePicture;
  @Input() set profilePicture(v: string)
  {
    this._profilePicture = v;
  }
  get profilePicture() { return this._profilePicture; }


  private _coreDrivers: CoreDriver[] = [];
  @Input() set coreDrivers(v: CoreDriver[]) { this._coreDrivers = v ? v : []; }
  get coreDrivers() { return this._coreDrivers; }


  get isAnon() { return this.user.status === 'ANONYMOUS'; }

  constructor(
    public themingService: ThemingService,
  )
  { }

  ngOnInit()
  {}

}
