import { Router } from '@angular/router';
import { Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth/auth.service';
import { UserAssessment } from '../../../models/admin/assesment.def';
import { Subscription } from 'rxjs';
import { ThemingService } from 'src/app/theme/theming.service';
import { AssesmentService } from '../../../services/user/assesment.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { RetakeQuizDialogComponent } from '../dialogs/retake-quiz-dialog/retake-quiz-dialog.component';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';

export class BaseDashboardComponent extends BaseRouteParamComponent implements OnInit, OnDestroy
{
  loading = false;
  name = '';

  @Input() userAssessments: UserAssessment[] = [];

  subs = new Subscription();

  constructor(
    public themingService: ThemingService,
    protected auth: AuthService,
    protected assesmentService: AssesmentService,
    protected router: Router,
    protected dialog: MatDialog,
    protected errService: ErrorService,
    protected routerParams: RouterParamsService
  )
  {
    super(routerParams);
    this.name = this.auth.user.first_name;
  }

  ngOnInit()
  {
    super.ngOnInit();

    console.log('Recived assessments: ', this.userAssessments);
    if (!this.userAssessments)
    {
      this.userAssessments = [];
    }
  }

  ngOnDestroy()
  {
    super.ngOnDestroy();
    if (this.subs) { this.subs.unsubscribe(); }
  }

  /**
   * Open assessment results page
   * @param assesment_id
   */
  viewAssesmentResults(assesment_id: string) {
    this.router.navigate([`${this.accountNameParam}/user/assessment/${assesment_id}/results`]);
  }

  /**
   * Loads the quiz
   * @param item
   */
  loadForm(ass: UserAssessment)
  {
    if (ass.status === 'completed' || ass.status === 'pending')
    {
      const dialogRef = this.dialog.open(RetakeQuizDialogComponent, {
        autoFocus: false,
        closeOnNavigation: true,
        data: ass.assessment.name
      });

      dialogRef.afterClosed().subscribe(d_res =>
      {
        if (d_res)
        {
          this.router.navigate([`${this.accountNameParam}/assessment/${ass.assessment.form_id}`], { queryParams: { 'new': true } });
        }
      });
    }
    else
    {
      this.router.navigate([`${this.accountNameParam}/assessment/${ass.assessment.form_id}`]);
    }
  }
}
