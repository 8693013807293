import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TsqColor, TsqStore } from '../../tsq.store';
import { QuizQuestion } from '../../../../../models/user/quiz.model';
import { MatRadioChange } from '@angular/material';

export interface SelectedAnswer {
  numberOfQuestions: number;
  selectedAnswer?: string;
  currentStep?: number;
}

export interface AnswerItem {
  value: string;
  step: number;
  key: string;
  text: string;
  opened_at?: string;
}

export interface QuestionLabel {
  id: string;
  label: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-questions-page',
  templateUrl: './tsq-questions-page.component.html',
  styleUrls: ['./tsq-questions-page.component.scss']
})
export class TsqQuestionsPageComponent implements OnInit, OnChanges {
  private _answers: Array<AnswerItem> = [];
  private _step: number;
  subtitle: string;
  previousEnabled: boolean = true;
  nextEnabled: boolean = false;
  description: string;


  get answers(): AnswerItem[] {
    return this._answers;
  }

  @Input()
  set answers(value: AnswerItem[]) {
    if (this._answers.length === 0) {
      this._answers = value;
    }
  }


  @Input()
  color: TsqColor = 'blue';
  @Input()
  title: string;
  @Input()
  questions: Array<QuestionLabel> = [];
  @Input()
  quizQuestions: Array<QuizQuestion> = [];
  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  back: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set step(value: number) {
    this._step = value;
    this.description = this.quizQuestions[value].properties.text;
    this.subtitle = this.quizQuestions[value].properties.subtitle;
  }

  get step() {
    return this._step;
  }

  @Output()
  updateAnswer: EventEmitter<{ answers: AnswerItem[], answer: string }> = new EventEmitter<{ answers: AnswerItem[], answer: string }>();


  getAnswer(step: number): string {
    const index = this.answers.map(answer => answer.step).indexOf(step);
    if (index >= 0) {
      return this.answers[index].value;
    } else {
      return null;
    }
  }


  descriptionLength(description: string): number {
    return description.length;
  }

  constructor(
    private _store: TsqStore
  ) {
  }

  ngOnInit() {
  }


  onChangeAnswer(matRadioChange: MatRadioChange, step: number) {
    const index = this.answers.map(answer => answer.step).indexOf(step);
    const labels: Array<QuestionLabel> = this.quizQuestions[step].properties.labels as any;
    const idx = labels.map(label => label.id).indexOf(matRadioChange.value);

    if (idx === -1) return;

    if (this.nextEnabled) {
      this.nextEnabled = false;
    }


    console.log('question', this.quizQuestions[step]);

    if (index === -1) {
      this._answers.push({
        value: matRadioChange.value,
        step: step,
        key: this.quizQuestions[step].field_key,
        text: labels[idx].label,
        opened_at: this.quizQuestions[step].opened_at
      });
    } else {
      this._answers[index] = {
        value: matRadioChange.value,
        step: step,
        key: this.quizQuestions[step].field_key,
        text: labels[idx].label,
        opened_at: this.quizQuestions[step].opened_at
      };
    }

    this.previousEnabled = true;

    this.updateAnswer.emit({ answers: this.answers, answer: this.quizQuestions[step].field_key });

    setTimeout(() => {
      this.next.emit();
    }, 0);

  }

  onBack() {
    if (!this.previousEnabled) return;
    this.nextEnabled = true;
    this.previousEnabled = false;
    if (this.step === 0) {
      this._store.pageType = 'landing';
    } else {
      this.back.emit();
    }

  }

  onNext() {
    if (!this.nextEnabled) return;
    this.nextEnabled = false;
    this.previousEnabled = true;
    this.next.emit();
  }

  continue() {
    this.nextEnabled = false;
    this.previousEnabled = true;
    this.next.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  hasDescription(description: string) {
    if (description) {
      return !description.includes('$');
    }

    return false;
  }

  getDescription(description: string) {
    let newDescription = description;

    for (let i = 0; i < 50; i++) {
      newDescription = `${ newDescription } &nbsp;`;
    }
    return newDescription;
  }
}
