import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ECharts, EChartOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ds-echart-graph',
  templateUrl: './echart-graph.component.html',
  styleUrls: ['./echart-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EchartGraphComponent implements OnInit, OnDestroy
{
  @ViewChild('myChart', { static: true }) chartElement: ElementRef<HTMLDivElement>;

  private chartCanvas: ECharts;
  private _option: EChartOption;
  @Input() set option(value: EChartOption)
  {
    this._option = value;
    if (this.initialized && !this.chartCanvas.isDisposed())
    {
      console.log('Set option after data change');
      try
      {
        this.chartCanvas.setOption(this._option);
      }
      catch (error)
      {
        console.log('Error for chart ', error);
      }
      this.calcHeight();
    }
  }
  get option()
  {
    return this._option;
  }

  private initialized = false;
  /** Do not set this variable manually, use the publicly available "height" variable instead */
  private _height = 500;
  private heightObservable = new BehaviorSubject<number>(500);
  set height(v: number)
  {
    this._height = v;
    this.heightObservable.next(this._height);
  }
  get height(): number { return this._height; }

  @Input() set forceResize(v: any) { this.resizeCanvas(); }

  error: any;


  private _minHeight = 500;
  @Input()
  set minHeight(v: number) { this._minHeight = v; }
  get minHeight() { return this._minHeight; }


  @Input() set canvasHeight(v: number)
  {
    let h = v;
    if (v < this.minHeight)
    {
      h = this.minHeight;
    }

    this.chartElement.nativeElement.style.height = `${h}px`;
    this.height = v;
  }

  constructor()
  {
    this.heightObservable.subscribe(v => this.resizeCanvas());
  }

  ngOnInit()
  {
    this.chartINIT();
    this.initialized = true;
    setTimeout(() => {
      try
      {
        this.chartCanvas.setOption(this._option);
        this.resizeCanvas();
      }
      catch (error)
      {
        console.log('Error for chart ');
        this.error = error;
        console.log(this.error);
        this.chartCanvas.dispose();
      }
    }, 0);
  }

  ngOnDestroy()
  {
    this.heightObservable.unsubscribe();
    this.chartCanvas.clear();
  }

  @HostListener('window:resize', ['$event'])
  resize(e)
  {
    this.resizeCanvas();
  }

  private resizeCanvas()
  {
    if (this.chartCanvas && !this.chartCanvas.isDisposed())
    {
      try
      {
        this.error = null;
        this.chartCanvas.resize();
      }
      catch (error)
      {
        console.log('Error for chart ', error);
        this.error = error;
        console.log(this.error);
      }
    }
  }

  private chartINIT()
  {
    this.chartCanvas = echarts.init(this.chartElement.nativeElement);

    /* this.option = {

    };

    setTimeout(() => {
      this.chartCanvas.setOption(this._option);
      this.resizeCanvas();
    }, 0); */
  }

  private calcHeight()
  {
    // this.height = (<EChartOption.YAxis>this._option.yAxis[0]).data.length * 70;
  }

}
