import { Injectable } from '@angular/core';
import { AccountModel } from '../../models/admin/account.def';

@Injectable({
  providedIn: 'root'
})
export class AccountSelectService
{
  private _selectedAccount: AccountModel;

  set selectedAccount(v: AccountModel)
  {
    this._selectedAccount = v;
    if (localStorage)
      localStorage.setItem('selectedAccount', JSON.stringify(v));
  }
  get selectedAccount(): AccountModel
  {
    if (localStorage)
    {
      if (localStorage.getItem('selectedAccount') !== 'undefined' && localStorage.getItem('selectedAccount') !== undefined)
      {
        return JSON.parse(localStorage.getItem('selectedAccount')) || this._selectedAccount;
      }
      else
      {
        console.log('Selected account is undefined', localStorage.getItem('selectedAccount'));
        return null;
      }
    }
    else
      return this._selectedAccount;
  }

  constructor()
  { }
}
