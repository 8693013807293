import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  DemographicsFormData
} from 'src/app/main/content/pages/shared/tsq-assesment/components/tsq-demographics/tsq-demographics.component';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { TsqStore } from 'src/app/main/content/pages/shared/tsq-assesment/tsq.store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'demographics-form-one',
  templateUrl: './demographics-form-one.component.html',
  styleUrls: ['./demographics-form-one.component.scss']
})
export class DemographicsFormOneComponent implements OnInit {
  @Input() set formData(user: DemographicsFormData) {
    this._formData = user;
    if (!user) return;
    this.jobTitleFormControl.setValue(user.job_title);
    this.emailFormControl.setValue(user.email);
    this.firstNameFormControl.setValue(user.first_name);
    this.lastNameFormControl.setValue(user.last_name);
    this.companyFormControl.setValue(user.company);
    this.termsAndCondition.setValue(user.terms);
  }


  constructor(private _fb: FormBuilder, private _auth: AuthService, private _store: TsqStore, private _usersService: UsersService) {
    this.form = this.createForm();
  }


  get jobTitleFormControl(): FormControl {
    return this.form.get('job_title') as FormControl;
  }

  get emailFormControl(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get firstNameFormControl(): FormControl {
    return this.form.get('first_name') as FormControl;
  }

  get lastNameFormControl(): FormControl {
    return this.form.get('last_name') as FormControl;
  }

  get companyFormControl(): FormControl {
    return this.form.get('company') as FormControl;
  }

  get termsAndCondition(): FormControl {
    return this.form.get('terms') as FormControl;
  }

  form: FormGroup;

  @Input() pending: boolean = false;


  private _formData: DemographicsFormData;

  @Output() submit: EventEmitter<DemographicsFormData> = new EventEmitter<DemographicsFormData>();

  @Output() skip: EventEmitter<void> = new EventEmitter<void>();


  createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this._usersService.checkEmail(control.value).pipe(
        catchError(() => of({ email: true })),
        map((res: any) => {
            console.log(res);
            if (res && res.email === true) return res;
            if (res && res.active) {
              return { emailExist: true };
            }

            return null;

          }
        )
      );
    };
  }

  ngOnInit() {
  }

  private createForm(): FormGroup {
    // Email, First name, Last name, Company,
    return this._fb.group({
      email: this._fb.control(null, [
        Validators.required,
        Validators.pattern(HelperService.EMAIL_REGEX)
      ], this.createValidator()),
      first_name: this._fb.control(null, [Validators.required]),
      last_name: this._fb.control(null, [Validators.required]),
      company: this._fb.control(null, [Validators.required]),
      job_title: this._fb.control(null, [Validators.required]),
      terms: this._fb.control(false, [Validators.required])
    });
  }

  onSubmit(value: DemographicsFormData) {
    console.log(value, this._formData);
    this.submit.emit({
      ...value,
      id: this._formData.id
    });
  }
}
