import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-core-drivers-table',
  templateUrl: './core-drivers-table.component.html',
  styleUrls: ['./core-drivers-table.component.scss']
})
export class CoreDriversTableComponent implements OnInit
{
  private _color = 'blue';
  @Input()
  set color(v: string) { this._color = v; }
  get color() { return this._color; }

  constructor() { }

  ngOnInit()
  {
  }

}
