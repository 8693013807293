import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseResetPasswordComponent } from './reset-password.component';
import { ResetPasswordDesign1Component } from './reset-password-design1/reset-password-design1.component';
import { ResetPasswordDesign2Component } from './reset-password-design2/reset-password-design2.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/reset-password`,
    component: FuseResetPasswordComponent
  },

  {
    path: 'reset-password',
    component: FuseResetPasswordComponent
  }
];

@NgModule({
  declarations: [
    FuseResetPasswordComponent,
    ResetPasswordDesign1Component,
    ResetPasswordDesign2Component
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ]
})

export class ResetPasswordModule
{

}
