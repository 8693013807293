import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'share-image',
  templateUrl: './share-image.component.html',
  styleUrls: ['./share-image.component.scss']
})
export class ShareImageComponent implements OnInit {
  @Input() title: string;
  @Input() icons: string[];
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
