import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TsqStore} from '../../tsq.store';
import {QuizSystemService} from '../../../../../services/quiz/quiz-system.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ShareService} from '../../../../share/share-services/share.service';
import {AssessmentResultsComponent} from '../../../../user/assessment-results/assessment-results.component';
import {AnonReportPageComponent} from '../../../../anonymous-quiz/anon-report-page/anon-report-page.component';
import { API } from 'src/app/api-url/api-urls';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


export interface Dimension {
  chart: string;
  href: string;
  description: string;
  ratinglongdescription: string;
  thankYou: string;
  thankYouMore: string;
  title: string;
}

export interface Report {
  score: {
    scores: {
      calculate: {
        dimension: {
          aggregateResponse: Dimension;
          acquiringHiringTalent: Dimension;
          engagingEvolvingTalent: Dimension;
          improvingCultureRetention: Dimension;
        }
      }
    }
  };
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-results',
  templateUrl: './tsq-results.component.html',
  styleUrls: ['./tsq-results.component.scss']
})
export class TsqResultsComponent implements OnInit, OnDestroy {

  subs: Subscription;
  scores = null;
  dimensions = null;
  isPrint: boolean;
  svg: string | null = null;
  aggregateResponse: Dimension;
  acquiringHiringTalent: Dimension;
  engagingEvolvingTalent: Dimension;
  improvingCultureRetention: Dimension;

  disableReportBtn = false;

  @Input()
  set report(value: Report) {
    this.aggregateResponse = value.score.scores.calculate.dimension.aggregateResponse;
    this.acquiringHiringTalent = value.score.scores.calculate.dimension.acquiringHiringTalent;
    this.engagingEvolvingTalent = value.score.scores.calculate.dimension.engagingEvolvingTalent;
    this.improvingCultureRetention = value.score.scores.calculate.dimension.improvingCultureRetention;
  }


  constructor(
    private _store: TsqStore,
    private _quizSystem: QuizSystemService,
    private _auth: AuthService,
    public sharedService: ShareService,
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.isPrint = this._store.isPrint;
    this.aggregateResponse.href = this.aggregateResponse.href.replace('%media_url%', API.MEDIA_API);

    if( this.router.routerState.snapshot.url.includes('admin'))
      this.disableReportBtn = true;

    this.subs = this.sharedService.downloadSvg(this.aggregateResponse.href).pipe(
      map( svg =>this.svg = svg)
    ).subscribe();
  }

  ngOnDestroy(): void {
    if(this.subs) this.subs.unsubscribe();
  }

  getHeight(): string {
    if (this.engagingEvolvingTalent.ratinglongdescription.length < 270) {
      return '290px';
    }

    return '350px';
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    this._auth.isLoggedIn() ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }


  mailMe() {
    const mail = document.createElement('a');
    mail.href = 'mailto:consulting.talentsolutions@manpowergroup.com';
    mail.click();
  }
}
