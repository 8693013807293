import { Component, OnInit, Input } from '@angular/core';
import { SubmittedAssessment } from 'src/app/main/content/models/admin/results.def';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import * as moment from 'moment';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';

@Component({
  selector: 'lq-details-card',
  templateUrl: './lq-details-card.component.html',
  styleUrls: ['./lq-details-card.component.scss']
})
export class LqDetailsCardComponent implements OnInit {
  @Input() result: SubmittedAssessment;

  arrayOfShortTraitDescriptions: string[] = [];
  descriptionText: string = '';
  myMoment = moment;
  constructor(
    public helper: HelperService
  ) { }

  ngOnInit() {
    this.getShortTraitDescriptions();
  }

  getShortTraitDescriptions() {
    this.arrayOfShortTraitDescriptions = Object.keys(this.result.score.scores.calculate.body).map(key => this.result.score.scores.calculate.body[key]['ratingshortdescription']);
    this.arrayOfShortTraitDescriptions.forEach(trait => {
      this.descriptionText += (trait + ' ');
    });
  }
}
