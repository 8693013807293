import { Injectable } from '@angular/core';
import { MANPOWER } from './theme.configs';
import { Title } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeModel } from './theme.models';


@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  id = MANPOWER.id;
  theme = MANPOWER.themeName;
  primary = MANPOWER.primary;
  accent = MANPOWER.accent;
  logoURL = MANPOWER.logoURL;
  title = MANPOWER.title;
  fullLogoUrl = MANPOWER.fullLogoURL;
  bgGradient = MANPOWER.backgroundGradient;
  bgGradientPicture = MANPOWER.backgroundGradientPicture;
  logoLinkURL = MANPOWER.logoLinkURL;
  showQuizInfo = MANPOWER.showQuizInfo;
  dashboardIntroText = MANPOWER.dashboardIntroText;
  backgroundPath = MANPOWER.backgroundPath;
  dashboardActionButtonText = MANPOWER.dashboardActionButtonText;
  helpPageUrl = MANPOWER.helpPageUrl;
  toolbarBGColor = MANPOWER.toolbarBGColor;
  toolbarFontColor = MANPOWER.toolbarFontColor;
  fontFamily = MANPOWER.fontFamily;
  linkToCookiePolicy = MANPOWER.linkToCookiePolicy;
  loginDesign = MANPOWER.loginDesign;
  registerDesign = MANPOWER.registerDesign;
  forgotPasswordDesing = MANPOWER.forgotPasswordDesing;
  resetPasswordDesing = MANPOWER.resetPasswordDesing;
  dashboardDesign = MANPOWER.dashboardDesign;
  quizQuestionBolded = MANPOWER.quizQuestionBolded;
  quizUsePrimaryColor = MANPOWER.quizUsePrimaryColor;
  quizCenterQuestion = MANPOWER.quizCenterQuestion;
  faviconURL = MANPOWER.faviconURL;

  constructor(
    private overlayContainer: OverlayContainer,
    private titleService: Title
  ) {
    setFavicon(this.faviconURL);
  }

  /**
   * Sets the theme of the application
   * @param account_id
   */
  setTheme(account_id: number) {
    if (account_id !== this.id) // don't set the theme that is already set
    {
      switch (account_id) {
        case MANPOWER.id:
          this.setThemeVariables(MANPOWER);
          break;

        default: // Default case, use deeprt signals colors
          this.setThemeVariables(MANPOWER);
          break;
      }
    }
  }


  /**
   * Sets the theme variables to the theming service variables
   */
  private setThemeVariables(theme: ThemeModel) {
    const prevBodyClass: string = document.body.classList.item(2);

    this.id = theme.id;
    this.theme = theme.themeName;
    this.primary = theme.primary;
    this.accent = theme.accent;
    this.logoURL = theme.logoURL;
    this.title = theme.title;
    this.fullLogoUrl = theme.fullLogoURL;
    this.bgGradient = theme.backgroundGradient;
    this.bgGradientPicture = theme.backgroundGradientPicture;
    this.logoLinkURL = theme.logoLinkURL;
    this.showQuizInfo = theme.showQuizInfo;
    this.dashboardIntroText = theme.dashboardIntroText;
    this.backgroundPath = theme.backgroundPath;
    this.dashboardActionButtonText = theme.dashboardActionButtonText;
    this.helpPageUrl = theme.helpPageUrl;
    this.toolbarBGColor = theme.toolbarBGColor;
    this.toolbarFontColor = theme.toolbarFontColor;
    this.fontFamily = theme.fontFamily;
    this.linkToCookiePolicy = theme.linkToCookiePolicy;
    this.loginDesign = theme.loginDesign;
    this.registerDesign = theme.registerDesign;
    this.forgotPasswordDesing = theme.forgotPasswordDesing;
    this.resetPasswordDesing = theme.resetPasswordDesing;
    this.dashboardDesign = theme.dashboardDesign;
    this.quizQuestionBolded = theme.quizQuestionBolded;
    this.quizUsePrimaryColor = theme.quizUsePrimaryColor;
    this.quizCenterQuestion = theme.quizCenterQuestion;
    this.clearOverlayTheme(theme.themeName);

    this.titleService.setTitle(theme.title);
    setFavicon(theme.faviconURL);

    if (prevBodyClass) {
      document.body.classList.replace(prevBodyClass, this.theme);
    } else {
      document.body.classList.add(this.theme);
    }
  }

  /**
   * Removes the previous class from the overlay component and sets a new one
   */
  private clearOverlayTheme(newClass: string) {
    if (this.overlayContainer.getContainerElement().classList.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(this.overlayContainer.getContainerElement().classList.item(1));
      this.overlayContainer.getContainerElement().classList.add(newClass);
    } else {
      this.overlayContainer.getContainerElement().classList.add(newClass);
    }
  }

  /**
   * Resets the theme name and sets the primary and accent colors to the provided ones
   * @param primaryColor
   * @param accentColor
   */
  setCustomThemeColors(primaryColor: string, accentColor: string) {
    this.theme = '';
    this.primary = primaryColor;
    this.accent = accentColor;
  }

  /**
   * Returns an rgba string from primary color's hex value
   * @param alpha [0-1] value
   */
  getPrimaryColor_RGBA_Value(alpha: number = 0.15) {
    const rgb = hexToRgb(this.primary);
    return `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ alpha })`;
  }

  /**
   * Returns an rgba string from accent color's hex value
   * @param alpha [0-1] value
   */
  getAccentColor_RGBA_Value(alpha: number = 0.15) {
    const rgb = hexToRgb(this.accent);
    return `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ alpha })`;
  }

  /**
   * Open the link that the theme links to
   */
  openLogoLink() {
    if (this.logoLinkURL) {
      window.open(this.logoLinkURL, '_blank');
    }
  }
}

/**
 * Converts HEX colors to RGB values
 * @param hex
 */
function hexToRgb(hex): { r: number, g: number, b: number } {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

/**
 * Function to remove the favicon
 */
function removeFavicon() {
  const links = document.getElementsByTagName('link');
  const head = document.getElementsByTagName('head')[0];
  for (let i = 0; i < links.length; i++) {
    if (links[i].getAttribute('rel') === 'icon') {
      head.removeChild(links[i]);
    }
  }
}


/**
 * Chrome allows you to simply tweak the HREF of the LINK tag.
 * Firefox appears to require that you remove it and read it.
 * @param url
 */
function setFavicon(url) {
  removeFavicon();
  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
  /* if (window.console)
  {
    console.log('Set FavIcon URL to ' + getFavicon().href);
  } */
}

function getFavicon() {
  const links = document.getElementsByTagName('link');
  for (let i = 0; i < links.length; i++) {
    if (links[i].getAttribute('rel') === 'icon') {
      return links[i];
    }
  }
  return undefined;
}

