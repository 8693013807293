import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ds-overwrite-quiz-results-dialog',
  templateUrl: './overwrite-quiz-results-dialog.component.html',
  styleUrls: ['./overwrite-quiz-results-dialog.component.scss']
})
export class OverwriteQuizResultsDialogComponent implements OnInit
{

  constructor(
    public dialogRef: MatDialogRef<OverwriteQuizResultsDialogComponent>
  ) { }

  ngOnInit()
  {
  }

}
