import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QuizHeader } from 'src/app/main/content/models/user/quiz.model';
import { AppStore } from 'src/app/app.store';
import {
  GearDefinitionDialogComponent
} from 'src/app/main/content/pages/shared/gear-assessment/gear-definition-dialog/gear-definition-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gear-questions',
  templateUrl: './gear-questions.component.html',
  styleUrls: ['./gear-questions.component.scss']
})
export class GearQuestionsComponent implements OnInit, OnDestroy {
  private destroySubject: ReplaySubject<void> = new ReplaySubject<void>();

  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();


  showPrevious: boolean = false;
  showNext: boolean = false;

  @Input()
  quizHeader: QuizHeader;
  @Input()
  progress: number = 0;
  @Input()
  index: number = 0;
  selected: string = null;
  flashSelected$: Observable<string>;

  constructor(private appStore: AppStore, private matDialog: MatDialog, private ref: ChangeDetectorRef) {
    this.appStore.showPrevious$.subscribe(res => this.showPrevious = res);
    this.appStore.showNext$.subscribe(res => this.showNext = res);
    this.appStore.selected$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(100)
    ).subscribe((selected) => {
      console.log('selected', selected);
      this.selected = selected;
      this.ref.detectChanges();
    });
    this.appStore.fleshSelected = 'None';
    this.flashSelected$ =  this.appStore.fleshSelected$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(100)
      );
  }

  ngOnInit() {
  }


  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get destroy$() {
    return this.destroySubject.asObservable();
  }

  get buttonMore() {
    return this.quizHeader.properties.button_more;
  }

  onSelect(id: string) {
    const newId = String(Number(id) - 1);
    this.appStore.selected = id;
    this.appStore.fleshSelected = id;
    this.appStore.showPrevious = true;
    this.appStore.showNext = false;
    setTimeout(() => {
      this.next.emit(newId);
    });
    setTimeout(() => {
      this.appStore.fleshSelected = 'None';
    }, 600);
  }

  onPrevious() {
    this.previous.emit();
    this.appStore.showNext = true;
    this.appStore.showPrevious = false;
  }

  onNext() {
    if (this.selected) {
      setTimeout(() => {
        const selectedInt = Number(this.selected) - 1;
        this.next.emit(String(selectedInt));
      });
      this.appStore.showNext = false;
      this.appStore.showPrevious = true;
    }

  }

  openDefinitionDialog() {
    GearDefinitionDialogComponent.openDialog(this.matDialog, this.buttonMore).subscribe();
  }
}
