import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseLoginDesignComponent } from '../base-login-design.component';
import { AuthService as SocialService } from 'angular-6-social-login';
import { fuseAnimations } from 'src/app/animations/animations';
import { AuthGuard } from 'src/app/main/content/guards/auth.guard';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { VerifyService } from 'src/app/main/content/services/auth/verify.service';
import { Observable } from 'rxjs';
import { AppStore } from 'src/app/app.store';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-login-design1',
  templateUrl: './login-design1.component.html',
  styleUrls: [ './login-design1.component.scss' ],
  animations: fuseAnimations
})
export class LoginDesign1Component extends BaseLoginDesignComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;

  showSocialLogin = true;

  @HostListener('window:beforeunload', ['$event'])
    handleBeforeunload(event) {
      localStorage.removeItem('verify_email_msg');
    }


  constructor(
    protected authGuard: AuthGuard,
    public authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected title: Title,
    protected verifyService: VerifyService,
    protected translocoService: TranslocoService,
    public themingService: ThemingService,
    
    public appStore: AppStore
  ) {
    super(authGuard, authService, router, helper, errorService, socialService, formBuilder, routerParams, dialog, route, title, verifyService, translocoService, themingService,);

    if (authService.isLoggedIn()) {
      this.isLoggedIn = true;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      console.log('params', params);
      this.email.setValue(params.email);
      if (params.email) {
        localStorage.removeItem('invite_login_email');
        this.email.setValue(params.email);
        localStorage.setItem('invite_login_email', params.email);
      } else {
        const inviteLoginEmail = localStorage.getItem('invite_login_email');
        if (inviteLoginEmail) this.email.setValue(inviteLoginEmail);
      }
    });
    const login_element: HTMLElement = document.getElementById('login');
    if (this.themingService.bgGradient) {
      login_element.style.background = this.themingService.bgGradient;
    } else if (this.themingService.bgGradientPicture) {
      login_element.style.background = `url('${ this.themingService.bgGradientPicture }')`;
    }
    if (localStorage.getItem('show_social_login'))
      this.showSocialLogin = localStorage.getItem('show_social_login') === 'true';
  }

  ngOnDestroy(): void {
    localStorage.removeItem('verify_email_msg');
  }
}
