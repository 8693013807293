import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssesmentResultModel } from 'src/app/main/content/models/admin/assesment.def';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-more-details-dialog',
  templateUrl: './more-details-dialog.component.html',
  styleUrls: [ './more-details-dialog.component.scss' ]
})
export class MoreDetailsDialogComponent implements OnInit {
  result: AssesmentResultModel;

  constructor(
    public dialogRef: MatDialogRef<MoreDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssesmentResultModel,
    public themingService: ThemingService
  ) {
  }

  ngOnInit() {
    this.result = this.data;
  }
}
