import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
  name: 'isControlRequired'
})
export class IsControlRequiredPipe implements PipeTransform {

  transform(value: FormControl): boolean {
    const validator = value.validator({} as AbstractControl);
    return !!(validator && validator.required);
  }

}
