import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { BaseVerifyComponent } from '../base-verify-component';
import { MatDialog } from '@angular/material/dialog';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-public-link-verify',
  templateUrl: './public-link-verify.component.html',
  styleUrls: ['./public-link-verify.component.scss']
})
export class PublicLinkVerifyComponent extends BaseVerifyComponent implements OnInit, OnDestroy
{
  @HostBinding('style.backgroundImage') bgIMG: string;
  @HostBinding('style.backgroundColor') bgColor: string;

  constructor(
    protected routerParams: RouterParamsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected inviteService: InvitesService,
    protected auth: AuthService,
    protected helper: HelperService,
    protected dialog: MatDialog,
    public themingService: ThemingService,
    protected anonService: AnonUserService,
    protected translocoService: TranslocoService
  )
  {
    super(routerParams, route, router, inviteService, auth, helper, dialog, themingService, anonService, translocoService);
  }

  ngOnInit()
  {
    super.ngOnInit();

    if (this.themingService.bgGradient)
    {
      this.bgColor = this.themingService.bgGradient;
    }
    else if (this.themingService.bgGradientPicture)
    {
      this.bgIMG = `url('${this.themingService.bgGradientPicture}')`;
    }

    this.checkIfUserIsLoggedIn().then(res =>
    {
      if (!res)
      {
        if (this.skipVerify)
        {
          this.handleInvite();
        }
        else
        {
          this.handleInvite();
        }
      }
    });
  }
}
