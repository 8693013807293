import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { API } from 'src/app/api-url/api-urls';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { downloadFile } from 'src/app/functions/helpful-functions';
import { AppStore } from 'src/app/app.store';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { Subscription } from 'rxjs';

export interface GearReportForm {
  client_name: string;
  group_name: string;
  locale: string;
}


@Component({
  selector: 'gear-report-dialog',
  templateUrl: './gear-report-dialog.component.html',
  styleUrls: ['./gear-report-dialog.component.scss']
})
export class GearReportDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  exporting: boolean = false;

  localizations: any;
  subs: Subscription

  constructor(
    private appStore: AppStore,
    public dialogRef: MatDialogRef<GearReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    public errService: ErrorService
  ) {
    this.form = this.createForm();
  }


  public static openDialog(matDialog: MatDialog, config: any) {
    return matDialog.open(GearReportDialogComponent, { ...config }).afterClosed();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      client_name: this.fb.control(null, Validators.required),
      group_name: this.fb.control(null, Validators.required),
      locale: this.fb.control(null, Validators.required)
    });
  }

  get clientNameFormControl(): FormControl {
    return this.form.get('client_name') as FormControl;
  }

  get groupNameFormControl(): FormControl {
    return this.form.get('group_name') as FormControl;
  }

  get localeFormControl(): FormControl {
    return this.form.get('locale') as FormControl;
  }

  get disableButton(): boolean {
    return this.form.invalid || this.form.pending || this.exporting;
  }

  ngOnInit() {

     this.subs = this.appStore.localizations$.pipe(
      map( locale => {
        this.localizations = Object.entries(locale);
        return locale;
      })
    ).subscribe()

  }

  ngOnDestroy(): void {
    if(this.subs) this.subs.unsubscribe();
  }

  onSubmit(value: GearReportForm) {
    this.exporting = true;
    const payload = { ...this.data, ...value };
    console.log(payload);

    const options: any = this.auth.httpOptions;
    const url = API.APIDOMAIN + `report/${ this.data.form_id }/gear`;
    if (this.data.userToken) {
      options.headers = {
        'Content-Type': 'application/pdf',
        'X-TOKEN': this.data.userToken
      };

    }
    options.responseType = 'blob' as 'json';
    options.observe = 'response';


    return this.http.post<any>(url, payload, options).pipe(
      tap((res: any) => {
        downloadFile(
          res.body,
          res.headers.get('content-type'),
          res.headers.get('content-disposition').split('=')[1]
        );
        this.exporting = false;
        this.dialogRef.close(value);
      })
    ).subscribe(
      () => {},
      (err) => {this.errService.handleHttpErrorForLogedInUser(err); this.dialogRef.close();},
      () => {this.dialogRef.close(value)}
    );

  }
}
