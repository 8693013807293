import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'systemDateFormat'
})
export class SystemDateFormatPipe implements PipeTransform {

  transform(value: string): string {
    const locale = navigator.language;
    const date = new Date(value);

    const formated = (new Intl.DateTimeFormat(locale, {
      hour12: false,
    }).format(date));

     return `${locale} : ${formated}`;
  }

}
