import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/app/animations/animations';
import { ActivatedRoute } from '@angular/router';
import { ThemingService } from 'src/app/theme/theming.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-mail-confirm',
  templateUrl: './mail-confirm.component.html',
  styleUrls: ['./mail-confirm.component.scss'],
  animations: fuseAnimations
})
export class FuseMailConfirmComponent implements OnInit, OnDestroy
{
  email = 'test@test.com';
  accountNameParam = '';

  subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private routerParams: RouterParamsService,
    public themingService: ThemingService
  )
  { }

  ngOnInit()
  {
    this.subs.add(
      this.routerParams.params.subscribe(param =>
      {
        const p = param[ACCOUNT_NAME];
        if (p)
        {
          this.accountNameParam = '/' + p;
        }
        else
        {
          this.accountNameParam = '';
        }
      })
    );

    const _element: HTMLElement = document.getElementById('mail-confirm');
    if (this.themingService.bgGradient)
    {
      _element.style.background = this.themingService.bgGradient;
    }
    else if (this.themingService.bgGradientPicture)
    {
      _element.style.background = `url('${this.themingService.bgGradientPicture}')`;
    }


    this.route.queryParams.subscribe((params) =>
    {
      this.email = params.email;
    });
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
