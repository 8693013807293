import { Component, OnInit, Input } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { JQDiagnosticResult } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-jqdiagnostic-user-report',
  templateUrl: './jqdiagnostic-user-report.component.html',
  styleUrls: ['./jqdiagnostic-user-report.component.scss']
})
export class JQdiagnosticUserReportComponent implements OnInit
{
  @Input() result: JQDiagnosticResult;

  _showThumbs = true;
  @Input()
  set showThumbs(v: boolean) { this._showThumbs = v; }
  get showThumbs() { return this._showThumbs; }

  ethicsSolutions = [
    {
      title: 'Barrels not apples',
      subtitle: 'Reflect on your workplace culture and explore what really makes an ethical company.',
      link: 'https://themindgym.com/topics/barrels-not-apples/'
    },
    {
      title: 'Before the fall',
      subtitle: 'Keeping our cool in the heat of the moment benefits our professional and personal lives alike.',
      link: 'https://themindgym.com/topics/before-the-fall/'
    },
    {
      title: 'Good Business',
      subtitle: 'Don\'t choose between right actions and right results. Equip your team to see things differently.',
      link: 'https://themindgym.com/topics/good-business/'
    },
    {
      title: 'In Fairness',
      subtitle: 'In order to be fair and ethical leaders, we must be prepared to communicate and defend our decisions.',
      link: 'https://themindgym.com/topics/in-fairness/'
    },
  ];

  inclusionSolutions = [
    {
      title: 'Hearing what people say',
      subtitle: 'Become a good listener, build stronger relationships, and gain new ideas from those around you.',
      link: 'https://themindgym.com/topics/hearing-what-people-say/'
    },
    {
      title: 'Ignite inclusion',
      subtitle: 'Pull the curtains back on the unconscious mind, seize control over subtle biases, and enhance inclusivity.',
      link: 'https://themindgym.com/topics/ignite-inclusion/'
    },
    {
      title: 'Micro messaging',
      subtitle: 'Learn how to drive inclusion by avoiding and calling out subtle everyday acts of exclusion.',
      link: 'https://themindgym.com/topics/micro-messaging/'
    },
    {
      title: 'One of us',
      subtitle: 'Only when we understand why embracing diversity is difficult can we build a culture of inclusion.',
      link: 'https://themindgym.com/topics/one-of-us/'
    },
  ];

  respectWorkouts = [
    {
      title: 'Call it out',
      subtitle: 'Speaking up is the best way to nip bullying at the bud. So why are so many afraid to say something about bad behavior?',
      link: 'https://themindgym.com/topics/call-it-out/'
    },
    {
      title: 'Fair warning',
      subtitle: 'Harassment isn\'t always easy to spot. To curb it, managers need to identify its more subtle signs and understand how to take action.',
      link: 'https://themindgym.com/topics/fair-warning/'
    },
    {
      title: 'Help at hand',
      subtitle: 'In a harassment investigation, we can end up walking the thin line between supportive and authoritative. Learn how to navigate the situation responsibly.',
      link: 'https://themindgym.com/topics/help-at-hand/'
    },
    {
      title: 'Respect me',
      subtitle: 'What may seem harmless can cause real damage and erode morale. Like anything, when we understand the problem, solving it is easier.',
      link: 'https://themindgym.com/topics/respect-me/'
    },
  ];

  constructor(
    public themingService: ThemingService
  )
  {}

  ngOnInit()
  {
  }

  openLink(link: string)
  {
    window.open(link, '_blank');
  }
}
