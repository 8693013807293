import { Component, OnInit, Input } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { Subscription } from 'rxjs';
import { ShareService } from '../../../share/share-services/share.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { Facebook, Twitter, LinkedIN } from 'src/app/functions/share';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { AssessmentResultsComponent } from '../../../user/assessment-results/assessment-results.component';
import { AnonReportPageComponent } from '../../../anonymous-quiz/anon-report-page/anon-report-page.component';

@Component({
  selector: 'lq-user-report',
  templateUrl: './lq-user-report.component.html',
  styleUrls: ['./lq-user-report.component.scss']
})
export class LqUserReportComponent extends BaseRouteParamComponent implements OnInit {
  @Input() result: any;
  @Input() isAnon: boolean;

  subs = new Subscription();
  formID: string;
  
  constructor(
    protected routerParams: RouterParamsService,
    private themingService: ThemingService,
    private shareService: ShareService,
    private auth: AuthService,
    private errService: ErrorService,
    private route: ActivatedRoute,
    private helper: HelperService,
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent
  ) { 
    super(routerParams);
  }

  ngOnInit() { 
    this.formID = this.route.snapshot.params.id;
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    !this.isAnon ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }

  ngOnDestroy() {
    if (this.subs) { this.subs.unsubscribe(); }
  }

}
