import { Component, OnInit, Input } from '@angular/core';
import { ContentModel } from 'src/app/main/content/models/user/article.model';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-tabed-article',
  templateUrl: './tabed-article.component.html',
  styleUrls: ['./tabed-article.component.scss']
})
export class TabedArticleComponent implements OnInit
{
  _content: ContentModel[];
  @Input() set content(value)
  {
    const parsed = JSON.parse(value);

    this._content = parsed.content;
  }

  tab_index: number = 0;

  constructor(
    public themingService: ThemingService
  ) { }

  ngOnInit()
  {
    if (!this._content)
    {
      throw new Error('No content provided');
    }
  }

  selectedTabIndexChange(indx: number)
  {
    this.tab_index = indx;
  }
}
