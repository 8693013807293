import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API } from 'src/app/api-url/api-urls';
import { HttpClient } from '@angular/common/http';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { AssesmentComponent } from 'src/app/main/content/pages/shared/assesment/assesment.component';
import { QuizSystemService } from 'src/app/main/content/services/quiz/quiz-system.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';

@Component({
  selector: 'cat-game-embed',
  templateUrl: './cat-game-embed.component.html',
  styleUrls: [ './cat-game-embed.component.scss' ]
})
export class CatGameEmbedComponent implements OnInit, AfterViewInit {
  catConfig: any;
  constructor(
    public dialogRef: MatDialogRef<CatGameEmbedComponent>,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private _http: HttpClient,
    private _anonService: AnonUserService,
    private _quizService: QuizSystemService,
    private _errorService: ErrorService
  ) {
    this.catConfig = API.CAT_CONFIG;
    console.log(data.locale);
  }

  ngOnInit() {

    //const form_id = this._anonService.assessment.assessment.form_id;
    //console.log('formId', form_id);

    this.breakpointObserver.observe([ Breakpoints.XSmall ]).subscribe(size => {
      if (size.matches) {
        this.dialogRef.updateSize('100%', '100%');
      } else {
        this.dialogRef.updateSize('540px', '720px');
      }
    });
  }

  handleCatResults(result: any) {
    this.dialogRef.close(result);
  }

  onStart(payload: {hash: string, testId: string}) {
    console.log('data', payload);
    this._quizService.getCatResults(this.data.fieldKey, this.data.quizId, payload, this.data.locale, this.data.usertoken).pipe(
      catchError((error) => {
        this._errorService.handleHttpErrorForLogedInUser(error);
        return of(null);
      })
    )
      .subscribe(

    );
  }

  handleSessionId(sessionId: string) {
    console.log('sessionId', sessionId);
  }

  ngAfterViewInit(): void {
  }

}

interface DialogOptions {
  locale: string;
  fieldKey: string;
  catId: string;
  quizId: string;
  usertoken: string;
}
