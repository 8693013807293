import { AfterViewInit, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

export class BaseHttpTableComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = [];

  /* ************************************************************************** */
  protected _filters: { groups?: string[]; roles?: string[]; } = {};
  protected _query = '';
  protected _account = undefined;

  /* Input variables */
  @Input()
  set account(value: string) {
    this._account = value;
    this.changeSubject.next();
  }

  @Input() pageSizeOptions = [10, 20, 50, 100, 200, 500];
  @Input() clickableRows = true;

  @Input()
  set filters(value: { groups?: string[]; roles?: string[]; }) {
    if (!value) {
      this._filters = {};
    } else {
      this._filters = value;
    }

    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }

  @Input()
  set query(value: string) {
    console.log('Query changed: ', value);
    this._query = value ? value : '';
    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }

  @Input()
  set triggerRefresh(value: any) {
    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }


  start?: string;

  @Input()
  set startDate(value: any) {
    if (value) {
      this.start = new Date(value).toISOString();
    } else {
      this.start = null;
    }
    this.paginator.pageIndex = 0;
    this.changeSubject.next();

  }

  end?: string;

  @Input()
  set endDate(value: any) {
    if (value) {
      this.end = new Date(value).toISOString();
    } else {
      this.end = null;
    }
    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }

  /* Input variables */
  /* ************************************************************************** */

  /* ************************************************************************** */
  /* Outputs */
  @Output() initializedEvent = new EventEmitter<boolean>();
  @Output() isLoadingEvent = new EventEmitter<boolean>();
  @Output() groups = new EventEmitter<string[]>();
  @Output() personas = new EventEmitter<string[]>();
  @Output() selectionEvent = new EventEmitter<any[]>();
  /* Outputs */
  /* ************************************************************************** */

  resultsLength = 0;
  protected _isLoadingResults = true;
  set isLoadingResults(value: boolean) {
    this._isLoadingResults = value;
    this.isLoadingEvent.emit(value);
  }

  get isLoadingResults() {
    return this._isLoadingResults;
  }

  isError = false;
  error: any;

  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  protected changeSubject = new Subject();

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.changeSubject.next();
    });
    this.paginator.page.subscribe(() => {
      this.changeSubject.next();
    });


    this.selection.changed.subscribe(value => this.selectionEvent.emit(value.source.selected));
    this.changeSubject.subscribe(() => {
      setTimeout(() => {
        this.selection.clear();
      }, 0);
    });
  }

  ngOnDestroy() {
    this.changeSubject.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
