import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiDefinition } from '../../models/api/api.def';
import {
  AnonFulfillResponse,
  CreateInviteLinkResponse,
  EmailInvite,
  EmailInviteModel,
  EmailInviteStatus,
  FollowUp,
  FulfillResponse,
  PublicInvite,
  SuccessfulResponse,
  VerifyResponse
} from '../../models/admin/invites.def';
import { AuthService } from '../auth/auth.service';
import { API } from 'src/app/api-url/api-urls';
import { PaginationQueryParams } from '../../models/params/params.def';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { AppStore } from 'src/app/app.store';

@Injectable({
  providedIn: 'root'
})
export class InvitesService {
  api: ApiDefinition;

  followUpFormId$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private appStore: AppStore
  ) {
    this.api = this.auth.api;
  }

  setFollowupFormId(id): void {
    this.followUpFormId$.next(id);
  }

  getFollowupFormId$(): Observable<string>{
    return this.followUpFormId$;
  }

  /**
   * Get all public invites for a form
   *
   * GET /invites/assessment/link/assessment/{form_id}
   * @param form_id
   */
  getPublicInvites(form_id: string) {
    return this.http.get<PublicInvite[]>(API.APIDOMAIN + this.api.invitesAssessmentLink + `/assessment/${ form_id }`, this.auth.httpOptions);
  }

  /**
   * Create invite link
   *
   * POST /invites/assessment/link
   */
  createInviteLink(
    token: string,
    form_id: string,
    groups: string[],
    isAnonQuiz = false,
    emailRequired = false,
    incognito = false,
    game: string,
    shareOptions: any
  ) {
    return this.http.post<CreateInviteLinkResponse>(API.APIDOMAIN + this.api.invitesAssessmentLink, {
      token,
      form_id,
      groups,
      anonymous: isAnonQuiz,
      emailRequired,
      incognito,
      game,
      ...shareOptions
    }, this.auth.httpOptions);
  }

  /**
   * Delete invite link
   *
   * DELETE /invites/assessment/link/{token}
   */
  deleteInviteLink(token: string) {
    return this.http.delete(API.APIDOMAIN + this.api.invitesAssessmentLink + `/${ token }`, this.auth.httpOptions);
  }

  /**
   * Toggle the enable property of an invite link
   *
   * GET /invites/assessment/link/enable/{token}
   */
  toggleEnableInviteLink(token: string) {
    return this.http.get<PublicInvite>(API.APIDOMAIN + this.api.invitesAssessmentLink + `/enable/${ token }`, this.auth.httpOptions);
  }

  /**
   * Verify the assessment invitation token is valid.
   *
   * If the user is logged in send the X-TOKEN header as well
   *
   * POST /invites/assessment/link/verify
   */
  verifyLinkTokenIsValid(token: string) {
    return this.http.post<VerifyResponse>(API.APIDOMAIN + this.api.invitesAssessmentLink + '/verify', { token }, this.auth.httpOptions);
  }

  /**
   * Fulfill the invitation.
   *
   * If the user is not logged in, send the password and email he entered, that user will be created. Otherwise send X-TOKEN header
   *
   * POST /invites/assessment/link/fulfill
   */
  fulfilInvitation(token: string, email?: string, password?: string) {
    return this.http.post<FulfillResponse>(API.APIDOMAIN + this.api.invitesAssessmentLink + '/fulfill', {
      token,
      email,
      password
    }, this.auth.httpOptions);
  }


  /**
   * Fulfill the invitation.
   *
   * If the user is not logged in, send the password and email he entered, that user will be created. Otherwise send X-TOKEN header
   *
   * POST /invites/assessment/link/fulfill
   */
  fulfilAnonInvitation(token: string, email?: string, password?: string) {
    return this.http.post<AnonFulfillResponse>(API.APIDOMAIN + this.api.invitesAssessmentLink + '/fulfill', {
      token,
      email,
      password
    }, this.auth.httpOptions);
  }


  /* EMAIL INVITES */

  /**
   * Create email invite
   *
   * POST /invites/assessment/email/send
   *
   * @param data
   * @param shareOptions optional param for additional share options of the invite
   * @param userToken optional param for use with anon users
   */
  sendEmailInvites(data: EmailInviteModel, shareOptions: any, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) {
      options.headers = {
        'X-TOKEN': userToken
      };
    }

    return this.http.post<{status: string, message: string}>(API.APIDOMAIN + this.api.invitesAssessmentEmail + '/send', { ...data, ...shareOptions }, options);
  }

  /**
   * Create email invite
   *
   * POST /invites/assessment/email/send
   *
   * @param data
   * @param shareOptions optional param for additional share options of the invite
   * @param userToken optional param for use with anon users
   */
  sendEmailNotification(data: any) {
    return this.http.post<any>(API.APIDOMAIN + this.api.admin + '/send/notification/email', data, this.auth.httpOptions);
  }

  /**
   * Verify the email assessment invitation token is valid.
   *
   * If the user is logged in send the X-TOKEN header as well
   *
   * POST /invites/assessment/email/verify
   */
  verifyEmailInviteTokenIsValid(token: string) {
    return this.http.post<VerifyResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + '/verify', { token }, this.auth.httpOptions);
  }

  /**
   * Fulfill the email invitation.
   *
   * If the user is not logged in, send the password he entered. Otherwise send X-TOKEN header
   *
   * POST /invites/assessment/email/fulfill
   */
  fulfilEmailInvitation(token: string, password?: string) {
    return this.http.post<FulfillResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + '/fulfill', {
      token,
      password
    }, this.auth.httpOptions);
  }

  /**
   *
   * Invite route handles all invite options
   *
   * POST /invite
   */
  handleInvite(token: string, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) {
      options.headers = {
        'X-TOKEN': userToken
      };
    }

    return this.http.post<any>(API.APIDOMAIN + 'invite', { token }, options).pipe(
      tap((res) => {
        this.appStore.incognito = res.incognito;
        this.appStore.hideSocial = res.hide  && res.hide.social_login ? res.hide.social_login :  false;
        if(res.hasOwnProperty('hide')){
          if(res.hide.social_login === false) {
            localStorage.setItem('show_social_login', 'true');
          } else {
            localStorage.setItem('show_social_login', 'false');
          }
        } else {
          localStorage.setItem('show_social_login', 'true');
        }
      })
    );
  }

  /**
   * Get all invites grouped by collection_uuid with count and errors
   *
   * GET /invites/assessment/email/collection/assessment/{form_id}
   * @param form_id the id of the form for which the collections will be fetched
   */
  getEmailInviteCollectionsByFormID(form_id: string, groups?: string[]) {
    const body = {
      groups: groups ? groups : []
    };
    return this.http.post<any>(`${ API.APIDOMAIN }${ this.api.invitesAssessmentEmail }/collection/assessment/${ form_id }`, body, this.auth.httpOptions);
  }

  /**
   * Resends all emails that were sent in the collection
   *
   * GET /invites/assessment/email/resend/collection/{uuid}
   *
   * @param uuid
   */
  resendAllEmailInvites(uuid: string) {
    return this.http.get<SuccessfulResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/resend/collection/${ uuid }`, this.auth.httpOptions);
  }

  /**
   * Auto resends all emails that were sent in the collection
   *
   * PATCH /invites/assessment/email/collection/{collection_uuid}/autoresend
   *
   * @param uuid
   */
  autoResendAllEmailInvites(uuid: string) {
    return this.http.patch<any>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/collection/${ uuid }/autoresend`, {}, this.auth.httpOptions);
  }

  /**
   * Resends all emails that had errored out in the collection
   *
   * GET /invites/assessment/email/resend/collection/{uuid}/error
   *
   * @param uuid
   */
  resendErroredEmailInvites(uuid: string) {
    return this.http.post<SuccessfulResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/resend/collection/${ uuid }/error`, {}, this.auth.httpOptions);
  }

  /**
   * Resends all emails that were sent in the collection
   *
   * GET /invites/assessment/email/resend/{id}
   *
   * @param id
   */
  resendIndividualEmailInvite(id: number) {
    return this.http.get<SuccessfulResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/resend/${ id }`, this.auth.httpOptions);
  }


  /**
   * Get data for the follow-ups table
   *
   * GET /invites/assessment/email/followups/collection/{collection_uuid}
   *
   * @param collection_uuid
   */
  getFollowUpData(collection_uuid: string) {
    return this.http.get<FollowUp[]>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/followups/collection/${ collection_uuid }`, this.auth.httpOptions);
  }


  /**
   * Get all e-mails for collection
   *
   * GET /invites/assessment/email/collection/{collection_uuid}/{status}
   * @param collection_uuid
   * @param status
   */
  getEmailsForCollection(collection_uuid: string, status: EmailInviteStatus) {
    return this.http.get<EmailInvite[]>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/collection/${ collection_uuid }/${ status }`, this.auth.httpOptions);
  }

  /**
   * Get all links from assessment
   *
   * GET /invites/assessment/link/assessment/{formId}/track
   * @param formId
   */
  getAllAssessmentLinks(formId: string, groups?: string[]) {
    const body = {
      groups: groups ? groups : []
    };

    return this.http.post<any>(API.APIDOMAIN + this.api.invitesAssessmentLink + `/assessment/${ formId }/track`, body, this.auth.httpOptions);
  }

  /**
   * Get all links from assessment
   *
   * GET /invites/assessment/link/assessment/{formId}/track
   * @param formId
   */
  getLinkUsers(linkId: string, status: string, params: PaginationQueryParams) {
    const options = this.auth.httpOptions;
    options.params = <any>params;

    return this.http.get<any>(API.APIDOMAIN + this.api.invitesAssessmentLink + `/${ linkId }/track/${ status }`, options);
  }

  /**
   * Get all links from assessment
   *
   * GET /invites/assessment/link/assessment/{formId}/track
   * @param formId
   */
  downloadLinkUsersAsCsv(linkId: string, status: string) {
    const options = this.auth.httpOptions;
    options.responseType = 'blob' as 'json';

    return this.http.get<any>(API.APIDOMAIN + this.api.invitesAssessmentLink + `/${ linkId }/csv/${ status }`, options);
  }

  /**
   * Resends invites for collection.
   *
   * POST /invites/assessment/email/resend/collection/{uuid}/{status}
   *
   * @param uuid
   * @param status
   * @param body - set invite_ids when status is set to `custom`
   */
  resendInvitesForCollection(
    uuid: string,
    status: EmailInviteStatus,
    body: { invite_ids?: number[]; invite_template_id?: number; invite_text?: string; invite_subject?: string }
  ) {
    return this.http.post<SuccessfulResponse>(API.APIDOMAIN + this.api.invitesAssessmentEmail + `/resend/collection/${ uuid }/${ status }`, body, this.auth.httpOptions);
  }

  /**
   * Export invites for collection.
   *
   * POST /invites/assessment/export
   *
   * @param body - list of invite_ids
   */
  assessmentInvitesExport(invite_ids: number[]) {
    const options = this.auth.httpOptions;
    options.responseType = 'blob' as 'json';

    return this.http.post<any>(API.APIDOMAIN + this.api.invitesAssessment + `/export`, { ...invite_ids }, options);
  }
}
