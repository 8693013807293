import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseRouteParamComponent} from 'src/app/shared/base-route-param.component';
import {RouterParamsService} from 'src/app/main/main-service/router-params.service';
import {Subscription} from 'rxjs';
import {AnonUserService} from '../../../services/user/anon-user.service';
import {ErrorService} from '../../../services/helpers/error.service';
import {UserModel} from '../../../models/user/user.def';
import {ThemingService} from 'src/app/theme/theming.service';
import {HelperService} from '../../../services/helpers/helper.service';

@Component({
  selector: 'ds-anon-register-page',
  templateUrl: './anon-register-page.component.html',
  styleUrls: ['./anon-register-page.component.scss']
})
export class AnonRegisterPageComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  token: string;
  loading = true;
  loadingMSG = '';

  user: UserModel;

  step: 'login' | 'register' = null;

  constructor(
    protected routerParams: RouterParamsService,
    private route: ActivatedRoute,
    private router: Router,
    private anonService: AnonUserService,
    private errService: ErrorService,
    public themingService: ThemingService,
    private helper: HelperService,
  ) {
    super(routerParams);
  }

  ngOnInit() {
    super.ngOnInit();

    this.token = this.route.snapshot.params.token;

    this.verifyToken();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  async onUserActionCompleted(user: UserModel) {
    await this.router.navigate([this.accountNameParam + '/home']);
  }

  switchToLogin(email: string) {
    this.step = 'login';
  }

  /**
   * Opens the brazenx T&C
   */
  openTerms() {
    window.open('https://www.manpowergroup.com/terms-of-use');
  }

  openPrivacyPolicy() {
    window.open('https://www.manpowergroup.com/privacy-policy');
  }

  private verifyToken() {
    this.loadingMSG = 'Verifying invite';
    this.loading = true;


    this.anonService.getAnonUserByEmailToken(this.token).subscribe(res => {
      console.log('Get anon user by email token response: ', res);
      this.user = res.user;
      this.user.token_key = this.anonService.anonToken = res.token_key;
      this.user.refresh_token = this.anonService.anonRefreshToken = res.refresh_token;
      this.anonService.anonQuizToken = res.quizToken.token;

      if (this.user.status === 'EMAIL_EXISTS') {
        this.step = 'login';
      } else {
        this.step = 'register';
      }

      this.loading = false;

    }, err => {
      console.log('Error getting anon user by email token', err);
      if (err.error.trans_code && err.error.trans_code === 'exception.user.not.anon') {
        this.loading = false;
        this.helper.showAlert('You already saved your results, log into your account to see them');
        this.router.navigate([this.accountNameParam + '/login']);
      } else if (err.status == 307) {
        this.loading = false;
        this.helper.showAlert('Your emails was verified please log in.');
        this.router.navigate([this.accountNameParam + '/login']);
      } else {
        this.loading = false;
        this.errService.handleHttpErrorForLogedOutUser(err);
      }
    });

  }
}
