import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService as SocialService } from 'angular-6-social-login';
import { fuseAnimations } from 'src/app/animations/animations';
import { BaseRegisterDesignComponent } from '../base-register-design.component';
import { AuthGuard } from 'src/app/main/content/guards/auth.guard';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-register-design2',
  templateUrl: './register-design2.component.html',
  styleUrls: ['./register-design2.component.scss'],
  animations: fuseAnimations
})
export class RegisterDesign2Component extends BaseRegisterDesignComponent
{
  // Theme colors
  accent;
  toolbarBGColor;
  toolbarFontColor;

  showSocialLogin = true;

  constructor(
    protected authGuard: AuthGuard,
    protected authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected route: ActivatedRoute,
    protected title: Title,
    protected dialog: MatDialog,
    protected translocoService: TranslocoService,
    public themingService: ThemingService,
  )
  {
    super(authGuard, authService, router, helper, errorService, socialService, formBuilder, routerParams, route, title, dialog, translocoService, themingService);

    this.accent = themingService.accent;
    this.toolbarBGColor = themingService.toolbarBGColor;
    this.toolbarFontColor = themingService.toolbarFontColor;
    
    if (localStorage.getItem('show_social_login'))
      this.showSocialLogin = localStorage.getItem('show_social_login') === 'true';
  }
}
