import { Component, OnInit, HostBinding } from '@angular/core';
import { fuseAnimations } from 'src/app/animations/animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'ds-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: fuseAnimations
})
export class ContentComponent
{
  @HostBinding('@routerTransitionUp') routeAnimationUp = false;
  @HostBinding('@routerTransitionDown') routeAnimationDown = false;
  @HostBinding('@routerTransitionRight') routeAnimationRight = false;
  @HostBinding('@routerTransitionLeft') routeAnimationLeft = false;
  @HostBinding('@routerTransitionFade') routeAnimationFade = false;

  routerAnimation = 'fadeIn';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  )
  {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute))
      .subscribe((event) =>
      {
        switch (this.routerAnimation)
        {
          case 'fadeIn':
            this.routeAnimationFade = !this.routeAnimationFade;
            break;
          case 'slideUp':
            this.routeAnimationUp = !this.routeAnimationUp;
            break;
          case 'slideDown':
            this.routeAnimationDown = !this.routeAnimationDown;
            break;
          case 'slideRight':
            this.routeAnimationRight = !this.routeAnimationRight;
            break;
          case 'slideLeft':
            this.routeAnimationLeft = !this.routeAnimationLeft;
            break;
        }
      });
  }
}
