import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'ds-box-chart',
  templateUrl: './box-chart.component.html',
  styleUrls: ['./box-chart.component.scss']
})
export class BoxChartComponent implements OnInit
{
  _title: string = 'Title';
  @Input() set title(value: string)
  {
    this._title = value || 'Title';
  }
  _subtitle: string;
  @Input() set subtitle(value: string)
  {
    this._subtitle = value;
  }

  _data: number[];
  @Input() set chartData(value: number[])
  {
    this._data = value;
  }

  textColour: SafeStyle = '';
  _colorValues: { [value: number]: string; } = {};
  @Input() set colorValues(value: { [value: number]: string; })
  {
    this._colorValues = value || {};

    this.textColour = this.getColor();
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit()
  {
  }

  /** Gets the correct colour for the value */
  private getColor(): SafeStyle
  {
    let colour: SafeStyle = '';
    Object.entries(this._colorValues).forEach(([value, color]) =>
    {
      if (this._data.every(d => d >= Number.parseFloat(value)))
      {
        colour = this.sanitizer.bypassSecurityTrustStyle(color);
      }
    });

    return colour;
  }
}
