import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-htmlarticle',
  templateUrl: './htmlarticle.component.html',
  styleUrls: ['./htmlarticle.component.scss']
})
export class HTMLArticleComponent implements OnInit
{
  _content: string;
  @Input() set content(value)
  {
    try
    {
      this._content = JSON.parse(value);
    }
    catch (error)
    {
      console.log(error);
      this._content = '';
    }
  }

  constructor()
  {
  }

  ngOnInit()
  {
  }

}
