import { Component, OnInit } from '@angular/core';
import { AuthService as SocialService } from 'angular-6-social-login';
import { fuseAnimations } from 'src/app/animations/animations';
import { BaseRegisterDesignComponent } from '../base-register-design.component';
import { AuthGuard } from 'src/app/main/content/guards/auth.guard';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { FormBuilder } from '@angular/forms';
import { ThemingService } from 'src/app/theme/theming.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AppStore } from 'src/app/app.store';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-register-design1',
  templateUrl: './register-design1.component.html',
  styleUrls: ['./register-design1.component.scss'],
  animations: fuseAnimations
})
export class RegisterDesign1Component extends BaseRegisterDesignComponent implements OnInit
{

  hideSocialLogin$: Observable<boolean>;
  showSocialLogin = true;

  constructor(
    protected authGuard: AuthGuard,
    protected authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected route: ActivatedRoute,
    protected title: Title,
    protected dialog: MatDialog,
    protected translocoService: TranslocoService,
    public themingService: ThemingService,
    public appStore: AppStore
  )
  {
    super(authGuard, authService, router, helper, errorService, socialService, formBuilder, routerParams, route, title, dialog, translocoService, themingService);
  }

  ngOnInit()
  {
    super.ngOnInit();

    this.hideSocialLogin$ = this.appStore.hideSocial$;

    if (localStorage.getItem('show_social_login'))
      this.showSocialLogin = localStorage.getItem('show_social_login') === 'true';

    const _element: HTMLElement = document.getElementById('register');
    if (this.themingService.bgGradient)
    {
      _element.style.background = this.themingService.bgGradient;
    }
    else if (this.themingService.bgGradientPicture)
    {
      _element.style.background = `url('${this.themingService.bgGradientPicture}')`;
    }
  }
}
