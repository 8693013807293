import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from 'src/app/animations/animations';
import { DesignType } from 'src/app/theme/theme.models';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: fuseAnimations
})
export class FuseResetPasswordComponent implements OnInit
{
  designTypes = DesignType;

  constructor(
    public themingService: ThemingService
  )
  { }

  ngOnInit()
  { }
}

