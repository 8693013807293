import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { QuillModule } from 'ngx-quill';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MoreDetailsDialogComponent
} from '../main/content/pages/shared/dialogs/more-details-dialog/more-details-dialog.component';
import {
  RetakeQuizDialogComponent
} from '../main/content/pages/shared/dialogs/retake-quiz-dialog/retake-quiz-dialog.component';
import { RouterModule } from '@angular/router';
import {
  ChooseAccountDialogComponent
} from '../main/content/pages/shared/dialogs/choose-account-dialog/choose-account-dialog.component';
import {
  WhoAreYouUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/who-are-you-user-report/who-are-you-user-report.component';
import {
  CoreDriversUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/core-drivers-user-report/core-drivers-user-report.component';
import {
  HTMLArticleComponent
} from '../main/content/pages/shared/components/articles/htmlarticle/htmlarticle.component';
import {
  TabedArticleComponent
} from '../main/content/pages/shared/components/articles/tabed-article/tabed-article.component';
import { ThumbsComponent } from '../main/content/pages/shared/components/thumbs/thumbs.component';
import {
  JQdiagnosticUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/jqdiagnostic-user-report/jqdiagnostic-user-report.component';
import { SafePipe } from '../main/content/pipes/safe.pipe';
import { SafeUrlPipe } from '../main/content/pipes/safe-url.pipe';
import { CustomDatePipe } from '../main/content/pipes/custom-date.pipe';
import {
  AreYouSureDialogComponent
} from '../main/content/pages/shared/dialogs/are-you-sure-dialog/are-you-sure-dialog.component';
import {
  WhoAreYouUserDetailsCardComponent
} from '../main/content/pages/shared/user-detail-cards/who-are-you-user-details-card/who-are-you-user-details-card.component';
import {
  CoreDriversUserDetailsCardComponent
} from '../main/content/pages/shared/user-detail-cards/core-drivers-user-details-card/core-drivers-user-details-card.component';
import {
  JqdiagnosticUserDetailsCardComponent
} from '../main/content/pages/shared/user-detail-cards/jqdiagnostic-user-details-card/jqdiagnostic-user-details-card.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import {
  ShareSpeedDialComponent
} from '../main/content/pages/shared/components/share-speed-dial/share-speed-dial.component';
import {
  CopyValueDialogComponent
} from '../main/content/pages/shared/dialogs/copy-value-dialog/copy-value-dialog.component';
import {
  VisualRepresentationComponent
} from '../main/content/pages/shared/components/visual-representation/visual-representation.component';
import { NouisliderModule } from 'ng2-nouislider';
import { SvgInlineDirective } from '../main/content/directives/svg-inline.directive';
import {
  InsightUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/insight-user-report/insight-user-report.component';
import {
  CoreDriversTableComponent
} from '../main/content/pages/shared/components/core-drivers-table/core-drivers-table.component';
import {
  UsersInviteButtonComponent
} from '../main/content/pages/shared/components/users-invite-button/users-invite-button.component';
import {
  UsersInviteDialogComponent
} from '../main/content/pages/shared/dialogs/users-invite-dialog/users-invite-dialog.component';
import {
  J3personicaUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/j3personica-user-report/j3personica-user-report.component';
import { ProfileImageComponent } from '../main/content/pages/shared/components/profile-image/profile-image.component';
import {
  InviteLinkLoginDialogComponent
} from '../main/content/pages/shared/dialogs/invite-link-login-dialog/invite-link-login-dialog.component';
import {
  ExtremesUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/extremes-user-report/extremes-user-report.component';
import {
  ExtremesTableComponent
} from '../main/content/pages/shared/components/extremes-table/extremes-table.component';
import {
  ExtremesUserDetailsCardComponent
} from '../main/content/pages/shared/user-detail-cards/extremes-user-details-card/extremes-user-details-card.component';
import {
  ExtremesScaleSliderGraphComponent
} from '../main/content/pages/shared/components/extremes-scale-slider-graph/extremes-scale-slider-graph.component';
import {
  OverwriteQuizResultsDialogComponent
} from '../main/content/pages/shared/dialogs/overwrite-quiz-results-dialog/overwrite-quiz-results-dialog.component';
import {
  AnonLoginRegisterDialogComponent
} from '../main/content/pages/shared/dialogs/anon-login-register-dialog/anon-login-register-dialog.component';
import { AnonRegisterComponent } from '../main/content/pages/shared/components/anon-register/anon-register.component';
import { AnonLoginComponent } from '../main/content/pages/shared/components/anon-login/anon-login.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import {
  TwoFactorCodeDialogComponent
} from '../main/content/pages/shared/dialogs/two-factor-code-dialog/two-factor-code-dialog.component';
import {
  CoreDriversTeamSkillsTableComponent
} from '../main/content/pages/shared/components/core-drivers-team-skills-table/core-drivers-team-skills-table.component';
import { ReplacePipe } from '../main/content/pipes/replace.pipe';
import {
  AllDriversComponent
} from '../main/content/pages/shared/components/core-drivers/all-drivers/all-drivers.component';
import {
  CustomDriverSliderComponent
} from '../main/content/pages/shared/components/core-drivers/custom-driver-slider/custom-driver-slider.component';
import {
  IncompetentMenUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/incompetent-men-user-report/incompetent-men-user-report.component';
import { EchartGraphComponent } from '../main/content/pages/admin/custom-charts/echart-graph/echart-graph.component';
import {
  IncompetentMenUserDetailsCardComponent
} from '../main/content/pages/shared/user-detail-cards/incompetent-men-user-details-card/incompetent-men-user-details-card.component';
import {
  KnowledgeOfScienceUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/knowledge-of-science-user-report/knowledge-of-science-user-report.component';
import {
  LqUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/lq-user-report/lq-user-report.component';
import {
  LqDetailsCardComponent
} from '../main/content/pages/admin/components/details-cards/lq-details-card/lq-details-card.component';
import {
  SkillsInsightUserReportComponent
} from '../main/content/pages/shared/user-assessment-reports/skills-insight-user-report/skills-insight-user-report.component';
import {
  SkillsInsightDetailsCardComponent
} from '../main/content/pages/admin/components/details-cards/skills-insight-details-card/skills-insight-details-card.component';
import { AssessmentResultsComponent } from '../main/content/pages/user/assessment-results/assessment-results.component';
import {
  RestartAssessmentComponent
} from '../main/content/pages/shared/dialogs/restart-assessment/restart-assessment.component';
import { ShareImageComponent } from '../main/content/pages/shared/components/share-image/share-image.component';
import {
  AnonReportPageComponent
} from '../main/content/pages/anonymous-quiz/anon-report-page/anon-report-page.component';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { CatGameEmbedComponent } from '../main/content/pages/shared/dialogs/cat-game-embed/cat-game-embed.component';
import {
  SkillsGamingReportComponent
} from '../main/content/pages/shared/user-assessment-reports/skills-gaming-report/skills-gaming-report.component';
import {
  SkillsGamingDetailsCardComponent
} from '../main/content/pages/admin/components/details-cards/skills-gaming-details-card/skills-gaming-details-card.component';
import {
  SiemensReportComponent
} from '../main/content/pages/shared/user-assessment-reports/siemens-report/siemens-report.component';
import {
  SiemensDetailsCardComponent
} from '../main/content/pages/admin/components/details-cards/siemens-details-card/siemens-details-card.component';
import { TranslatedLogoPipe } from '../main/content/pipes/translated-logo.pipe';
import { VarDirective } from './ng-var.directive';
import {
  DemographicsChangeCardComponent
} from '../main/content/pages/user/profile/demographics-change-card/demographics-change-card.component';
import {
  TermsAndConditionPrivacyPolicyComponent
} from '../main/content/pages/shared/components/terms-and-condition-privacy-policy/terms-and-condition-privacy-policy.component';
import {
  GearReportDialogComponent
} from 'src/app/main/content/pages/shared/dialogs/gear-report-dialog/gear-report-dialog.component';
import { GearPdfReportComponent } from '../main/content/pages/shared/user-assessment-reports/gear-pdf-report/gear-pdf-report.component';


const sharedModules = [
  CommonModule,
  FlexLayoutModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,

  RoundProgressModule,
  QuillModule,
  FontAwesomeModule,
  SatPopoverModule,
  NouisliderModule,

  RecaptchaModule,
  RecaptchaFormsModule,
  TranslocoRootModule
];

const sharedComponents = [
  EchartGraphComponent,
  DemographicsChangeCardComponent,
  WhoAreYouUserReportComponent,
  CoreDriversUserReportComponent,
  JQdiagnosticUserReportComponent,
  InsightUserReportComponent,
  J3personicaUserReportComponent,
  ExtremesUserReportComponent,
  IncompetentMenUserReportComponent,
  KnowledgeOfScienceUserReportComponent,
  LqUserReportComponent,
  SkillsInsightUserReportComponent,
  SkillsGamingReportComponent,
  SiemensReportComponent,
  GearPdfReportComponent,

  WhoAreYouUserDetailsCardComponent,
  CoreDriversUserDetailsCardComponent,
  JqdiagnosticUserDetailsCardComponent,
  ExtremesUserDetailsCardComponent,
  IncompetentMenUserDetailsCardComponent,
  LqDetailsCardComponent,
  SkillsInsightDetailsCardComponent,
  SkillsGamingDetailsCardComponent,
  SiemensDetailsCardComponent,

  HTMLArticleComponent,
  TabedArticleComponent,
  ThumbsComponent,

  ShareSpeedDialComponent,
  VisualRepresentationComponent,
  CoreDriversTableComponent,
  UsersInviteButtonComponent,
  ProfileImageComponent,
  ExtremesTableComponent,
  ExtremesScaleSliderGraphComponent,
  CoreDriversTeamSkillsTableComponent,
  AllDriversComponent,
  CustomDriverSliderComponent,

  AnonRegisterComponent,
  AnonLoginComponent,

  ShareImageComponent,
  TermsAndConditionPrivacyPolicyComponent
];

const pipes = [
  SafePipe,
  SafeUrlPipe,
  CustomDatePipe,
  ReplacePipe,
  TranslatedLogoPipe
];

const directives = [
  VarDirective
];

const sharedDialogs = [
  MoreDetailsDialogComponent,
  RetakeQuizDialogComponent,
  ChooseAccountDialogComponent,
  AreYouSureDialogComponent,
  CopyValueDialogComponent,
  UsersInviteDialogComponent,
  InviteLinkLoginDialogComponent,
  OverwriteQuizResultsDialogComponent,
  AnonLoginRegisterDialogComponent,
  TwoFactorCodeDialogComponent,
  RestartAssessmentComponent,
  CatGameEmbedComponent,
  GearReportDialogComponent
];

const sharedDirectives = [
  SvgInlineDirective
];

@NgModule({
  imports: [
    ...sharedModules
  ],
  exports: [
    ...sharedModules,
    ...sharedComponents,
    ...sharedDirectives,
    ...pipes,
    ...directives
  ],
  declarations: [
    ...sharedDialogs,
    ...sharedComponents,
    ...sharedDirectives,
    ...pipes,
    ...directives
  ],
  providers: [
    AssessmentResultsComponent,
    AnonReportPageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ...sharedDialogs
  ]
})
export class SharedModule {
}
