import { Injectable } from '@angular/core';
import { ComponentStore } from 'src/app/component.store';
import { Quiz, QuizHeader, QuizQuestion, Result } from 'src/app/main/content/models/user/quiz.model';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, timer } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UpdateQuizParams } from 'src/app/main/content/models/quiz/quiz.model';
import { QuizSystemService } from 'src/app/main/content/services/quiz/quiz-system.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { ProfileDefinition } from 'src/app/main/content/models/user/user.def';
import { DemographicsService } from 'src/app/main/content/services/user/demographics.service';
import { CountryModel, StateModel, TranslateKeyItem } from 'src/app/main/content/models/user/demographics.model';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { DemographicsFormData } from 'src/app/main/content/pages/shared/tsq-assesment/components/tsq-demographics/tsq-demographics.component';
import { RestartAssessmentComponent } from 'src/app/main/content/pages/shared/dialogs/restart-assessment/restart-assessment.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { AnswerItem } from './components/tsq-questions-page/tsq-questions-page.component';
import { API } from 'src/app/api-url/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export type TsqColor = 'orange' | 'green' | 'blue' | 'grey';
export type PageType = 'landing' | 'demographics' | 'circleGraph' | 'questions' | 'results';

export interface CircleChart {
  toAdvanceYourPractice: string;
  yourStage: string;
  chart: string;
  href: string;
  dimension: string;
  level: string;
  ratingshortdescription: string;
  ratinglongdescription: string;
  score: string;
}

export interface UpdateQuizSubject {
  params: UpdateQuizParams;
  userToken?: string;
  quizToken?: string;
}

export interface TsqState {
  states: StateModel[];
  demoFormData?: DemographicsFormData;
  chart?: CircleChart;
  answers: AnswerItem[];
  pageType: PageType;
  error: Error | null;
  report?: any;
  color: TsqColor;
  quiz?: Quiz;
  quizQuestions: Array<QuizQuestion>;
  step: number;
  quizParams: UpdateQuizParams | null;
  countries: Array<CountryModel>;
  industries: Array<TranslateKeyItem>;
  companyRevenue: Array<TranslateKeyItem>;
  companySize: Array<TranslateKeyItem>;
  showDemoFormTwo: boolean;
}


const initialState: TsqState = {
  states: null,
  answers: [],
  pageType: 'landing',
  error: null,
  quizQuestions: [],
  color: 'blue',
  step: 0,
  quizParams: null,
  countries: [],
  industries: [],
  companyRevenue: [],
  companySize: [],
  showDemoFormTwo: false
};


@Injectable()
export class TsqStore extends ComponentStore<TsqState> {
  quizToken: string;
  formID: string;
  isPrint: boolean = false;
  private conferenceModeSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private conferenceModeRestartSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private updateAnswersRequestStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateAnswersSubject: ReplaySubject<UpdateQuizSubject> = new ReplaySubject<UpdateQuizSubject>();

  constructor(
    private _quizSystem: QuizSystemService,
    private _auth: AuthService,
    private _userService: UsersService,
    protected demoService: DemographicsService,
    private _anonService: AnonUserService,
    private _dialog: MatDialog,
    private _errorService: ErrorService,
    private _quizService: QuizSystemService,
    private _http: HttpClient,
  ) {
    super(initialState);


    this.updateProfile();

    this.demoService.country.getAllCountries().subscribe(res => {
      res.sort((c1, c2) => c1.name.localeCompare(c2.name));
      this.countries = res;
    });


    this.demoService.industries.getAllIndustries().subscribe((res: Array<TranslateKeyItem>) => {
      this.industries = res;
    });

    this.demoService.getCompanyRevenue().subscribe(res => {
      this.companyRevenue = res;
    });

    this.demoService.getCompanySize().subscribe(res => {
      this.companySize = res;
    });


    this.conferenceModeRestartSubject.asObservable().pipe(
      switchMap(
        () => this.conferenceModeLogic()
      )
    ).subscribe((res) => {
      console.log('restartAssessment', res);
      if (res) {
        this.quiz = res;
        this.step = 0;
        this.pageType = 'landing';
        this.answers = [];
        this.conferenceModeRestartSubject.next();
      }
    }, err => {
      this._errorService.handleHttpErrorForLogedInUser(err);
    });


    const circleChart$: Observable<CircleChart> = combineLatest([
      this.dimension$,
      this.type$
    ]).pipe(
      filter(([_, type]) => type === 'chart'),
      map(res => res[0]),
      switchMap(dimension => this.getQuizResults$(dimension))
    );


    this.updateAnswersRequestStatus.asObservable().pipe(
      switchMap(() => circleChart$)
    ).subscribe((chart) => {
      this.chart = chart;
    });


    this.updateAnswersSubject.asObservable().pipe(
      tap(() => this.updateAnswersRequestStatus.next(true)),
      concatMap(
        (res) => this._quizSystem.updateQuizWithResult(res.params, res.userToken, res.quizToken)
      )
    ).subscribe((quiz: Quiz) => {
      this.quiz = quiz;
      this.updateAnswersRequestStatus.next(false);
    }, err => {
      console.error(err);
    });


  }

  getSubmit$() {
    return combineLatest([this.quizParams$, this.state$]).pipe(
      switchMap(([params, state]) => {
        const quiz: Quiz = state.quiz;
        const quizParams: UpdateQuizParams = {
          hidden_fields: quiz.result.hidden_fields,
          locale: quiz.locale,
          quiz_key: quiz.result.quiz.quiz_key,
          result: quiz.result_answers,
          result_key: quiz.result.result_key
        };
        return this._quizSystem.submitQuizResults(params ? params : quizParams, this.token, this.quizToken);
      })
    );

  }


  private conferenceModeLogic() {


    const combine = combineLatest([
      this.conferenceModeSubject.asObservable(),
      this.conferenceMode$.pipe(filter((num) => {
        return Number(num) > 0;
      }))
    ]).pipe(
      switchMap(
        ([_, conferenceMode]) => timer(conferenceMode * 1000).pipe(
          map(() => {
            return { conferenceMode };
          })
        )
      )
    ).pipe(
      switchMap(
        ({ conferenceMode }) => {

          if (this.value.pageType === 'questions' || this.value.pageType === 'circleGraph') {
            return this.openRestartAssessmentDialog(conferenceMode).pipe(
              tap((res) => {
                if (res) {
                  this.conferenceModeSubject.next();
                }
              }),
              mergeMap(
                (res) => res ? of(null) : this.restartAssessment()
              )
            );
          }

          return of(null);
        }
      )
    );


    return this.state$.pipe(filter(state => state.pageType === 'questions' || state.pageType === 'circleGraph')).pipe(
      switchMap((state) => combine)
    );
  }

  updateProfile() {
    of(this._anonService.anonUser).pipe(
      switchMap(
        (user) => user ? of({ user }) : this._userService.getProfile(this._auth.user ? this._auth.user.id : this._anonService.anonUser.id)
      )
    ).subscribe((res: ProfileDefinition) => {
      this.updateUserProfileData(res);
    });
  }

  get chart$(): Observable<CircleChart> {
    return this.state$.pipe(
      map(s => s.chart)
    );
  }

  set chart(chart: CircleChart) {
    this.patch({ chart });
  }

  set states(states: StateModel[]) {
    this.patch({ states });
  }

  get states$(): Observable<StateModel[]> {
    return this.state$.pipe(
      map(s => s.states)
    );
  }

  get answers$(): Observable<AnswerItem[]> {
    return this.state$.pipe(
      map(s => s.answers)
    );
  }

  set answers(answers: AnswerItem[]) {
    this.patch({ answers });
  }


  get showFillEnd$(): Observable<boolean> {
    return this.state$.pipe(
      map((s) => {
        return !environment.production && (s.pageType === 'landing' || s.pageType === 'questions' || s.pageType === 'circleGraph');
      })
    );
  }

  get quizResult$(): Observable<Result> {
    return this.quiz$.pipe(
      map(q => q.result),
      filter(r => !!r)
    );
  }

  get conferenceMode$(): Observable<number> {
    return this.quizResult$.pipe(
      map(result => Number(result.hidden_fields.conference_mode)),
      distinctUntilChanged()
    );
  }

  get pageType$(): Observable<PageType> {
    return this.state$.pipe(
      map(state => state.pageType)
    );
  }

  set pageType(pageType: PageType) {
    this.patch({ pageType });
  }


  get isLastStep$(): Observable<boolean> {
    return this.step$.pipe(
      map(step => step === this.numOfSteps - 1)
    );
  }


  get localization$(): Observable<{ [p: string]: string }> {
    return this.state$.pipe(
      map(s => s.quiz.result.quiz.properties.localizations)
    );
  }

  get locale$(): Observable<string> {
    return this.state$.pipe(
      filter(s => s != null && s.quiz != null),
      map(s => s.quiz.locale || s.quiz.result.locale),
      distinctUntilChanged()
    );
  }


  protected restartAssessment(): Observable<Quiz> {
    let userToken = '', quizToken = '';
    const updateParams: UpdateQuizParams = {
      hidden_fields: this.value.quiz.result.hidden_fields,
      quiz_key: this.value.quiz.result.quiz.quiz_key,
      result_key: this.value.quiz.result.result_key,
      result: {},
      locale: this.value.quiz.locale || this.value.quiz.result.locale
    };

    if (this._auth.isLoggedIn()) {
      userToken = this._auth.user.token_key;
      quizToken = this._auth.quiz_token;
    } else {
      userToken = this._anonService.anonToken;
      quizToken = this._anonService.anonQuizToken;
    }

    return this._quizService.updateQuizWithResult(updateParams, (!this._auth.isLoggedIn() ? userToken : null), (!this._auth.isLoggedIn() ? quizToken : null));
  }


  private updateUserProfileData(res: ProfileDefinition) {
    const na = 'N/A';
    res.user.email = (res.user.email && res.user.email !== na) ? res.user.email : null;
    res.user.first_name = (res.user.first_name && res.user.first_name !== na) ? res.user.first_name : null;
    res.user.last_name = (res.user.last_name && res.user.last_name !== na) ? res.user.last_name : null;
    res.company = (res.company && res.company !== na) ? res.company : null;
    res.city = (res.city && res.city !== na) ? res.city : null;
    res.region = (res.region && res.region !== na) ? res.region : null;

    res.industry = res.industry ? res.industry : { name: na };
    res.gender = res.gender ? res.gender : { name: na };
    res.country = res.country ? res.country : { name: na };
    res.state = res.state ? res.state : { name: na };
    res.age = res.age ? res.age : { name: na };
    res.income = res.income ? res.income : { name: na };
    res.job_level = res.job_level ? res.job_level : { name: na };
    res.education = res.education ? res.education : { name: na };
    res.company_size = res.company_size ? res.company_size : { name: na };
    res.company_revenue = res.company_revenue ? res.company_revenue : { name: na };
    console.log('res', res);


    this.setDemographicsFormData({
      id: res.user.profile.id || res.user.id,
      job_title: res.user.profile.job_title,
      email: this._auth.isLoggedIn() ? res.user.email : null,
      first_name: this._auth.isLoggedIn() ? res.user.first_name : null,
      last_name: this._auth.isLoggedIn() ? res.user.last_name : null,
      company: res.company,
      industry: res.industry.id,
      country: res.country.id,
      city: res.city,
      company_size: res.company_size.id,
      company_revenue: res.company_revenue.id,
      state: res.state.id
    });
  }

  setDemographicsFormData(data: DemographicsFormData) {
    console.log('setDemographicsFormData', data);
    if (data === null) {
      this.patch({ demoFormData: {} });
    }
    this.patch({
      demoFormData: {
        ...this.value.demoFormData,
        ...data
      }
    });
  }

  get demoFormData$(): Observable<DemographicsFormData> {
    return this.state$.pipe(
      map(s => s.demoFormData),
      filter(data => !!data),
      distinctUntilChanged()
    );
  }

  set countries(countries: Array<CountryModel>) {
    this.patch({ countries });
  }


  set companyRevenue(companyRevenue: Array<TranslateKeyItem>) {
    this.patch({ companyRevenue });
  }

  get companyRevenue$(): Observable<Array<TranslateKeyItem>> {
    return this.state$.pipe(
      map(s => s.companyRevenue)
    );
  }


  get report$(): Observable<any> {
    return this.state$.pipe(
      map(s => s.report)
    );
  }

  set report(report: any) {
    this.patch({ report });
  }


  get showDemoFormTwo$(): Observable<boolean> {
    return this.state$.pipe(
      map(s => s.showDemoFormTwo)
    );
  }

  set showDemoFormTwo(showDemoFormTwo: boolean) {
    this.patch({ showDemoFormTwo });
  }

  set companySize(companySize: Array<TranslateKeyItem>) {
    this.patch({ companySize });
  }

  get companySize$(): Observable<Array<TranslateKeyItem>> {
    return this.state$.pipe(
      map(s => s.companySize)
    );
  }

  set industries(industries: Array<TranslateKeyItem>) {
    this.patch({ industries });
  }

  get industries$(): Observable<Array<TranslateKeyItem>> {
    return this.state$.pipe(map(s => s.industries));
  }

  get countries$(): Observable<Array<CountryModel>> {
    return this.state$.pipe(
      map(s => s.countries)
    );
  }


  set color(color: TsqColor) {
    this.patch({ color });
  }

  get color$(): Observable<TsqColor> {
    return this.state$.pipe(
      map(s => s.color)
    );
  }


  set quizQuestions(quizQuestions: Array<QuizQuestion>) {
    this.patch({ quizQuestions });
  }

  set quiz(quiz: Quiz) {
    this.patch({ quiz });
  }

  set quizParams(quizParams: UpdateQuizParams) {
    this.patch({ quizParams });
  }

  get quizParams$(): Observable<UpdateQuizParams | null> {
    return this.state$.pipe(
      map(s => s.quizParams)
    );
  }

  get quiz$(): Observable<Quiz> {
    return this.state$.pipe(
      map(s => s.quiz),
      filter(q => !!q)
    );
  }

  private openRestartAssessmentDialog(data: number): Observable<any> {
    const dialogRef = this._dialog.open(RestartAssessmentComponent, {
      data
    });

    return dialogRef.afterClosed();
  }

  get step$(): Observable<number> {
    return this.state$.pipe(
      map(s => s.step),
      distinctUntilChanged()
    );
  }

  set step(step: number) {
    this.showPage(step);
    this.patch({ step });
  }

  get quizHeaders$(): Observable<QuizHeader[]> {
    return this.quiz$.pipe(
      map(q => q.result.quiz.fields)
    );
  }

  get status$(): Observable<string> {
    return this.step$.pipe(
      map(step => `${ step } / ${ this.numOfSteps - 1 }`)
    );
  }

  get progressValue$(): Observable<number> {
    return this.step$.pipe(
      map(step => (step / (this.numOfSteps - 1)) * 100)
    );
  }

  get resultKey$(): Observable<string> {
    return this.state$.pipe(
      map(s => s.quiz.result.result_key)
    );
  }


  get title$(): Observable<string> {
    return combineLatest([this.step$, this.quizQuestions$]).pipe(
      map(([step, q]) => q[step].title)
    );
  }


  get introductionHeader$(): Observable<QuizHeader | null> {
    return this.quizHeaders$.pipe(
      map((headers) => {
        const index = headers.map(h => h.title).indexOf('Introduction');

        if (index !== -1) {
          return headers[index];
        }

        return null;
      })
    );
  }


  get quizQuestions$(): Observable<QuizQuestion[]> {
    return this.state$.pipe(
      map(q => q.quizQuestions)
    );
  }

  get stepQuestions$(): Observable<Array<{ id: string; label: string }>> {
    return combineLatest([
      this.state$,
      this.quizQuestions$
    ]).pipe(
      map(([state, questions]) => {
        return questions[state.step].properties.labels as any;
      })
    );
  }

  get numOfSteps(): number {
    return this.value.quizQuestions.length;
  }


  private showPage(step: number) {
    if (this.value.quizQuestions[step].type === 'chart') {
      this.pageType = 'circleGraph';
    } else if (this.value.quizQuestions[step].type !== 'chart') {
      this.pageType = 'questions';
    }

    const properties = (this.value.quizQuestions[step].properties);
    if (properties.dimension === 'acquiringHiringTalent') {
      this.color = 'blue';
    } else if (properties.dimension === 'engagingEvolvingTalent') {
      this.color = 'orange';
    } else if (properties.dimension === 'improvingCultureRetention') {
      this.color = 'green';
    } else {
      this.color = 'grey';
    }
  }


  get dimension$(): Observable<string> {
    return this.step$.pipe(
      filter(step => !!this.value.quizQuestions[step]),
      map(step => this.value.quizQuestions[step].properties.dimension),
      distinctUntilChanged()
    );
  }

  get type$(): Observable<string> {
    return this.step$.pipe(
      filter(step => !!this.value.quizQuestions[step]),
      map(step => this.value.quizQuestions[step].type)
    );
  }

  nextStep() {
    if (this.numOfSteps - 1 <= this.value.step) return;
    const nextStep = this.value.step + 1;

    this.updateAnswersRequestStatus.asObservable().pipe(
      filter(res => !res),
      first()
    ).subscribe(() => {
      this.showPage(nextStep);
      setTimeout(() => {
        this.patch({ step: nextStep });
      }, 100);
    });

  }

  previousStep() {
    if (this.value.step === 0) return;
    const previousStep = this.value.step - 1;
    this.showPage(previousStep);
    this.patch({ step: previousStep });
  }

  get token(): string {
    return this._auth.user ? this._auth.token : this._anonService.anonToken;
  }


  private getQuizResults$(dimension: string): Observable<CircleChart> {
    return this._quizSystem.getQuizResults(
      { locale: 'en' },
      this.value.quiz.result.result_key,
      this.token, this.quizToken)
      .pipe(
        map((res: { result: { score: { scores: { calculate: { dimension: any } } } } }) => {
            const obj = res.result.score.scores.calculate.dimension;
            return obj[dimension];
          }
        ),
        filter(chart => chart),
        switchMap(
          (chart: CircleChart) => this.downloadChart(chart, chart.href.replace('%media_url%', API.MEDIA_API))
        )
      );
  }

  private downloadChart(chart: CircleChart, url: string): Observable<CircleChart> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this._http.get(url, {
      headers, responseType: 'text'
    }).pipe(
      map((chartText) => {
        return { ...chart, chart: chartText };
      })
    );
  }


}
