import { Thumb } from './thumbs.model';
import { ArticleModel } from './article.model';


/**
 * Base interface for assessment results
 */
interface BaseResult {
  user_report_template: TemplateTypes;
  user_invitable: boolean;
  user_invitable_count: number;
}


/**
 * Result model for "core drivers" assessment
 */
export interface CoreDriversResult extends BaseResult {
  score: CoreDriversScore;
}

/**
 * Template types for user assessment report templates
 */
export enum TemplateTypes {
  coreDrivers = 'core_drivers',
  whoAreYou = 'who_are_you',
  jqDiagnostic = 'jq_diagnostic',
  insight = 'insight',
  j3 = 'j3personica',
  extremes = 'extremes',
  incompetentMen = 'incompetent_men',
  knowledgeOfScience = 'knowledge_of_science',
  lq = 'lq_assessment',
  sis = 'sis_assessment',
  stg = 'stg_assessment',
  siemens = 'sie_assessment',
  tsq = 'tsq_assessment',
  gear = 'gear_assessment'
}

export interface CoreDriversScore {
  scales: Scales;
  scales_by_raw_percentage: string[];
  top_3_scales: string[];
  top_3_drivers: string[];
  drivers: Drivers;
  team_role: TeamRole;
  work_skills: WorkSkill[];
  potential: Potential;
  admin_report: {
    visual_representation: VisualRepresentation[];
  };
}

export interface Drivers {
  portrait: string;
  core_drivers: CoreDriver[];
  secondary_drivers: CoreDriver[];
  thumbs: {
    drivers: Thumb;
    extremes: Thumb;
  };
}

export interface CoreDriver {
  driver: string;
  driver_type: string;
  driver_text: string;
  extreme_text: string;
  visual_representation?: VisualRepresentation;
}

export interface VisualRepresentation {
  left_driver: string;
  right_driver: string;
  /** high: from 0% to 100% || low: from 100% to 0% */
  scale_type: 'high' | 'low';
  percentage: number;
}

export interface Potential {
  sparks: string[];
  habits: Habit[];
  interests: {
    [sectionName: string]: Interest;
  }[];
  knowledge: Knowledge[];
}

export interface Knowledge {
  knowledge_article_1: string;
  knowledge_article_1_image: string;
  knowledge_article_1_description: string;
  knowledge_article_2: string;
  knowledge_article_2_image: string;
  knowledge_article_2_description: string;
  knowledge_video: string;
  knowledge_video_image: string;
  knowledge_video_description: string;
  knowledge_podcast: string;
  knowledge_podcast_image: string;
  knowledge_podcast_description: string;
}

export interface Habit extends Thumb {
  habit_title: string;
  habit_text: string;
  habit_links: {
    [key: string]: string;
  }[];
}

export interface Interest {
  interest_title: string;
  interest_text: string;
  interest_link: string;
}

export interface Scales {
  [scaleName: string]: Scale;
}

export interface Scale {
  raw_score: number;
  scale_name: string;
  raw_percentage: number;
  display_percentage: number;
  cutoffs: Cutoffs;
}

export interface Cutoffs {
  cutoff1: Cutoff;
  cutoff2: Cutoff;
}

export interface Cutoff {
  type: string;
  label: string;
}

export interface TeamRole {
  teamwork: {
    name: string;
    icon: string;
    text_role: string;
    behaviors_to_watch_1: string;
    behaviors_to_watch_2: string;
    mitigate_risk_1: string;
    mitigate_risk_2: string;
    team_strength: string;
  };
  thumbs: {
    best: Thumb;
    extreme: Thumb;
  };
}

export interface WorkSkill {
  title: string;
  text: string;
}

export interface LQResult extends BaseResult {
  score: LQScore;
}

export interface LQScore {
  calculate: any;
  report: LQReport;
  score: any;
}

export interface LQReport {
  related_content: any;
}

/**
 * Result model for "who are you" assessment
 */
export interface WhoAreYouResult extends BaseResult {
  score: ScoreWhoAreYou[];
}

export interface ScoreWhoAreYou {
  name: string;
  score: number;
  raw_score: number;
  label: string;
  text: string;
  text_type: number;
  tips: Tips;
  drive_type: number;
  drive_place: number;
}

export interface Tips {
  strength: string;
  weakness: string;
}


/**
 * Result model for "JQDiagnostic" assessment
 */
export interface JQDiagnosticResult extends BaseResult {
  score: JQDiagnosticScore;
}

export interface JQDiagnosticScore {
  scales: JQScales;
  ethics: {
    best: string;
    risk: string;
  };
  inclusion: {
    best: string;
    risk: string;
  };
  respect: {
    best: string;
    risk: string;
  };
  persona: Persona;
}

export interface Persona {
  text: string;
  title: string;
  key: string;
  icon: string;
}

export interface JQScales {
  [scaleName: string]: JQScales;
}

export interface JQScale {
  raw_score: number;
  scale_name: string;
  percentage: string;
  cutoffs: Cutoffs;
}


export interface SharedUserReportResponse extends BaseResult {
  score: any;
  user: {
    first_name: string;
    last_name: string;
    // image_file: string;
  };
  articles?: {
    [slug: string]: ArticleModel;
  };
}


/**
 * Model for insight user report data
 */
export interface InsightResult extends BaseResult {
  score: InsightScore;
}

export interface InsightScore {
  scales: Scales;
  drivers_with_percentage: { [driver: string]: number };
  scales_by_display_percentage: string[];
  top_3_scales: string[];
  top_3_drivers: string[];
  top_3_drivers_keyed: {
    first: string;
    second: string;
    third: string;
  };
  drivers_display_percentages: { [driver: string]: number };
  drivers: Drivers;
  leadership: Leadership;
  communication: Communication;
}

export interface Leadership {
  leadership_presence: DriveSection;
  strategic_thinking: DriveSection;
  thumbs: {
    leadership_presence: Thumb;
    strategic_thinking: Thumb;
  };
}

export interface Communication {
  communicating_change: DriveSection;
  influencing_upwards: DriveSection;
  thumbs: {
    communicating_change: Thumb;
    influencing_upwards: Thumb;
  };
}

export interface DriveSection {
  overview: string;
  paras: Para[];
  tips: string[];
}

export interface Para {
  title: string;
  text: string;
}


/**
 * Model for extremes user report data
 */
export interface ExtremesResult extends BaseResult {
  score: ExtremesScore;
}


export interface ExtremesScore {
  scales: Scales;
  drivers_with_percentage: DriversWithPercentage;
  scales_by_display_percentage: string[];
  top_3_scales: string[];
  top_3_drivers: string[];
  top_3_drivers_keyed: Top3DriversKeyed;
  top_5_display_scales: string[];
  drivers_display_percentages: DriversDisplayPercentages;
  report: Report;
}

export interface DriversDisplayPercentages {
  Impulsive: number;
  Conformist: number;
  Withdrawn: number;
}

export interface DriversWithPercentage {
  Oversensitive: number;
  Insensitive: number;
  Rigid: number;
  Impulsive: number;
  Impassive: number;
  Intense: number;
  Dependent: number;
  Withdrawn: number;
  Eccentric: number;
  Conformist: number;
}

export interface Report {
  Challenge_1: ScalesString;
  Challenge_2: ScalesString;
  Challenge_3: ScalesString;
  'Decision-making': ScalesString;
  In_leadership: ScalesString;
  Leadership_effectiveness_1: ScalesString;
  Leadership_effectiveness_2: ScalesString;
  Leadership_effectiveness_3: ScalesString;
  On_the_plus_side: ScalesString;
  Relationship: ScalesString;
  Scale_overview: ScalesString;
  solve_it_1: ScalesString;
  Solve_it_2: ScalesString;
  Solve_it_3: ScalesString;
  Stressed_in_general: ScalesString;
  Intro_1: string;
  Intro_2: string;
  Intro_3: string;
  'Intro_on the plus side': string;
  Intro_reduce_the_risk: string;
  'Intro_what this means': string;
  'Quote_on the plus side': string;
  Tagline_1: string;
  Tagline_2: string;
  Description_short: ScaleDescriptionShort;
  scale_labels: ScaleLabels;
  thumbs: ExtremesThumbs;
}

export interface ScalesString {
  agreeableness: string;
  conscientiousness: string;
  stability: string;
  extraversion: string;
  openness: string;
}

export interface ScaleDescriptionShort {
  agreeableness: DescriptionShort;
  conscientiousness: DescriptionShort;
  extraversion: DescriptionShort;
  openness: DescriptionShort;
  stability: DescriptionShort;
}

export interface DescriptionShort {
  left: DescShortSide;
  right: DescShortSide;
  value: Value;
}

export interface DescShortSide {
  label: string;
  text: string;
}

export interface Value {
  type: string;
  label: string;
  percentage: number;
}

export interface ScaleLabels {
  agreeableness: Labels;
  conscientiousness: Labels;
  stability: Labels;
  extraversion: Labels;
  openness: Labels;
}

export interface Labels {
  low: string;
  high: string;
}

export interface Top3DriversKeyed {
  first: string;
  second: string;
  third: string;
}


export interface ExtremesThumbs {
  agreeableness: ThumbTabs;
  conscientiousness: ThumbTabs;
  stability: ThumbTabs;
  extraversion: ThumbTabs;
  openness: ThumbTabs;
}

export interface ThumbTabs {
  reduce_risk: Thumb;
  what_this_means: Thumb;
}


export interface IncompetentMenResults extends BaseResult {
  score: IncompetentMenScore;
}

export interface IncompetentMenScore {
  incompetent: Incompetence;
  scores: IMScores;
}


export interface Incompetence {
  container: {
    type: IMSectionType;
  };
  title: IMSection;
  related_content: IMSection;
  the_book: IMSection;
  about: IMSection;
  user_container: IMSection;
}

export interface IMSection {
  type: IMSectionType;

  text?: string;
  slug?: string;
  name?: string;

  image?: IMSection;
  title?: IMSection;
  paragraph?: IMSection;
  url?: IMSection;
  'sub-title'?: IMSection;

  [element: string]: any;
}

export enum IMSectionType {
  card = 'card',
  Paragraph = 'paragraph',
  SubTitle = 'sub-title',
  Title = 'title',
  Container = 'container',
  URL = 'url',
  UserCard = 'user_card'
}


export interface IMScores {
  incompetence: {
    overall: number;
  };
  grouped: {
    incompetence: {
      [scale: string]: string[];
    };
  };
  scales: {
    incompetence: ScalesIncompetence;
  };
}

export interface ScalesIncompetence {
  raw: number;
  display: number;
}

export interface KnowledgeOfScienceResult extends BaseResult {
  score: KosScore;
}

export interface KosScore {
  kos: Incompetence;
  my_score: number;
}

