import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'ds-multi-line-slider-chart',
  templateUrl: './multi-line-slider-chart.component.html',
  styleUrls: ['./multi-line-slider-chart.component.scss']
})
export class MultiLineSliderChartComponent implements OnInit, AfterViewInit
{
  private viewInitialized = false;

  _title: string = 'Title';
  @Input() set title(value: string)
  {
    this._title = value || 'Title';
  }
  _subtitle: string;
  @Input() set subtitle(value: string)
  {
    this._subtitle = value;
  }

  _leftLabel: string = 'Not an issue';
  @Input() set leftLabel(value: string)
  {
    this._leftLabel = value;
  }
  _rightLabel: string = 'An issue';
  @Input() set rightLabel(value: string)
  {
    this._rightLabel = value;
  }

  _data: MultiLineSliderChartData;
  @Input() set chartData(value: MultiLineSliderChartData)
  {
    this._data = value;
    if (this.viewInitialized)
    {
      setTimeout(() => {
        this.changeThumbsColour();

      }, 0);
    }
  }

  _sliderThumbColor: string;
  @Input() set sliderThumbColor(value: string)
  {
    this._sliderThumbColor = value;
  }

  @ViewChildren('slider') sliders: QueryList<MatSlider>;

  constructor() { }

  ngOnInit()
  {
  }

  ngAfterViewInit()
  {
    this.viewInitialized = true;
    this.changeThumbsColour();
  }

  private changeThumbsColour()
  {
    // If the thumb colour is defined, set all the thumbs from the sliders to that colour
    if (this._sliderThumbColor)
    {
      // For each slider in sliders collection
      this.sliders.forEach(slider =>
      {
        // Get the thumb element by it's class
        const thumbs = (<HTMLElement>slider._elementRef.nativeElement).getElementsByClassName('mat-slider-thumb');

        Array.from(thumbs).forEach((thumb: HTMLElement) =>
        {
          thumb.style.setProperty('background-color', this._sliderThumbColor);
        });
      });
    }
  }
}

export interface MultiLineSliderChartData
{
  /** A single row with the scale data */
  rows: {
    name: string;
    maxValue: number;
    minValue: number;
    avgValue: number;
  }[];
}
