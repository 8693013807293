/**
 * Thumb response model
 */
export interface ThumbResponse {
  thumb:      boolean;
  created_at: string;
  updated_at: string;
}

export interface Thumb {
  thumb_id:   string;
  thumb_type: ThumbType;
}


export enum ThumbType
{
  thumbableAtom = 'thumbable_atom'
}
