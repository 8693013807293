import { Component } from '@angular/core';
import { BaseResetPasswordComponent } from '../base-reset-password.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { fuseAnimations } from 'src/app/animations/animations';
import { ThemingService } from 'src/app/theme/theming.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';

@Component({
  selector: 'ds-reset-password-design2',
  templateUrl: './reset-password-design2.component.html',
  styleUrls: ['./reset-password-design2.component.scss'],
  animations: fuseAnimations
})
export class ResetPasswordDesign2Component extends BaseResetPasswordComponent
{
  accent;
  toolbarBGColor;
  toolbarFontColor;
  fontFamily;

  constructor(
    public themingService: ThemingService,
    protected userService: UsersService,
    protected helper: HelperService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected errService: ErrorService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService
  )
  {
    super(userService, helper, router, route, errService, formBuilder, routerParams);

    this.accent = themingService.accent;
    this.toolbarBGColor = themingService.toolbarBGColor;
    this.toolbarFontColor = themingService.toolbarFontColor;
    this.fontFamily = themingService.fontFamily;
  }
}
