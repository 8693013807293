import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ChartType, ChartsModel } from 'src/app/main/content/models/admin/report.def';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-report-charts',
  templateUrl: './report-charts.component.html',
  styleUrls: ['./report-charts.component.scss']
})
export class ReportChartsComponent implements OnInit
{
  chartType = ChartType;

  _charts: ChartsModel[] = [];
  @Input() set charts(value: ChartsModel[])
  {
    if (!value || value.length < 1)
    {
      this._charts = [];
    }
    else
    {
      this._charts = value;
    }
    if (this._charts.length <= 1)
    {
      this._templateColumns = this.sanitizer.bypassSecurityTrustStyle('minmax(300px, 1fr)');
    }
  }

  @Input() total = 0;

  _templateColumns: SafeStyle = '';
  @Input() set templateColumns(value: string)
  {
    this._templateColumns = this.sanitizer.bypassSecurityTrustStyle(value.split(' ').map(v => `minmax(300px, ${v})`).join(' '));
  }

  _isCardDesign = true;
  @Input() set isCardDesign(value: boolean)
  {
    this._isCardDesign = value;
  }

  constructor(
    public themingService: ThemingService,
    private sanitizer: DomSanitizer
  )
  {
    this._templateColumns = sanitizer.bypassSecurityTrustStyle('minmax(300px, 1fr) minmax(300px, 1fr)');
  }

  ngOnInit()
  {
  }



}
