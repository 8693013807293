import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'ds-skills-insight-user-report',
  templateUrl: './skills-insight-user-report.component.html',
  styleUrls: ['./skills-insight-user-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SkillsInsightUserReportComponent implements OnInit {
  @Input() result: any;

  constructor() { }

  ngOnInit() {
    console.log(this.result);
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  getCategory(ratingicone: string) {
    return ratingicone.split('_')[0];
  }
}
