import { OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helpers/helper.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';

export class BaseForgotPasswordComponent extends BaseRouteParamComponent implements OnInit, OnDestroy
{
  forgotPasswordForm: FormGroup;

  get emailControl() { return this.forgotPasswordForm.controls.email as FormControl; }

  constructor(
    protected authService: AuthService,
    protected helper: HelperService,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
  )
  {
    super(routerParams);
  }

  ngOnInit()
  {
    super.ngOnInit();

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)]]
    });
  }

  /**
   * Send the email user provided to API so he can recive an e-mail with a new password
   */
  sendResetLink()
  {
    const email = this.forgotPasswordForm.controls['email'].value;

    this.authService
      .forgotPassword(email, this.accountNameParam.replace('/', ''))
      .subscribe((res: { message: string }) =>
      {
        if (res && res.message) this.helper.showAlertWithouAutoDismiss(res.message);
        this.router.navigate([`${this.accountNameParam}/login`]);

      }, (err: HttpErrorResponse) =>
      {
        this.helper.showAlertWithouAutoDismiss(err.error.message);
      });
  }
}

