import { Component, OnInit, Input, HostBinding, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { EmailInviteStatus, emailStatusTranslationMap } from 'src/app/main/content/models/admin/invites.def';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailsPreviewDialogComponent } from '../../dialogs/emails-preview-dialog/emails-preview-dialog.component';

@Component({
  selector: 'ds-recipients-info',
  templateUrl: './recipients-info.component.html',
  styleUrls: ['./recipients-info.component.scss']
})
export class RecipientsInfoComponent implements OnInit, OnDestroy
{
  subs = new Subscription();

  @Input() type: EmailInviteStatus = EmailInviteStatus.total;
  @Input() value: number = 0;
  @Input() uuid: string;

  loading = false;
  emailStatusTranslationMap = emailStatusTranslationMap;

  @HostBinding('class') colorClass: string;
  @Input()
  set color(v: ThemePalette)
  {
    this.colorClass = v ? (v + '-color') : '';
  }

  @Output() followUpClick = new EventEmitter();

  constructor(
    private inviteService: InvitesService,
    private errService: ErrorService,
    private dialog: MatDialog,
  )
  { }

  ngOnInit()
  { }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }


  getEmailsForCollection()
  {
    if (!+this.value) return;

    this.loading = true;

    this.subs.add(
      this.inviteService.getEmailsForCollection(this.uuid, this.type).subscribe(res =>
      {
        console.log('E-mails: ', res);
        this.loading = false;

        this.dialog.open(EmailsPreviewDialogComponent, {
          autoFocus: false,
          restoreFocus: false,
          data: { emails: res.map(e => ({ id: e.id, email: e.email, status: e.user_assessment_status })) }
        });

      }, err =>
      {
        console.log('Error getting emails', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }

}
