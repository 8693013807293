import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Accounts, AccountV2 } from 'src/app/main/content/models/auth/auth.model';

@Component({
  selector: 'ds-choose-account-dialog',
  templateUrl: './choose-account-dialog.component.html',
  styleUrls: ['./choose-account-dialog.component.scss']
})
export class ChooseAccountDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChooseAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Accounts
  ) {
  }

  private static openDialog(dialog: MatDialog, data: Accounts[]) {
    return dialog.open(ChooseAccountDialogComponent, {
      data,
      disableClose: true,
      closeOnNavigation: true,
      autoFocus: false
    });
  }

  ngOnInit() {
    console.log('Dialog data', this.data);
  }

  choose(acc: AccountV2) {
    this.dialogRef.close({ accountIndex: acc.index_name });
  }
}
