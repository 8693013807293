import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { API } from 'src/app/api-url/api-urls';

@Component({
  selector: 'gear-pdf-report',
  templateUrl: './gear-pdf-report.component.html',
  styleUrls: ['./gear-pdf-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GearPdfReportComponent implements OnInit {

  @Input() report;

  public get results() {
    return this.report.score.scores.calculate;
  }

  constructor() { }

  ngOnInit() {
  }

  public getMediaUrl(mediaUrl) {
    return mediaUrl ? mediaUrl.replace('%media_url%', API.MEDIA_API) : '';
  }

  public getIntroImageNode(): string {
    return '<span class="gear-intro-img-container"><img class="gear-intro-img" src="' + this.getMediaUrl(this.report.score.scores.calculate.header_src) + '" alt=""></span>';
  }

  public getIntroWithImg(): string {
    const paragraphs = this.report.score.scores.calculate.static_content.text.split('<br>');

    if (paragraphs[2]){
      paragraphs[2] = paragraphs[2] + this.getIntroImageNode();
    } else if(paragraphs.length)  {
      paragraphs.push(this.getIntroImageNode());
    }

    return paragraphs.join("<br>") || '';
  }

  public getResultsWithImage(item: {src: string; body: string}) {
    const results = '<img class="result-imgs" src="' + this.getMediaUrl(item.src) + '"' + ' alt="">' + item.body;
    return results;
  }

}
