import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AssesmentComponent } from './assesment.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { QuizComponent } from '../quiz-system/quiz/quiz.component';
import { MarkdownModule } from 'ngx-markdown';
import { AuthGuard } from '../../../guards/auth.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { TsqAssesmentModule } from '../tsq-assesment/tsq-assesment.module';
import { GearAssesmentModule } from 'src/app/main/content/pages/shared/gear-assessment/gear-assesment.module';

const routes = [
  {
    path: `:${ ACCOUNT_NAME }/assessment/:id`, component: AssesmentComponent, canActivate: [ AuthGuard ]
  }
];

@NgModule({
  declarations: [
    AssesmentComponent,
    DemographicsComponent,
    QuizComponent
  ],
  imports: [SharedModule, TsqAssesmentModule, RouterModule.forChild(routes), MarkdownModule.forChild(), GearAssesmentModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})

export class AssesmentModule {

}
