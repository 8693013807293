import { Injectable } from '@angular/core';
import { ApiDefinition } from '../../models/api/api.def';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { API } from 'src/app/api-url/api-urls';
import { GeneralResponse } from '../../models/general/general-api-response.model';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
  }


  /**
   * Resends verification email
   *
   * POST /email/verify/resend
   * @param email orginal user email, not `new_email`
   * @param account_index
   */
  resendVerificationEmail(email: string, account_index: string) {
    return this.http.post<GeneralResponse>(API.APIDOMAIN + this.api.emailVerify + `/resend`, {
      email,
      account: account_index
    }, this.auth.httpOptions);
  }


  /**
   * Verifies the email verification token
   *
   * PATCH /email/verify/check/{token}
   * @param token
   */
  checkEmailVerificationToken(token: string) {
    return this.http.patch<GeneralResponse>(API.APIDOMAIN + this.api.emailVerify + `/check/${ token }`, {}, this.auth.httpOptions);
  }
}
