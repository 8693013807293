import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageType, TsqColor, TsqState, TsqStore } from './tsq.store';
import { Quiz, QuizHeader, QuizQuestion, ResultAnswer } from 'src/app/main/content/models/user/quiz.model';
import { Observable } from 'rxjs';
import { AnswerItem } from './components/tsq-questions-page/tsq-questions-page.component';
import { QuizParamsResult, UpdateQuizParams } from 'src/app/main/content/models/quiz/quiz.model';
import { QuizSystemService } from '../../../services/quiz/quiz-system.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { first, map, tap } from 'rxjs/operators';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { ROLE_DEVELOPER } from 'src/app/constants/user.role';
import * as moment from 'moment';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-assesment',
  templateUrl: './tsq-assesment.component.html',
  styleUrls: ['./tsq-assesment.component.scss'],
  providers: [TsqStore]
})
export class TsqAssesmentComponent implements OnInit {
  private _quiz: Quiz;
  private _quizParams: UpdateQuizParams;
  private _quizQuestions: QuizQuestion[] = [];
  state$: Observable<TsqState>;
  quizQuestions$: Observable<QuizQuestion[]>;
  introductionHeader$: Observable<QuizHeader | null>;
  questions$: Observable<Array<{ id: string; label: string }>>;
  step$: Observable<number>;
  status$: Observable<string>;
  progressValue$: Observable<number>;
  title$: Observable<string>;
  pageType$: Observable<PageType>;
  color$: Observable<TsqColor>;
  isLast$: Observable<boolean>;
  locale$: Observable<string>;
  showTestingButtons$: boolean;
  report$: Observable<any>;
  @Output()
  redirect: EventEmitter<void> = new EventEmitter<void>();


  @Input()
  hideFooter: boolean = false;
  enableEnd: boolean = false;
  showFillEnd$: Observable<boolean>;

  @Input()
  set report(value: any) {
    if (value) {
      this._store.report = value;
    }
  }


  @Input()
  set pageType(value: PageType) {
    if (value) {
      this._store.pageType = value;
    }
  }

  @Output()
  localeChanged: EventEmitter<string> = new EventEmitter<string>();


  @Input()
  userToken: string;

  @Input()
  userID: string;

  @Input()
  set formID(value: string) {
    this._store.formID = value;
  }

  @Input()
  set isPrint(value: boolean) {
    if (value === undefined) return;
    this._store.isPrint = value;
  }

  @Input()
  set quizToken(value: string) {
    this._store.quizToken = value ? value : this._auth.quiz_token;
  }


  @Input()
  emailRequired: boolean;

  @Input()
  set quiz(value: Quiz) {
    console.log('quiz', value);
    if (value === null) {
      this._store.pageType = 'results';
      return;
    }
    this._quiz = value;
    const quiz = value.result.quiz;
    console.log('quiz', value);
    this._quizQuestions = quiz.fields.filter(item => item.title !== 'Introduction')
      .reduce((previousValue: QuizQuestion[], currentValue: QuizHeader) => {
        return [...previousValue, ...currentValue.fields];
      }, []);

    this._store.quizQuestions = this._quizQuestions;

    const answers: AnswerItem[] = [];
    const fields = value.result.quiz.fields[1].fields;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.type === 'opinion_scale_label') {
        const obj: { text: string; value: string } = value.result_answers[field.field_key] as any;
        if (obj) {
          const answerItem: AnswerItem = { ...obj, key: field.field_key, step: i };
          answers.push(answerItem);
        }
      }
    }

    this._store.answers = answers;
    this._store.quiz = value;
  }


  constructor(
    private _store: TsqStore,
    private _quizSystem: QuizSystemService,
    private _auth: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _anonService: AnonUserService,
    private _translationService: TranslocoService
  ) {
    this.state$ = _store.state$;
    this.introductionHeader$ = this._store.introductionHeader$;
    this.questions$ = this._store.stepQuestions$;
    this.step$ = this._store.step$;
    this.status$ = this._store.status$;
    this.progressValue$ = this._store.progressValue$;
    this.title$ = this._store.title$;
    this.quizQuestions$ = this._store.quizQuestions$;
    this.color$ = this._store.color$;
    this.isLast$ = this._store.isLastStep$;
    this.locale$ = this._store.locale$;
    this.showFillEnd$ = this._store.showFillEnd$;
    this.showTestingButtons$ = !environment.production || (this._auth && this._auth.user && this._auth.user.roles && this._auth.user.roles.includes(ROLE_DEVELOPER));


    this.pageType$ = this._store.pageType$;

    this.report$ = this._store.report$;
  }

  async ngOnInit() {
    this.locale$.pipe(
      first()
    ).subscribe(locale => this._translationService.setActiveLang(locale));
    if (!this._quiz) return;
    const len = Object.keys(this._quiz.result_answers).length;
    if (len && this._quiz.last_answer) {
      const lastAnswerFieldKey = this._quiz.last_answer;
      const idx = this._quizQuestions.map(item => item.field_key).indexOf(lastAnswerFieldKey);
      if (idx !== -1) {
        this._store.step = idx + 1;
      }
    }


    const newParam = this.getQueryParam('new');

    if (newParam) {
      await this.navigateRelative({ new: null });
    }
  }

  onLandingPageNext() {
    this._store.pageType = 'questions';
  }

  onQuestionsNextStep() {
    this._store.nextStep();
  }

  onPreviousStep() {
    this._store.previousStep();
  }


  onUpdateAnswers(res: { answers: AnswerItem[]; answer: string }) {
    // 2022-02-22 22:22:22

    const result_answers = this._store.value.quiz.result_answers;
    console.log('resultAnswers', result_answers);
    const result: QuizParamsResult = {};
    for (let i = 0; i < res.answers.length; i++) {
      const answer = res.answers[i];
      const oby: any = result[answer.key];

      const fun = (key1: string, key2: string, obj: AnswerItem, resultAnswers: ResultAnswer): string => {
        const dateString: string = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS');
        const resultA: any = resultAnswers[key1];
        console.log('resultA', resultA);
        if (key1 === key2 || !!!resultA) {
          return dateString;
        }

        return resultA.opened_at;
      };

      result[answer.key] = {
        opened_at: fun(answer.key, res.answer, answer, result_answers),
        text: answer.text,
        value: answer.value
      };

    }

    this._quizParams = {
      quiz_key: this._quiz.result.quiz.quiz_key,
      result_key: this._quiz.result.result_key,
      locale: this._quiz.locale,
      hidden_fields: this._quiz.result.hidden_fields,
      result
    };

    this._store.quizParams = this._quizParams;


    this._store.updateAnswersSubject.next({
      params: this._quizParams,
      userToken: this._store.token,
      quizToken: this._store.quizToken
    });

  }

  getQueryParam(queryParamName: string): string {
    return this._activatedRoute.snapshot.queryParamMap.get(queryParamName);
  }

  async navigateRelative(queryParams: Params | null, queryParamsHandling: QueryParamsHandling = 'merge'): Promise<boolean> {
    return await this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling
      });
  }


  async onFill() {
    this._quizSystem.getRandomResults(this._quiz.result.quiz.quiz_key, this._quiz.locale, this._store.token, this._store.quizToken).pipe(
      map((answers: any) => {
        const answerItems = [];
        let step = 1;
        // tslint:disable-next-line:forin
        for (const key in answers) {
          const answer = answers[key];
          answerItems.push({
            step: step++,
            key: key,
            text: answer.text,
            value: answer.value
          });
        }

        return answerItems;
      }),
      tap((answerItems: AnswerItem[]) => {
        this._store.answers = answerItems;
        this.onUpdateAnswers({ answers: answerItems, answer: null });
        setTimeout(() => {
          this.enableEnd = true;
        }, 1000);
      })
    ).subscribe();
  }


  onStart() {
    this._store.step = 0;
  }

  onEnd() {
    this._store.step = this._store.numOfSteps - 1;
  }

  onRedirect() {
    this.redirect.emit();
  }

  onLocaleChanged(locale: string) {
    this.localeChanged.emit(locale);
    this._translationService.setActiveLang(locale);
  }
}
