import { OnDestroy, OnInit } from '@angular/core';
import {
  AuthService as SocialService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser
} from 'angular-6-social-login';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLE_ADMIN } from 'src/app/constants/user.role';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../services/auth/auth.service';
import { UserDefinition } from '../../../models/user/user.def';
import { AuthGuard } from '../../../guards/auth.guard';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import {
  LoginV2AccountListResponse,
  LoginV2Response,
  SocialLoginAccountListResponse
} from '../../../models/auth/auth.model';
import { combineLatest, from, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  ChooseAccountDialogComponent
} from '../../shared/dialogs/choose-account-dialog/choose-account-dialog.component';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { Title } from '@angular/platform-browser';
import { ThemingService } from 'src/app/theme/theming.service';
import {
  InviteLinkLoginDialogComponent
} from '../../shared/dialogs/invite-link-login-dialog/invite-link-login-dialog.component';
import { config } from '../../../../../../config';
import { VerifyService } from '../../../services/auth/verify.service';
import {
  MfaAction,
  MFALoginResponse,
  SocialMFALoginResponse
} from '../../../models/general/general-api-response.model';
import {
  TwoFactorCodeDialogComponent
} from '../../shared/dialogs/two-factor-code-dialog/two-factor-code-dialog.component';
import { map, take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

export class BaseLoginDesignComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  loading: boolean;
  previousURL: string;
  /** A return url for invites */
  returnURL: string;
  inviteToken: string;
  inviteEmail: string;

  loginForm: FormGroup;

  verifyEmailMsg = 'false';

  siteKey = config.recaptchaSiteKey;

  subs = new Subscription();

  resendEmailButtonVisible = false;
  protected unVerifiedEmail: string;
  protected unVerifiedAccount: string;

  public get email() {
    return this.loginForm.get('email') as FormControl;
  }

  public get password() {
    return this.loginForm.get('password') as FormControl;
  }

  constructor(
    protected authGuard: AuthGuard,
    public authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected title: Title,
    protected verifyService: VerifyService,
    protected translocoService: TranslocoService,
    public themingService: ThemingService,
  ) {
    super(routerParams);
  }

  /**
   * Call this in the component constructor or ngOnInit
   */
  ngOnInit() {
    super.ngOnInit();
    this.title.setTitle('Login - ' + this.themingService.title);
    this.loading = false;
    this.previousURL = undefined;

    this.verifyEmailMsg = localStorage.getItem('verify_email_msg') || 'false';

    if (this.authService.isLoggedIn()) {
      if (this.authService.user && this.authService.user.roles.includes(ROLE_ADMIN)) {
        this.authService.isUserAdmin = true;
        this.redirectAfterLogin();
      } else {
        this.redirectAfterLogin();
      }
    }

    if (this.authGuard.redirectURL) {
      // Check if user was redirected here by an authGuard
      combineLatest([this.translocoService.selectTranslate<string>('toasts.pleaseLoginFirst'),
        this.translocoService.selectTranslate<string>('toasts.ok')],
      )
        .pipe(take(1))
        .subscribe(([message, closeBtnText]) => {
          this.helper.showAlertWithouAutoDismiss(message, closeBtnText);
        });
      this.previousURL = this.authGuard.redirectURL; // Remember where the user was before
    }

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)]],
      password: ['', Validators.required]
    });

    // Check if return URL exists
    this.returnURL = this.route.snapshot.queryParams['returnUrl'] || localStorage.getItem('returnUrl');
    this.inviteToken = this.route.snapshot.queryParams['token'];
    this.inviteEmail = this.route.snapshot.queryParams['email'];

    if (this.inviteEmail) {
      (<FormControl>this.loginForm.controls.email).setValue(this.inviteEmail);
      (<FormControl>this.loginForm.controls.email).disable();
    }
  }


  get disableLoginButton(): boolean {
    return this.loginForm.invalid || this.loginForm.pending;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.title.setTitle(this.themingService.title);
  }

  private redirectAfterLogin() {
    if (this.previousURL) {
      this.authGuard.redirectURL = undefined; // Clear the redirecturl
      this.router.navigate([this.previousURL]);
    } else {
      if (!AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM.replace('/', '')) {
        this.authService.logout();
      } else {
        if (this.returnURL) // If return url exist, we need to return the user that just logged in to that url
        {
          localStorage.removeItem('returnUrl');
          localStorage.removeItem('user-had-account');
          this.router.navigate([this.returnURL], {
            queryParams: {
              skipVerify: true
            }
          });
        } else // otherwise, just route normally to dashboard
        {
          if (this.authService.isUserAdmin)
            this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/admin/accounts`]);
          else
            this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/dashboard`]);
        }
      }
    }
  }


  loginV2(captchaResponse: string, extras: { MFA_code?: string; account?: string } = {}) {
    if (this.loginForm.invalid) {
      return;
    }

    const email = this.email.value;
    const password = this.password.value;

    this.loading = true;

    this.subs.add(
      this.authService.login.login(
        email,
        password,
        this.inviteToken,
        this.accountNameParam.replace('/', '') || extras.account || null,
        captchaResponse,
        extras.MFA_code || null
      )
        .subscribe((res: LoginV2Response | LoginV2AccountListResponse | MFALoginResponse) => {
          console.log(this.inviteToken);
          // check if login is a 2FA
          if ((<MFALoginResponse>res).action && (<MFALoginResponse>res).action === MfaAction.googleAuthenticator) {
            const dialog_ref = this.dialog.open(TwoFactorCodeDialogComponent, {
              closeOnNavigation: false,
              disableClose: true,
              minWidth: 250
            });

            dialog_ref.afterClosed().subscribe(code => {
              if (code) {
                this.loginV2(captchaResponse, { MFA_code: code });
              } else {
                this.loading = false;
              }
            });
          } else {
            this.standardLogin(res, email, password, captchaResponse, extras.MFA_code || null);
          }
        }, (err: HttpErrorResponse) => {
          console.log('Login V2 error', err);
          this.loading = false;
          if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
            this.handleUnVerifiedError(err, email, this.accountNameParam.replace('/', ''));
          } else
            this.errorService.handleHttpErrorForLogedOutUser(err);
        })
    );
  }


  private standardLogin(
    res: LoginV2Response | LoginV2AccountListResponse | MFALoginResponse,
    email: string,
    password: string,
    captchaResponse: string,
    twoFactorCode?: string
  ) {
    let isAccountList = false;
    let resLogin: LoginV2Response;
    let resAccount: LoginV2AccountListResponse;

    // Check the type of the response
    if ((<LoginV2AccountListResponse>res).accounts) {
      isAccountList = true;
      resAccount = <LoginV2AccountListResponse>res;
    } else {
      isAccountList = false;
      resLogin = <LoginV2Response>res;
    }


    if (isAccountList) {
      if (resAccount.accounts.length === 1) // Check if has only one accout
      {
        console.log('Only one account', resAccount);
        // login user using this one account
        // Have to call the login api again but this time with account name
        this.subs.add(
          this.authService.login.login(email, password, this.inviteToken, resAccount.accounts[0].account.index_name, captchaResponse, twoFactorCode)
            .subscribe((accLogin: LoginV2Response | MFALoginResponse) => {
                // check if login is a 2FA
                if ((<MFALoginResponse>accLogin).action && (<MFALoginResponse>accLogin).action === MfaAction.googleAuthenticator) {
                  const dialog_ref = this.dialog.open(TwoFactorCodeDialogComponent, {
                    closeOnNavigation: false,
                    disableClose: true,
                    minWidth: 250
                  });

                  dialog_ref.afterClosed().subscribe(code => {
                    if (code) {
                      this.loginV2(captchaResponse, {
                        account: resAccount.accounts[0].account.index_name,
                        MFA_code: code
                      });
                    } else {
                      this.loading = false;
                    }
                  });
                } else {
                  this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
                }

              },
              err => {
                console.log('One account login error', err);
                this.loading = false;
                if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
                  this.handleUnVerifiedError(err, email, resAccount.accounts[0].account.index_name);
                } else
                  this.errorService.handleHttpErrorForLogedOutUser(err);
              })
        );
      } else // If more that one account
      {
        this.loading = false;
        console.log('More than one account', resAccount);
        // open selector of accounts
        const dialogRef = this.dialog.open(ChooseAccountDialogComponent, {
          data: resAccount.accounts,
          disableClose: true,
          closeOnNavigation: true,
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(dialogResponse => {
          if (dialogResponse) {
            this.loading = true;
            this.subs.add(
              this.authService.login.login(email, password, this.inviteToken, dialogResponse.accountIndex, captchaResponse, twoFactorCode)
                .subscribe((accLogin: LoginV2Response | MFALoginResponse) => {
                    // check if login is a 2FA
                    if ((<MFALoginResponse>accLogin).action && (<MFALoginResponse>accLogin).action === MfaAction.googleAuthenticator) {
                      const dialog_ref = this.dialog.open(TwoFactorCodeDialogComponent, {
                        closeOnNavigation: false,
                        disableClose: true,
                        minWidth: 250
                      });

                      dialog_ref.afterClosed().subscribe(code => {
                        if (code) {
                          this.loginV2(captchaResponse, { account: dialogResponse.accountIndex, MFA_code: code });
                        } else {
                          this.loading = false;
                        }
                      });
                    } else {
                      this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
                    }
                  },
                  err => {
                    console.log('One account login error', err);
                    this.loading = false;
                    if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
                      this.handleUnVerifiedError(err, email, dialogResponse.accountIndex);
                    } else
                      this.errorService.handleHttpErrorForLogedOutUser(err);
                  })
            );
          } else {
            this.loading = false;
          }
        });
      }
    } else // We know the account, login user regularly
    {
      console.log('Exact account is knows, login normal');

      this.checkForInviteLinkLogin(resLogin);
    }
  }


  private handleUnVerifiedError(err: HttpErrorResponse, email: string, account: string) {
    this.helper.showAlertWithouAutoDismiss(err.error.message || 'You must verify your account by following a link in your e-mail');
    this.resendEmailButtonVisible = true;
    this.unVerifiedEmail = email;
    this.unVerifiedAccount = account;
  }

  protected checkForInviteLinkLogin(data: LoginV2Response) {
    if (localStorage.getItem('user-had-account') === 'true') {
      console.log('%cUser was logged in before', 'color: red; font-weight: bold');
      const dialog_ref = this.dialog.open(InviteLinkLoginDialogComponent, {
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        data: data
      });

      dialog_ref.afterClosed().subscribe(dialog_res => {
        if (dialog_res === 'login') {
          this.returnURL = null;
          localStorage.removeItem('returnUrl');
          localStorage.removeItem('user-had-account');
          this.saveUserInfo(data);
        } else {
          localStorage.removeItem('user-had-account');
          this.router.navigate([this.returnURL]);
        }
      });
    } else {
      this.saveUserInfo(data);
    }
  }

  protected saveUserInfo(data: LoginV2Response) {
    console.log('Login data: ', data);

    AccountIndexClass.ACCOUNT_INDEX = data.user.account.index_name;
    AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + data.user.account.index_name;

    const user: UserDefinition = data.user;
    user['token_key'] = data.token_key;
    user['refresh_token'] = data.refresh_token;
    this.authService.setData(user, data.quizToken.token);

    if (this.authService.user.roles.includes(ROLE_ADMIN)) {
      this.authService.isUserAdmin = true;
      this.redirectAfterLogin();
    } else {
      this.redirectAfterLogin();
    }
  }

  protected resendEmailVerification() {
    this.subs.add(
      this.verifyService.resendVerificationEmail(this.unVerifiedEmail, this.unVerifiedAccount)
        .subscribe(res => {
          console.log('Resend email response:', res);
          this.helper.showAlertWithouAutoDismiss(res.message || 'Verification e-mail has been resent');
          this.resendEmailButtonVisible = false;

        }, err => {
          console.log('Error resending email', err);
          this.resendEmailButtonVisible = false;
          this.errorService.handleHttpErrorForLogedInUser(err);
        })
    );
  }


  /**
   * Gets called every time we need to resend the request
   */
  private socialLogin(
    type: 'facebook' | 'google',
    user: any,
    account: string,
    token: string,
    checksum: string,
    twoFactorCode: string
  ) {


    console.log({ type, user, account, token, checksum, twoFactorCode });
    this.authService.login[type](user, account, this.inviteToken, token, checksum, twoFactorCode).subscribe((res: LoginV2Response | SocialLoginAccountListResponse | SocialMFALoginResponse) => {
      // check if login is a 2FA
      if ((<SocialMFALoginResponse>res).action && (<SocialMFALoginResponse>res).action === MfaAction.googleAuthenticator) {
        this.socialMFAlogin(<SocialMFALoginResponse>res, user.email, account);
      } else {
        this.standardSocialLogin(res, type, user, account, twoFactorCode);
      }
    }, (err: HttpErrorResponse) => {
      console.error('Login V2 error', err);
      this.loading = false;
      if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
        this.handleUnVerifiedError(err, user.email, this.accountNameParam.replace('/', ''));
      } else
        this.errorService.handleHttpErrorForLogedOutUser(err);
    });
  }


  /**
   * Checks the type of login and further steps
   */
  private standardSocialLogin(
    res: LoginV2Response | SocialLoginAccountListResponse | SocialMFALoginResponse,
    type: 'facebook' | 'google',
    user: any,
    account: string,
    twoFactorCode?: string
  ) {
    let isAccountList = false;
    let resLogin: LoginV2Response;
    let resAccount: SocialLoginAccountListResponse;

    // Check the type of the response
    if ((<SocialLoginAccountListResponse>res).accounts) {
      isAccountList = true;
      resAccount = <SocialLoginAccountListResponse>res;
    } else {
      isAccountList = false;
      resLogin = <LoginV2Response>res;
    }


    if (isAccountList) {
      if (resAccount.accounts.length === 1) // Check if has only one accout
      {
        console.log('Only one account', resAccount);
        // login user using this one account
        // Have to call the login api again but this time with account name
        this.subs.add(
          this.authService.login[type](user, resAccount.accounts[0].account.index_name, resAccount.token, resAccount.checksum, twoFactorCode)
            .subscribe((accLogin: LoginV2Response | SocialMFALoginResponse) => {
                // check if login is a 2FA
                if ((<SocialMFALoginResponse>accLogin).action && (<SocialMFALoginResponse>accLogin).action === MfaAction.googleAuthenticator) {
                  this.socialMFAlogin(<SocialMFALoginResponse>accLogin, user.email, resAccount.accounts[0].account.index_name);
                } else {
                  this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
                }

              },
              err => {
                console.log('One account login error', err);
                this.loading = false;
                if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
                  this.handleUnVerifiedError(err, user.email, resAccount.accounts[0].account.index_name);
                } else
                  this.errorService.handleHttpErrorForLogedOutUser(err);
              })
        );
      } else // If more that one account
      {
        this.loading = false;
        console.log('More than one account', resAccount);
        // open selector of accounts
        const dialogRef = this.dialog.open(ChooseAccountDialogComponent, {
          data: resAccount.accounts,
          disableClose: true,
          closeOnNavigation: true,
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(dialogResponse => {
          if (dialogResponse) {
            this.loading = true;
            this.subs.add(
              this.authService.login[type](user, dialogResponse.accountIndex, resAccount.token, resAccount.checksum, twoFactorCode)
                .subscribe((accLogin: LoginV2Response | SocialMFALoginResponse) => {
                    // check if login is a 2FA
                    if ((<SocialMFALoginResponse>accLogin).action && (<SocialMFALoginResponse>accLogin).action === MfaAction.googleAuthenticator) {
                      this.socialMFAlogin(<SocialMFALoginResponse>accLogin, user.email, dialogResponse.accountIndex);
                    } else {
                      this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
                    }
                  },
                  err => {
                    console.log('One account login error', err);
                    this.loading = false;
                    if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
                      this.handleUnVerifiedError(err, user.email, dialogResponse.accountIndex);
                    } else
                      this.errorService.handleHttpErrorForLogedOutUser(err);
                  })
            );
          } else {
            this.loading = false;
          }
        });
      }
    } else // We know the account, login user regularly
    {
      console.log('Exact account is knows, login normal');

      this.checkForInviteLinkLogin(resLogin);
    }
  }


  /**
   * Opens 2FA dialog and send the code to the api
   */
  private socialMFAlogin(res: SocialMFALoginResponse, email: string, account: string) {
    const dialog_ref = this.dialog.open(TwoFactorCodeDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      minWidth: 250
    });

    dialog_ref.afterClosed().subscribe(code => {
      if (code) {
        this.subs.add(
          this.authService.login.mfaLogin(res.MFAToken, code).subscribe(mfaRes => {
            console.log('MFA login response, login normal', mfaRes);
            this.checkForInviteLinkLogin(mfaRes);

          }, err => {
            console.log('MFA login error', err);
            this.loading = false;
            if (err.error && err.error.trans_code === 'exception.user.admin.not.verified') {
              this.handleUnVerifiedError(err, email, account);
            } else
              this.errorService.handleHttpErrorForLogedOutUser(err);
          })
        );
      } else {
        this.loading = false;
      }
    });
  }


  facebookLogin() {
    console.log('facebookLogin', this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID));
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData: SocialUser) => {
      const user = {
        first_name: userData.name.split(' ')[0],
        last_name: userData.name.split(' ')[1],
        email: userData.email,
        facebook_id: userData.id,
        access_token: userData.token,
      };
      console.log('userData', userData);
      this.socialLogin('facebook', user, this.accountNameParam.replace('/', ''), null, null, null);
    });
  }

  googleLogin() {
    console.log(this.socialService);
    const promise = this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
    console.log('googleLogin', promise);
    from(promise).pipe(
      map((userData) => {
        return {
          first_name: userData.name.split(' ')[0],
          last_name: userData.name.split(' ')[1],
          email: userData.email,
          google_id: userData.id,
          access_token: userData.token
        };
      })
    ).subscribe((user) => {
      this.socialLogin('google', user, this.accountNameParam.replace('/', ''), null, null, null);
    }, (err) => {
      console.error(err);
    });
  }
}
