import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuizHeader } from 'src/app/main/content/models/user/quiz.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'gear-intro',
  templateUrl: './gear-intro.component.html',
  styleUrls: ['./gear-intro.component.scss']
})
export class GearIntroComponent implements OnInit, OnChanges {

  languages = {};

  @Output()
  start: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  quizHeader: QuizHeader;
  @Input()
  localization: { [key: string]: string; };
  @Input()
  locale: string = 'en';


   @Output()
   languageChange: EventEmitter<string> = new EventEmitter<string>();

  text: string;

  constructor() {
  }

  ngOnInit() {
    this.addCurrentYear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.quizHeader) {
      const langArray = changes.quizHeader.currentValue.properties.choices;

      langArray.forEach(lang => {
        console.log(lang);
        Object.assign(this.languages, {[lang.id]: lang.label})
        console.log(this.languages);
      })
    }
  }

  handleLanguageSelect(event: MatSelectChange) {
    this.languageChange.emit(event.value);
  }

  addCurrentYear() {
    const date = new Date();
    const year = date.getFullYear();
    this.text = this.quizHeader.properties.text.replace("{year}", year.toString());
  }
}
