import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiDefinition } from '../../models/api/api.def';
import { AssessmentDetailsModel, UserAssessment, UserAssessmentModel } from '../../models/admin/assesment.def';
import { API } from 'src/app/api-url/api-urls';
import { Quiz } from '../../models/user/quiz.model';
import { AssessmentModel, SubmittedAssessmentCollection } from '../../models/admin/results.def';

const FORMS_PER_PAGE = 3;

@Injectable({
  providedIn: 'root'
})
export class AssesmentService {
  api: ApiDefinition;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.api = this.auth.api;
  }

  /* getTypeForms(page: number = 1) {
    return this.http.get<Forms>(`${API.APIDOMAIN}${this.api.type_forms}?pageSize=${FORMS_PER_PAGE}&page=${page}`, this.auth.httpOptions);
  } */

  /**
   * Get a quiz by it's ID
   *
   * GET /userassessments/form/{formId}
   * @param id Quiz id
   * @param newResults if true, quiz will be retaken as new
   */
  getAssessmentForm(id: string, locale: string, newResults?: boolean) {
    const httpOptions = this.auth.httpOptions;
    httpOptions.params = new HttpParams();

    if (newResults) {
      httpOptions.params = httpOptions.params.set('new', 'true').set('locale', locale);
    } else {
      httpOptions.params = httpOptions.params.set('locale', locale);
    }

    return this.http.get<Quiz>(`${ API.APIDOMAIN }${ this.api.userassessmentsForm }/${ id }`, httpOptions);
  }


  /**
   * Get a quiz by it's ID for anonymous users
   *
   * GET /userassessments/form/{formId}
   */
  getAnonAssessmentForm(formID: string, userToken: string, locale: string, newResults?: boolean) {
    const options = this.auth.httpOptions;
    const headers = {
      'Content-Type': 'application/json',
      'X-TOKEN': userToken
    };
    options.headers = new HttpHeaders(headers);
    options.params = new HttpParams();

    if (locale) {
      options.params = options.params.set('locale', locale);
      localStorage.setItem('anon_quiz_locale', locale); // 07.04.23 TODO: Handle this on FE2
    }

    if (newResults) {
      options.params = options.params.set('new', 'true');
    }

    return this.http.get<Quiz>(`${ API.APIDOMAIN }${ this.api.userassessmentsForm }/${ formID }`, options);
  }


  getTypeFormResultById(id, page: number = 1) {
    return this.http.get(`${ API.APIDOMAIN }${ this.api.type_form_results }/${ id }/${ FORMS_PER_PAGE }/${ page }`, this.auth.httpOptions);
  }


  /**
   * GET completed assessments the user submitted
   *
   * GET /submitted-assessments/elastic/{user_id}
   * @param user_id
   */
  getSubmittedAssessments(user_id: number) {
    return this.http.get<SubmittedAssessmentCollection>(API.APIDOMAIN + this.api.submittedAssessments + '/' + user_id, this.auth.httpOptions);
  }

  /**
   * Get all the assessments the user has finished.
   *
   * GET /userassessments/{id}
   * @param user_id
   */
  getFinishedUsersAssessments(user_id: number) {
    return this.http.get<UserAssessmentModel[]>(API.APIDOMAIN + this.api.user_assessments + '/' + user_id, this.auth.httpOptions);
  }

  /**
   * Get all the assessments the user has assigned.
   *
   * GET /userassessments
   */
  getUsersAssessments() {
    return this.http.get<UserAssessment[]>(API.APIDOMAIN + this.api.user_assessments, this.auth.httpOptions);
  }

  /**
   * GET assessment list
   *
   */
  getAssessmentList() {
    return this.http.get(API.APIDOMAIN + this.api.assessmentList, this.auth.httpOptions);
  }

  /**
   * Sets user's assessment as finished
   *
   * POST /userassessments/submitted/
   *
   * @param body
   * @param userToken optional param for usage with anon quiz
   */
  finishAssessment(body: { form_id: string, locale: string }, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) {
      options.headers = {
        'X-TOKEN': userToken
      };
    }

    return this.http.post(API.APIDOMAIN + this.api.user_assessments + '/submitted', body, options);
  }


  /**
   * POST /assessments/uploadimage
   * @param body
   */
  uploadAssessmentImage(body: { image: string; assessment_id: number }) {
    const options: {
      headers?: HttpHeaders;
      reportProgress?: boolean;
      params?: HttpParams;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {};
    options.headers = (<any>this.auth.httpOptions.headers);
    options.reportProgress = true;

    return this.http.request<{ image: string }>(
      new HttpRequest('POST', API.APIDOMAIN + this.api.assessmentList + '/uploadimage', body, options)
    );

    /* return this.http.post<HttpResponse<{ image: string }>>(API.APIDOMAIN + this.api.assessmentList + '/uploadimage', body, options); */
  }

  /**
   * Get results for user by the form id and user id
   *
   * GET /results/{form_id}/{user_id}/score
   *
   * @param form_id
   * @param user_id
   */
  getResultByFormIdAndUserID(form_id: string, user_id: number) {
    return this.http.get<any>(API.APIDOMAIN + `${ this.api.results }/${ form_id }/${ user_id }/score`, this.auth.httpOptions);
  }

  /**
   * Get assessments that are not yet assigned to a user
   *
   * GET /assessments/{user_id}/available
   * @param user_id
   */
  getAvailableAssessments(user_id: number) {
    return this.http.get<AssessmentModel[]>(API.APIDOMAIN + this.api.assessmentList + `/${ user_id }/available`, this.auth.httpOptions);
  }

  /**
   * Assign an assessment to a user
   *
   * POST /assessments/user/add
   * @param user_id
   * @param form_id
   */
  assignUserToAssessment(user_id: number, form_id: string, shareOptions?: boolean[], game?: string) {
    return this.http.post<UserAssessment>(API.APIDOMAIN + this.api.assessmentList + `/user/add`, {
      user_id,
      form_id,
      game,
      ...shareOptions
    }, this.auth.httpOptions);
  }

  /**
   * Get an assessment's details
   *
   * GET /assessments/{id}
   * @param id
   */
  getAssessmentDetails(id: number) {
    return this.http.get<AssessmentDetailsModel>(API.APIDOMAIN + this.api.assessmentList + `/${ id }`, this.auth.httpOptions);
  }

  /**
   * Enable or disable an assessment
   *
   * POST /assessments/{id}/enable
   *
   * @param assessment_id
   * @param enabled
   */
  toggleAssessmentState(assessment_id: number, enabled: boolean) {
    return this.http.post<{ enabled: boolean }>(API.APIDOMAIN + this.api.assessmentList + `/${ assessment_id }/enable`, {enabled}, this.auth.httpOptions);
  }


  /**
   * Remove user results from elastic and optionally from result log
   *
   * DELETE /userassessments/result/{userAssessmentId}
   * @param userAssessmentId
   * @param removeHistory
   */
  deleteAssessmentResults(userAssessmentId: number, removeHistory: boolean) {
    const options = this.auth.httpOptions;
    options.params = <any>{removeHistory};

    return this.http.delete(API.APIDOMAIN + this.api.user_assessments + `/result/${ userAssessmentId }`, options);
  }


  /**
   * Removes assessment from user and delete results
   *
   * DELETE /userassessments/{id}/remove
   * @param assessmentId
   */
  unassignAssessment(assessmentId: number) {
    return this.http.delete(API.APIDOMAIN + this.api.user_assessments + `/${ assessmentId }/remove`, this.auth.httpOptions);
  }


  /**
   * Set if users can invite other users to the assessment
   *
   * PATCH /assessment/{assessmentId}/userinvitable
   * @param assessmentID
   * @param user_invitable
   */
  toggleUserInvitesForAssessment(assessmentID: number, user_invitable: boolean) {
    return this.http.patch<AssessmentDetailsModel>(API.APIDOMAIN + this.api.assessment + `/${ assessmentID }/userinvitable`, {user_invitable}, this.auth.httpOptions);
  }


  setAssessmentStartedAtStatus(userAssessmentId: number) {
    return this.http.patch(API.APIDOMAIN + `userassessments/${userAssessmentId}`, {})
  }
}
