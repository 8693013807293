import { Injectable } from '@angular/core';
import { ApiDefinition } from 'src/app/main/content/models/api/api.def';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { API } from 'src/app/api-url/api-urls';
import { Assessment } from 'src/app/main/content/models/admin/results.def';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ResultsOverviewSharedService {
  api = new ApiDefinition();

  assessment: Assessment;

  disableInviteAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {
  }

  /**
   * GET /assessments/{form_id}/info
   */
  getAssessmentInfo(form_id: string) {
    return this.http.get<Assessment>(API.APIDOMAIN + this.api.assessmentList + `/${ form_id }/info`, this.auth.httpOptions)
     .pipe(
      map(response => {

        if (response.isGear) {
          this.disableInviteActions(response.isGear);
        } else {
          this.disableInviteActions(false);
        }

        return response;
      })
     );
  }

  disableInviteActions(disable: boolean): void {
    this.disableInviteAction$.next(disable);
  }
}
