import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AnonReportPageComponent } from '../../../anonymous-quiz/anon-report-page/anon-report-page.component';
import { AssessmentResultsComponent } from '../../../user/assessment-results/assessment-results.component';

@Component({
  selector: 'siemens-report',
  templateUrl: './siemens-report.component.html',
  styleUrls: ['./siemens-report.component.scss']
})
export class SiemensReportComponent implements OnInit {
  @Input() result: any;
  @Input() isAnon: boolean;

  constructor(
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent
  ) { }

  ngOnInit() {
    console.log(this.result);
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    !this.isAnon ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  getCategory(ratingicone: string) {
    return ratingicone.split('_')[0];
  }
}
