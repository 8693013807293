export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_REPORT_ADMIN = 'ROLE_REPORT_ADMIN';
export const ROLE_DEVELOPER = 'ROLE_DEVELOPER';

export const ROLE_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN';
export const ROLE_ACCOUNT_ADMIN = 'ROLE_ACCOUNT_ADMIN';
export const ROLE_ACCOUNT_JUNIOR_ADMIN = 'ROLE_ACCOUNT_JUNIOR_ADMIN';

export const USER_ROLE_MAP = {
  ROLE_ADMIN: 'Admin role',
  ROLE_USER: 'User role',

  ROLE_PLATFORM_ADMIN: 'Platform admin',
  ROLE_ACCOUNT_ADMIN: 'Account admin',
  ROLE_ACCOUNT_JUNIOR_ADMIN: 'Junior admin',
  ROLE_REPORT_ADMIN: 'Report admin'
};

