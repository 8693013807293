import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IncompetentMenResults, IMSectionType } from 'src/app/main/content/models/user/user-results.def';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { deepCopy } from 'src/app/functions/helpful-functions';

@Component({
  selector: 'ds-incompetent-men-user-report',
  templateUrl: './incompetent-men-user-report.component.html',
  styleUrls: ['./incompetent-men-user-report.component.scss']
})
export class IncompetentMenUserReportComponent implements OnInit, OnDestroy
{
  subs = new Subscription();

  option: any = {
    tooltip: {
      formatter: '{c}%'
    },
    series: [
      {
        name: 'Incompetence',
        type: 'gauge',
        detail: { formatter: '{value}%', fontSize: 28 },
        data: [{ value: 50, name: 'Incompetence' }],
        axisLabel: {
          fontSize: 18
        },
        title: {
          fontSize: 16
        }
      }
    ]
  };

  private _result: IncompetentMenResults;
  @Input() set result(value: IncompetentMenResults)
  {
    this._result = value;

    try
    {
      this.option.series[0].data[0].value = +(this._result.score.scores.scales.incompetence.display * 100).toFixed(2);
    }
    catch (error)
    {
      console.log(error);
      this.option.series[0].data[0].value = 0;
    }
  }
  get result() { return this._result; }


  sectionTypes = IMSectionType;

  chartHeight = 500;

  constructor(
    private breakpointObserver: BreakpointObserver,
  )
  { }

  ngOnInit()
  {
    this.subs.add(
      this.breakpointObserver.observe('(max-width: 750px)').subscribe(result =>
      {
        if (result.matches)
        {
          this.chartHeight = 300;

          // need to trigger the setter by changing the object
          const op = deepCopy(this.option);
          op.series[0].axisLabel.fontSize = 12;
          op.series[0].title.fontSize = 10;
          op.series[0].detail.fontSize = 18;

          this.option = deepCopy(op);
        }
        else
        {
          this.chartHeight = 500;

          // need to trigger the setter by changing the object
          const op = deepCopy(this.option);
          op.series[0].axisLabel.fontSize = 18;
          op.series[0].title.fontSize = 16;
          op.series[0].detail.fontSize = 28;

          this.option = deepCopy(op);
        }
      })
    );
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
