import { Component, OnInit } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { DesignType } from 'src/app/theme/theme.models';

@Component({
  selector: 'ds-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class FuseLoginComponent implements OnInit
{
  designTypes = DesignType;

  constructor(
    public themingService: ThemingService
  )
  {}

  ngOnInit()
  {
  }
}
