import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AuthService as SocialService
} from 'angular-6-social-login';
import { fuseAnimations } from 'src/app/animations/animations';
import { BaseLoginDesignComponent } from '../base-login-design.component';
import { AuthGuard } from 'src/app/main/content/guards/auth.guard';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { VerifyService } from 'src/app/main/content/services/auth/verify.service';
import { TranslocoService } from '@ngneat/transloco';



@Component({
  selector: 'ds-login-design2',
  templateUrl: './login-design2.component.html',
  styleUrls: ['./login-design2.component.scss'],
  animations: fuseAnimations
})
export class LoginDesign2Component extends BaseLoginDesignComponent
{
  // Theme colors
  accent;
  toolbarBGColor;
  toolbarFontColor;

  showSocialLogin = true;

  constructor(
    protected authGuard: AuthGuard,
    public authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected title: Title,
    protected verifyService: VerifyService,
    protected translocoService: TranslocoService,
    public themingService: ThemingService
  )
  {
    super(authGuard, authService, router, helper, errorService, socialService, formBuilder, routerParams, dialog, route, title, verifyService, translocoService, themingService,);
    this.accent = themingService.accent;
    this.toolbarBGColor = themingService.toolbarBGColor;
    this.toolbarFontColor = themingService.toolbarFontColor;

    if (localStorage.getItem('show_social_login'))
      this.showSocialLogin = localStorage.getItem('show_social_login') === 'true';
  }
}
