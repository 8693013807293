import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { AnonQuizComponent } from '../shared/quiz-system/quiz/anon-quiz.component';
import { AnonymousQuizPageComponent } from './anonymous-quiz-page/anonymous-quiz-page.component';
import { AnonReportPageComponent } from './anon-report-page/anon-report-page.component';
import { AnonRegisterPageComponent } from './anon-register-page/anon-register-page.component';
import {TsqAssesmentModule} from '../shared/tsq-assesment/tsq-assesment.module';
import { GearAssesmentModule } from 'src/app/main/content/pages/shared/gear-assessment/gear-assesment.module';

const routes: Routes = [
  {
    path: `:${ACCOUNT_NAME}/quiz/:token`,
    component: AnonymousQuizPageComponent
  },
  {
    path: `:${ACCOUNT_NAME}/quiz/:token/:locale`,
    component: AnonymousQuizPageComponent
  },
  {
    path: `:${ACCOUNT_NAME}/report/:formID`,
    component: AnonReportPageComponent
  },
  {
    path: `:${ACCOUNT_NAME}/anon/register/:token`,
    component: AnonRegisterPageComponent
  },
];


@NgModule({
  declarations: [
    AnonymousQuizPageComponent,
    AnonQuizComponent,
    AnonReportPageComponent,
    AnonRegisterPageComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    TsqAssesmentModule,
    GearAssesmentModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AnonymousQuizModule { }
