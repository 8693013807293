import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/functions/validators';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/admin/users.service';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'ds-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent extends BaseRouteParamComponent implements OnInit, OnDestroy
{
  @HostBinding('style.backgroundImage') bgIMG: string;
  @HostBinding('style.backgroundColor') bgColor: string;

  user_id: number;
  token: string;

  passwordControl = new FormControl('', Validators.compose([
    Validators.required,
    CustomValidators.passwordPattern
  ]));

  loading = false;

  constructor(
    protected routerParams: RouterParamsService,
    private themingService: ThemingService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private helper: HelperService,
    private errService: ErrorService,
    private auth: AuthService,
  )
  {
    super(routerParams);
  }

  ngOnInit()
  {
    super.ngOnInit();
    if (this.themingService.bgGradient)
    {
      this.bgColor = this.themingService.bgGradient;
    }
    else if (this.themingService.bgGradientPicture)
    {
      this.bgIMG = `url('${this.themingService.bgGradientPicture}')`;
    }

    this.user_id = +this.route.snapshot.queryParams['id'];
    this.token = this.route.snapshot.queryParams['token'];

    if (this.auth.isLoggedIn())
    {
      this.auth.logout(true);
    }

    if (!this.user_id || !this.token)
    {
      this.router.navigate([`${this.accountNameParam}/login`]);
    }
  }

  ngOnDestroy()
  {
    super.ngOnDestroy();
  }

  createPassword()
  {
    this.loading = true;

    this.userService.resetPassword(this.user_id, this.passwordControl.value, this.token).subscribe(res =>
    {
      this.helper.showAlert(res.message);
      this.router.navigate([`${this.accountNameParam}/login`]);
      this.loading = false;

    }, (err) =>
      {
        console.log(err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedOutUser(err, { msg: 'This token has already been used.' });
      });
  }
}
