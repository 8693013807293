import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fuseAnimations } from 'src/app/animations/animations';
import { AuthService } from '../../../services/auth/auth.service';
import { DemographicsService } from '../../../services/user/demographics.service';
import { Quiz } from '../../../models/user/quiz.model';
import { Subscription } from 'rxjs';
import { AssesmentService } from '../../../services/user/assesment.service';
import { UsersService } from '../../../services/admin/users.service';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { ProfileDefinition } from '../../../models/user/user.def';
import { TranslocoService } from '@ngneat/transloco';
import { QuizSystemService } from '../../../services/quiz/quiz-system.service';

@Component({
  selector: 'ds-app-assesment',
  templateUrl: './assesment.component.html',
  styleUrls: ['./assesment.component.scss'],
  animations: fuseAnimations
})
export class AssesmentComponent implements OnInit, OnDestroy {
  @HostBinding('style.backgroundImage') bg;
  @HostBinding('class.assessment-bg') assessmentBg: boolean = false;
  @HostBinding('class') class = '';

  currentId = '';

  loading = true;
  loading_MSG = '';

  noDemo = false;

  formData: any = {}; // Form demographics
  allDemoReady = {
    country: false,
    age: false,
    education: false,
    income: false,
    job_level: false,
    industry: false,
    genders: false
  };
  demoReady = false;
  showDemoQuestions = false;

  profile_id: number;
  profileData: ProfileDefinition;

  quiz: Quiz;
  isNew: boolean;
  locale: string;

  subscriptons: Subscription = new Subscription();

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private assesmentService: AssesmentService,
    private userService: UsersService,
    private helper: HelperService,
    // Demopraphics
    private demoService: DemographicsService,
    private errService: ErrorService,
    public themingService: ThemingService,
    private translocoService: TranslocoService,
    private quizService: QuizSystemService
  ) {
    translocoService.selectTranslate('global.loading').subscribe(res => {
      this.loading_MSG = res;
    });
  }

  get quizToken(): string {
    return this.auth.quizToken;
  }

  ngOnInit() {
    this.locale = this.translocoService.getActiveLang();

    this.bg = `${ this.themingService.bgGradientPicture }`;

    this.currentId = this.route.snapshot.params.id;
    this.isNew = this.route.snapshot.queryParams.new;

    this.loading = true;
    this.translocoService.selectTranslate('global.loaderProfile').subscribe(res => {
      this.loading_MSG = res;
    });


    // Get demographics data
    this.getDemoData();

    // Get user's profile data
    this.userService.getProfile(this.auth.user.id).subscribe((profileData: any) => {
      console.log('Profile data from :', profileData);
      this.profile_id = profileData.id;
      this.profileData = profileData;

    }, (err: HttpErrorResponse) => {
      this.errService.handleHttpErrorForLogedInUser(err);
    });

    this.fetchTypeform();

    this.translocoService.langChanges$.subscribe(res => {
      this.subscriptons.add(
        // Get all the countries and states
        this.demoService.country.getAllCountries().subscribe(res => {
          this.formData.country = res;
          this.formData.country = this.formData.country.filter(c => !(c.name === 'N/A')); // Filter out the N/A choice
          this.allDemoReady.country = true;
          this.checkDemoValuesReady();
        })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptons.unsubscribe();
  }

  handleAssessmentType() {
    if (this.quiz.theme === 'stg_assessment-quiz-theme') return true;

    return false;
  }

  /**
   * GET all the demographics data
   */
  getDemoData() {
    this.loading = true;

    this.subscriptons.add(
      // Get all the countries and states
      this.demoService.country.getAllCountries().subscribe(res => {
        this.formData.country = res;
        this.formData.country = this.formData.country.filter(c => !(c.name === 'N/A')); // Filter out the N/A choice
        this.allDemoReady.country = true;
        this.checkDemoValuesReady();
      })
    );


    // Get all the ages
    this.demoService.age.getAllAges().subscribe((res: any) => {
      this.formData.age = res;
      this.formData.age = this.formData.age.filter(a => !(a.name === 'N/A')); // Filter out the N/A choice
      this.allDemoReady.job_level = true;
      this.checkDemoValuesReady();
    });


    // Get all the educations
    this.demoService.education.getAllEducations().subscribe((res: any) => {
      this.formData.education = res;
      this.formData.education = this.formData.education.filter(e => !(e.name === 'N/A')); // Filter out the N/A choice
      this.allDemoReady.job_level = true;
      this.checkDemoValuesReady();
    });

    this.subscriptons.add(
      // Get all job levels
      this.demoService.job.getAllLevels().subscribe((res: any) => {
        this.formData.job_level = res;
        this.formData.job_level = this.formData.job_level.filter(j => !(j.name === 'N/A')); // Filter out the N/A choice
        this.allDemoReady.job_level = true;
        this.checkDemoValuesReady();
      })
    );


    // Get all the incomes
    this.demoService.income.getAllIncomes().subscribe((res: any) => {
      this.formData.income = res;
      this.formData.income = this.formData.income.filter(i => !(i.name === 'N/A')); // Filter out the N/A choice
      this.allDemoReady.income = true;
      this.checkDemoValuesReady();
    });


    // Get all industries
    this.demoService.industries.getAllIndustries().subscribe((res: any) => {
      this.formData.industry = res;
      this.formData.industry = this.formData.industry.filter(j => !(j.name === 'N/A')); // Filter out the N/A choice
      this.allDemoReady.industry = true;
      this.checkDemoValuesReady();
    });


    // Get all genders
    this.demoService.genders.getAllGenders().subscribe((res: any) => {
      this.formData.genders = res;
      this.formData.genders = this.formData.genders.filter(j => !(j.name === 'N/A')); // Filter out the N/A choice
      this.allDemoReady.genders = true;
      this.checkDemoValuesReady();
    });

  }

  /**
   * Gets the quiz data from the api
   */
  fetchTypeform() {
    this.loading = true;
    this.translocoService.selectTranslate('global.loading').subscribe(res => {
      this.loading_MSG = res;
    });

    this.assesmentService.getAssessmentForm(this.currentId, this.locale, this.isNew).subscribe((data: Quiz) => {
      console.log('Typeform:', data);
      this.quiz = data;
      this.loading = false;
      this.class = data.theme ? data.theme : '';

    }, (err: HttpErrorResponse) => {
      if (err.status === 403 || err.status === 404) {
        this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/dashboard']);
        this.errService.handleHttpErrorForLogedInUser(err, { msg_404: '404. Quiz not found.' });
      }
      this.errService.handleHttpErrorForLogedInUser(err);
    });

  }

  /**
   * Checks if all demo values have been loaded
   */
  checkDemoValuesReady() {
    for (const key in this.allDemoReady) {
      if (this.allDemoReady.hasOwnProperty(key)) {
        const element = this.allDemoReady[key];
        if (!element) {
          this.demoReady = false;
          return;
        }
      }
    }
    this.loading = false;
    this.demoReady = true;
  }

  quizDone(event: boolean) {
    this.showDemoQuestions = true;

    this.assessmentBg = this.handleAssessmentType();
    if (this.quiz.theme === 'gear_assessment-quiz-theme') {
      this.sendUserToTheReport();
    }
  }

  sendUserToTheReport() {
    if (this.handleAssessmentType()) return this.updateAssessmentReportDemographics();

    this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/user/assessment/${ this.currentId }/results`]);
  }

  updateAssessmentReportDemographics() {
    this.quizService.updateQuizResultsDemographics(localStorage.getItem('result_key')).subscribe(res => {
      this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/user/assessment/${ this.currentId }/results`]);
    }, error => {
      this.errService.handleHttpErrorForLogedInUser(error);
    });
  }

  private checkDemoFieldsInProfile() {
    for (const key in this.profileData) {
      if (this.profileData.hasOwnProperty(key)) {
        if (
          key === 'image_path' || key === 'image_file' || key === 'user' || key === 'income' || key === 'age' ||
          key === 'education' || key === 'job_level'
        ) {
          continue;
        }
        const element = this.profileData[key];
        if (!element || element === 'N/A') {
          this.noDemo = true;
          return;
        }
        if (element.hasOwnProperty('name') && element.name === 'N/A') {
          this.noDemo = true;
          return;
        }
      }
    }
  }

  onLocaleChanged(locale: string) {
    this.locale = locale;
    this.fetchTypeform();
  }

  get showDemoScreen(): boolean {
    return !this.loading && this.showDemoQuestions && this.quiz.theme !== 'gear_assessment-quiz-theme';
  }
}
