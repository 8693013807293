import { Component, OnInit, Input } from '@angular/core';
import { DescriptionShort } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-extremes-scale-slider-graph',
  templateUrl: './extremes-scale-slider-graph.component.html',
  styleUrls: ['./extremes-scale-slider-graph.component.scss']
})
export class ExtremesScaleSliderGraphComponent implements OnInit
{
  private _data: DescriptionShort;
  @Input()
  set data(v: DescriptionShort) { this._data = v; }
  get data() { return this._data; }

  private _mode: 'large' | 'small' = 'large';
  @Input()
  set mode(v: 'large' | 'small') { this._mode = v; }
  get mode() { return this._mode; }

  constructor()
  { }

  ngOnInit()
  { }
}
