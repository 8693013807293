import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { ThemingService } from './theme/theming.service';
import { MANPOWER } from './theme/theme.configs';
import { faFacebookF, faGoogle, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faBook,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faExternalLinkAlt,
  faFilter,
  faHeadphonesAlt,
  faLink,
  faList,
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { faEdit, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { RouterParamsService } from './main/main-service/router-params.service';
import { ACCOUNT_NAME, AccountIndexClass } from './constants/router-params';
import { AuthService } from './main/content/services/auth/auth.service';
import { RouteState } from './main/content/pages/error-pages/route-values/route-values';
import { config } from 'src/config';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { animate, style, transition, trigger } from '@angular/animations';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'ds-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('triggerName', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1000ms ease-in-out', style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class AppComponent implements OnInit {
  loading = true;

  /** Used to keep track of the account index value change */
  private accountIndex = '';

  constructor(
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    public themingService: ThemingService,
    private routerParamService: RouterParamsService,
    private auth: AuthService,
    private faIconLibrary: FaIconLibrary,
    private translocoService: TranslocoService,
  ) {
    const browserLocale = window.navigator.language;
    if (browserLocale) {
      translocoService.setActiveLang(window.navigator.language);
    } else {
      translocoService.setActiveLang('en');
    }
    this.addFontAwesomeIconsToLibrary();
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.matIconRegistry.addSvgIcon(
      'thumbsUp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumbs_up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thumbsDown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumbs_down.svg')
    );
  }

  ngOnInit() {

    this.routerParamService.params.subscribe(params => {
      const p = params[ACCOUNT_NAME];

      if (p !== AccountIndexClass.ACCOUNT_INDEX) {
        AccountIndexClass.ACCOUNT_INDEX = p;

        if (p) {
          AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + p;
        } else {
          AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '';
        }
      }
      if (p !== this.accountIndex) {
        this.accountIndex = p;
        this.setTheme();
      }
    });
  }


  /** Shows and hides the loading spinner during RouterEvent changes */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      RouteState.previousRoute = event.url;
      RouteState.urlAfterRedirect = event.urlAfterRedirects;
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }
  }

  private addFontAwesomeIconsToLibrary() {
    this.faIconLibrary.addIcons(
      faFacebookF,
      faGoogle,
      faLinkedinIn,
      faList,
      faEnvelope,
      faLink,
      faCheck,
      faTimes,
      faPlus,
      faChevronUp,
      faChevronDown,
      faPlayCircle,
      faBook,
      faHeadphonesAlt,
      faExternalLinkAlt,
      faTwitter,
      faFilter,
      faEdit,
    );
  }

  private setTheme() {
    const theme = AccountIndexClass.ACCOUNT_INDEX;

    switch (theme) {
      case 'manpower':
        this.themingService.setTheme(MANPOWER.id);
        break;

      default:
        this.themingService.setTheme(config.theme_id);
        break;
    }

    this.auth.makeNavLinks();
  }
}
