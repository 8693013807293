import { Component, OnInit, Input } from '@angular/core';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ds-custom-driver-slider',
  templateUrl: './custom-driver-slider.component.html',
  styleUrls: ['./custom-driver-slider.component.scss']
})
export class CustomDriverSliderComponent implements OnInit
{
  private _invert: boolean = false;
  @Input()
  set invert(v: boolean)
  {
    this._invert = coerceBooleanProperty(v);
  }
  get invert() { return this._invert; }


  private _value: number = 0;
  @Input()
  set value(v: number)
  {
    this._value = coerceNumberProperty(v);
  }
  get value() { return this._value; }


  private _showValue: boolean = true;
  @Input()
  set showValue(v: boolean)
  {
    this._showValue = coerceBooleanProperty(v);
  }
  get showValue() { return this._showValue; }

  constructor() { }

  ngOnInit()
  {
  }

  getDirection()
  {
    if (this.invert)
      return { right: `${this.value}%` };
    else
      return { left: `${this.value}%` };
  }
}
