import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ReportingFiltersService {
  /** A list of groups */
  groups: string[] = [];
  countries: string[] = [];
  ages: string[] = [];
  genders: string[] = [];


  /** A list of groups that are selected for filtering */
  selectedGroups: string[] = [];
  selectedCountries: string[] = [];
  selectedAges: string[] = [];
  selectedGenders: string[] = [];
  /** Date range filters */
  dateFrom: string | null = null;
  dateTo: string | null = null;

  /**
   * Emits a changed array of selected groups
   */
  selectedGroupsChange = new EventEmitter<string[]>();
  selectedDateRangeChange = new EventEmitter<string[]>();
  selectedCountriesChange = new EventEmitter<string[]>();
  selectedAgesChange = new EventEmitter<string[]>();
  selectedGendersChange = new EventEmitter<string[]>();

  constructor() {
  }
}
