import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ROLE_ADMIN } from 'src/app/constants/user.role';
import { MatDialog } from '@angular/material/dialog';
import { OverwriteQuizResultsDialogComponent } from '../../dialogs/overwrite-quiz-results-dialog/overwrite-quiz-results-dialog.component';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';

@Component({
  selector: 'ds-anon-login',
  templateUrl: './anon-login.component.html',
  styleUrls: ['./anon-login.component.scss']
})
export class AnonLoginComponent implements OnInit, OnDestroy
{
  subs = new Subscription();

  private _user: UserModel;
  @Input()
  set user(v: UserModel)
  {
    this._user = v;

    if (v.info && v.info.email)
    {
      this.emailControl.setValue(v.info.email);
      this.emailControl.disable();
    }
  }
  get user() { return this._user; }

  @Output() userLoggedin = new EventEmitter<UserModel>();


  loading = false;

  form: FormGroup;
  get passwordControl() { return this.form.controls.password as FormControl; }
  get emailControl() { return this.form.controls.email as FormControl; }

  constructor(
    private anonService: AnonUserService,
    private fb: FormBuilder,
    private errService: ErrorService,
    private auth: AuthService,
    private dialog: MatDialog,
  )
  {
    this.createForm();
  }

  ngOnInit()
  {
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }

  private createForm()
  {
    this.form = this.fb.group({
      password: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)])]
    });
  }


  loginUser(overwrite?: boolean)
  {
    if (this.form.invalid) return;


    this.loading = true;

    this.subs.add(
      this.anonService.loginAnonUser(
        this.emailControl.value,
        this.passwordControl.value,
        this.user.account.index_name,
        this.user.token_key,
        overwrite
      ).subscribe(res =>
      {
        console.log('Login anon user response:', res);
        this.loading = false;

        this.user = res.user;
        this.user.token_key = res.token_key;
        this.user.refresh_token = res.refresh_token;

        this.auth.setData(this.user, res.quizToken.token);
        if (this.auth.user.roles.includes(ROLE_ADMIN))
        {
          this.auth.isUserAdmin = true;
        }

        this.userLoggedin.emit(this.user);

        this.passwordControl.reset('');

      }, err =>
      {
        if (err.error.trans_code && err.error.trans_code === 'exception.userAssessment.assessmentAlreadyAssignedToUser')
        {
          const ref = this.dialog.open(OverwriteQuizResultsDialogComponent, {
            autoFocus: false,
            restoreFocus: false,
            closeOnNavigation: true,
          });

          ref.afterClosed().subscribe(dialog_res =>
          {
            this.loading = false;
            if (dialog_res)
            {
              this.loginUser(true);
            }
          });
        }
        else
        {
          console.log('Error logingin in anon user', err);
          this.loading = false;
          this.errService.handleHttpErrorForLogedOutUser(err);
        }
      })
    );
  }
}
