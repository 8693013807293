import { Component, OnInit, Input } from '@angular/core';
import { ThemingService } from 'src/app/theme/theming.service';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { Persona } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-jqdiagnostic-user-details-card',
  templateUrl: './jqdiagnostic-user-details-card.component.html',
  styleUrls: ['./jqdiagnostic-user-details-card.component.scss']
})
export class JqdiagnosticUserDetailsCardComponent implements OnInit
{

  private _user: UserModel;
  @Input() set user(v: UserModel)
  {
    this._user = v;
  }
  get user() { return this._user; }

  private _persona: Persona;
  @Input() set persona(v: Persona) { this._persona = v; }
  get persona() { return this._persona; }

  get isAnon() { return this.user.status === 'ANONYMOUS'; }

  constructor(
    public themingService: ThemingService,
  )
  { }

  ngOnInit()
  {
  }

}
