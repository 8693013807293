/**
 * General quiz model. Holds the result and result answers
 */

export interface ResultAnswer {
  [key: string]: string;
}

export interface Quiz {
  last_answer?: string;
  result: Result;
  result_answers: ResultAnswer;
  submit_url: string;
  update_url: string;
  theme: string;
  locale: string;
  userAssessmentId: number;
}

export interface Result {
  result_key: string;
  ref: string;
  quiz: QuizModel;
  hidden_fields: HiddenFields;
  started_at: string;
  finished_at: string;
  quiz_state_at: string;
  last_submit_at: string;
  locale: string;
}

interface HiddenFields {
  uid: number;
  conference_mode: string;
  hide_games: boolean;
  show_occupation_results: boolean;
}

/**
 * The quiz itself. Holds all the headers, questions and choices
 */
export interface QuizModel {
  id: number;
  quiz_key: string;
  ref: string;
  title: string;
  fields: QuizHeader[];
  properties: {
    localizations: { [key: string]: string; }
    end_text: string;
    goback_label: string;
    show_field_headers: boolean;
    show_question_number: boolean;
  };
}

/**
 * Quiz header
 *
 * Title usually explains the type of questions
 */
export interface QuizHeader {
  id: number;
  field_key: string;
  ref: string;
  title: string;
  type: string;
  properties: Properties;
  fields: QuizQuestion[];
}

export interface QuizQuestion {
  id: number;
  field_key: string;
  ref: string;
  title: string;
  type: string;
  properties: Properties;
  fields: any[];
  opened_at?: string;

  /**
   * Custom Fields (not retrived in a api request) used for locally keeping track
   * of the user's answer for the question
   * */
  selectedAnswer?: string | number | string[];
  showOther?: {
    otherValue?: string;
    show?: boolean;
  };
}

/**
 * Properties of the question
 *
 * Contains the choices, labels, validations...
 */
export interface Properties {
  action?: string;
  subtitle?: string;
  choices?: Choice[];
  title?: string;
  button_title?: string;
  validations?: Validations;
  dimension?: string;
  allow_other_choice?: boolean;
  allow_multiple_selection?: boolean;
  steps?: number;
  labels?: Array<Choice>;
  description?: string;
  user_profile?: string;
  condition?: {
    action: ActionType;
    action_value: string;
    condition: ConditionType;
    condition_value: string;
  };
  group_label?: string;
  renderType?: RenderType;
  /** Whether or not the "section description" should be shown */
  display_title?: boolean;
  layout_type?: 'choice';
  button_next?: string;
  button_more?: string;
  parse?: 'html' | 'markdown' | 'plain';
  text?: string;
  nogame?: string;
  timeout_help_popup?: {
    seconds?: number;
    text?: string;
  };
  help_text?: {
    text: string;
    trans_key: string;
  };
  other_choice_placeholder?: string;
  isCountry?: boolean;
  parser: [{ with: number, replace: string }];
  cat_id: string;
}

/**
 * User's choice
 */
export interface Choice {
  id: string;
  label: string;
}

/**
 * Labels for opinion scales
 */
export interface Labels {
  left: string;
  right: string;
  center?: string;

  [key: string]: string;
}

export interface Validations {
  required: boolean;
  max_value?: number;
}

export enum QuestionType {
  multipleChoice = 'multiple_choice',
  shortText = 'short_text',
  opinionScale = 'opinion_scale',
  opinionScaleLabel = 'opinion_scale_label',
  number = 'number',
  longText = 'long_text',
  embed = 'embed',
  autocompleteMultiple = 'multiple_autocomplete'
}

export enum RenderType {
  dropdown = 'dropdown',
  button = 'button',
  buttonTouching = 'buttonTouching'
}

export enum ActionType {
  jump = 'jump'
}

export enum ConditionType {
  empty = 'empty'
}
