import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Md5 } from 'ts-md5';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public static EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private snackBar: MatSnackBar, private translocoService: TranslocoService) {}

  public static validateEmail(email: string): boolean {
    return HelperService.EMAIL_REGEX.test(String(email).toLowerCase());
  }

  showAlert(message: string, closeSnackbarText?: string, config = { duration: 4000 }) {

    if(!closeSnackbarText || closeSnackbarText.trim().length === 0){
      this.translocoService.selectTranslate('toasts.ok').pipe(take(1)).subscribe(okMsg => {
        this.snackBar.open(message, okMsg, config);
      });
    } else {
      this.snackBar.open(message, closeSnackbarText, config);
    }
  }

  showAlertWithouAutoDismiss(message: string, closeSnackbarText?: string) {
    if(!closeSnackbarText || closeSnackbarText.trim().length === 0){
      this.translocoService.selectTranslate('toasts.ok').pipe(take(1)).subscribe(okMsg => {
        this.snackBar.open(message, okMsg, { duration: 20000 });
      });
    } else {
      this.snackBar.open(message, closeSnackbarText, { duration: 20000 });
    }
  }

  dynamicAlert(horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition, message: string) {

    this.translocoService.selectTranslate('global.Close').pipe(take(1)).subscribe(closeMsg => {
      this.snackBar.open(message, closeMsg, {
        duration: 5000,
        horizontalPosition,
        verticalPosition,
      });
    });
  }

  md5Hash(code: string = new Date().toString()) {
    return Md5.hashStr(code);
  }

  /**
   * Removes all spaces from a given word and makes all first letters capital case
   *
   * example. "How it works" --> "HowItWorks"
   * @param word
   */
  makeTitleCase(word: string): string {
    return word
      .split(' ')
      .map(c => c.slice(0, 1).toUpperCase() + c.slice(1))
      .join('');
  }

  /**
   * Flattens multi dimension array into 1D array
   *
   * ex. [ [ ], [ ], [ [ ], [ ] ] ] --> [...]
   * @param arr
   */
  flattenDeep<T>(arr: any): Array<T> {
    return arr.reduce((acc, e) => (Array.isArray(e) ? acc.concat(this.flattenDeep(e)) : acc.concat(e)), []);
  }

  /**
   * Gets the difference in the provided dates
   * @param started
   * @param finished
   */
  getTimeDifference(started: string, finished: string): string {
    const a = moment(finished);
    const b = moment(started);

    const difH = a.diff(b, 'h');

    if (isNaN(difH)) {
      return 'Invalid date';
    }

    if (difH > 24) {
      return a.diff(b, 'd') + ' days';
    } else if (difH >= 1) {
      return a.diff(b, 'h') + ' hours';
    } else {
      const difS = a.diff(b, 's');
      if (difS > 60) {
        return a.diff(b, 'm') + ' minutes';
      } else {
        return a.diff(b, 's') + ' seconds';
      }
    }
  }
}
