import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseDashboardComponent } from '../base-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from 'src/app/animations/animations';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { AssesmentService } from 'src/app/main/content/services/user/assesment.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';

@Component({
  selector: 'ds-dashboard-design1',
  templateUrl: './dashboard-design1.component.html',
  styleUrls: ['./dashboard-design1.component.scss'],
  animations: fuseAnimations
})
export class DashboardDesign1Component extends BaseDashboardComponent implements OnInit, AfterViewInit {
  fakeNumber = 897;
  cardTitleGradient;

  constructor(
    public themingService: ThemingService,
    protected auth: AuthService,
    protected assesmentService: AssesmentService,
    protected router: Router,
    protected dialog: MatDialog,
    protected errService: ErrorService,
    protected routerParams: RouterParamsService
  ) {
    super(themingService, auth, assesmentService, router, dialog, errService, routerParams);
  }

  ngOnInit() {
    super.ngOnInit();

    this.cardTitleGradient = this.themingService.getPrimaryColor_RGBA_Value(0.4);
  }

  ngAfterViewInit() {
    // Set the bg image
    document.getElementById('dashboard').style.setProperty('background-image', `${ this.themingService.bgGradientPicture }`);
  }
}
