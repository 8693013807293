import { OnDestroy, OnInit } from '@angular/core';
import {
  AuthService as SocialService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser
} from 'angular-6-social-login';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthGuard } from '../../../guards/auth.guard';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../services/helpers/helper.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { ROLE_ADMIN } from 'src/app/constants/user.role';
import { UserDefinition } from '../../../models/user/user.def';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { Title } from '@angular/platform-browser';
import { ThemingService } from 'src/app/theme/theming.service';
import { LoginV2Response, SocialLoginAccountListResponse } from '../../../models/auth/auth.model';
import { Subscription, combineLatest } from 'rxjs';
import {
  ChooseAccountDialogComponent
} from '../../shared/dialogs/choose-account-dialog/choose-account-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/functions/validators';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

export class BaseRegisterDesignComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  loading: boolean;
  previousURL: string;
  /** A return url for invites */
  returnURL: string;
  /** An email of a user for invites */
  inviteEmail: string;

  token: string;

  registerForm: FormGroup;

  emailError = false;

  subs = new Subscription();

  constructor(
    protected authGuard: AuthGuard,
    protected authService: AuthService,
    protected router: Router,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected socialService: SocialService,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    protected route: ActivatedRoute,
    protected title: Title,
    protected dialog: MatDialog,
    protected translocoService: TranslocoService,
    public themingService: ThemingService,
  ) {
    super(routerParams);
  }

  /**
   * Call this in the component constructor or ngOnInit
   */
  ngOnInit() {
    super.ngOnInit();
    this.loading = false;
    this.previousURL = undefined;

    this.title.setTitle('Register - ' + this.themingService.title);

    if (this.authService.isLoggedIn()) {
      if (this.authService.user && this.authService.user.roles[0] === ROLE_ADMIN) {
        this.authService.isUserAdmin = true;
        this.redirectAfterLogin();
      } else {
        this.redirectAfterLogin();
      }
    }

    if (this.authGuard.redirectURL) // Check if user was redirected here by an authGuard
    {
      combineLatest([this.translocoService.selectTranslate<string>('toasts.pleaseLoginFirst'),
        this.translocoService.selectTranslate<string>('toasts.ok')],
      )
        .pipe(take(1))
        .subscribe(([message, closeBtnText]) => {
          this.helper.showAlertWithouAutoDismiss(message, closeBtnText);
        });
      this.previousURL = this.authGuard.redirectURL; // Remember where the user was before
    }

    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, CustomValidators.noWhitespaceValidator]],
      last_name: ['', [Validators.required, CustomValidators.noWhitespaceValidator]],
      email: ['', Validators.compose([Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)])],
      password: ['', Validators.compose([Validators.required, CustomValidators.passwordPattern])],
      accepted: [false, Validators.requiredTrue]
    });

    // Check if return URL exists
    this.returnURL = this.route.snapshot.queryParams['returnUrl'] || localStorage.getItem('returnUrl');
    this.inviteEmail = this.route.snapshot.queryParams['email'];
    this.token = this.route.snapshot.queryParams['token'];


    if (this.inviteEmail) {
      (<FormControl>this.registerForm.controls.email).setValue(this.inviteEmail);
      (<FormControl>this.registerForm.controls.email).disable();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.title.setTitle(this.themingService.title);
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  /**
   * Gets the form's control
   * @param field
   */
  getFormControl(field: string): FormControl {
    return this.registerForm.controls[field] as FormControl;
  }

  private redirectAfterLogin() {
    if (this.previousURL) {
      this.authGuard.redirectURL = undefined; // Clear the redirecturl
      this.router.navigate([this.previousURL]);
    } else {
      if (this.returnURL) // If return url exist, we need to return the user that just logged in to that url
      {
        localStorage.removeItem('returnUrl');
        localStorage.removeItem('user-had-account');
        this.router.navigate([this.returnURL], {
          queryParams: {
            skipVerify: true
          }
        });
      } else // otherwise, just route normally to dashboard
      {
        this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/dashboard`]);
      }
    }
  }

  registerEmail(e) {
    e.preventDefault(); // This is for Edge

    const browserLanguages = window.navigator.languages;

    if (this.registerForm.invalid) {
      return;
    }

    this.emailError = false;

    const register_data = {
      email: this.getFormControl('email').value,
      first_name: this.getFormControl('first_name').value.trim(),
      last_name: this.getFormControl('last_name').value.trim(),
      password: this.getFormControl('password').value,
      account: this.accountNameParam.replace('/', '')
    };

    if (this.token) {
      register_data['token'] = this.token;
    }

    if (browserLanguages) {
      register_data['locales'] = browserLanguages;
    } else {
      register_data['locales'] = ['en'];
    }

    this.loading = true;
    this.authService.register.email(register_data).subscribe(res => {
      // this.helper.showAlertWithouAutoDismiss('Registration Successful!');

      this.saveUserInfoToAuthService(res);

    }, (err: HttpErrorResponse) => {
      this.loading = false;
      if (err.error.code === 554) {
        this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/mail-confirm'], { queryParams: { email: register_data.email } });
      } else if ((<string>err.error.message).toLowerCase().includes('email')) {
        this.emailError = true;
      } else {
        this.errorService.handleHttpErrorForLogedOutUser(err);
      }
    });
  }

  protected saveUserInfoToAuthService(data: any) {
    console.log('Login data: ', data);

    if (data.action === 'login') {
      localStorage.setItem('verify_email_msg', 'true');
      // this.helper.showAlertWithouAutoDismiss(data.message);
      this.router.navigate(['/login']);
    } else if (data.tokens) {
      AccountIndexClass.ACCOUNT_INDEX = data.tokens.user.account.index_name;
      AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + data.tokens.user.account.index_name;

      const user: UserDefinition = data.tokens.user;
      user['token_key'] = data.tokens.token_key;
      user['refresh_token'] = data.tokens.refresh_token;
      this.authService.setData(user, data.tokens.quizToken.token);

      if (this.authService.user.roles.includes(ROLE_ADMIN)) {
        this.authService.isUserAdmin = true;
        this.redirectAfterLogin();
      } else {
        this.redirectAfterLogin();
      }
    } else {
      console.warn('Something went wrong');
    }

  }

  protected saveUserInfoSocialProvider(data: LoginV2Response) {
    console.log('saveUserInfoSocialProvider: ', data);

    AccountIndexClass.ACCOUNT_INDEX = data.user.account.index_name;
    AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + data.user.account.index_name;

    const user: UserDefinition = data.user;
    user['token_key'] = data.token_key;
    user['refresh_token'] = data.refresh_token;
    this.authService.setData(user, data.quizToken.token);

    if (this.authService.user.roles.includes(ROLE_ADMIN)) {
      this.authService.isUserAdmin = true;
      this.redirectAfterLogin();
    } else {
      this.redirectAfterLogin();
    }
  }

  facebookRegister() {
    if (!this.checkAcceptedTandC()) {
      return;
    }
    const invite_token = this.route.snapshot.queryParamMap.get('token');
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData: SocialUser) => {
        const user = {
          first_name: userData.name.split(' ')[0],
          last_name: userData.name.split(' ')[1],
          email: userData.email,
          facebook_id: userData.id,
          access_token: userData.token,
          invite_token
        };


        this.authService.login
          .facebook(user, this.accountNameParam.replace('/', ''))
          .subscribe((res) => {
              let isAccountList = false;
              let resLogin: LoginV2Response;
              let resAccount: SocialLoginAccountListResponse;

              // Check the type of the response
              if ((<SocialLoginAccountListResponse>res).accounts) {
                isAccountList = true;
                resAccount = <SocialLoginAccountListResponse>res;
              } else {
                isAccountList = false;
                resLogin = <LoginV2Response>res;
              }


              if (isAccountList) {
                if (resAccount.accounts.length === 1) {
                  // Check if has only one account
                  console.log('Only one account', resAccount);
                  // login user using this one account
                  // Have to call the login api again but this time with account name

                  this.authService.login
                    .facebook(user, resAccount.accounts[0].account.index_name, resAccount.token, resAccount.checksum)
                    .subscribe((accLogin: LoginV2Response) => {
                        this.saveUserInfoSocialProvider(accLogin);
                      },
                      err => {
                        console.log('One account login error', err);
                        this.loading = false;
                      });

                } else // If more that one account
                {
                  this.loading = false;
                  console.log('More than one account', resAccount);
                  // open selector of accounts
                  const dialogRef = this.dialog.open(ChooseAccountDialogComponent, {
                    data: resAccount.accounts,
                    disableClose: true,
                    closeOnNavigation: true,
                    autoFocus: false
                  });

                  dialogRef.afterClosed().subscribe(dialogResponse => {
                    if (dialogResponse) {
                      this.loading = true;
                      this.subs.add(
                        this.authService.login
                          .facebook(user, dialogResponse.accountIndex, resAccount.token, resAccount.checksum)
                          .subscribe((accLogin: LoginV2Response) => {
                              this.saveUserInfoSocialProvider(accLogin);
                            },
                            err => {
                              console.log('One account login error', err);
                              this.loading = false;
                            })
                      );
                    } else {
                      this.loading = false;
                    }
                  });
                }
              } else {
                // We know the account, login user regularly
                console.log('Exact account is knows, login normal');

                this.saveUserInfoSocialProvider(resLogin);
              }
            },
            (err: HttpErrorResponse) => {
              this.errorService.handleHttpErrorForLogedOutUser(err);
            });
      }
    );
  }

  googleRegister() {
    if (!this.checkAcceptedTandC()) {
      return;
    }

    this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData: SocialUser) => {
        const user = {
          first_name: userData.name.split(' ')[0],
          last_name: userData.name.split(' ')[1],
          email: userData.email,
          google_id: userData.id,
          access_token: userData.token
        };


        this.authService.login
          .google(user, this.accountNameParam.replace('/', ''))
          .subscribe((res) => {
              let isAccountList = false;
              let resLogin: LoginV2Response;
              let resAccount: SocialLoginAccountListResponse;

              // Check the type of the response
              if ((<SocialLoginAccountListResponse>res).accounts) {
                isAccountList = true;
                resAccount = <SocialLoginAccountListResponse>res;
              } else {
                isAccountList = false;
                resLogin = <LoginV2Response>res;
              }


              if (isAccountList) {
                if (resAccount.accounts.length === 1) // Check if has only one account
                {
                  console.log('Only one account', resAccount);
                  // login user using this one account
                  // Have to call the login api again but this time with account name

                  this.authService.login
                    .google(user, resAccount.accounts[0].account.index_name, resAccount.token, resAccount.checksum)
                    .subscribe((accLogin: LoginV2Response) => this.saveUserInfoSocialProvider(accLogin),
                      err => {
                        console.log('One account login error', err);
                        this.loading = false;
                      });

                } else // If more that one account
                {
                  this.loading = false;
                  console.log('More than one account', resAccount);
                  // open selector of accounts
                  const dialogRef = this.dialog.open(ChooseAccountDialogComponent, {
                    data: resAccount.accounts,
                    disableClose: true,
                    closeOnNavigation: true,
                    autoFocus: false
                  });

                  dialogRef.afterClosed().subscribe(dialogResponse => {
                    if (dialogResponse) {
                      this.loading = true;

                      this.authService.login
                        .google(user, dialogResponse.accountIndex, resAccount.token, resAccount.checksum)
                        .subscribe((accLogin: LoginV2Response) => this.saveUserInfoSocialProvider(accLogin),
                          err => {
                            console.log('One account login error', err);
                            this.loading = false;
                          });

                    } else {
                      this.loading = false;
                    }
                  });
                }
              } else // We know the account, login user regularly
              {
                console.log('Exact account is knows, login normal');

                this.saveUserInfoSocialProvider(resLogin);
              }
            },
            (err: HttpErrorResponse) => {
              this.errorService.handleHttpErrorForLogedOutUser(err);
            });
      }
    );
  }

  /**
   * Checks if the brazen t&c has been accepted and any other active account's T&C
   */
  checkAcceptedTandC(): boolean {
    if (!this.registerForm.controls.accepted.valid) {
      this.helper.showAlert('You must accept terms and conditions.');
      this.registerForm.controls.accepted.markAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Opens the brazenx T&C
   */
  openTerms() {
    window.open('https://www.manpowergroup.com/terms-of-use');
  }

  openPrivacyPolicy() {
    window.open('https://www.manpowergroup.com/privacy-policy');
  }
}
