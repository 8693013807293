import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {QuizHeader} from '../../../../../models/user/quiz.model';
import {TsqStore} from '../../tsq.store';
import {Observable} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-landing-page',
  templateUrl: './tsq-landing-page.component.html',
  styleUrls: ['./tsq-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TsqLandingPageComponent implements OnInit {
  private _header: QuizHeader;
  @Input()
  set header(value: QuizHeader) {
    console.log('header', value);
    this._header = value;
  }

  get header(): QuizHeader {
    return this._header;
  }

  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  localizations$: Observable<{ [p: string]: string }>;


  @Output()
  languageChanged: EventEmitter<string> = new EventEmitter<string>();


  private _locale: string;
  get locale(): string {
    return this._locale;
  }
  @Input()
  set locale(value: string) {
    this._locale = value;
  }

  constructor(
    private _store: TsqStore
  ) {
    this.localizations$ = this._store.localization$;
  }

  ngOnInit() {
  }

  onChange(event: EventTarget) {
    this.languageChanged.emit(this.locale);
  }
}
