import { ThemeModel, DesignType } from './theme.models';


export const MANPOWER: ThemeModel = {
  id: 1,
  themeName: 'manpower-theme',
  primary: '#466EA5',
  accent: '#e77c22',
  logoURL: 'assets/logo/mpg-logo.png',
  fullLogoURL: 'assets/logo/mpg-logo.png',
  title: 'ManpowerGroup',
  backgroundGradient: '',
  backgroundGradientPicture: 'linear-gradient(to right bottom, #a8bbb3, #82aba9, #5c9aa8, #4286aa, #466ea5)',
  logoLinkURL: 'https://www.manpowergroup.com/',
  faviconURL: '/assets/logo/mpg-logo.png',
  showQuizInfo: false,
  dashboardIntroText: 'Explore your assessments and reports below.',
  backgroundPath: '/assets/images/backgrounds/material-brazen-bg.png',
  dashboardActionButtonText: 'Start',
  helpPageUrl: '',
  toolbarBGColor: '#fff',
  toolbarFontColor: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Source Sans Pro',
  linkToCookiePolicy: 'https://www.manpowergroup.com/cookie-policy',
  loginDesign: DesignType.design1,
  registerDesign: DesignType.design1,
  dashboardDesign: DesignType.design1,
  forgotPasswordDesing: DesignType.design1,
  resetPasswordDesing: DesignType.design1,
  quizQuestionBolded: false,
  quizUsePrimaryColor: true,
  quizCenterQuestion: true
};
