import { Component, OnInit, Input } from '@angular/core';
import { InsightResult } from 'src/app/main/content/models/user/user-results.def';
import { ThemingService } from 'src/app/theme/theming.service';
import { UserModel } from 'src/app/main/content/models/user/user.def';

@Component({
  selector: 'ds-insight-user-report',
  templateUrl: './insight-user-report.component.html',
  styleUrls: ['./insight-user-report.component.scss']
})
export class InsightUserReportComponent implements OnInit
{
  _result: InsightResult;
  @Input() set result(value: InsightResult)
  {
    this._result = value;

    if (this._result && this._user)
    {
      this._result.score.drivers.portrait = this._result.score.drivers.portrait.replace('<name>', this.user.first_name || this.user.email);
    }
  }

  _user: UserModel;
  @Input() set user(value: UserModel)
  {
    this._user = value;

    if (this._result && this._user)
    {
      this._result.score.drivers.portrait = this._result.score.drivers.portrait.replace('<name>', this.user.first_name || this.user.email);
    }
  }
  get user() { return this._user; }

  _showThumbs = true;
  @Input()
  set showThumbs(v: boolean) { this._showThumbs = v; }
  get showThumbs() { return this._showThumbs; }

  constructor(
    public themingService: ThemingService,
  )
  { }

  ngOnInit()
  {
  }

}
