import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { ThemingService } from 'src/app/theme/theming.service';
import { ScoreWhoAreYou } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-who-are-you-user-details-card',
  templateUrl: './who-are-you-user-details-card.component.html',
  styleUrls: ['./who-are-you-user-details-card.component.scss']
})
export class WhoAreYouUserDetailsCardComponent implements OnInit
{
  letter = '';

  private _user: UserModel;
  @Input() set user(v: UserModel)
  {
    this._user = v;
    this.letter = this.user.first_name && this.user.last_name ? (this.user.first_name[0] + this.user.last_name[0]) : this.user.email[0];
  }
  get user() { return this._user; }

  private _primaryDrivers: ScoreWhoAreYou[] = [];
  @Input() set primaryDrivers(v: ScoreWhoAreYou[])
  {
    this._primaryDrivers = v ? v : [];
  }
  get primaryDrivers() { return this._primaryDrivers; }


  private _profilePicture;
  @Input() set profilePicture(v: string)
  {
    this._profilePicture = v;
  }
  get profilePicture() { return this._profilePicture; }


  private _firstLogin;
  @Input() set firstLogin(v: boolean) { this._firstLogin = v; }
  get firstLogin() { return this._firstLogin; }

  get isAnon() { return this.user.status === 'ANONYMOUS'; }

  constructor(
    public themingService: ThemingService,
  ) { }

  ngOnInit()
  {
  }

}
