import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from './helper.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { UserDefinition } from '../../models/user/user.def';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private auth: AuthService,
    private helper: HelperService,
    private router: Router,
    private translocoService: TranslocoService
  ) {
  }

  /**
   * Handle http error when user is logedin and he should be
   * @param err
   * @param config
   */
  handleHttpErrorForLogedInUser(err: HttpErrorResponse, config: ConfigModel = {}) {
    try {
      if (err.status === 401) {
        this.auth.refreshToken().subscribe(res => {
          console.log('refresh token response', res);
          const user: UserDefinition = this.auth.user;
          user.token_key = res.token_key;
          user.refresh_token = res.refresh_token;
          this.auth.setData(user, res.quizToken.token);
          this.router.navigate([ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/login' ]);

        }, refreshErr => {
          console.log('Refresh token error', refreshErr);
          //this.helper.showAlertWithouAutoDismiss(config.msg_401 || 'Please login again.');
          this.helper.showAlertWithouAutoDismiss(refreshErr.error.message);
          this.auth.logout();
        });
      } else if (err.status === 404) {
        if (err.error.message) {
          this.helper.showAlertWithouAutoDismiss(err.error.message);
        } else {
          this.helper.showAlertWithouAutoDismiss(config.msg_404 || '404. Whatever you were looking for was not found. Sorry');
        }
      } else if (err.status >= 500 && err.status < 600) {
        this.helper.showAlertWithouAutoDismiss(this.translocoService.translate('toasts.error500', {error_code: err.status}) || config.msg_5xx || `Server error ${ err.status }. Please try again later.`);
      } else if (err.status === 0) {
        this.helper.showAlertWithouAutoDismiss('Unknown error occured.');
      } else {
        if (err.error.message) {
          this.helper.showAlertWithouAutoDismiss(err.error.message);
        } else {
          this.helper.showAlertWithouAutoDismiss(config.msg || err.error.message || config.backupMSG);
        }
      }
    } catch (error) {
      console.error(error);
    }

  }

  /**
   * Handle http errors for when user is not logged in nor he should be.
   * @param err
   * @param config
   */
  handleHttpErrorForLogedOutUser(err: HttpErrorResponse, config: ConfigModel = {}, redirect = true) {
    try {
      if (err.status >= 500 && err.status < 600) {
        this.helper.showAlertWithouAutoDismiss(this.translocoService.translate('toasts.error500', {error_code: err.status}) || config.msg_5xx || `Server error ${ err.status }. Please try again later.`);
      } else if (err.status === 404) {
        if (err.error.message) {
          this.helper.showAlertWithouAutoDismiss(err.error.message);
        } else {
          this.helper.showAlertWithouAutoDismiss(config.msg_404 || '404. Whatever you were looking for was not found. Sorry');
        }
      } else if (err.status === 0) {
        this.helper.showAlertWithouAutoDismiss('Unknown error occured.');
      } else {
        if (err.error.message) {
          this.helper.showAlertWithouAutoDismiss(err.error.message);
        } else {
          this.helper.showAlertWithouAutoDismiss(config.msg || err.error.message || config.backupMSG);
        }
      }
      if (redirect) {
        this.router.navigate([ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/login' ]);
        localStorage.setItem('verify_email_msg', 'error');
      }
    } catch (error) {
      console.error(error);
    }
  }
}

interface ConfigModel {
  msg_401?: string;
  msg_404?: string;
  msg_5xx?: string;
  msg?: string;
  backupMSG?: string;
}
