import { Injectable } from '@angular/core';
import { ApiDefinition } from '../../../models/api/api.def';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../services/auth/auth.service';
import { API } from 'src/app/api-url/api-urls';
import { SharedUserReportResponse } from '../../../models/user/user-results.def';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  api: ApiDefinition = new ApiDefinition();
  exportLoading: boolean = false;
  exportPdfSubject: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {
  }

  get exportPdf$(): Observable<void> {
    return this.exportPdfSubject.asObservable();
  }

  /**
   * Get public user report data
   *
   * PUBLIC GET /share/public/{token}
   * @param token
   */
  getReport(token: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.get<SharedUserReportResponse>(API.APIINTERNAL + this.api.share + `/public/${ token }`, options);
  }


  /**
   * Create a token for sharing user report
   *
   * POST /share/token/user/report
   * @param user_id
   * @param form_id
   * @param userToken optional param for anon quiz users
   */
  createShareToken(user_id: number, form_id: string, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) {
      options.headers = {
        'Content-Type': 'application/json',
        'X-TOKEN': userToken
      };
    }

    return this.http.post<{ token: string }>(API.APIDOMAIN + this.api.share + `/token/user/report`, {
      user_id, form_id
    }, options);
  }

  exportAsPdf(userToken?: string, publicToken?: string, type?: string) {

    const options: any = this.auth.httpOptions;
    let url = API.APIDOMAIN + `export/pdf/${ publicToken }`;
    if (userToken) {
      options.headers = {
        'Content-Type': 'application/pdf',
        'X-TOKEN': userToken,
      };

    }
    options.responseType = 'blob' as 'json';
    options.observe = 'response';

    if (type) {
      url = url + `/${ type }`;
    }

    return this.http.get<any>(url, options);
  }

  shareUserBadgeImage(badge: any, assessmentId: string, userId: any, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) {
      options.headers = {
        'Content-Type': 'application/json',
        'X-TOKEN': userToken
      };
    }

    const url = API.SHAREDOMAIN + `picture`;

    return this.http.post<any>(url, {img: badge, user: userId, assessment: assessmentId}, options);
  }

  /**
   * Downloads SVG from BE
   * @param url
   * @returns string SVG
   */
  downloadSvg(url: string): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(url, {headers, responseType: 'text'});
  }
}
