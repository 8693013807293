import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ThemingService } from 'src/app/theme/theming.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import {
  AreYouSureDialogComponent
} from 'src/app/main/content/pages/shared/dialogs/are-you-sure-dialog/are-you-sure-dialog.component';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';

@Component({
  selector: 'forgotten-right',
  templateUrl: './forgotten-right.component.html',
  styleUrls: ['./forgotten-right.component.scss']
})
export class ForgottenRightComponent implements OnInit {
  forgottenRight: FormGroup;
  loading = false;
  mailSent = false;
  message: string;
  accountNameParam: string;

  get emailControl() {
    return this.forgottenRight.controls.email as FormControl;
  }

  constructor(
    protected authService: AuthService,
    protected helper: HelperService,
    protected errorService: ErrorService,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService,
    public themingService: ThemingService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.forgottenRight = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(HelperService.EMAIL_REGEX)]]
    });

    this.routerParams.params.subscribe(param => {
      const p = param[ACCOUNT_NAME];
      if (p) {
        this.accountNameParam = '/' + p;
      } else {
        this.accountNameParam = '';
      }
    });
  }

  deleteMyData() {
    const ref = this.dialog.open(AreYouSureDialogComponent, {
      autoFocus: false,
      maxWidth: 500,
      data: {
        extraMSG: 'Confirmation mail is on its way, continuing with this action will permanently delete your data and all related information.',
        yesText: 'Delete',
        noText: 'Cancel'
      }
    });

    ref.afterClosed().subscribe(dialog_res =>
    {
      if (dialog_res) {
        this.loading = true;
        this.authService.userDeleteSend(this.emailControl.value).subscribe(result => {
          this.loading = false;
          this.mailSent = true;
          this.message = 'Confirmation mail has been sent.';
        }, error => {
          this.loading = false;
          this.message = 'Something went wrong.';
          this.errorService.handleHttpErrorForLogedOutUser(error);
        });
      }
    });
  }

}
