import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiDefinition } from '../../models/api/api.def';
import { AuthService } from '../auth/auth.service';
import {
  CreateUserModel,
  ProfileDefinition,
  ProfilesResponse,
  UpdateUserProfileModel,
  UserDefinition,
  UserModel,
  UserResponse
} from '../../models/user/user.def';
import { API } from 'src/app/api-url/api-urls';
import { PaginationQueryParams } from '../../models/params/params.def';
import { MFAResponse } from '../../models/general/general-api-response.model';
import { AnonUserService } from '../user/anon-user.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  api: ApiDefinition;

  languageChanged$: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient, private auth: AuthService, private anonService: AnonUserService) {
    this.api = this.auth.api;
  }

  /**
   * GET request to fetch all users
   */
  getAllUsers(): Observable<{ groups: string[], users: UserModel[] }> {
    return this.http.get<{ groups: string[], users: UserModel[] }>(API.APIDOMAIN + this.api.users, this.auth.httpOptions);
  }

  /**
   * Get all users
   *
   * GET /users
   *
   * @param params Optional params filters to sort by, filter by, order by... etc
   */
  getUsers(params: PaginationQueryParams) {
    const options = this.auth.httpOptions;
    options.params = <any> params;

    return this.http.get<UserResponse>(API.APIDOMAIN + this.api.users, options);
  }

  /**
   * GET user by id
   *
   * GET /users/{id}
   * @param id
   */
  getUserById(id: number, userToken?: string) {
    const options = this.auth.httpOptions;
    if (userToken) options.headers = { 'X-TOKEN': userToken };

    return this.http.get<UserModel>(API.APIDOMAIN + this.api.users + '/' + id, options);
  }

  /**
   * POST Create new user
   * @param user
   */
  createUser(user: UserModel) {
    return this.http.post(API.APIDOMAIN + this.api.users, { user }, this.auth.httpOptions);
  }

  /**
   * POST Create new users bulk
   * @param accountName
   * @param users
   */
  createUsersBulk(accountName: string, users: UserModel[]) {
    const body = {
      account_name: accountName,
      users: users
    };
    return this.http.post(API.APIDOMAIN + 'admin/adduser/bulk', body, this.auth.httpOptions);
  }

  /**
   * PUT request for updating user data
   *
   * PUT /users/{user_id}
   * @param user
   */
  updateUser(user: UserModel) {
    return this.http.put<UserModel>(API.APIDOMAIN + this.api.users + `/${ user.id }`, user, this.auth.httpOptions);
  }

  changeAppLanguage(key: string) {
    this.languageChanged$.next(key);
  }

  /**
   * PUT request for updating anon user data
   *
   * PUT /users/{user_id}
   * @param user
   */
  updateAnonUser(user: UserModel) {
    if(!user.locale) user['locale'] = window.navigator.language || 'en';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-TOKEN': this.anonService.anonToken
      })
    };

    this.auth.getData();

    return this.http.put<UserModel>(API.APIDOMAIN + this.api.users + `/${ user.id }`, user, httpOptions);
  }

  /**
   * For use on /verify route
   */
  updateUserAfterVerification(user: UserDefinition) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-TOKEN': user.token_key
      })
    };
    return this.http.put(API.APIDOMAIN + this.api.users + `/admininvite/${ user.id }`, {
      first_name: user.first_name,
      last_name: user.last_name,
      password: user.password
    }, httpOptions);
  }

  /**
   * GET profile information from all users
   *
   * GET /profiles
   */
  getAllProfiles(params: PaginationQueryParams) {
    const options = this.auth.httpOptions;
    options.params = <any> params;

    return this.http.get<ProfilesResponse>(API.APIDOMAIN + this.api.profiles, options);
  }

  /**
   * GET user's profile.
   *
   * GET /profile/{user_id}
   * @param user_id Id of user
   */
  getProfile(user_id: number) {
    return this.http.get<ProfileDefinition>(API.APIDOMAIN + this.api.profile + `/${ user_id }`, this.auth.httpOptions);
  }

  /**
   * Update user's profile data
   *
   * PUT /profile/{profile_id}
   * @param profile_id user's profile id
   * @param data profile data
   */
  updateProfile(profile_id: number, data: UpdateUserProfileModel, token?: string): Observable<ProfileDefinition> {
    const options = this.auth.httpOptions;

    if (token) options.headers = { 'X-TOKEN': token };

    return this.http.put<ProfileDefinition>(API.APIDOMAIN + this.api.profile + `/${ profile_id }`, data, options);
  }

  /**
   * Update anon user's profile data
   *
   * PUT /profile/{profile_id}
   * @param user_id user's profile id
   * @param data profile data
   */
  updateAnonProfile(user_id: number, data: UpdateUserProfileModel, token?: string): Observable<ProfileDefinition> {
    const options = this.auth.httpOptions;

    options.headers = { 'X-TOKEN': this.anonService.anonToken };

    return this.http.put<ProfileDefinition>(API.APIDOMAIN + this.api.profile + `/${ user_id }`, data, options);
  }



  cancelEmailChange(id: number, token?: string): Observable<ProfileDefinition> {
    const options = this.auth.httpOptions;

    if (token) options.headers = { 'X-TOKEN': token };

    return this.http.post<ProfileDefinition>(API.APIDOMAIN + this.api.changeEmailCancel + `/${ id }`, {}, options);
  }

  /**
   * PATCH /reset-password/{user_id}/{token}
   * @param user_id
   * @param newPassword
   * @param token
   */
  resetPassword(user_id: number, newPassword: string, token: string) {
    return this.http.patch<any>(API.APIDOMAIN + `reset-password/${ user_id }/${ token }`, { password: newPassword }, this.auth.httpOptions);
  }

  /**
   * Used on profile page to reset the password
   *
   * PATCH /users/{user_id}/update-password
   * @param user_id
   * @param new_password
   * @param old_password
   */
  resetPasswordNoToken(user_id: number, new_password: string, old_password: string) {
    return this.http
      .patch<UserModel>(API.APIDOMAIN + this.api.users + `/${ user_id }/update-password`, {
        new_password,
        old_password
      }, this.auth.httpOptions);
  }

  resetPasswordAnon(email: string, account: string) {
    return this.http
      .post<UserModel>(API.APIDOMAIN + `reset-password`, {
        email,
        account
      }, this.auth.httpOptions);
  }

  /**
   * POST /profile/saveimage
   * @param body
   */
  uploadProfileImage(body: { user_id: number, image: string }) {
    const options: {
      headers?: HttpHeaders; reportProgress?: boolean; params?: HttpParams; responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'; withCredentials?: boolean;
    } = {};
    options.headers = (<any> this.auth.httpOptions.headers);
    options.reportProgress = true;

    return this.http.request<{ image: string }>(new HttpRequest('POST', API.APIDOMAIN + this.api.profile + '/saveimage', body, options));

    /* return this.http.post(API.APIDOMAIN + this.api.profile + '/saveimage', body, this.auth.httpOptions); */
  }

  /**
   * Updates admin's permissions and groups he has access to
   * /admin/info/{id}
   */
  updateAdminRole(user_id: number, body: { roles: string[], groups: string[] }) {
    return this.http.put(API.APIDOMAIN + this.api.updateAdminRoles + `/${ user_id }`, body, this.auth.httpOptions);
  }

  /**
   * Deletes a user
   *
   * DELETE /users/{user_id}
   * @param user_id
   */
  deleteUserById(user_id: number) {
    return this.http.delete(API.APIDOMAIN + this.api.users + `/${ user_id }`, this.auth.httpOptions);
  }


  /**
   * Create a new user
   *
   * POST /admin/adduser
   * @param user
   */
  createNewUser(user: CreateUserModel) {
    return this.http.post<UserModel>(API.APIDOMAIN + this.api.admin + `/adduser`, user, this.auth.httpOptions);
  }


  /**
   * Admin copy user to another account
   *
   * POST /admin/copyuser
   * @param user_id
   * @param account_id
   */
  copyUserToAnotherAccount(user_id: number, account_id: number) {
    return this.http.post<UserModel>(API.APIDOMAIN + this.api.admin + `/copyuser`, {
      user_id,
      account_id
    }, this.auth.httpOptions);
  }

  /**
   * Copy user and user results to another account
   *
   * POST /users/copy/results
   * @param body
   */
  copyUserResults(body: any) {
    return this.http.post<any>(API.APIDOMAIN + this.api.users + `/copy/results`, body, this.auth.httpOptions);
  }

  /**
   * Removes a profile image
   *
   * DELETE /profile/{profileId}/image
   * @param profileId
   */
  removeProfileImage(profileId: number) {
    return this.http.delete(API.APIDOMAIN + `profile/${ profileId }/image`, this.auth.httpOptions);
  }


  /**
   * Get the QR code image for enabling 2FA
   *
   * GET /user/two-factor/qr
   */
  get_2FA_QR_code() {
    return this.http.get<MFAResponse>(API.APIDOMAIN + `user/two-factor/qr`, this.auth.httpOptions);
  }


  /**
   * Enables 2FA for the user's account
   *
   * POST /user/two-factor/enable
   *
   * @param code 2FA code needed to confirm activation
   */
  enable2FA(code: string) {
    return this.http.post<MFAResponse>(API.APIDOMAIN + `user/two-factor/enable`, { twoFactorCode: code }, this.auth.httpOptions);
  }

  checkEmail(email: string) {
    const headers = {};
    headers['Content-Type'] = 'application/json';

    const token = localStorage.getItem('anon_token') || localStorage.getItem('access_token');
    if (token) {
      headers['X-TOKEN'] = token;
      this.auth.httpOptions = {
        headers: new HttpHeaders(headers)
      };
    }
    return this.http.get<MFAResponse>(API.APIDOMAIN + `users/${ email }`, this.auth.httpOptions);
  }


  /**
   * Disables 2FA for the user's account
   *
   * POST /user/two-factor/disable
   */
  disable2FA() {
    return this.http.post<null>(API.APIDOMAIN + `user/two-factor/disable`, {}, this.auth.httpOptions);
  }

  /**
   * Resend emails based on users status
   *
   * POST admin/users/resendemail/{status}
   * @param users_id
   */
  resendEmailByStatus(data: any, index_name: string, status: string) {
    return this.http.post(API.APIDOMAIN + this.api.admin + '/' + this.api.users + `/resendmail/${ status }`, {
      user_ids: data,
      account: index_name
    }, this.auth.httpOptions);
  }

  /**
   * POST user/userdetails
   * @param data
   */
  exportUsersList(data: any) {
    const options = this.auth.httpOptions;

    if (data.user_id) {
      let params = new HttpParams();
      if (data.account_id) {
        params = params.append('account_id', data.account_id);
      }
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            data[key].forEach((item) => {
              params = params.append(`${ key.toString() }[]`, item);
            });
          }
        }
      }
      options.params = params;
    } else {
      options.params = data;
    }
    options.responseType = 'blob' as 'json';


    return this.http.get(API.APIDOMAIN + `user/userdetails`, options);
  }

}

