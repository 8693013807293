import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BaseForgotPasswordComponent } from '../base-forgot-password.component';
import { fuseAnimations } from 'src/app/animations/animations';
import { ThemingService } from 'src/app/theme/theming.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';

@Component({
  selector: 'ds-forgot-password-design2',
  templateUrl: './forgot-password-design2.component.html',
  styleUrls: ['./forgot-password-design2.component.scss'],
  animations: fuseAnimations
})
export class ForgotPasswordDesign2Component extends BaseForgotPasswordComponent
{
  accent;
  toolbarBGColor;
  toolbarFontColor;
  fontFamily;

  constructor(
    public themingService: ThemingService,
    protected authService: AuthService,
    protected helper: HelperService,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService
  )
  {
    super(authService, helper, router, formBuilder, routerParams);

    this.accent = themingService.accent;
    this.toolbarBGColor = themingService.toolbarBGColor;
    this.toolbarFontColor = themingService.toolbarFontColor;
    this.fontFamily = themingService.fontFamily;
  }
}
