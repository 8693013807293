import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiDefinition } from '../../models/api/api.def';
import { ThumbType, ThumbResponse } from '../../models/user/thumbs.model';
import { API } from 'src/app/api-url/api-urls';

@Injectable({
  providedIn: 'root'
})
export class ThumbsService
{
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  )
  {}

  /**
   * Get the thumbs data
   *
   * POST /thumbs/{type}/{id}
   */
  getThumb(id: string, type: ThumbType)
  {
    return this.http.get<ThumbResponse>(API.APIDOMAIN + this.api.thumbs + `/${type}/${id}`, this.auth.httpOptions);
  }

  /**
   * Set the thumb data
   *
   * POST /thumbs
   */
  setThumb(id: string, type: ThumbType, value: boolean)
  {
    return this.http.post<ThumbResponse>(API.APIDOMAIN + this.api.thumbs, { id, type, thumb: value }, this.auth.httpOptions);
  }
}
