import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { copyToClipboard } from 'src/app/functions/helpful-functions';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';

@Component({
  selector: 'ds-copy-value-dialog',
  templateUrl: './copy-value-dialog.component.html',
  styleUrls: ['./copy-value-dialog.component.scss']
})
export class CopyValueDialogComponent implements OnInit
{

  constructor(
    public dialogRef: MatDialogRef<CopyValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CopyValueDialogParams = { copyValue: '' },
    private helper: HelperService,
  )
  { }

  ngOnInit()
  {
    console.log('Data:', this.data);
  }

  copy()
  {
    if (!copyToClipboard(this.data.copyValue))
    {
      alert('Please copy the text manually');
    }
    else
    {
      this.helper.showAlert('Link copied');
      setTimeout(() =>
      {
        this.dialogRef.close();
      }, 1000);
    }
  }
}

interface CopyValueDialogParams
{
  copyValue: string;
  title?: string;
  text?: string;
  hasClose?: boolean;
  hasYes?: boolean;
  closeText?: string;
  yesText?: string;
}
