import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CustomValidators } from 'src/app/functions/validators';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { EmailInviteModel } from 'src/app/main/content/models/admin/invites.def';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';

@Component({
  selector: 'ds-users-invite-dialog',
  templateUrl: './users-invite-dialog.component.html',
  styleUrls: ['./users-invite-dialog.component.scss']
})
export class UsersInviteDialogComponent implements OnInit, OnDestroy
{
  subs = new Subscription();
  loading = false;

  form: FormGroup;

  get message() { return this.form.controls.message as FormControl; }
  get emailsArray() { return this.form.get('emails') as FormArray; }

  constructor(
    public dialogRef: MatDialogRef<UsersInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { formID: string; emailCount: number; },
    private fb: FormBuilder,
    private inviteService: InvitesService,
    private errService: ErrorService,
    private helper: HelperService,
    private auth: AuthService,
    private anonService: AnonUserService,
  ) { }

  ngOnInit()
  {
    this.createForm();
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }

  private createForm()
  {
    this.form = this.fb.group({
      message: ['', Validators.compose([CustomValidators.noWhitespaceValidator])],

      emails: this.fb.array([])
    });

    for (let i = 0; i < this.data.emailCount; i++)
    {
      this.addEmailControl();
    }
  }

  private addEmailControl()
  {
    const email = this.fb.group({
      email: ['', Validators.pattern(HelperService.EMAIL_REGEX)]
    });

    this.emailsArray.push(email);
  }

  /**
   * Checks if there is any value in the email fields
   */
  allEmailsEmpty()
  {
    let emails: string[] = this.emailsArray.controls.map((formGroup: FormGroup) => formGroup.controls.email.value);
    emails = emails.filter(e => !!e);

    return !(emails.length > 0);
  }

  sendEmails()
  {
    if (this.form.invalid || this.allEmailsEmpty()) return;

    this.loading = true;

    let emails: string[] = this.emailsArray.controls.map((formGroup: FormGroup) => formGroup.controls.email.value);
    emails = emails.filter(e => !!e);

    const data: EmailInviteModel = {
      email_template_id: null,
      form_id: this.data.formID,
      user_invite: true,
      invites: emails.map(e =>
      {
        if (e && e.length > 0) return {
          email: e,
          notes: this.message.value
        };
      })
    };

    this.subs.add(
      this.inviteService.sendEmailInvites(
        data,
        [],
        this.auth.isLoggedIn() ? null : this.anonService.anonToken
      ).subscribe(res =>
      {
        console.log('Send email response:', res);
        this.loading = false;
        this.helper.showAlert('E-mails sent');
        this.dialogRef.close({ emailsUsed: emails.length });

      }, err =>
        {
          this.loading = false;
          console.log('Error sending emails:', err);
          this.errService.handleHttpErrorForLogedInUser(err);
        })
    );
  }
}
