import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDefinition } from 'src/app/main/content/models/user/user.def';
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { ROLE_ADMIN } from 'src/app/constants/user.role';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { LoginV2Response, SocialLoginAccountListResponse } from 'src/app/main/content/models/auth/auth.model';
import { Subscription } from 'rxjs';
import { ChooseAccountDialogComponent } from '../../../shared/dialogs/choose-account-dialog/choose-account-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InviteLinkLoginDialogComponent } from '../../../shared/dialogs/invite-link-login-dialog/invite-link-login-dialog.component';
import { VerifyService } from 'src/app/main/content/services/auth/verify.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { SocialMFALoginResponse, MfaAction } from 'src/app/main/content/models/general/general-api-response.model';
import { TwoFactorCodeDialogComponent } from '../../../shared/dialogs/two-factor-code-dialog/two-factor-code-dialog.component';

@Component({
  selector: 'ds-linkedin',
  templateUrl: './linkedin.component.html',
  styleUrls: ['./linkedin.component.scss']
})
export class LinkedinComponent implements OnInit, OnDestroy
{
  code: string;
  state: string;
  decoded_account: string;
  /** A return url for invites */
  returnURL: string;

  accountNameParam = '';

  subs = new Subscription();

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private helper: HelperService,
    private router: Router,
    private dialog: MatDialog,
    private verifyService: VerifyService,
    private errService: ErrorService,
  )
  { }

  ngOnInit()
  {
    this.code = this.route.snapshot.queryParams['code'];
    this.state = this.route.snapshot.queryParams['state'];

    console.log('CODE:', this.code);
    console.log('STATE:', this.state);

    if (this.code && this.state)
    {
      this.accountNameParam = this.decoded_account = atob(this.state).split('--')[1];
      this.returnURL = atob(this.state).split('--')[2] || localStorage.getItem('returnUrl');
      console.log('DECODED: ', atob(this.state));

      this.auth.login.linkedinDATA({
        code: this.code,
        state: this.state,
        account: this.decoded_account
      }).subscribe((res) =>
      {
        console.log('Linkedin DATA:', res);

        // check if login is a 2FA
        if ((<SocialMFALoginResponse>res).action && (<SocialMFALoginResponse>res).action === MfaAction.googleAuthenticator)
        {
          this.socialMFAlogin(<SocialMFALoginResponse>res);
        }
        else
        {
          this.standardSocialLogin(res);
        }

      }, (err: HttpErrorResponse) =>
      {
        console.log(err);

        if (err.error && err.error.trans_code === 'exception.user.admin.not.verified')
        {
          this.resendEmailVerification(err.error.data.email, err.error.data.account);
        }
        else
        {
          this.errService.handleHttpErrorForLogedOutUser(err);
          this.router.navigate([`${this.accountNameParam}/login`]);
        }
      });
    }
    else
    {
      this.router.navigate([`${this.accountNameParam}/login`]);
    }
  }




  /**
   * Checks the type of login and further steps
   */
  private standardSocialLogin(res: LoginV2Response | SocialLoginAccountListResponse | SocialMFALoginResponse)
  {
    let isAccountList = false;
    let resLogin: LoginV2Response;
    let resAccount: SocialLoginAccountListResponse;

    // Check the type of the response
    if ((<SocialLoginAccountListResponse>res).accounts)
    {
      isAccountList = true;
      resAccount = <SocialLoginAccountListResponse>res;
    }
    else
    {
      isAccountList = false;
      resLogin = <LoginV2Response>res;
    }


    if (isAccountList)
    {
      if (resAccount.accounts.length === 1) // Check if has only one accout
      {
        console.log('Only one account', resAccount);
        // login user using this one account
        // Have to call the login api again but this time with account name
        this.subs.add(
          this.auth.login.linkedinDATA({
            account: resAccount.accounts[0].account.index_name,
            token: resAccount.token,
            checksum: resAccount.checksum
          }).subscribe((accLogin: LoginV2Response | SocialMFALoginResponse) =>
          {
            // check if login is a 2FA
            if ((<SocialMFALoginResponse>accLogin).action && (<SocialMFALoginResponse>accLogin).action === MfaAction.googleAuthenticator)
            {
              this.socialMFAlogin(<SocialMFALoginResponse>accLogin);
            }
            else
            {
              this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
            }

          },
            err =>
            {
              console.log('One account login error', err);
              if (err.error && err.error.trans_code === 'exception.user.admin.not.verified')
              {
                this.resendEmailVerification(err.error.data.email, err.error.data.account);
              }
              else
              {
                this.errService.handleHttpErrorForLogedOutUser(err);
                this.router.navigate([`${this.accountNameParam}/login`]);
              }
            })
        );
      }
      else // If more that one account
      {
        console.log('More than one account', resAccount);
        // open selector of accounts
        const dialogRef = this.dialog.open(ChooseAccountDialogComponent, {
          data: resAccount.accounts,
          disableClose: true,
          closeOnNavigation: true,
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          if (dialogResponse)
          {
            this.subs.add(
              this.auth.login.linkedinDATA({
                account: dialogResponse.accountIndex,
                token: resAccount.token,
                checksum: resAccount.checksum
              }).subscribe((accLogin: LoginV2Response | SocialMFALoginResponse) =>
              {
                // check if login is a 2FA
                if ((<SocialMFALoginResponse>accLogin).action && (<SocialMFALoginResponse>accLogin).action === MfaAction.googleAuthenticator)
                {
                  this.socialMFAlogin(<SocialMFALoginResponse>accLogin);
                }
                else
                {
                  this.checkForInviteLinkLogin(<LoginV2Response>accLogin);
                }
              },
                err =>
                {
                  console.log('One account login error', err);
                  if (err.error && err.error.trans_code === 'exception.user.admin.not.verified')
                  {
                    this.resendEmailVerification(err.error.data.email, err.error.data.account);
                  }
                  else
                  {
                    this.errService.handleHttpErrorForLogedOutUser(err);
                    this.router.navigate([`${this.accountNameParam}/login`]);
                  }
                })
            );
          }
          else
          {
            this.router.navigate([`${this.accountNameParam}/login`]);
          }
        });
      }
    }
    else // We know the account, login user regularly
    {
      console.log('Exact account is knows, login normal');

      this.checkForInviteLinkLogin(resLogin);
    }
  }


  /**
   * Opens 2FA dialog and send the code to the api
   */
  private socialMFAlogin(res: SocialMFALoginResponse)
  {
    const dialog_ref = this.dialog.open(TwoFactorCodeDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      minWidth: 250
    });

    dialog_ref.afterClosed().subscribe(code =>
    {
      if (code)
      {
        this.subs.add(
          this.auth.login.mfaLogin(res.MFAToken, code).subscribe(mfaRes =>
          {
            console.log('MFA login response, login normal', mfaRes);
            this.checkForInviteLinkLogin(mfaRes);

          }, err =>
          {
            console.log('MFA login error', err);
            if (err.error && err.error.trans_code === 'exception.user.admin.not.verified')
            {
              this.resendEmailVerification(err.error.data.email, err.error.data.account);
            }
            else
              this.errService.handleHttpErrorForLogedOutUser(err);
          })
        );
      }
      else
      {
        this.router.navigate([`${this.accountNameParam}/login`]);
      }
    });
  }





  private checkForInviteLinkLogin(data: LoginV2Response)
  {
    if (localStorage.getItem('user-had-account') === 'true')
    {
      console.log('%cUser was logged in before', 'color: red; font-weight: bold');
      const dialog_ref = this.dialog.open(InviteLinkLoginDialogComponent, {
        disableClose: true,
        autoFocus: false,
        restoreFocus: false
      });

      dialog_ref.afterClosed().subscribe(dialog_res =>
      {
        if (dialog_res === 'login')
        {
          this.returnURL = null;
          localStorage.removeItem('returnUrl');
          localStorage.removeItem('user-had-account');
          this.saveUserInfo(data);
        }
        else
        {
          localStorage.removeItem('user-had-account');
          this.saveUserInfo(data);
        }
      });
    }
    else
    {
      this.saveUserInfo(data);
    }
  }


  private saveUserInfo(data: LoginV2Response)
  {
    console.log('Login data: ', data);
    this.returnURL = data.returnUrl || localStorage.getItem('returnUrl');

    AccountIndexClass.ACCOUNT_INDEX = data.user.account.index_name;
    AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + data.user.account.index_name;

    const user: UserDefinition = data.user;
    user['token_key'] = data.token_key;
    user['refresh_token'] = data.refresh_token;
    this.auth.setData(user, data.quizToken.token);

    if (this.returnURL) // If return url exist, we need to return the user that just logged in to that url
    {
      localStorage.removeItem('returnUrl');
      this.router.navigate([this.returnURL], {
        queryParams: {
          skipVerify: true
        }
      });
    }
    else // otherwise, just route normally to dashboard
    {
      if (this.auth.user.roles.includes(ROLE_ADMIN))
      {
        this.auth.isUserAdmin = true;
        this.router.navigate([`${AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM}/dashboard`]);
      }
      else
      {
        this.router.navigate([`${AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM}/dashboard`]);
      }
    }
  }


  private resendEmailVerification(email: string, account: string)
  {
    this.subs.add(
      this.verifyService.resendVerificationEmail(email, account)
        .subscribe(res =>
        {
          console.log('Resend email response:', res);
          this.helper.showAlert(res.message || 'Verification e-mail has been resent', 'OK', { duration: 10000 });
          this.router.navigate([account + '/login']);

        }, err =>
        {
          console.log('Error resending email', err);
          this.errService.handleHttpErrorForLogedInUser(err);
          this.router.navigate([account + '/login']);
        })
    );
  }


  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
