import { config } from 'src/config';

declare const FB: any;

export const Facebook = {
  share: (url_to_share: string) => {
    try {
      // initialise facebook sdk after it loads if required
      if (!window['fbAsyncInit']) {
        FB.init({
          appId: config.facebook_app_id,
          autoLogAppEvents: true,
          cookie: true,
          xfbml: true,
          version: 'v2.10'
        });
        FB.AppEvents.logPageView();
      }


      FB.ui({
        method: 'share',
        href: url_to_share,
      }, function (response) {
        console.groupCollapsed('Facebook share');
        console.log(response);
        console.groupEnd();
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const Twitter = {
  tweet: (url_to_share: string) => {
    try {
      window.open(`https://twitter.com/intent/tweet?text=${ encodeURI(url_to_share) }`, 'Tweet', 'height=300,width=500');
    } catch (error) {
      console.error(error);
    }
  }
};


export const LinkedIN = {
  share: (url_to_share: string) => {
    try {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${ encodeURI(url_to_share) }`, 'Share', 'height=300,width=500');
    } catch (error) {
      console.error(error);
    }
  }
};
