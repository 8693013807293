import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginV2Response } from 'src/app/main/content/models/auth/auth.model';

@Component({
  selector: 'ds-invite-link-login-dialog',
  templateUrl: './invite-link-login-dialog.component.html',
  styleUrls: ['./invite-link-login-dialog.component.scss']
})
export class InviteLinkLoginDialogComponent implements OnInit
{
  get returnUrl() { return localStorage.getItem('returnUrl'); }
  get userHadAccount() { return localStorage.getItem('user-had-account'); }

  inviteAccount: string = '';

  constructor(
    public dialogRef: MatDialogRef<InviteLinkLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoginV2Response,
  )
  { }

  ngOnInit()
  {
    const inv = this.returnUrl;
    const i1 = inv.indexOf('/');
    const i2 = inv.indexOf('/', i1 + 1);
    this.inviteAccount = inv.substring(i1, i2).replace('/', '');
  }

}
