import { Component, OnInit, Input } from '@angular/core';
import { CoreDriversResult, Interest } from 'src/app/main/content/models/user/user-results.def';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-j3personica-user-report',
  templateUrl: './j3personica-user-report.component.html',
  styleUrls: ['./j3personica-user-report.component.scss']
})
export class J3personicaUserReportComponent implements OnInit
{
  _result: CoreDriversResult;
  @Input() set result(value: CoreDriversResult)
  {
    this._result = value;

    if (this._result && this._user)
    {
      this._result.score.drivers.portrait = this._result.score.drivers.portrait.replace('<name>', this.user.first_name || this.user.email);
    }

    this.mapData(this._result);
  }

  _user: UserModel;
  @Input() set user(value: UserModel)
  {
    this._user = value;

    if (this._result && this._user)
    {
      this._result.score.drivers.portrait = this._result.score.drivers.portrait.replace('<name>', this.user.first_name || this.user.email);
    }
  }
  get user() { return this._user; }

  _showThumbs = true;
  @Input()
  set showThumbs(v: boolean) { this._showThumbs = v; }
  get showThumbs() { return this._showThumbs; }


  success: Interest[] = [];
  team: Interest[] = [];
  organizational: Interest[] = [];

  constructor(
    public themingService: ThemingService,
  )
  { }

  ngOnInit()
  {
  }


  private mapData(data: CoreDriversResult)
  {
    this.success = [];
    this.team = [];
    this.organizational = [];

    data.score.potential.interests.forEach(section =>
    {
      Object.entries(section).forEach(([title, value]) =>
      {
        switch (title)
        {
          case 'Individual success':
            this.success.push(value);
            break;

          case 'Team success':
            this.team.push(value);
            break;

          case 'Organizational success':
            this.organizational.push(value);
            break;

          default:
            break;
        }
      });
    });
  }
}
