import { Component, Input, OnInit } from '@angular/core';
import { SubmittedAssessment } from 'src/app/main/content/models/admin/results.def';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { AccountSelectService } from 'src/app/main/content/services/admin/account-select.service';


@Component({
  selector: 'ds-assessment-info',
  templateUrl: './assessment-info.component.html',
  styleUrls: ['./assessment-info.component.scss'],
})
export class AssessmentInfoComponent extends BaseRouteParamComponent implements OnInit
{
  @Input() assessment: SubmittedAssessment;
  constructor(
    protected routerParams: RouterParamsService,
    public helper: HelperService,
    public accountSelect: AccountSelectService,
  )
  {
    super(routerParams);
  }

  ngOnInit()
  {
    super.ngOnInit();
  }

  dateFormat(started_at: string) {
    return new Date(started_at).toDateString();
  }
}
