import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { isGearSubdomain } from 'src/app/functions/helpful-functions';

@Component({
  selector: 'terms-and-condition-privacy-policy',
  templateUrl: './terms-and-condition-privacy-policy.component.html',
  styleUrls: ['./terms-and-condition-privacy-policy.component.scss'],
})
export class TermsAndConditionPrivacyPolicyComponent {
  unsubscribe$: Subject<void> = new Subject();
  termsHTML: string;

  gearSudomain = isGearSubdomain;
}
