import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FuseLoginComponent } from './login.component';
import {
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialLoginModule,
} from 'angular-6-social-login';
import { LinkedinComponent } from './linkedin/linkedin.component';
import { LoginDesign1Component } from './login-design1/login-design1.component';
import { LoginDesign2Component } from './login-design2/login-design2.component';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { config } from 'src/config';

const routes: Routes = [
  {
    path: `:${ ACCOUNT_NAME }/login`,
    component: FuseLoginComponent
  },
  {
    path: `:${ ACCOUNT_NAME }/login/linkedin`,
    component: LinkedinComponent
  },

  {
    path: 'login',
    component: FuseLoginComponent
  },
  {
    path: 'login/linkedin',
    component: LinkedinComponent
  }
];

// Social configs
export const getAuthServiceConfigs = () => {
  return new AuthServiceConfig([
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(config.facebook_app_id)
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(config.google_app_id)
      }
    ]
  );
};


@NgModule({
  declarations: [
    FuseLoginComponent,
    LinkedinComponent,
    LoginDesign1Component,
    LoginDesign2Component
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    SocialLoginModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    }
  ]
})

export class LoginModule {

}
