import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseRegisterComponent} from './register.component';
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angular-6-social-login';
import {RegisterDesign1Component} from './register-design1/register-design1.component';
import {RegisterDesign2Component} from './register-design2/register-design2.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {ACCOUNT_NAME} from 'src/app/constants/router-params';
import {config} from 'src/config';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/register`,
    component: FuseRegisterComponent
  },
  {
    path: 'register',
    component: FuseRegisterComponent
  }
];

// Social configs
export const getAuthServiceConfigs = () => {
  return new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(config.facebook_app_id)
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(config.google_app_id)
      }
    ]
  );
};


@NgModule({
  declarations: [
    FuseRegisterComponent,
    RegisterDesign1Component,
    RegisterDesign2Component
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    SocialLoginModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ]
})

export class RegisterModule {

}
