import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { query, trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'ds-share-speed-dial',
  templateUrl: './share-speed-dial.component.html',
  styleUrls: ['./share-speed-dial.component.scss'],
  animations: [
    trigger('spinInOut', [
      state('in', style({ transform: 'rotate(0)', opacity: '1' })),
      transition(':enter', [style({ transform: 'rotate(-180deg)', opacity: '0' }), animate('150ms ease')]),
      transition(':leave', [animate('150ms ease', style({ transform: 'rotate(180deg)', opacity: '0' }))])
    ]),
    trigger('preventInitialAnimation', [transition(':enter', [query(':enter', [], { optional: true })])])
  ],
})
export class ShareSpeedDialComponent implements OnInit
{
  @Output() selected = new EventEmitter<string>();

  constructor() { }

  ngOnInit()
  {
  }

  select(type: string)
  {
    this.selected.emit(type);
  }
}
