import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
interface Data {
  emails: { id: number; email: string }[];
  formId: string;
}
@Component({
  selector: 'retake-dialog',
  templateUrl: './retake-dialog.component.html',
  styleUrls: ['./retake-dialog.component.scss']
})
export class RetakeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RetakeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
  }


  public static openDialog(matDialog: MatDialog, emails: { id: number; email: string }[], formId: string): Observable<any> {
    return matDialog.open(RetakeDialogComponent, { data: { emails, formId } }).afterClosed();
  }

  ngOnInit() {
  }

}
