import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ds-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }


}
