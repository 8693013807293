import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { FollowUp, emailStatusTranslationMap } from 'src/app/main/content/models/admin/invites.def';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailsPreviewDialogComponent } from '../../dialogs/emails-preview-dialog/emails-preview-dialog.component';

@Component({
  selector: 'ds-follow-up-table',
  templateUrl: './follow-up-table.component.html',
  styleUrls: ['./follow-up-table.component.scss']
})
export class FollowUpTableComponent implements OnInit, OnDestroy
{
  subs = new Subscription();

  followUps: FollowUp[] = [];
  emailStatusTranslationMap = emailStatusTranslationMap;

  displayedColumns: string[] = ['created_at', 'follow_up_type', 'to', 'sent_by', 'success', 'failed', 'download'];
  dataSource = new MatTableDataSource<FollowUp>([]);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  loading = false;

  @Input()
  set uuid(v: string)
  {
    this.getFollowUps(v);
  }

  constructor(
    private inviteService: InvitesService,
    private errService: ErrorService,
    private dialog: MatDialog,
  )
  { }

  ngOnInit()
  { }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }


  private getFollowUps(uuid: string)
  {
    this.loading = true;

    this.subs.add(
      this.inviteService.getFollowUpData(uuid).subscribe(res =>
      {
        console.log('Followups response:', res);
        this.followUps = res;
        try
        {
          this.followUps.sort((f1, f2) => new Date(f2.created_at).getTime() - new Date(f1.created_at).getTime());
        }
        catch (error)
        {
          console.log(error);
        }
        this.dataSource.data = this.followUps;

        this.loading = false;

        // wait for it to show up in the DOM
        setTimeout(() =>
        {
          this.dataSource.paginator = this.paginator;
        }, 0);

      }, err =>
      {
        console.log('Error ', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }

  previewEmails(followUp: FollowUp)
  {
    const dialog_ref = this.dialog.open(EmailsPreviewDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { emails: followUp.emails.addresses || [] }
    });
  }
}
