import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export type LangCode = 'auto' | 'en' | 'fr' | 'es' | 'no';

@Pipe({
  name: 'translatedLogo'
})
export class TranslatedLogoPipe implements PipeTransform {

  constructor(private translocoService: TranslocoService) {
  }

  private static getPath(langCode?: LangCode): string {
    if (!langCode) return 'https://cdn.manpowergroupassessments.com/quiz-media/stg/skillstranslator-logo-en_us.png';


    if (langCode.includes('fr')) {
      return 'https://cdn.manpowergroupassessments.com/quiz-media/stg/skillstranslator-logo-fr_fr.png';
    } else if (langCode.includes('es')) {
      return 'https://cdn.manpowergroupassessments.com/quiz-media/stg/skillstranslator-logo-es_es.png';
    } else if (langCode.includes('no')) {
      return 'https://cdn.manpowergroupassessments.com/quiz-media/stg/skillstranslator-logo-no.png';
    }

    return 'https://cdn.manpowergroupassessments.com/quiz-media/stg/skillstranslator-logo-en_us.png';

  }

  transform(language: LangCode): Observable<string> {
    if (language === 'auto') {
      return this.translocoService.langChanges$.pipe(
        map(lang => TranslatedLogoPipe.getPath(lang as LangCode))
      );
    }

    return of(TranslatedLogoPipe.getPath(language));
  }

}
