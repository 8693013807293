import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: string, format?: string, nullValue?: string, timezone?: string, locale?: string): string | null {
    if (!value) return nullValue || value;

    const _timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const _locale = locale || navigator.language;
    let suffix = '';

    if (format === 'short') {
      suffix = ' ' + super.transform(value, 'z', _timezone, _locale);
    }

    return super.transform(value, format, _timezone, _locale) + suffix;
  }
}
