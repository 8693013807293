import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiDefinition } from '../../models/api/api.def';
import { API } from 'src/app/api-url/api-urls';
import { AccountListResponse, AccountDetailsModel, AccountModel } from '../../models/admin/account.def';
import { AssessmentLibrary } from '../../models/admin/assesment.def';
import { config } from 'src/config';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
  }


  /**
   * GET a list of all accounts
   *
   * GET /accounts
   * @param ordered If set, the list will be ordered in hierarchical way
   */
  getAccountList(order?: boolean) {
    const options = this.auth.httpOptions;
    if (order) options.params = <any>{ order };

    return this.http.get<AccountListResponse | AccountModel>(API.APIDOMAIN + this.api.accounts, options);
  }


  /**
   * GET details of an account
   *
   * GET /accounts/{id}/details
   */
  getAccountDetails(id: number) {
    return this.http.get<AccountDetailsModel>(API.APIDOMAIN + this.api.accounts + `/${ id }/details`, this.auth.httpOptions);
  }


  /**
   * Create a new account
   *
   * POST /accounts
   * @param account
   */
  createAccount(account: { name: string, index_name: string, account: number }) {
    return this.http.post<AccountModel>(API.APIDOMAIN + this.api.accounts, account, this.auth.httpOptions);
  }


  retakeOption(emails: string[]) {
    const options = this.auth.httpOptions;
    const url = `${config.api_url}users/validate/emails`;
    return this.http.post(url, emails, options);
  }


  /**
   * Delete an account by ID
   *
   * DELETE /accounts/{id}
   */
  deleteAccountById(id: number) {
    return this.http.delete(API.APIDOMAIN + this.api.accounts + '/' + id, this.auth.httpOptions);
  }


  /**
   * Add assessment to the account
   *
   * POST /accounts/addassessment
   *
   * @param account Account ID
   * @param library Library ID
   * @param title Optional title for the assessment, if not provided, default will be set
   */
  addAssessmentToAccount(account: number, library: number, title?: string) {
    return this.http.post(API.APIDOMAIN + this.api.accounts + '/addassessment', {
      account,
      library,
      title
    }, this.auth.httpOptions);
  }


  /**
   * Add Library to Account.
   *
   * POST /accounts/addassessmentlibrary
   *
   * @param account Account ID
   * @param library Library ID
   * @param title Optional title for the assessment, if not provided, default will be set
   */
  addAssessmentLibraryToAccount(account: number, library: number) {
    return this.http.post<AssessmentLibrary[]>(API.APIDOMAIN + this.api.accounts + '/addassessmentlibrary', {
      account,
      library
    }, this.auth.httpOptions);
  }


  /**
   * Get library of assessments
   *
   * GET /accounts/assessment_library/{accountId}
   */
  getAccountAssessmentLibraryByAccountID(accountId: number) {
    return this.http.get<AssessmentLibrary[]>(API.APIDOMAIN + this.api.accounts + `/assessment_library/${ accountId }`, this.auth.httpOptions);
  }

  /**
   * Get library of assessments with values for download type
   *
   * GET /accounts/assessment_library/{accountId}/values
   */
  getAvailableAccountAssessmentLibraryByAccountIDWithValues(accountId: number) {
    return this.http.get<AssessmentLibrary[]>(API.APIDOMAIN + this.api.accounts + `/assessment_library/${ accountId }/values`, this.auth.httpOptions);
  }

  getAccountAssessmentLibraryByID(accountId: number, libraryId: number) {
    return this.http.get<AssessmentLibrary[]>(API.APIDOMAIN + this.api.accounts + `/${ accountId }/library/${ libraryId }`, this.auth.httpOptions);
  }


  /**
   * Get available library of assessments
   *
   * GET /accounts/assessment_library/{accountId}/available
   */
  getAvailableAccountAssessmentLibraryByAccountID(accountId: number) {
    return this.http.get<AssessmentLibrary[]>(API.APIDOMAIN + this.api.accounts + `/assessment_library/${ accountId }/available`, this.auth.httpOptions);
  }


  /**
   * Delete an assessment for account
   *
   * DELETE /accounts/{account_id}/assessment/{form_id}
   * @param account_id
   * @param form_id
   */
  deleteAssessmentForAccount(account_id: number, form_id: string) {
    return this.http.delete(API.APIDOMAIN + this.api.accounts + `/${ account_id }/assessment/${ form_id }`, this.auth.httpOptions);
  }


  /**
   * Remove assessment library from account
   *
   * DELETE /accounts/{accountId}/assessment_library/{assessmentLibraryId}
   * @param accountId
   * @param assessmentLibraryId
   */
  removeAssessmentLibraryFromAccount(accountId: number, assessmentLibraryId: number) {
    return this.http.delete(API.APIDOMAIN + this.api.accounts + `/${ accountId }/assessment_library/${ assessmentLibraryId }`, this.auth.httpOptions);
  }

  /**
   * Downloads all account results for specific assessment
   *
   * POST /account/results/download/csv
   */
  masterDownload(body: any) {
    return this.http.post<any>(API.APIDOMAIN + `account/results/download/csv`, body, this.auth.httpOptions);
  }

  /**
   * Create a new account
   *
   * POST /accounts
   * @param account
   */
  updateAccount(accountId: number, status?: number, name?: string, index_name?: string) {
    let body = {};
    if (status == 0 || status == 1) {
      body = {
        status: status
      };
    } else if (name && index_name) {
      body = {
        name: name,
        index_name: index_name
      };
    }
    console.log(body);
    return this.http.post<any>(API.APIDOMAIN + this.api.accounts + `/${ accountId }`, body, this.auth.httpOptions);
  }
}
