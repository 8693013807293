import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Papa } from 'ngx-papaparse';
import { downloadFile } from 'src/app/functions/helpful-functions';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { ResultsOverviewSharedService } from '../../admin-results/results-overview/results-overview-shared.service';

@Component({
  selector: 'ds-emails-preview-dialog',
  templateUrl: './emails-preview-dialog.component.html',
  styleUrls: ['./emails-preview-dialog.component.scss']
})
export class EmailsPreviewDialogComponent implements OnInit {
  displayedColumns: string[] = ['select', 'email', 'status'];
  dataSource = new MatTableDataSource<EmailData>([]);
  selection = new SelectionModel<EmailData>(true, []);


  constructor(
    public dialogRef: MatDialogRef<EmailsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private papa: Papa,
    private invitesService: InvitesService,
    private ros: ResultsOverviewSharedService
  ) {
  }

  ngOnInit() {
    console.group('Preview dialog');
    console.log(this.data);
    console.groupEnd();

    this.dataSource.data = this.data ? (this.data.emails || []) : [];


    if (this.data && this.data.selectedIDs && this.data.selectedIDs.length > 0) {
      this.data.selectedIDs.forEach(id =>
      {
        const email = this.data.emails.find(e => e.id === id || e.invite_id === id);
        if (email) this.selection.select(email);
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  createCSV() {
    const userInviteIds = this.selection.selected.map(user => user.id || user.invite_id);

    this.invitesService.assessmentInvitesExport(userInviteIds).subscribe(result => {
      downloadFile(result, 'text/csv', `${ this.ros.assessment.name }_email_invites`);
    }, error => {
      console.log(error);
    });
  }


  closeWithSelected() {
    console.log(this.selection);
    this.dialogRef.close(this.selection.selected.map(e => e.id || e.invite_id));
  }
}


interface DialogData {
  emails: EmailData[];
  selectedIDs: number[];
  enableSelection: boolean;
  hideDownloadCsv: boolean;
}

// TODO: 31.03.23 refactor this interface in FE2
interface EmailData {
  id: number;
  invite_id: number;
  email: string;
  status: string;
}
