import { Component, Input, OnInit } from '@angular/core';
import { SubmittedAssessment } from 'src/app/main/content/models/admin/results.def';
import { AnonReportPageComponent } from '../../../../anonymous-quiz/anon-report-page/anon-report-page.component';
import { AssessmentResultsComponent } from '../../../../user/assessment-results/assessment-results.component';

@Component({
  selector: 'siemens-details-card',
  templateUrl: './siemens-details-card.component.html',
  styleUrls: ['./siemens-details-card.component.scss']
})
export class SiemensDetailsCardComponent implements OnInit {
  @Input() result: SubmittedAssessment;
  @Input() isAnon: boolean;

  constructor(
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent
  ) { }

  ngOnInit() {
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    !this.isAnon ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }
}
