import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountIndexClass } from 'src/app/constants/router-params';

@Component({
  selector: 'ds-account-switching',
  templateUrl: './account-switching.component.html',
  styleUrls: ['./account-switching.component.scss']
})
export class AccountSwitchingComponent implements OnInit
{
  url: string;
  id: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  )
  { }

  ngOnInit()
  {
    this.url = this.route.snapshot.queryParams.url;
    this.id = this.route.snapshot.queryParams.acc_id;

    /* if (location.hostname.includes('staging') || location.hostname.includes('localhost'))
    {
      switch (+this.id)
      {
        case 1:
          API.APIDOMAIN = BRAZEN_STAGING_API_URL;
          break;
        default:
          alert('Error changing API url');
          break;
      }
    }
    else
    {
      switch (+this.id)
      {
        case 1:
          API.APIDOMAIN = BRAZEN_API_URL;
          break;
        default:
          alert('Error changing API url');
          break;
      }
    } */

    this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/results']);
  }
}
