import { Component, OnInit } from '@angular/core';
import { DesignType } from 'src/app/theme/theme.models';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class FuseForgotPasswordComponent implements OnInit
{
  designTypes = DesignType;

  constructor(
    public themingService: ThemingService
  )
  {}

  ngOnInit()
  {}

}
