import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { AdminResultsService } from 'src/app/main/content/services/admin/admin-results.service';
import { AdminAssessmentResults, UserResult } from 'src/app/main/content/models/admin/results.def';
import { debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'ds-user-autocomplete-search',
  templateUrl: './user-autocomplete-search.component.html',
  styleUrls: ['./user-autocomplete-search.component.scss']
})
export class UserAutocompleteSearchComponent implements OnInit, OnDestroy {
  @Input() formID: string;
  @Output() selectedUser = new EventEmitter<UserResult>();

  searchText = new FormControl();
  filteredUsers$: Observable<UserResult[]>;
  private nextPage$ = new Subject();
  private _onDestroy = new Subject();
  /** Used to check if the input was written in */
  private wasWrittenTo = false;

  constructor(
    private adminResults: AdminResultsService
  ) {
  }

  ngOnInit() {
    // listen for search text changes
    const filter$ = this.searchText.valueChanges.pipe(
      startWith(''),
      tap(value => {
        if (typeof value !== 'string') {
          this.selectedUser.emit(value);
          setTimeout(() => {
            // By emiting the event the user list is reset back to initial list
            this.searchText.setValue('', { emitEvent: this.wasWrittenTo });
            // Set to false to let us know that the input was not written into, only user selected from a list
            this.wasWrittenTo = false;
          }, 0);
        } else // If value is of type string that means that input was written into
        {
          this.wasWrittenTo = true; // Set this to true to let us know that we need to reset the user list
        }
      }),
      debounceTime(200),
      // We want to perform search only when the type is string
      filter(q => typeof q === 'string')
    );

    // There are 2 streams here: the search text changes stream and the nextPage$ (raised by directive at 80% scroll)
    // On every search text change, we issue a backend request starting the first page
    // While the backend is processing our request we ignore any other NextPage emitts (exhaustMap).
    // If in this time the search text changes, we don't need those results anymore (switchMap)
    this.filteredUsers$ = filter$.pipe(
      switchMap(searchString =>
      {
        // Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Until the backend responds, ignore NextPage requests.
          exhaustMap(_ =>
          {
            return this.adminResults.getResultsByFormID(this.formID, {
              _page: currentPage,
              _size: 20,
              _sort: 'first_name',
              _order: 'asc',
              _q: searchString
            }, [], []);
          }),
          tap((data) => currentPage++),
          // Stop if there are no more pages, or no results at all for the current search text.
          takeWhile((p: AdminAssessmentResults) => p.items.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts.items.filter(u => u && u.user && (u.user.first_name || u.user.last_name || u.user.email))), [])
        );
      })
    ); // asyncPipe handles the subscription
  }

  displayWith(user: UserResult) {
    return user ? `${ user.user.first_name } ${ user.user.last_name }` : null;
  }

  onScroll() {
    // This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
