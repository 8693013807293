import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-two-factor-code-dialog',
  templateUrl: './two-factor-code-dialog.component.html',
  styleUrls: ['./two-factor-code-dialog.component.scss']
})
export class TwoFactorCodeDialogComponent implements OnInit, OnDestroy
{
  subs = new Subscription();
  codeControl = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<TwoFactorCodeDialogComponent>,
  ) { }

  ngOnInit()
  {
    this.subs.add(
      this.codeControl.valueChanges.subscribe((code: string) =>
      {
        if (code && code.length >= 6)
        {
          this.dialogRef.close(code);
        }
      })
    );
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }
}
