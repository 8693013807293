import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileDefinition, UpdateUserProfileModel } from 'src/app/main/content/models/user/user.def';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { DemographicsService } from 'src/app/main/content/services/user/demographics.service';
import { CountryModel } from 'src/app/main/content/models/user/demographics.model';
import { TranslocoService } from '@ngneat/transloco';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'ds-demographics-change-card',
  templateUrl: './demographics-change-card.component.html',
  styleUrls: ['./demographics-change-card.component.scss'],
})
export class DemographicsChangeCardComponent implements OnInit, OnDestroy {
  /** Flag used for only initializing controls on first change */
  firstChange = true;
  subs = new Subscription();

  private _profile: ProfileDefinition;
  @Input()
  set profile(v: ProfileDefinition) {
    this._profile = v;
    if (this.firstChange) this.fillFormWithValues(this._profile);
  }

  get profile() {
    return this._profile;
  }

  /** Emitted once the user's demographisc are updated */
  @Output() profileChange = new EventEmitter<ProfileDefinition>();

  countries: CountryModel[] = [];
  allAges: any;
  educations: any;
  jobLevels: any;
  industries: any;
  genders: any;
  isCustomGenders = false; // show custom text input
  ethnicities$: Observable<any>;
  ethnicities = [];
  showEthnicities = true; // Should we show Ethnicities input field? (we won't show if BE returns null)
  isEthnicitiesDisabled = false;
  isCustomEthnicities = false; // show custom text input
  form: FormGroup;
  states: any[] = [];

  get industryFormControl(): FormControl {
    return this.form.get('industry') as FormControl;
  }

  get genderFormControl(): FormControl {
    return this.form.get('gender') as FormControl;
  }

  get genderInputFormControl(): FormControl {
    return this.form.get('gender_input') as FormControl;
  }

  get ethnicitiesFormControl(): FormControl {
    return this.form.get('ethnicities') as FormControl;
  }

  get ethnicityInputFormControl(): FormControl {
    return this.form.get('ethnicity_input') as FormControl;
  }

  get countryFormControl(): FormControl {
    return this.form.get('country') as FormControl;
  }

  get stateFormControl(): FormControl {
    return this.form.get('state') as FormControl;
  }

  get ageFormControl(): FormControl {
    return this.form.get('age') as FormControl;
  }

  get educationFormControl(): FormControl {
    return this.form.get('education') as FormControl;
  }

  get jobLevelFormControl(): FormControl {
    return this.form.get('job_level') as FormControl;
  }

  get cityFormControl(): FormControl {
    return this.form.get('city') as FormControl;
  }

  get regionFormControl(): FormControl {
    return this.form.get('region') as FormControl;
  }

  @ViewChild('ethnicityInput', { static: false })
  ethnicityInput: ElementRef;

  @ViewChild('ethnicitySelect', { static: false })
  ethnicitySelect: MatSelect;

  @ViewChild('genderInput', { static: false })
  genderInput: ElementRef;

  @ViewChild('genderSelect', { static: false })
  genderSelect: MatSelect;

  constructor(
    private fb: FormBuilder,
    private errService: ErrorService,
    private userService: UsersService,
    private helper: HelperService,
    private demoService: DemographicsService,
    private translocoService: TranslocoService
  ) {
    this.createForm();

    this.countryFormControl.valueChanges.pipe(distinctUntilChanged(), skip(1)).subscribe(() => {
      setTimeout(() => {
        this.stateFormControl.setValue(3923);
      });
    });
  }

  ngOnInit() {
    this.loadDemographics();

    this.translocoService.langChanges$.subscribe(res => {
      this.demoService.country.getAllCountries().subscribe(
        result => {
          this.countries = JSON.parse(JSON.stringify(result));
          this.countries = this.countries.filter(c => !(c.name === 'N/A'));
          this.countries.sort((c1, c2) => c1.name.localeCompare(c2.name));
          this.getStates(this.countryFormControl.value);
        },
        err => {
          console.log('Error getting countries', err);
          this.errService.handleHttpErrorForLogedInUser(err);
        }
      );
    });

    this.genderFormControl.valueChanges.pipe().subscribe(value => {
      if (value === 103) {
        this.isCustomGenders = true;
        setTimeout(() => {
          if (this.genderInput) {
            this.genderSelect.close();
            this.genderInput.nativeElement.focus();
          }
        });
      } else {
        this.isCustomGenders = false;
        this.genderInputFormControl.patchValue(null);
      }
    });

    this.ethnicitiesFormControl.valueChanges.pipe().subscribe(value => {
      if (value.includes(9) && value.length > 1) {
        this.ethnicitiesFormControl.patchValue([9]);
        this.isEthnicitiesDisabled = true;
        this.isCustomEthnicities = true;
      }

      if (value.includes(10) && value.length > 1) {
        this.ethnicitiesFormControl.patchValue([10]);
        this.isEthnicitiesDisabled = true;
        this.isCustomEthnicities = false;
      }

      if (value.includes(9)) {
        this.isCustomEthnicities = true;
        setTimeout(() => {
          if (this.ethnicityInput) {
            if (this.ethnicitySelect) this.ethnicitySelect.close();
            this.ethnicityInput.nativeElement.focus();
          }
        });
      } else {
        this.isCustomEthnicities = false;
        this.ethnicityInputFormControl.patchValue(null);
      }

      if (value.includes(10)) {
        this.isCustomEthnicities = false;
        this.ethnicityInputFormControl.patchValue(null);
        if (this.ethnicitySelect) this.ethnicitySelect.close();
      }

      if (!value.includes(9) && !value.includes(10)) {
        this.isEthnicitiesDisabled = false;
      } else {
        this.isEthnicitiesDisabled = true;
      }
    });

    this.countryFormControl.valueChanges
      .pipe(
        map(countryId => {
          const selectedCountry = this.countries.find(country => country.id === countryId);
          this.states = selectedCountry.states;

          return countryId;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  patchEthnicitiesControlValue(id: number) {
    if ((id === 9 || id === 10) && this.form.controls.ethnicities.value.length === 1) {
      this.form.controls.ethnicities.value !== id
        ? this.form.controls.ethnicities.patchValue([id])
        : this.form.controls.ethnicities.patchValue([]);
    }
  }

  private createForm() {
    this.form = this.fb.group({
      industry: [null],
      city: [null],
      gender: [null],
      gender_input: [null],
      country: [null],
      state: [null],
      region: [null],
      age: [null],
      education: [null],
      ethnicities: [null],
      ethnicity_input: [null],
      job_level: [null],
    });
  }

  /**
   * Fills the form controls with user details when profile object changes
   * @param profile
   */
  private fillFormWithValues(profile: ProfileDefinition) {
    const na = 'N/A';
    const industry = profile.industry ? profile.industry.id : 0;

    if (profile.gender.id === 103) {
      this.isCustomGenders = true;
    }
    const gender = profile.gender ? profile.gender.id : 0;
    const gender_input = profile.gender_input ? profile.gender_input : null;
    if (profile.ethnicities.length === 0) {
      const ethnicities = [];
      this.ethnicitiesFormControl.setValue(ethnicities, { emitEvent: false });
    } else {
      const ethnicities = profile.ethnicities ? profile.ethnicities : [];
      this.ethnicitiesFormControl.setValue(
        ethnicities.map(e => {
          if (e.id === 9) {
            this.isEthnicitiesDisabled = true;
            this.isCustomEthnicities = true;
          }

          return e.id;
        }),
        { emitEvent: false }
      );
    }
    const ethnicity_input = profile.ethnicity_input ? profile.ethnicity_input : '';
    const country = profile.country ? profile.country.id : 0;
    const state = profile.state ? profile.state.id : 0;
    const age = profile.age ? profile.age.id : 0;
    const education = profile.education ? profile.education.id : 0;
    const job = profile.job_level ? profile.job_level.id : 0;
    const city = profile.city ? (profile.city === na ? null : profile.city) : null;
    const region = profile.region ? (profile.region === na ? null : profile.region) : null;

    this.industryFormControl.setValue(industry, { emitEvent: false });
    this.genderFormControl.setValue(gender, { emitEvent: false });
    this.genderInputFormControl.setValue(gender_input, { emitEvent: false });

    this.ethnicityInputFormControl.setValue(ethnicity_input, { emitEvent: false });
    this.countryFormControl.setValue(country, { emitEvent: false });
    this.stateFormControl.setValue(state);
    this.ageFormControl.setValue(age, { emitEvent: false });
    this.educationFormControl.setValue(education, { emitEvent: false });
    this.jobLevelFormControl.setValue(job, { emitEvent: false });
    this.cityFormControl.setValue(city, { emitEvent: false });
    this.regionFormControl.setValue(region, { emitEvent: false });
    this.firstChange = false;
  }

  private loadDemographics() {
    this.form.disable();

    // Get all industries
    this.demoService.industries.getAllIndustries().subscribe((res: any) => {
      // Filter out the N/A choice
      const industriesResponse = res.filter(j => !(j.name === 'N/A'));

      this.translocoService.langChanges$.subscribe(() => {
        // translate each industry name by translation key
        this.industries = industriesResponse.map(industry => {
          return {
            id: industry.id,
            name: this.translocoService.translate('demographics.industries.' + industry.translateKey),
          };
        });

        // sort industries by locale
        const localeSort = (a, b) => a.name.localeCompare(b.name);

        // sort industries in alphabetical order
        this.industries = this.industries.sort(localeSort);
      });
    });

    this.demoService.country.getAllCountries().subscribe(
      result => {
        this.countries = JSON.parse(JSON.stringify(result));
        this.countries = this.countries.filter(c => !(c.name === 'N/A'));
        this.countries.sort((c1, c2) => c1.name.localeCompare(c2.name));
        this.getStates(this.countryFormControl.value);
      },
      err => {
        console.log('Error getting countries', err);
        this.errService.handleHttpErrorForLogedInUser(err);
      }
    );

    // Get all the ages
    this.demoService.age.getAllAges(`${this.profile.id}`).subscribe((res: any) => {
      this.allAges = res;
      this.allAges = this.allAges.filter(a => !(a.name === 'N/A')); // Filter out the N/A choice
    });

    // Get all the educations
    this.demoService.education.getAllEducations().subscribe((res: any) => {
      this.educations = res;
      this.educations = this.educations.filter(e => !(e.name === 'N/A')); // Filter out the N/A choice
    });

    // Get all job levels
    this.demoService.job.getAllLevels().subscribe((res: any) => {
      this.jobLevels = res;
      this.jobLevels = this.jobLevels.filter(j => !(j.name === 'N/A')); // Filter out the N/A choice
    });

    // Get all genders
    this.demoService.genders.getAllGenders(`${this.profile.id}`).subscribe((res: any) => {
      this.genders = res;
      this.genders = this.genders.filter(j => !(j.name === 'N/A')); // Filter out the N/A choice
    });

    this.demoService
      .getEthnicities(`${this.profile.id}`)
      .pipe(
        map(value => {
          console.log(value);
          this.ethnicities = value;
          if (value === null) this.showEthnicities = false;
        })
      )
      .subscribe();

    this.form.enable();
  }

  updateProfile() {
    if (this.form.invalid) return;

    this.form.disable();

    const p: UpdateUserProfileModel = {
      user_id: this.profile.user.id,
      industry: this.industryFormControl.value,
      gender: this.genderFormControl.value,
      gender_input: this.genderInputFormControl.value,
      ethnicities: this.ethnicitiesFormControl.value,
      ethnicity_input: this.ethnicityInputFormControl.value,
      country: this.countryFormControl.value,
      state: this.stateFormControl.value,
      age: this.ageFormControl.value,
      education: this.educationFormControl.value,
      job_level: this.jobLevelFormControl.value,
      city: this.cityFormControl.value ? this.cityFormControl.value.trim() : null,
      region: this.regionFormControl.value ? this.regionFormControl.value.trim() : null,
    };

    this.subs.add(
      this.userService.updateProfile(this.profile.id, p).subscribe(
        res => {
          console.log('Profile update response:', res);
          this.helper.showAlert(this.translocoService.translate('demographics.demographicsUpdated'));

          this.form.enable();
          this.profileChange.emit(res);
        },
        err => {
          console.log('Error updating profile', err);
          this.form.enable();
          this.errService.handleHttpErrorForLogedInUser(err);
        }
      )
    );
  }

  getStates(id: number) {
    const selectedCountry = this.countries.find(d => d.id === id);

    if (selectedCountry && selectedCountry.states) {
      this.states = selectedCountry.states;
    }
  }

  getState(country: CountryModel, state_id: number) {
    return country.states.find(d => d.id === state_id);
  }
}
