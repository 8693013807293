import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DemographicsFormData } from 'src/app/main/content/pages/shared/tsq-assesment/components/tsq-demographics/tsq-demographics.component';
import { CountryModel, StateModel, TranslateKeyItem } from 'src/app/main/content/models/user/demographics.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { TsqStore } from 'src/app/main/content/pages/shared/tsq-assesment/tsq.store';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'demographics-form-two',
  templateUrl: './demographics-form-two.component.html',
  styleUrls: ['./demographics-form-two.component.scss']
})
export class DemographicsFormTwoComponent implements OnInit {
  form: FormGroup;


  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _store: TsqStore
  ) {
    this.form = this.createForm();
  }


  @Input()
  states: StateModel[];

  private _formData: DemographicsFormData;
  @Input()
  set formData(data: DemographicsFormData) {
    this._formData = data;
    if (!data) return;
    this.industryFormControl.setValue(data.industry);
    this.stateFormControl.setValue(data.state);
    this.regionFormControl.setValue(data.region);
    this.countryFormControl.setValue(data.country);
    this.cityFormControl.setValue(data.city);
    this.companyRevenueFormControl.setValue(data.company_revenue);
    this.companySizeFormControl.setValue(data.company_size);

  }

  @Input()
  countries: Array<CountryModel> = [];

  @Input()
  industries: Array<TranslateKeyItem> = [];

  @Input()
  companyRevenue: Array<TranslateKeyItem> = [];

  @Input()
  companySize: Array<TranslateKeyItem> = [];

  @Input()
  pending: boolean = false;

  @Input()
  skipPending: boolean = false;

  @Output()
  submit: EventEmitter<DemographicsFormData> = new EventEmitter<DemographicsFormData>();

  @Output()
  skip: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  back: EventEmitter<DemographicsFormData> = new EventEmitter<DemographicsFormData>();


  get industryFormControl(): FormControl {
    return this.form.get('industry') as FormControl;
  }

  get countryFormControl(): FormControl {
    return this.form.get('country') as FormControl;
  }

  get stateFormControl(): FormControl {
    return this.form.get('state') as FormControl;
  }


  get regionFormControl(): FormControl {
    return this.form.get('region') as FormControl;
  }

  get cityFormControl(): FormControl {
    return this.form.get('city') as FormControl;
  }

  get companySizeFormControl(): FormControl {
    return this.form.get('company_size') as FormControl;
  }

  get companyRevenueFormControl(): FormControl {
    return this.form.get('company_revenue') as FormControl;
  }


  get countryStates$(): Observable<StateModel[]> {
    return this.countryFormControl.valueChanges.pipe(
      map((id) => {
        const idx = this.countries.map(countryItem => countryItem.id).indexOf(id);
        if (idx === -1) return [];
        return this.countries[idx].states;
      })
    );
  }


  private createForm(): FormGroup {
    // Industry, Country, Region, City, Company size
    return this._fb.group({
      industry: this._fb.control(null, []),
      country: this._fb.control(null, []),
      state: this._fb.control(null, []),
      region: this._fb.control(null, []),
      city: this._fb.control(null, []),
      company_size: this._fb.control(null, []),
      company_revenue: this._fb.control(null, []),
    });
  }

  ngOnInit() {
    this.countryStates$.subscribe((states) => {
      this._store.states = states;
    }, ((error) => {
      this._store.states = null;
    }));

    this.countryFormControl.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(() => {
      this.stateFormControl.setValue(null);
      this.cityFormControl.setValue(null);
    });

  }

  onSubmit(value: DemographicsFormData) {
    this.submit.emit({ ...value, id: this._formData.id });
  }
}
