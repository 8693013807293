import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiDefinition } from '../../models/api/api.def';
import { EmailTemplate, CreateEmailTemplateModel } from '../../models/admin/email-templates.def';
import { API } from 'src/app/api-url/api-urls';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService
{
  api: ApiDefinition;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  )
  {
    this.api = this.auth.api;
  }

  /**
   * Get all email templates
   *
   * GET /emailtemplates
   */
  getTemplates(account: string)
  {
    const options = this.auth.httpOptions;
    if (account) options.params = { account };

    return this.http.get<EmailTemplate[]>(API.APIDOMAIN + this.api.inviteTemplates, options);
  }

    /**
   * Get all email templates
   *
   * POST /emailtemplates/{assessmentID}
   */
  getTemplatesByShareOptions(assessmentFormId: string)
  {
    const options = this.auth.httpOptions;
   // if (assessmentFormId) options.params = { assessmentFormId };

    return this.http.post<EmailTemplate[]>(API.APIDOMAIN + this.api.inviteTemplates + `/${assessmentFormId}`, {}, options);
  }

  /**
   * Get all admin notification email templates
   *
   * GET /emailtemplates/admin/notification
   */
  getNotificationEmailTemplates()
  {
    return this.http.get<EmailTemplate[]>(API.APIDOMAIN + this.api.inviteTemplates + '/admin/notification', this.auth.httpOptions);
  }

  /**
   * Create a new email template
   *
   * POST /emailtemplates
   * @param template
   */
  createTemplate(template: CreateEmailTemplateModel, account: string)
  {
    const options = this.auth.httpOptions;
    if (account) options.params = { account };

    return this.http.post<EmailTemplate>(API.APIDOMAIN + this.api.inviteTemplates, template, options);
  }

  /**
   * Update an existing email template
   *
   * PUT /emailtemplates/{id}
   */
  updateTemplate(template: EmailTemplate)
  {
    const options = this.auth.httpOptions;

    return this.http
      .put<EmailTemplate>(API.APIDOMAIN + this.api.inviteTemplates + `/${template.id}`, template, options);
  }

  /**
   * Delete an existing email template
   *
   * DELETE /emailtemplates/{id}
   * @param id
   */
  deleteTemplate(id: number)
  {
    const options = this.auth.httpOptions;

    return this.http.delete(API.APIDOMAIN + this.api.inviteTemplates + `/${id}`, options);
  }
}
