import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ScoreWhoAreYou, TemplateTypes } from '../../../models/user/user-results.def';
import { UserModel } from '../../../models/user/user.def';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemingService } from 'src/app/theme/theming.service';
import { AnonUserService } from '../../../services/user/anon-user.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import {
  AnonLoginRegisterDialogComponent
} from '../../shared/dialogs/anon-login-register-dialog/anon-login-register-dialog.component';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { Facebook, LinkedIN, Twitter } from 'src/app/functions/share';
import { ShareService } from 'src/app/main/content/pages/share/share-services/share.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { ShareImageComponent } from 'src/app/main/content/pages/shared/components/share-image/share-image.component';
import html2canvas from 'html2canvas';
import { downloadFile } from 'src/app/functions/helpful-functions';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { AppStore } from 'src/app/app.store';
import { API } from 'src/app/api-url/api-urls';

@Component({
  selector: 'ds-anon-report-page',
  templateUrl: './anon-report-page.component.html',
  styleUrls: ['./anon-report-page.component.scss']
})
export class AnonReportPageComponent extends BaseRouteParamComponent implements OnInit, OnDestroy, AfterViewChecked {
  loading = false;
  subs = new Subscription();
  socialShareInProgress = false;
  shareImageFirstLoad = false;
  provider: 'facebook' | 'twitter' | 'linkedin' | 'plain';

  formID: string;
  result: any;
  chart: string;
  _user: UserModel = null;
  set user(value: UserModel) {
    this._user = value;
  }

  get user(): UserModel {
    return this._user || this.auth.user || this.anonService.anonUser;
  }

  profilePicture: string;

  reportTypes = TemplateTypes;

  primaryDrivers: ScoreWhoAreYou[] = [];

  shareImage = { title: <string> '', icons: <string[]> [], text: <string> '' };

  @ViewChild('shareImgCmp', { static: false }) shareImgCmp: ShareImageComponent;
  exportPdfLoading: boolean;
  step: 'login' | 'register' = null;
  incognito$: Observable<boolean>;

  constructor(
    protected routerParams: RouterParamsService,
    private route: ActivatedRoute,
    private router: Router,
    public themingService: ThemingService,
    public anonService: AnonUserService,
    private errService: ErrorService,
    private title: Title,
    private dialog: MatDialog,
    public auth: AuthService,
    private shareService: ShareService,
    private helper: HelperService,
    private userService: UsersService,
    private appStore: AppStore
  ) {
    super(routerParams);
    this.shareService.exportPdf$.subscribe(() => {
      if (!this.auth.isLoggedIn()) {
        this.exportAsPdf();
      }


    });
    this.incognito$ = this.appStore.incognito$;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.anonService.anonToken || !this.anonService.anonUser || !this.anonService.assessment) {
      window.location.href = 'https://www.manpowergroup.com/';
      return;
    }

    this.formID = this.route.snapshot.params.formID;

    this.getUserReport();


  }

  ngAfterViewChecked() {
    if (this.shareImgCmp && this.shareImageFirstLoad) {
      this.shareImageFirstLoad = false;
      this.shareReport(this.provider);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.title.setTitle(this.themingService.title);
  }


  get showSaveYourResultsButton(): boolean {
    return this.result.user_report_template !== 'stg_assessment';
  }

  private getUserReport() {
    this.loading = true;

    this.anonService.getAnonUserResult(this.formID, this.anonService.anonUser.id, this.anonService.anonToken)
      .subscribe(res => {
        console.log('Anon user report:', res);
        this.result = res;
        const href = res.score.scores.calculate.href;
        if (!this.chart && href) {
          this.chart = href.replace('%media_url%', API.MEDIA_API);
        }
        this.user = this.anonService.anonUser;
        this.getUserDetails();

        if (res.user_report_template === TemplateTypes.whoAreYou) {
          this.primaryDrivers = (<ScoreWhoAreYou[]> res.score).filter(score => score.drive_type === 1);
        }

        this.loading = false;

        this.title.setTitle(`Your Report for ${ this.anonService.assessment.assessment.name } - ${ this.themingService.title }`);

      }, err => {
        console.log('Error getting anon user report', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedOutUser(err);
      });

  }


  saveUserResults() {
    const dialog_ref = this.dialog.open(AnonLoginRegisterDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      data: this.user.id,
      maxWidth: 400
    });


    dialog_ref.afterClosed().subscribe(dialog_res => {
      console.log('Dialog response:', dialog_res);

      if (dialog_res) {
        this.getUserDetails();
      }
    });
  }

  createShareImage(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    const self = this;
    this.provider = provider;

    switch (this.result.user_report_template) {
      case this.reportTypes.lq:
        this.shareImage.title = this.result.score.scores.calculate.header.intro_title;
        const arrayOfShortTraitDescriptions = Object.keys(
          this.result.score.scores.calculate.body
        ).map(
          (key) =>
            this.result.score.scores.calculate.body[key][
              'ratingshortdescription'
              ]
        );
        arrayOfShortTraitDescriptions.forEach((trait) => {
          this.shareImage.text += trait + ' ';
        });
        Object.keys(this.result.score.scores.calculate.body).forEach(function (
          key
        )
        {
          self.shareImage.icons.push(
            `lq-report/${ self.result.score.scores.calculate.body[key].ratingicone }`
          );
        });
        break;

      case this.reportTypes.sis:
        this.shareImage.title = this.result.score.scores.calculate.header.title;
        this.shareImage.text = this.result.score.scores.calculate.header.text;
        this.result.score.scores.calculate.header.icone.forEach((icon) => {
          this.shareImage.icons.push(`skills-insight-report/${ icon }`);
        });
        break;

      case this.reportTypes.stg:
        this.shareImage.title = this.result.score.scores.calculate.header.title;
        this.shareImage.text = this.result.score.scores.calculate.header.text;
        this.result.score.scores.calculate.body.forEach(res => {
          this.shareImage.icons.push(res.icone);
        });
        break;
    }

    this.socialShareInProgress = true;
    this.shareImageFirstLoad = true;
  }


  shareReport(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    this.helper.showAlert('Creating link', '', { duration: 5000 });
    const userId = this.user.id;
    const userTokenKey = this.user.token_key;
    const assessmentId = this.formID;

    const self = this;
    let shareUrl: string;
    let badgeImage: any;

    html2canvas(document.querySelector('.share-image'), {
      width: 500,
      height: 260,
      onclone: function (doc) {
        doc.getElementById('share-image').style.visibility = 'visible';
      }
    }).then(function (canvas) {
      badgeImage = canvas.toDataURL();
      self.subs.add(
        self.shareService
          .shareUserBadgeImage(badgeImage, assessmentId, userId, userTokenKey)
          .subscribe((res) => {
            shareUrl = res.page;
            self.socialShareInProgress = false;
            switch (provider) {
              case 'facebook':
                Facebook.share(shareUrl);
                break;

              case 'twitter':
                Twitter.tweet(shareUrl);
                break;

              case 'linkedin':
                LinkedIN.share(shareUrl);
                break;

              default:
                break;
            }
          })
      );
    });
  }

  private getUserDetails() {
    this.loading = true;


    this.userService.getUserById(this.user.id, this.anonService.anonToken).subscribe(res => {
      console.log('Get User details response: ', res);
      this.user = res;
      this.user.token_key = this.anonService.anonToken;
      this.user.refresh_token = this.anonService.anonRefreshToken;

      if (this.user.status === 'NORMAL') {
        this.step = 'login';
      } else {
        this.step = 'register';
      }

      this.loading = false;

    }, err => {
      console.log('Get User error:', err);
      this.loading = false;
    });

  }


  exportAsPdf() {
    this.shareService.exportLoading = true;
    this.exportPdfLoading = true;
    console.log('USER', this.user);
    if (!!!this.user) {
      console.log('User is undefined', this.user, this.auth.user, this.anonService.anonUser);
      return;
    }

    const getUserId = () => {
      if (this.auth.user && this.auth.user.id) {
        return this.auth.user.id;
      }

      return this.anonService.anonUser.id;
    };
    const createShareToken$ = this.shareService.createShareToken(getUserId(), this.formID, this.user.token_key);
    createShareToken$.subscribe(res => {
      this.shareService.exportAsPdf(this.user.token_key, res.token).subscribe((result: any) => {
        downloadFile(
          result.body,
          result.headers.get('content-type'),
          result.headers.get('content-disposition').split('=')[1]
        );
        this.exportPdfLoading = false;
        this.shareService.exportLoading = false;

      }, err => {
        console.log('PDF export Error', err);
        this.errService.handleHttpErrorForLogedInUser(err);
        this.exportPdfLoading = false;
        this.shareService.exportLoading = false;
      });

    }, err => {
      console.log('Share Error', err);
      this.errService.handleHttpErrorForLogedInUser(err);
      this.exportPdfLoading = false;
      this.shareService.exportLoading = false;
    });

  }
}
