import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsersInviteDialogComponent } from '../../dialogs/users-invite-dialog/users-invite-dialog.component';

@Component({
  selector: 'ds-users-invite-button',
  templateUrl: './users-invite-button.component.html',
  styleUrls: ['./users-invite-button.component.scss']
})
export class UsersInviteButtonComponent implements OnInit
{
  @Input() formID: string;
  @Input() userInviteCount: number;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit()
  {
  }

  openSendInvitesDialog()
  {
    const ref = this.dialog.open(UsersInviteDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      minWidth: 250,
      data: { formID: this.formID, emailCount: this.userInviteCount }
    });

    ref.afterClosed().subscribe(dialog_res =>
    {
      console.log('Dialog res:', dialog_res);
      if (dialog_res)
      {
        this.userInviteCount -= dialog_res.emailsUsed;
      }
    });
  }
}
