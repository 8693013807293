import { config } from 'src/config';

/**
 * API class that controls the apidomain
 */
export class API
{
  /** Ends with a '/' */
  public static APIDOMAIN = config.api_url;
  public static APIINTERNAL = config.api_internal;
  public static SHAREDOMAIN = config.share_url;
  public static QUIZ_API = config.quiz_api_url;
  public static MEDIA_API = config.media_url;
  public static CAT_CONFIG = config.concerto;
  public static MEDIADOMAIN = config.concerto.media_domain;
}
