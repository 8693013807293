import {AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SidenavService} from './main-service/sidenav.service';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthService} from './content/services/auth/auth.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'ds-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  @ViewChild('sidenavLeft', {static: true}) sidenavLeft: MatSidenav;
  @ViewChild('sidenavRight', {static: true}) sidenavRight: MatSidenav;

  isAuth = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public sidenavService: SidenavService,
    private auth: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher
  ) {
  }

  ngOnInit() {
    this.sidenavService.setLeftSidenav(this.sidenavLeft);
    this.sidenavService.setRightSidenav(this.sidenavRight);

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      this.changeDetectorRef.detectChanges();
      if (this.mobileQuery.matches) {
        this.sidenavService.leftSidenav.opened = false;
        this.sidenavService.isCollapsed = false;
        this.sidenavService.canBeCollapsed = false;
      } else {
        this.sidenavService.leftSidenav.opened = true;
        this.sidenavService.canBeCollapsed = true;
      }
    };
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidenavService.getSavedPositions();
      this.sidenavService.getSavedModes();
      if (this.auth.isLoggedIn() && !this.mobileQuery.matches) this.sidenavService.openSidenavIfSideMode();
    }, 0);
  }

  ngDoCheck() {
    this.isAuth = this.auth.isLoggedIn();
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
