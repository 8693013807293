import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';


@Component({
  selector: 'ds-stg-demo-form',
  templateUrl: './stg-demo-form.component.html',
  styleUrls: [ './stg-demo-form.component.scss' ]
})
export class StgDemoFormComponent implements OnInit {
  @Input()
  handleCustomAssessmentStyles: boolean = true;
  @Input()
  countries: any;
  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;


  get emailFormControl(): FormControl {
    return this.form.get('email') as FormControl;
  }

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.createForm();
  }

  ngOnInit() {
  }

  private createForm() {
    return this.fb.group({
      firstName: [ '', Validators.compose([ Validators.required ]) ],
      lastName: [ '', Validators.compose([ Validators.required ]) ],
      email: [ '', Validators.compose([ Validators.pattern(HelperService.EMAIL_REGEX), Validators.required ]) ],
      country: [ null, Validators.compose([ Validators.required ]) ],
    });
  }
}
