import { Component, OnInit, Input } from '@angular/core';
import { VisualRepresentation } from 'src/app/main/content/models/user/user-results.def';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ds-visual-representation',
  templateUrl: './visual-representation.component.html',
  styleUrls: ['./visual-representation.component.scss']
})
export class VisualRepresentationComponent implements OnInit
{
  _data: VisualRepresentation;
  @Input()
  set data(v: VisualRepresentation) { this._data = v; }
  get data() { return this._data; }


  private _showValue: boolean = true;
  @Input()
  set showValue(v: boolean)
  {
    this._showValue = coerceBooleanProperty(v);
  }
  get showValue() { return this._showValue; }

  constructor() { }

  ngOnInit()
  {
  }

}
