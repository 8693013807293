import { Component, HostBinding } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForgotPasswordComponent } from '../base-forgot-password.component';
import { fuseAnimations } from 'src/app/animations/animations';
import { ThemingService } from 'src/app/theme/theming.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';

@Component({
  selector: 'ds-forgot-password-design1',
  templateUrl: './forgot-password-design1.component.html',
  styleUrls: ['./forgot-password-design1.component.scss'],
  animations: fuseAnimations
})
export class ForgotPasswordDesign1Component extends BaseForgotPasswordComponent
{
  @HostBinding('style.backgroundImage') bgIMG: string;
  @HostBinding('style.backgroundColor') bgColor: string;

  constructor(
    public themingService: ThemingService,
    protected authService: AuthService,
    protected helper: HelperService,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected routerParams: RouterParamsService
  )
  {
    super(authService, helper, router, formBuilder, routerParams);

    if (this.themingService.bgGradient)
    {
      this.bgColor = this.themingService.bgGradient;
    }
    else if (this.themingService.bgGradientPicture)
    {
      this.bgIMG = `url('${themingService.bgGradientPicture}')`;
    }
  }
}
