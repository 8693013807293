import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-core-drivers-team-skills-table',
  templateUrl: './core-drivers-team-skills-table.component.html',
  styleUrls: ['./core-drivers-team-skills-table.component.scss']
})
export class CoreDriversTeamSkillsTableComponent implements OnInit
{

  constructor() { }

  ngOnInit()
  {
  }

}
