import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VerifyService } from 'src/app/main/content/services/auth/verify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { BaseRouteParamComponent } from 'src/app/shared/base-route-param.component';
import { RouterParamsService } from 'src/app/main/main-service/router-params.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';

@Component({
  selector: 'ds-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent extends BaseRouteParamComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  token: string;
  loading = true;

  constructor(
    private verifyService: VerifyService,
    private route: ActivatedRoute,
    private router: Router,
    private errService: ErrorService,
    private helper: HelperService,
    private auth: AuthService,
    protected routerParams: RouterParamsService
  ) {
    super(routerParams);
  }

  ngOnInit() {
    super.ngOnInit();


    this.token = this.route.snapshot.params.token;
    console.log('Verify token', this.token);

    this.verifyToken();
  }


  private verifyToken() {
    this.loading = true;


    this.verifyService.checkEmailVerificationToken(this.token).subscribe((res: any) => {
      if (this.auth.isLoggedIn()) this.auth.logout(true);
      console.log('Verify response:', res);

      if(res.hasOwnProperty('hide')) {
        if(res.hide.social_login === true) {
          localStorage.setItem('show_social_login', 'false');
        }
      }

      const email = res.email || 'admin@q.agency';
      this.helper.showAlertWithouAutoDismiss(res.message);
      this.router.navigate(
        [this.accountNameParam + '/login'],
        { queryParams: { email } }
      );
      localStorage.removeItem('verify_email_msg');
      this.loading = false;
    }, err =>
    {
      console.log('Verify error:', err);
      this.loading = false;
      this.errService.handleHttpErrorForLogedOutUser(err);
      if (this.auth.isLoggedIn()) {
        this.router.navigate([this.accountNameParam + '/']);
      } else {
        this.router.navigate([this.accountNameParam + '/login']);
      }
    });
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
