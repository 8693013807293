import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TsqStore } from '../../tsq.store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { ProfileDefinition, UpdateUserProfileModel, UserModel } from '../../../../../models/user/user.def';
import { CountryModel, StateModel, TranslateKeyItem } from '../../../../../models/user/demographics.model';
import { UsersService } from '../../../../../services/admin/users.service';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { AnonUserService } from '../../../../../services/user/anon-user.service';
import { concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../../services/auth/auth.service';
import { ErrorService } from '../../../../../services/helpers/error.service';

export interface DemographicsFormData {
  id?: number;
  job_title?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  company?: string;

  industry?: number;
  country?: number;
  city?: string;
  company_size?: number;
  company_revenue?: number;
  region?: string;
  state?: number;
  terms?: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-demographics',
  templateUrl: './tsq-demographics.component.html',
  styleUrls: ['./tsq-demographics.component.scss']
})
export class TsqDemographicsComponent implements OnInit, OnDestroy {
  countries$: Observable<Array<CountryModel>>;
  industries$: Observable<Array<TranslateKeyItem>>;
  companyRevenue$: Observable<Array<TranslateKeyItem>>;
  companySize$: Observable<Array<TranslateKeyItem>>;
  formData$: Observable<DemographicsFormData>;
  showFormTwo$: Observable<boolean>;
  states$: Observable<StateModel[]>;
  locale$: Observable<string>;
  locale: string;
  assessmentId: string;
  pending: boolean = false;
  skipPending: boolean = false;

  @Output()
  redirect: EventEmitter<void> = new EventEmitter<void>();
  private updateUser$: Observable<UserModel>;
  private updateProfile$: Observable<ProfileDefinition>;
  private readonly unsubscribe$: Subject<void> = new Subject();


  constructor(
    private _store: TsqStore,
    private _userService: UsersService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _anonService: AnonUserService,
    private _auth: AuthService,
    private _err: ErrorService
  ) {
    this.countries$ = this._store.countries$;
    this.industries$ = this._store.industries$;
    this.companyRevenue$ = this._store.companyRevenue$;
    this.companySize$ = this._store.companySize$;
    this.showFormTwo$ = this._store.showDemoFormTwo$;
    this.formData$ = this._store.demoFormData$;
    this.states$ = this._store.states$;
    this.locale$ = this._store.locale$;
  }


  ngOnInit() {
    this.assessmentId = this._auth.isLoggedIn() ? this._activatedRoute.snapshot.paramMap.get('id') : this._store.formID;
    this.locale$
    .pipe(
      takeUntil(this.unsubscribe$),
      map( locale => this.locale = locale )
    )
    .subscribe();
  }

  onSubmitOne(data: DemographicsFormData) {
    this._store.setDemographicsFormData(data);
    delete data.terms;

    console.assert(!!data.id, 'Missing user id for update User');


    const userData: UserModel = {
      ...data,
      locale: this.locale,
      profile: {
        company: data.company,
        image_path: null,
        image_file: null,
        job_title: data.job_title
      }
    };


    this.updateUser$ = of(this._auth.isLoggedIn()).pipe(
      switchMap(
        (loggedIn) => loggedIn ? this._userService.updateUser(userData) : this._anonService.updateAnonUser({
          ...userData,
          id: data.id
        })
      )
    );

    this._store.showDemoFormTwo = true;

  }

  onSubmitTwo(data: any) {
    this._store.setDemographicsFormData(data);
    const profileData: UpdateUserProfileModel = {
      ...data,
      company_size: data.company_size ? data.company_size : null,
      company_revenue: data.company_revenue ? data.company_revenue : null,
      country: data.country ? data.country : null,
      industry: data.industry ? data.industry : null,
      state: data.state ? data.state : null,
      region: data.region
    };

    console.log(data.id, 'Profile id');


    const updateProfile$: Observable<ProfileDefinition> = this._userService.updateProfile(data.id, profileData);
    const updateProfileAnon$ = this._userService.updateAnonProfile(data.id, profileData, this._anonService.anonToken);


    this.updateProfile$ = of(this._auth.isLoggedIn()).pipe(
      switchMap(
        (loggedIn) => loggedIn ? updateProfile$ : updateProfileAnon$
      )
    );


    this.save();

  }

  private save() {
    this.pending = true;
    combineLatest([
      this.updateUser$.pipe(
        concatMap(
          (res) => this._store.getSubmit$()
        )
      ),
      this.updateProfile$
    ]).subscribe(async () => {
      this.pending = false;
      await this.navigateToResults();
    }, (err) => {
      this.pending = false;
      this._err.handleHttpErrorForLogedOutUser(err, {}, false);
    });
  }

  onBack(data: DemographicsFormData) {
    this._store.setDemographicsFormData(data);
    this._store.showDemoFormTwo = false;
  }


  async navigateRelative(queryParams: Params | null, queryParamsHandling: QueryParamsHandling = 'merge'): Promise<boolean> {
    return await this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling
      });
  }

  private async navigateToResults() {
    if (this._auth.isLoggedIn()) {
      await this._router.navigate(['/manpower/user/assessment/', this.assessmentId, 'results']);
    } else {
      await this._router.navigate(['/manpower/report/', this.assessmentId]);

    }
  }


  async onSkip() {
    this.skipPending = true;
    this.updateUser$.pipe(
      concatMap(
        (res) => this._store.getSubmit$()
      )
    ).subscribe(async () => {
      this.skipPending = false;
      await this.navigateToResults();
    }, (err) => {
      this.skipPending = false;
      this._err.handleHttpErrorForLogedOutUser(err, {}, false);
    });

  }

  ngOnDestroy(): void {
    this._store.setDemographicsFormData(null);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
