import { Component, OnInit, Input } from '@angular/core';
import { NavLinksModel } from '../models/nav-link.model';
import { SidenavService } from '../../main-service/sidenav.service';

@Component({
  selector: 'ds-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss']
})
export class NavLinksComponent implements OnInit
{
  private _navLinks: NavLinksModel[] = [];
  @Input() set navLinks(links: NavLinksModel[])
  {
    this._navLinks = links;
  }
  get navLinks() { return this._navLinks; }

  constructor(
    public navService: SidenavService
  ) { }

  ngOnInit()
  {
  }

  openLink(link: NavLinksModel)
  {
    if (link.func)
    {
      if (this.navService.leftSidenav.mode !== 'side')
      {
        this.navService.closeLeftSidenav();
      }
      link.func();
    }
    else
    {
      if (this.navService.leftSidenav.mode !== 'side')
      {
        this.navService.closeLeftSidenav();
      }
    }
  }
}
