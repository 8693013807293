import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GearQuestionsComponent } from 'src/app/main/content/pages/shared/gear-assessment/gear-questions.component';
import { GearIntroComponent } from './gear-intro/gear-intro.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GearDefinitionDialogComponent } from './gear-definition-dialog/gear-definition-dialog.component';
import { EnterYourEmailComponent } from './enter-your-email/enter-your-email.component';


@NgModule({
  declarations: [
    GearQuestionsComponent,
    GearIntroComponent,
    GearDefinitionDialogComponent,
    EnterYourEmailComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    GearQuestionsComponent,
    GearIntroComponent,
    GearDefinitionDialogComponent,
    EnterYourEmailComponent
  ],
  entryComponents: [GearDefinitionDialogComponent]

})
export class GearAssesmentModule {
}
