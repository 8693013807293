export const ACCOUNT_NAME = 'accountName';

/**
 * Class for accessing current value of the account_index URL param
 */
export class AccountIndexClass
{
  /** Account index */
  public static ACCOUNT_INDEX = '';
  /**
   * Account index with '/' infront
   *
   * example:
   * ```
   * domain.com/{account_index}/any/other/route
   * ```
   */
  public static ACCOUNT_INDEX_ROUTE_PARAM = '';
}
