
/** Generic api respnse with a code and a message */
export interface GeneralResponse
{
  code:     number;
  message:  string;
}


/**
 * Multi factor response that returns an URL for the QR code image
 */
export interface MFAResponse
{
  message:  string;
  url: string;
}


export enum MfaAction
{
  googleAuthenticator = 'Google_Authenticator'
}

export interface MFALoginResponse
{
  action: MfaAction;
  message: string;
}

export interface SocialMFALoginResponse extends MFALoginResponse
{
  MFAToken: string;
}
