import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'ds-restart-assessment',
  templateUrl: './restart-assessment.component.html',
  styleUrls: ['./restart-assessment.component.scss']
})
export class RestartAssessmentComponent implements OnInit, OnDestroy {
  timeDisplayValue: number;
  timerSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RestartAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public timeLimit: number,
  ) { }

  ngOnInit() {
    this.timeDisplayValue = this.timeLimit;
    const source = timer(0, 1000);

    this.timerSub = source.subscribe(val => {
      this.timeDisplayValue = this.timeLimit - val;
      if (this.timeDisplayValue === 0) {
        this.timerSub.unsubscribe();
        this.dialogRef.close(false);
      }
    });
  }

  dialogAction(result: boolean) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    this.dialogRef.close(result);
  }

  ngOnDestroy() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }
}
