import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (request.responseType === 'blob' && error.error instanceof Blob) {
            return from(Promise.resolve(error).then(async x => {
              throw new HttpErrorResponse({
                error: JSON.parse(await x.error.text()),
                headers: x.headers,
                status: x.status,
                statusText: x.statusText,
                url: x.url
              });
            }));
          }
          return throwError(error);
        })
      );
  }
}
