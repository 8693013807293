import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ds-retake-quiz-dialog',
  templateUrl: './retake-quiz-dialog.component.html',
  styleUrls: ['./retake-quiz-dialog.component.scss']
})
export class RetakeQuizDialogComponent implements OnInit
{

  constructor(
    public dialogRef: MatDialogRef<RetakeQuizDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit()
  {
  }

}
