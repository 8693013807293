import { Component, OnInit, Input } from '@angular/core';
import { ScoreWhoAreYou, WhoAreYouResult } from 'src/app/main/content/models/user/user-results.def';
import { AssesmentResultModel } from 'src/app/main/content/models/admin/assesment.def';
import { MoreDetailsDialogComponent } from '../../dialogs/more-details-dialog/more-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-who-are-you-user-report',
  templateUrl: './who-are-you-user-report.component.html',
  styleUrls: ['./who-are-you-user-report.component.scss']
})
export class WhoAreYouUserReportComponent implements OnInit
{
  primaryDrivers: ScoreWhoAreYou[] = [];
  secondaryDrivers: ScoreWhoAreYou[] = [];

  _result: WhoAreYouResult;
  @Input() set result(value: WhoAreYouResult)
  {
    this._result = value;

    this.primaryDrivers = [];
    this.secondaryDrivers = [];

    this._result.score.forEach(element =>
    {
      if (element.drive_type === 1)
      {
        this.primaryDrivers.push(element);
      }
      else if (element.drive_type === 2)
      {
        this.secondaryDrivers.push(element);
      }
    });
  }

  expandAll = false;

  _showThumbs = true;
  @Input()
  set showThumbs(v: boolean) { this._showThumbs = v; }
  get showThumbs() { return this._showThumbs; }

  constructor(
    private dialog: MatDialog,
    public themingService: ThemingService,
  )
  { }

  ngOnInit()
  {}

  /**
   * Open a dialog and show secondary driver details
   * @param result {AssesmentResultModel}
   */
  moreButtonPress(result: AssesmentResultModel)
  {
    this.dialog.open(MoreDetailsDialogComponent, { data: result });
  }
}
