import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {API} from '../../api-url/api-urls';
import {AccountIndexClass} from '../../constants/router-params';
import {HelperService} from '../../main/content/services/helpers/helper.service';
import {AuthService} from '../../main/content/services/auth/auth.service';
import {ROLE_ADMIN} from '../../constants/user.role';
import {SidenavService} from '../../main/main-service/sidenav.service';

@Component({
  selector: 'ds-aouth',
  templateUrl: './sso-aouth.component.html',
  styleUrls: ['./sso-aouth.component.scss']
})
export class SsoAouthComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private helper: HelperService,
    private auth: AuthService,
    private sideNavService: SidenavService
  ) {
    const params$: Observable<any> = this.route.queryParams;

    params$.pipe(
      first(),
      switchMap(
        (params) => this.ssoLogin(params).pipe(
          tap(async (res: any) => {

            const ds_user = JSON.parse(localStorage.getItem('ds_user'));

            if (ds_user && ds_user.created_via && ds_user.created_via === 'sso_oauth_token') {
              this.sideNavService.closeLeftSidenav();
              this.sideNavService.showHamburgerIcon = false;
            }

            console.log('Login as user response:', res);
            const user = {
              ...res.user,
              token_key: res.token_key,
              refresh_token: res.refresh_token
            };

            this.auth.setData(user, res.quizToken.token);
            if (this.auth.user.roles.includes(ROLE_ADMIN)) {
              this.auth.isUserAdmin = true;
            }


            await this.router.navigate(['/']);
          }),
          catchError(async (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this.helper.showAlertWithouAutoDismiss(err.error.message);
              await this.router.navigate([AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/login']);
            }
            return null;
          })
        )
      )
    ).subscribe();
  }

  ngOnInit() {
  }

  ssoLogin(params: any) {
    return this.http.post(`${API.APIDOMAIN}mp/token/login`, params);
  }

}
