import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportingFiltersService } from '../reporting-filters.service';
import * as moment from 'moment';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';


@Component({
  selector: 'ds-report-range-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './report-range-filter.component.html',
  styleUrls: ['./report-range-filter.component.scss'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: navigator.language
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [
        MAT_DATE_LOCALE,
        MAT_MOMENT_DATE_ADAPTER_OPTIONS
      ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class ReportRangeFilterComponent implements OnInit, OnDestroy {


  constructor(
    public reportingFilters: ReportingFiltersService
  ) {
  }

  maxDate = new Date();

  subs: Subscription;

  fg = new FormGroup({
    dateFromControl: new FormControl(null),
    dateToControl: new FormControl(null)
  });


  public static formatStartDate(date: Date | string) {
    const m = moment(date);
    m.set({hours:0, minutes:0, seconds:0});
    return !date ? null : m.format('YYYY-MM-DDTHH:mm:ss') + '.000Z';

  }

  public static formatEndDate(date: Date | string) {
    const m = moment(date);
    m.set({hours:23, minutes:59, seconds:59});
    return !date ? null : m.format('YYYY-MM-DDTHH:mm:ss') + '.000Z';

  }

  ngOnInit() {
    // Two same request fix
    this.subs = this.fg.valueChanges.pipe(
      distinctUntilChanged(),
      throttleTime(500)
    ).subscribe(value => {
      this.reportingFilters.dateFrom = ReportRangeFilterComponent.formatStartDate(value.dateFromControl);
      this.reportingFilters.dateTo = ReportRangeFilterComponent.formatEndDate(value.dateToControl);
  
      this.reportingFilters.selectedDateRangeChange.emit([this.reportingFilters.dateFrom, this.reportingFilters.dateTo]);
    });

  }

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
  }

  onChangeEventTo(event: any) {
    const hasValue = !!event.target.value;
    if (!hasValue || !this.fg.get('dateToControl').valid) {
      this.fg.get('dateToControl').patchValue(null);
    }
  }

}
