import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss']
})
export class MultiLineChartComponent implements OnInit
{
  _title: string = 'Title';
  @Input() set title(value: string)
  {
    this._title = value || 'Title';
  }
  _subtitle: string;
  @Input() set subtitle(value: string)
  {
    this._subtitle = value;
  }

  _total: number = 0;
  @Input() set total(value: number)
  {
    this._total = value;
  }

  _data: MultiLineChartData;
  @Input() set chartData(value: MultiLineChartData)
  {
    this._data = value;
  }

  _normalize = false;
  @Input() set normalize(v: boolean) { this._normalize = v; }

  @Input() disabledScales: string[] = [];


  constructor() { }

  ngOnInit()
  {
    if (this._total == null || this._total === undefined)
    {
      throw new Error('Total not provided');
    }

    this._data.row.forEach(row =>
    {
      row.total = row.scales.reduce((prev, cur) => prev + cur.count, 0);
    });
  }

  disableScale(legendItem: { label: string; })
  {
    if (this.disabledScales.includes(legendItem.label))
    {
      this.disabledScales = this.disabledScales.filter(s => !(s === legendItem.label));
    }
    else
    {
      this.disabledScales.push(legendItem.label);
    }

    if (this._normalize)
    {
      this._data.row.forEach(row =>
      {
        row.total = this._total;

        this.disabledScales.forEach(ds =>
        {
          row.total -= row.scales.find(s => s.scaleName === ds).count;
        });
      });
    }
  }

}

export interface MultiLineChartData
{
  /** Legend data for the chart */
  legend: {
    label: string;
    bgColor: string;
  }[];
  /** A single row with the scale data */
  row: {
    label: string;
    /** Used for calculating the precentages per row, initial value taken from _total */
    total?: number;
    scales: {
      scaleName?: string;
      count: number;
      bgColor: string;
    }[]
  }[];
}
