import {NgModule} from '@angular/core';
import {TsqAssesmentComponent} from './tsq-assesment.component';
import {SharedModule} from '../../../../../shared/shared.module';
import {TsqLandingPageComponent} from './components/tsq-landing-page/tsq-landing-page.component';
import {TsqQuestionsPageComponent} from './components/tsq-questions-page/tsq-questions-page.component';
import {TsqDemographicsComponent} from './components/tsq-demographics/tsq-demographics.component';
import {TsqCircleGraphComponent} from './components/tsq-circle-graph/tsq-circle-graph.component';
import { DemographicsFormOneComponent } from './components/tsq-demographics/components/demographics-form-one/demographics-form-one.component';
import { DemographicsFormTwoComponent } from './components/tsq-demographics/components/demographics-form-two/demographics-form-two.component';
import {TsqResultsComponent} from './components/tsq-results/tsq-results.component';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview.component';


@NgModule({
  declarations: [
    TsqAssesmentComponent,
    TsqLandingPageComponent,
    TsqQuestionsPageComponent,
    TsqDemographicsComponent,
    TsqCircleGraphComponent,
    DemographicsFormOneComponent,
    DemographicsFormTwoComponent,
    TsqResultsComponent,
    PdfPreviewComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    TsqAssesmentComponent,
    PdfPreviewComponent
  ]
})
export class TsqAssesmentModule {
}
