import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { GroupsService } from 'src/app/main/content/services/admin/groups.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';

@Component({
  selector: 'ds-chip-groups',
  templateUrl: './chip-groups.component.html',
  styleUrls: ['./chip-groups.component.scss']
})
export class ChipGroupsComponent implements OnInit {
  private selectedTag: string = null;
  loading = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl('');
  filteredGroups: Observable<string[]>;
  allGroups: string[] = [];
  allGroupsCollection: any[];

  _disabled: boolean = false;

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    setTimeout(() => {
      this._disabled ? this.groupCtrl.disable() : this.groupCtrl.enable();
    }, 0);
  }

  @Input() placeholder = 'Add/Select Groups';
  @Input() hint: string;
  @Input() groups: string[] = [];
  @Input() allowDuplicates = true;
  @Input() allowRemovingChips = true;
  @Input() accountName: string = null;

  @ViewChild('groupInput', { static: true }) groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  /** Emits all groups as string array when a group is added or removed */
  @Output() groupsChange: EventEmitter<string[]> = new EventEmitter();
  /** Emits a group that was removed */
  @Output() groupRemoved: EventEmitter<string> = new EventEmitter();
  /** Emit a group that was added */
  @Output() groupAdded: EventEmitter<string> = new EventEmitter();
  /** Emit all groups fetched from BE */
  @Output() allKnownGroups: EventEmitter<any[]> = new EventEmitter();

  constructor(
    private groupService: GroupsService,
    private errService: ErrorService
  ) {
  }

  ngOnInit() {
    this.getUserGroups();
  }

  private getUserGroups() {
    this.loading = true;
    this.groupService.getAllGroups(this.accountName).subscribe(res => {
      this.allGroupsCollection = res;
      this.allGroups = res.map(g => g.name).sort((a, b) => a.localeCompare(b));
      if (!this.allowDuplicates) {
        this.allGroups = this.allGroups.filter(g => !(this.groups.includes(g))); // Filter out the already existing groups
      }

      this.setFilteredGroups();
      this.loading = false;
    }, err => {
      console.log('Error getting groups', err);
      this.loading = false;
      this.errService.handleHttpErrorForLogedInUser(err);
    });
  }

  private setFilteredGroups() {
    this.filteredGroups = this.groupCtrl.valueChanges.pipe(
      startWith(null),
      map((group: string | null) => group ? this._filter(group) : this.allGroups.slice())
    );
  }

  add(event: MatChipInputEvent): void {
    // Add group only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value ? (event.value.trim() ? event.value.trim() : null) : null;

      // Add our fruit
      if (value) {
        if (!this.allowDuplicates && this.groups.includes(value)) {
          return;
        }

        this.handleTags(value);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.groupCtrl.setValue(null);
    }
  }

  remove(group: string): void {
    const index = this.groups.indexOf(group);
    const g = this.groups[index];

    if (index >= 0) {
      this.groups.splice(index, 1);

      if (!this.allowDuplicates) // If duplicates are not allowed, push back the group that was removed to the list
      {
        this.allGroups.push(g);
        this.allGroups.sort((a, b) => a.localeCompare(b));
        this.setFilteredGroups();
      }

      // Emit events
      this.groupRemoved.emit(g);
      this.groupsChange.emit(this.groups);
      this.allKnownGroups.emit(this.allGroupsCollection);

    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTag = event.option.viewValue;

    if (!this.allowDuplicates && this.groups.includes(this.selectedTag)) {
      this.groupInput.nativeElement.value = '';
      this.groupCtrl.setValue(null);
      return;
    }

    this.handleTags(this.selectedTag);

    this.groupInput.nativeElement.value = '';
    this.groupCtrl.setValue(null);
  }

  private handleTags(value: string) {
    this.groups.push(value);

    // If duplicates are not allowed, filter out the newly added group
    if (!this.allowDuplicates) {
      this.allGroups = this.allGroups.filter(g => !(g.trim() === value));
      this.setFilteredGroups();
    }

    this.groupAdded.emit(value);
    this.groupsChange.emit(this.groups);
    this.allKnownGroups.emit(this.allGroupsCollection);

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allGroups.filter(group => group.toLowerCase().indexOf(filterValue) === 0);
  }

  onFocusOut() {
    setTimeout(() => {
      const value = this.groupInput.nativeElement.value;
      if (value && this.selectedTag === null) {
        this.groups.push(value);
        this.groupsChange.emit(this.groups);
        this.groupAdded.emit(value);
        this.groupInput.nativeElement.value = null;
      }
      this.selectedTag = null;
    }, 100);

  }
}
