import { Component, OnInit } from '@angular/core';
import { RouteState } from '../route-values/route-values';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'ds-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit
{
  previousUrl;
  urlAfterRedirect;

  sameLinks = false;
  isLoggedIn = false;

  constructor(
    private auth: AuthService
  )
  { }

  ngOnInit()
  {
    this.previousUrl = RouteState.previousRoute;
    this.urlAfterRedirect = RouteState.urlAfterRedirect;

    this.sameLinks = this.previousUrl === this.urlAfterRedirect;
    this.isLoggedIn = this.auth.isLoggedIn();
  }

}
