import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { UsersService } from 'src/app/main/content/services/admin/users.service';

@Component({
  selector: 'ds-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit, OnDestroy {
  private _userID: number;
  @Input()
  set userID(v: number) {
    this._userID = v;
  }

  get userID() {
    return this._userID;
  }

  private _profileID: number;
  @Input()
  set profileID(v: number) {
    this._profileID = v;
  }

  get profileID() {
    return this._profileID;
  }

  private _image: string;
  @Input()
  set image(v: string) {
    this._image = v;
  }

  get image() {
    return this._image;
  }

  private _userName: { first_name?: string; last_name?: string; email?: string; };
  @Input()
  set userName(v: { first_name?: string; last_name?: string; email?: string; }) {
    this._userName = v;
    if (v && (v.first_name || v.last_name)) {
      this.initials = (v.first_name ? v.first_name.charAt(0) : '') + (v.last_name ? v.last_name.charAt(0) : '');
    } else if (v && v.email) {
      this.initials = v.email.charAt(0) + v.email.charAt(1);
    } else {
      this.initials = '';
    }
  }

  get userName() {
    return this._userName;
  }


  subs = new Subscription();

  private _uploading = false;
  set uploading(v: boolean) {
    this._uploading = v;
    this.progressEvent.emit({ progress: this.progress, uploading: this.uploading, mode: this.progressMode });
  }

  get uploading() {
    return this._uploading;
  }


  private _progress: number = 0;
  set progress(v: number) {
    this._progress = v;
    this.progressEvent.emit({ progress: this.progress, uploading: this.uploading, mode: this.progressMode });
  }

  get progress() {
    return this._progress;
  }


  private _progressMode: 'determinate' | 'indeterminate' | 'buffer' | 'query' = 'determinate';
  set progressMode(v: 'determinate' | 'indeterminate' | 'buffer' | 'query') {
    this._progressMode = v;
    this.progressEvent.emit({ progress: this.progress, uploading: this.uploading, mode: this.progressMode });
  }

  get progressMode() {
    return this._progressMode;
  }


  /** Initials of a user's name or, if no name, his e-mail */
  initials = '';

  /** Element refrence for hidden input element that triggers image upload */
  @ViewChild('imageUpload', { static: true }) imageUpload: ElementRef<HTMLInputElement>;


  /** Set this to a different value to trigger image upload */
  @Input() set triggerImageUpload(v: any) {
    if (v) this.triggerUpload();
  }

  private _disabled = false;
  @Input()
  set disabled(v: boolean) {
    this._disabled = v;
  }

  get disabled() {
    return this._disabled;
  }


  /** Emits when image is finished uploading. Emits the uploaded image */
  @Output() imageUploaded = new EventEmitter<string>();
  /** Emits the progress and status of the upload */
  @Output() progressEvent = new EventEmitter<{ uploading: boolean; progress: number; mode: string; }>();

  constructor(
    private helper: HelperService,
    private errService: ErrorService,
    private userService: UsersService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  /**
   * Gets called when user selects a file
   */
  onFileChanged(files: File[]) {
    if (this.disabled) return;

    const file: File = files[0];
    let base64String;

    if (!file || !file.type) return;

    if (!file.type.startsWith('image/')) {
      this.helper.showAlert('Only images please...');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      base64String = reader.result;

      this.uploading = true;
      this.progress = 0;
      this.progressMode = 'determinate';

      this.subs.add(
        this.userService.uploadProfileImage({ user_id: this.userID, image: base64String })
          .subscribe(res => {
            console.log('Response:', res);
            switch (res.type) {
              case HttpEventType.UploadProgress:
                this.progress = Math.round((res.loaded / res.total) * 100);
                break;

              case HttpEventType.DownloadProgress:
                this.progressMode = 'query';
                break;

              case HttpEventType.Response:
                console.log('Successfull image upload', res);
                this.uploading = false;
                this.image = res.body.image;
                this.imageUploaded.emit(this.image);
                break;
            }

          }, err => {
            console.log(err);
            this.errService.handleHttpErrorForLogedInUser(err, { backupMSG: 'Error uploading image' });
            this.uploading = false;
          })
      );
    };

    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.helper.showAlert('Error on upload');
    };
  }

  triggerUpload() {
    if (this.disabled) return;

    if (!this.userID) {
      console.error('User id was not provided, so upload feature is not enabled');
      return;
    }

    this.imageUpload.nativeElement.click();
  }

  removeImage(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.profileID) {
      this.helper.showAlert('Oops, profile ID is missing');
      return;
    }

    this.uploading = true;
    this.progressMode = 'indeterminate';

    this.subs.add(
      this.userService.removeProfileImage(this.profileID).subscribe(res => {
        console.log('Remove profile image', res);
        this.image = null;
        this.uploading = false;

      }, err => {
        console.log('Error removing profile image', err);
        this.uploading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }
}
