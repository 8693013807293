import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CircleChart, TsqColor, TsqStore } from 'src/app/main/content/pages/shared/tsq-assesment/tsq.store';
import { map } from 'rxjs/operators';
import { QuizSystemService } from 'src/app/main/content/services/quiz/quiz-system.service';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tsq-circle-graph',
  templateUrl: './tsq-circle-graph.component.html',
  styleUrls: ['./tsq-circle-graph.component.scss']
})
export class TsqCircleGraphComponent implements OnInit, OnChanges {

  private _isLast = false;
  private _step: number;

  @Input()
  set isLast(value: boolean) {
    this._isLast = value;
  }


  constructor(
    private _store: TsqStore,
    private _quizSystem: QuizSystemService,
    private _auth: AuthService,
    private _breakpointObserver: BreakpointObserver,
    private _http: HttpClient
  ) {
    this.className$ = this._breakpointObserver.observe([
      '(min-width: 960px)'
    ]).pipe(
      map((s) => {
        return s.matches ? `tsq-bg-image-${ this.color }` : '';
      })
    );


    this.chart$ = this._store.chart$;
  }


  className$: Observable<string>;
  chart$: Observable<CircleChart>;

  @Input()
  title: string = '';

  @Input()
  color: TsqColor = 'blue';

  @Output()
  last: EventEmitter<void> = new EventEmitter<void>();


  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  continue() {
    this._store.chart = null;
    if (!this._isLast) {
      this.next.next();
      this._store.pageType = 'questions';
    } else {
      this.last.emit();
      this._store.pageType = 'demographics';
    }
  }
}
