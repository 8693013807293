import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-extremes-table',
  templateUrl: './extremes-table.component.html',
  styleUrls: ['./extremes-table.component.scss']
})
export class ExtremesTableComponent implements OnInit
{
  constructor() { }

  ngOnInit()
  {
  }

}
