import { Injectable } from '@angular/core';
import { ApiDefinition } from '../../models/api/api.def';
import { AuthService } from '../auth/auth.service';
import { API } from 'src/app/api-url/api-urls';
import { CountryModel } from '../../models/user/demographics.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import {Observable} from 'rxjs';
import {AnonUserService} from './anon-user.service';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService {
  api: ApiDefinition;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private anonService: AnonUserService,
    private translocoService: TranslocoService
  ) {


    this.setHeaders()

  }

  private setHeaders() {
    this.api = this.auth.api;
    const options = this.auth.httpOptions;
    options.params = new HttpParams();
    options.params = options.params.set('locale', this.translocoService.getActiveLang());

    let headers = new HttpHeaders();
    if (this.anonService.anonToken) {
      headers = headers.set('x-token', this.anonService.anonToken);
    } else if (this.auth.token) {
      headers = headers.set('x-token', this.auth.token);
    }

    options.headers = headers;
  }




  country = {
    /**
     * GET /countries
     */
    getAllCountries: (): Observable<CountryModel[]> => {



      return this.http.get<CountryModel[]>(API.APIDOMAIN + this.api.countries, this.auth.httpOptions);
    }
  };

  age = {
    /**
     * GET /ages
     */
    getAllAges: (userId?: string) => {
      let url = API.APIDOMAIN + this.api.ages;
      if (userId) url = API.APIDOMAIN + this.api.ages +`?userId=${userId}`;
      return this.http.get(url, this.auth.httpOptions);
    }
  };

  education = {
    /**
     * GET /educations
     */
    getAllEducations: () => {
      return this.http.get(API.APIDOMAIN + this.api.educations, this.auth.httpOptions);
    }
  };

  job = {
    /**
     * GET /job-levels
     */
    getAllLevels: () => {
      return this.http.get(API.APIDOMAIN + this.api.job_levels, this.auth.httpOptions);
    }
  };

  income = {
    /**
     * GET /incomes
     */
    getAllIncomes: () => {
      return this.http.get(API.APIDOMAIN + this.api.incomes, this.auth.httpOptions);
    }
  };

  industries = {
    /**
     * GET /industries
     */
    getAllIndustries: () => {
         this.setHeaders()
      return this.http.get(API.APIDOMAIN + this.api.industries, this.auth.httpOptions);
    }
  };

  genders = {
    /**
     * GET /genders
     */
    getAllGenders: (userId?: string) => {
      let url = API.APIDOMAIN + this.api.genders;
      if (userId) url = API.APIDOMAIN + this.api.genders + `?userId=${userId}`;
      return this.http.get(url, this.auth.httpOptions);
    }
  };

  getCompanyRevenue(): Observable<any> {
    this.setHeaders();
    return this.http.get(API.APIDOMAIN + 'company-revenue', this.auth.httpOptions);
  }

  getCompanySize(): Observable<any> {
    this.setHeaders();
    return this.http.get(API.APIDOMAIN + 'company-size', this.auth.httpOptions);
  }
  getEthnicities(userId?: string): Observable<any> {
    let url = API.APIDOMAIN + `ethnicities`;
    if (userId) url = API.APIDOMAIN + `ethnicities?userId=${userId}`;
    this.setHeaders();
    return this.http.get( url, this.auth.httpOptions );
  }
}
