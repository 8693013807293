import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export const CustomValidators = {

  /**
   * Validator: 'whitespace'
   * @param control
   */
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = control.value && control.value.length > 0 ? control.value.trim().length === 0 : false;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  },


  /**
   * Checks if password is 8 characthers long and has lower and uppercase letters
   *
   * Validator: 'passwordPattern'
   * @param control
   */
  passwordPattern(control: FormControl) {
    const pass: string = control.value;
    const errObj = {'passwordPattern': true};

    if (pass) {
      const containsDigits = /[0-9]/.test(pass);
      const containsUpper = /[A-Z]/.test(pass);
      const containsLower = /[a-z]/.test(pass);

      // if password not long enough, return error
      if (pass.length < 8) return errObj;

      // Check if contains at least one upper and lowercase letters
      else if (!containsDigits || !containsUpper || !containsLower) return errObj;

      // else return no error
      else return null;
    } else {
      return errObj;
    }
  },

   /**
   * Check if currentPassword and newPassword inputs match.
   *
   * Validator: 'passwordsMatch'
   *
   * @param group
   */
   passwordsMatchValidator(group: AbstractControl): ValidationErrors | null {
    const control = group.get('oldPassword');
    const matchingControl = group.get('newPassword');

    let error: { passwordsMatch: boolean } | null = null;

    if (control && matchingControl) {
      if (control.value === matchingControl.value) {
        error = { passwordsMatch: true };
      }

      if (matchingControl.errors && !matchingControl.errors['passwordsMatch']) {
        return null;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors(error);
      } else {
        matchingControl.setErrors(error);
      }
    }

    return error;
  },
};


