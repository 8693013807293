import { NgModule } from '@angular/core';
import { ChipGroupsComponent } from './chip-groups/chip-groups.component';
import { ReportChartsComponent } from './report-charts/report-charts.component';
import { CustomChartsModule } from '../custom-charts/custom-charts.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpUsersTableComponent } from './http-tables/http-users-table/http-users-table.component';
import { HttpProfilesTableComponent } from './http-tables/http-profiles-table/http-profiles-table.component';
import { WhoAreYouDetailsCardComponent } from './details-cards/who-are-you-details-card/who-are-you-details-card.component';
import { CoreDriversDetailsCardComponent } from './details-cards/core-drivers-details-card/core-drivers-details-card.component';
import { AssessmentInfoComponent } from './details-cards/assessment-info/assessment-info.component';
import { JqdiagnosticDetailsCardComponent } from './details-cards/jqdiagnostic-details-card/jqdiagnostic-details-card.component';
import { AccountSelectComponent } from './account-select/account-select.component';
import { HttpReportUsersTableComponent } from './http-tables/http-report-users-table/http-report-users-table.component';
import { UserAutocompleteSearchComponent } from './user-autocomplete-search/user-autocomplete-search.component';
import { OptionsScrollDirective } from './user-autocomplete-search/options-scroll.directive';
import { RecipientsInfoComponent } from './recipients-info/recipients-info.component';
import { FollowUpTableComponent } from './follow-up-table/follow-up-table.component';
import { SendFollowupComponent } from './send-followup/send-followup.component';

const components = [
  ChipGroupsComponent,
  ReportChartsComponent,
  HttpUsersTableComponent,
  HttpProfilesTableComponent,
  WhoAreYouDetailsCardComponent,
  CoreDriversDetailsCardComponent,
  JqdiagnosticDetailsCardComponent,
  AssessmentInfoComponent,
  AccountSelectComponent,
  HttpReportUsersTableComponent,
  UserAutocompleteSearchComponent,
  OptionsScrollDirective,
  RecipientsInfoComponent,
  FollowUpTableComponent,
  SendFollowupComponent,
];

@NgModule({
  imports: [
    SharedModule,
    CustomChartsModule,
    NgxChartsModule
  ],
  declarations: [...components],
  exports: [...components]
})
export class AdminComponentsModule { }
