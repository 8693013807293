import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/main/content/services/auth/auth.service';
import {ErrorService} from 'src/app/main/content/services/helpers/error.service';
import {AnonReportPageComponent} from '../../../anonymous-quiz/anon-report-page/anon-report-page.component';
import {ShareService} from '../../../share/share-services/share.service';
import {AssessmentResultsComponent} from '../../../user/assessment-results/assessment-results.component';
import {downloadFile} from 'src/app/functions/helpful-functions';
import {HelperService} from 'src/app/main/content/services/helpers/helper.service';
import {AnonUserService} from 'src/app/main/content/services/user/anon-user.service';

@Component({
  selector: 'skills-gaming-report',
  templateUrl: './skills-gaming-report.component.html',
  styleUrls: ['./skills-gaming-report.component.scss']
})
export class SkillsGamingReportComponent implements OnInit {
  @Input() result: any;
  @Input() isAnon: boolean;

  accountName: string;
  assessmentId: string;
  formId: string;

  disableReportBtn = false;

  subs = new Subscription();

  constructor(
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent,
    private router: Router,
    private route: ActivatedRoute,
    private errService: ErrorService,
    private shareService: ShareService,
    public auth: AuthService,
    private anonService: AnonUserService,
    private helper: HelperService
  ) {
    this.accountName = this.route.snapshot.params.accountName;
    this.assessmentId = this.route.snapshot.params.id;
    this.formId = this.route.snapshot.params.formID;
  }

  ngOnInit() {
    if( this.router.routerState.snapshot.url.includes('admin'))
      this.disableReportBtn = true;
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    !this.isAnon ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }

  replayAssessment() {
    if (this.isAnon) {
      this.helper.showAlert('Please check and confirm your email first.', 'OK');
    }

    this.router.navigate([`${this.accountName}/assessment/${this.assessmentId}`], {queryParams: {'new': true}});
  }

  exportAsPdf() {
    const uid = this.auth.user ? this.auth.user.id : this.anonService.anonUser.id;
    const token = this.auth.user ? this.auth.user.token_key : this.anonService.anonToken;
    const formId = this.assessmentId ? this.assessmentId : this.formId;

    this.subs.add(
      this.shareService.createShareToken(uid, formId, token).subscribe(res => {
        this.helper.showAlertWithouAutoDismiss('Creating PDF, please wait.');

        this.subs.add(
          this.shareService.exportAsPdf(token, res.token).subscribe((result: any) => {
            downloadFile(
              result.body,
              result.headers.get('content-type'),
              result.headers.get('content-disposition').split('=')[1]
            );

          }, err => {
            if (!this.auth.user) {
              return this.errService.handleHttpErrorForLogedOutUser(err);
            }
            console.log('PDF export Error', err);
            this.errService.handleHttpErrorForLogedInUser(err);
          })
        );
      }, err => {
        if (!this.auth.user) {
          return this.errService.handleHttpErrorForLogedOutUser(err);
        }
        console.log('Share Error', err);
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }

}
