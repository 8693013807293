import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'enter-your-email',
  templateUrl: './enter-your-email.component.html',
  styleUrls: ['./enter-your-email.component.scss']
})
export class EnterYourEmailComponent implements OnInit {

  @Output()
  skip: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  done: EventEmitter<string> = new EventEmitter<string>();
  form: FormGroup;

  @Input()
  skipAuto = false;

  constructor(private _fb: FormBuilder) {
    this.form = this.createForm();
  }

  ngOnInit() {
    if (this.skipAuto) {
      this.skip.emit();
    }
  }


  private createForm(): FormGroup {
    return this._fb.group({
      email: this._fb.control(null, []),
      checked: this._fb.control(false, [Validators.required])
    });
  }


  get emailFormControl(): FormControl {
      return this.form.get('email') as FormControl;
  }

  get checkedFormControl(): FormControl {
      return this.form.get('checked') as FormControl;
  }
}
