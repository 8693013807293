import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { ExtremesResult } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-extremes-user-details-card',
  templateUrl: './extremes-user-details-card.component.html',
  styleUrls: ['./extremes-user-details-card.component.scss']
})
export class ExtremesUserDetailsCardComponent implements OnInit
{
  private _user: UserModel;
  @Input() set user(v: UserModel)
  {
    this._user = v;
  }
  get user() { return this._user; }


  private _profilePicture;
  @Input() set profilePicture(v: string)
  {
    this._profilePicture = v;
  }
  get profilePicture() { return this._profilePicture; }


  private _extremes: string[] = [];
  @Input() set extremes(v: string[]) { this._extremes = v ? v : []; }
  get extremes() { return this._extremes; }

  private _result: ExtremesResult;
  @Input() set result(value: ExtremesResult)
  {
    this._result = value;
  }
  get result() { return this._result; }


  get isAnon() { return this.user.status === 'ANONYMOUS'; }

  constructor()
  {}

  ngOnInit()
  {}
}
