import { SwimlanePieChartDataModel, SingleChartData, MultiLineChartData, MultiLineSliderChartData } from '../chart/chart.models';
import { EChartOption } from 'echarts';
import { Assessment } from './results.def';
import { AccountModel } from './account.def';

export interface ReportModel {
  report:     TabNames;
  /** Number of users */
  documents:  number;

  additional_tabs?: string[];
  groups?:          { [groupName: string]: string };
  countries?:       string[];
  ages?:            string[];
  genders?:         string[];   
}

export interface TabNames {
  [tabName: string]: ReportData;
}

export interface ReportData {
  [chartName: string]: Charts;
}

export interface Charts {
  chart_data:         ChartData | ChartScale;
  chart_instructions: ChartInstructions;
  chart_json?:        EChartOption;
  text_box:           TextBoxModel[];
  tab_name?:          string;
}


export interface TextBoxModel {
  id:            number;
  level:         number;
  scaleName:     string;
  text:          string;
  assessment:    Assessment;
  mappingKey:    string;
  rendered_text: string;
  variables:     {
    [variableName: string]: string | number;
  };
  account: AccountModel;
}

export interface MappingVariables
{
  [mappingKey: string]: string[];
}


export interface ChartData {
  [scaleName: string]: ChartScale;
}


export interface ChartScale {
  [labelName: string]: number;
}

export interface ChartInstructions {
  chart_type:     ChartType;
  chart_title:    string;
  chart_subtitle?:    string;
  color_value: {
    [value: number]: string;
  };
  chart_details: {
    bars?:        number;

    max_label?:   string;
    min_label?:   string;
    max_value?:   number;
    min_value?:   number;

    labels?:       {
      label:      string;
      bgcolor:    string;
    }[];

    hidden_labels?: string[];
  };
  grouped?: boolean;
  show_empty_bars?: boolean;
  x_axis_chart_data?: string;
  x_axis_label?: string;
  x_axis_max_value?: number;
  x_axis_min_value?: number;
  x_axist_data_type?: string;
  y_axis_chart_data?: string;
  y_axis_label?: string;
  y_axist_data_type?: string;
  bars_colors: string | { [key: string]: string };
  bars_on_axis: 'x' | 'y';
}

export enum ChartType {
  multiline_slider = 'multiline_slider',
  multiline_chart = 'multiline_chart',
  singleline_chart = 'singleline_chart',
  swimlane_pie_chart = 'swimline_pie_chart',
  echarts = 'echarts',
  one_value_box = 'one_value_box'
}



/** Model for charts on the /report api */
export interface MappedChartData
{
  /** Data mapped in array form for things like overview tab */
  likeArray:  ChartsModel[];
  /** "Raw" data of charts grouped by their tab name */
  grouped:    TabNames;
}

/**
 * Charts model for charts dashboard in array form
 */
export interface ChartsModel
{
  type:           ChartType;
  title:          string;
  tabName:        string;
  subtitle?:      string;
  total:          number;
  minLabel?:      string;
  maxLabel?:      string;
  hiddenLabels?:  string[];
  color_value?: {
    [value: number]: string;
  };
  textBox:        TextBoxModel[];
  chartData:      SingleChartData[] |
                  MultiLineChartData |
                  MultiLineSliderChartData |
                  SwimlanePieChartDataModel[] |
                  EChartOption |
                  number[] | string[];
}





/**
 * Response model from the /report/users/formID api
 */
export interface ReportUsersResponse {
  users:            ReportUser[];
  groups:           string[];
  total:            number;
  page:             number;
  size:             number;
  pages:            number;
  graph:            EChartOption[];
  default_columns:  string[];
}

/**
 * User object model from report users api
 */
export interface ReportUser
{
  user_id:    string;
  first_name: string;
  last_name:  string;
  email:      string;
  company:    string;
  groups:     any[];

  gender:     string;
  income:     DemoField;
  country:    DemoField;
  education:  DemoField;
  state:      DemoField;
  job_level:  DemoField;
  age:        DemoField;
  scales:     {
    [scaleName: string]: number | string;
  };
}

interface DemoField
{
  id:   number;
  name: string;
}





/** Team comparison response */
export interface TeamComparisonResponse
{
  team1: { [fieldName: string]: number };
  team2: { [fieldName: string]: number };
}

/** Individuals comparison response */
export interface IndividualsComparisonResponse
{
  [userID: number]: { [fieldName: string]: number };
}
