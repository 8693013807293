export enum DesignType {
  design1 = 'design1',
  design2 = 'design2',
  design3 = 'design3'
}

export interface ThemeModel {
  id: number;
  /**
   * The name of the theme class used for applying material theme colors
   */
  themeName: string;
  /**
   * Primary color of the theme
   */
  primary: string;
  /**
   * Accent color of the theme
   */
  accent: string;
  /**
   * Link to the logo without words / Used usually on toolbar
   */
  logoURL: string;
  /**
   * Link to the logo with the image and the words / Used usually on login pages
   */
  fullLogoURL: string;
  /**
   * Title of the theme, usually displayd next to the logo
   */
  title: string;
  /**
   * Background gradient for authentication pages
   */
  backgroundGradient: string;
  /**
   * Background gradient in picture form.
   * This will be used if no background gradient is provided
   */
  backgroundGradientPicture: string;
  /**
   * Link that the user will be linked to when he presses on a logo picture
   * For now used on the authentication pages
   */
  logoLinkURL: string;
  /**
   * Path to the favicon (relative, must start with a '/')
   *
   * example. /assets/favicon.ico
   */
  faviconURL: string;
  /**
   * Determines whether or not the "Quiz is active" and the number of participants should be shown on
   * the dashboard quiz cards
   */
  showQuizInfo: boolean;
  /**
   * The sentence that encourages the user to take the quiz
   */
  dashboardIntroText: string;
  /**
   * Path to the background image (relative, must start with a '/')
   */
  backgroundPath: string;
  /**
   * Text for the action button ex. "GO" or "Take Survey"
   */
  dashboardActionButtonText: string;
  /**
   * Help page url for the theme
   */
  helpPageUrl: string;
  /**
   * Toolbar background color
   */
  toolbarBGColor: string;
  /**
   * Toolbar font color
   */
  toolbarFontColor: string;
  /**
   * Font of the theme
   */
  fontFamily: string;
  /**
   * Link to Cookie policy
   */
  linkToCookiePolicy: string;
  loginDesign: DesignType;
  registerDesign: DesignType;
  forgotPasswordDesing: DesignType;
  resetPasswordDesing: DesignType;
  dashboardDesign: DesignType;

  quizQuestionBolded: boolean;
  quizUsePrimaryColor: boolean;
  quizCenterQuestion: boolean;
}
