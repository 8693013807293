import { Component, OnInit, Input } from '@angular/core';
import { ExtremesResult } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-extremes-user-report',
  templateUrl: './extremes-user-report.component.html',
  styleUrls: ['./extremes-user-report.component.scss']
})
export class ExtremesUserReportComponent implements OnInit
{
  private _showThumbs = true;
  @Input()
  set showThumbs(v: boolean) { this._showThumbs = v; }
  get showThumbs() { return this._showThumbs; }


  private _result: ExtremesResult;
  @Input() set result(value: ExtremesResult)
  {
    this._result = value;
  }
  get result() { return this._result; }

  constructor()
  {}

  ngOnInit()
  {
  }
}
