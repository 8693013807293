import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { ACCOUNT_NAME } from 'src/app/constants/router-params';
import { GdprActionComponent } from './gdpr-action.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes = [
  {
    path: `:${ACCOUNT_NAME}/gdpr/:type/:token`,
    component: GdprActionComponent
  },
  {
    path: 'gdpr/:type/:token',
    component: GdprActionComponent
  },
  {
    path: 'gdpr/:master/:type/:token',
    component: GdprActionComponent
  },
  {
    path: `:${ACCOUNT_NAME}/gdpr/:master/:type/:token`,
    component: GdprActionComponent
  },
];

@NgModule({
  declarations: [
    GdprActionComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})

export class GdprActionModule { }
