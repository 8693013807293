import { Component, OnInit, Input } from '@angular/core';
import { CoreDriver } from 'src/app/main/content/models/user/user-results.def';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ds-all-drivers',
  templateUrl: './all-drivers.component.html',
  styleUrls: ['./all-drivers.component.scss']
})
export class AllDriversComponent implements OnInit
{
  @Input() coreDrivers: CoreDriver[] = [];
  @Input() secondaryDrivers: CoreDriver[] = [];


  private _showValue: boolean = true;
  @Input()
  set showValue(v: boolean)
  {
    this._showValue = coerceBooleanProperty(v);
  }
  get showValue() { return this._showValue; }


  private _showSubheaders: boolean = true;
  @Input()
  set showSubheaders(v: boolean)
  {
    this._showSubheaders = coerceBooleanProperty(v);
  }
  get showSubheaders() { return this._showSubheaders; }


  constructor() { }

  ngOnInit()
  {
  }

}
