import { NgModule } from '@angular/core';
import { LoginModule } from './authentication/login/login.module';
import { RegisterModule } from './authentication/register/register.module';
import { ForgotPasswordModule } from './authentication/forgot-password/forgot-password.module';
import { ResetPasswordModule } from './authentication/reset-password/reset-password.module';
import { MailConfirmModule } from './authentication/mail-confirm/mail-confirm.module';
import { VerifyModule } from './authentication/verify/verify.module';
import { AccountSwitchingModule } from './authentication/account-switching/account-switching.module';
import { SharedPagesModule } from './shared/shared.pages.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { CreatePasswordModule } from './authentication/create-password/create-password.module';
import { AnonymousQuizModule } from './anonymous-quiz/anonymous-quiz.module';
import { ForgottenRightModule } from './authentication/forgotten-right/forgotten-right.module';
import { GdprActionModule } from './authentication/gdpr-action/gdpr-action.module';

@NgModule({
  imports: [
    // Auth modules
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    MailConfirmModule,
    VerifyModule,
    AccountSwitchingModule,
    CreatePasswordModule,
    ForgottenRightModule,
    GdprActionModule,

    SharedPagesModule,
    ErrorPagesModule,
    AnonymousQuizModule,
  ],
  declarations: []
})
export class PagesModule { }
