import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AccountIndexClass } from 'src/app/constants/router-params';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  redirectURL: string;

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accountName = next.paramMap.get('accountName');
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.redirectURL = state.url;
      this.authService.logoutCleanUp();
      if (state.url.startsWith(AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/invites/public')) {
        this.router.navigate([ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/register' ]);
        return false;
      }

      this.router.navigate([ accountName + '/login' ]);
      return false;
    }
  }
}
