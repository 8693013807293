import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportModel, ReportUsersResponse, TeamComparisonResponse, IndividualsComparisonResponse } from '../../models/admin/report.def';
import { API } from 'src/app/api-url/api-urls';
import { ApiDefinition } from '../../models/api/api.def';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { UsersReportQueryParams, UsersReportFilters } from '../../models/params/params.def';

@Injectable({
  providedIn: 'root'
})
export class ReportService
{
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  )
  {}


  /**
   * Get assessment's report by form ID and Report id
   *
   * POST /report
   * @param form_id
   * @param report_id
   */
  getAssessmentReport(form_id: string, otherOptions?: { report_id?: number, groups?: string[], date_from?: string, date_to?: string, country?: string[], ages?: string[], genders?: string[], }): Observable<ReportModel> {
    const report_id = otherOptions ? otherOptions.report_id : null;
    const groups = otherOptions ? otherOptions.groups : [];
    const date_from = otherOptions ? otherOptions.date_from: '';
    const date_to = otherOptions ? otherOptions.date_to : '';
    const country = otherOptions ? otherOptions.country : [];
    const ages = otherOptions ? otherOptions.ages : [];
    const genders = otherOptions ? otherOptions.genders : [];


    return this.http.post<ReportModel>(API.APIDOMAIN + this.api.report, { form_id, report_id, filters: {groups, date_from, date_to, country, ages, genders} }, this.auth.httpOptions);
  }




  /**
   * Get a list of users and their scores for the quiz
   *
   * POST /report/users/{formId}
   * @param formId
   * @param queryParams
   * @param filterValues
   */
  getUsersReport(formId: string, queryParams: UsersReportQueryParams, filterValues: UsersReportFilters)
  {
    const options = this.auth.httpOptions;
    options.params = <any>queryParams;

    return this.http.post<ReportUsersResponse>(API.APIDOMAIN + this.api.report + `/users/${formId}`, filterValues, options);
  }




  /**
   * Compare reports of 2 teams
   *
   * POST /report/comparison/team
   * @param form_id
   * @param team_1_ids
   * @param team_2_ids
   */
  compareReportsOfTeams(form_id: string, team_1_ids: number[], team_2_ids: number[])
  {
    return this.http.post<TeamComparisonResponse>(API.APIDOMAIN + this.api.report_comparison + '/team', { form_id, team_1_ids, team_2_ids }, this.auth.httpOptions);
  }


  /**
   * Compare reports of a team
   *
   * POST /report/comparison/individual
   * @param form_id
   * @param team_ids
   */
  compareIndividuals(form_id: string, team_ids: number[])
  {
    return this.http.post<IndividualsComparisonResponse>(API.APIDOMAIN + this.api.report_comparison + '/individual', { form_id, team_ids }, this.auth.httpOptions);
  }
}
