import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {from, Observable} from 'rxjs';
import {AuthService} from '../main/content/services/auth/auth.service';
import {config} from 'src/config';

@Injectable({
  providedIn: 'root'
})
export class SsoGuard implements CanActivate, CanActivateChild {
  redirectURL: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.activate(next));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.activate(next));
  }

  private getQueryParams(): string {
    const queryParams = this.router.getCurrentNavigation().extractedUrl.queryParams;
    let str = '';
    for (const [key, value] of Object.entries(queryParams)) {
      str = `${str}&${key}=${value}`;
    }

    return str;
  }

  private async activate(router: ActivatedRouteSnapshot): Promise<boolean> {
    this.authService.logoutCleanUp(true);
    // const email = router.queryParams['Email'] || router.queryParams['email'];
    // const ds_user = JSON.parse(localStorage.getItem('ds_user'));
    // if (ds_user && ds_user.email !== email) {
    //   this.authService.logoutCleanUp(true);
    // }
    if (this.authService.isLoggedIn()) {
      await this.router.navigate(['/']);
    } else {
      if (config.manpower_sso.substr(0, 4) === 'http') {
        window.location.href = config.manpower_sso + this.getQueryParams();
      } else if (document.referrer) {
        window.location.href = document.referrer + config.manpower_sso + this.getQueryParams();
      }
    }
    return false;
  }
}
