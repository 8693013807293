import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-single-chart',
  templateUrl: './single-chart.component.html',
  styleUrls: ['./single-chart.component.scss']
})
export class SingleChartComponent implements OnInit
{
  _title: string = 'Title';
  @Input() set title(value: string)
  {
    this._title = value || 'Title';
  }
  _subtitle: string;
  @Input() set subtitle(value: string)
  {
    this._subtitle = value;
  }

  initialTotal = 0;
  _total: number = 0;
  @Input() set total(value: number)
  {
    this._total = value;
    this.initialTotal = this._total;
  }

  _scales: SingleChartData[];
  @Input() set scales(value: SingleChartData[])
  {
    this._scales = value;
  }

  _normalize = true;
  @Input() set normalize(v: boolean) { this._normalize = v; }

  @Input() disabledScales: string[] = [];

  constructor() { }

  ngOnInit()
  {
    if (this._total == null || this._total === undefined)
    {
      throw new Error('Total not provided');
    }
  }

  disableScale(name: string)
  {
    if (this.disabledScales.includes(name))
    {
      this.disabledScales = this.disabledScales.filter(s => !(s === name));
    }
    else
    {
      this.disabledScales.push(name);
    }

    if (this._normalize)
    {
      this._total = this.initialTotal;
      this.disabledScales.forEach(s =>
      {
        this._total -= this._scales.find(scale => scale.name === s).count;
      });
    }
  }
}


export interface SingleChartData
{
  /** For the value of the bar */
  count: number;
  /** For the name of the scale on the legend */
  name: string;
  /** Color of the bar */
  bgColor: string;
}
