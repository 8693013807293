import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InvitesService } from 'src/app/main/content/services/admin/email-invite.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { HelperService } from 'src/app/main/content/services/helpers/helper.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EmailCollection,
  EmailInvite,
  EmailInviteStatus,
  emailStatusTranslationMap
} from 'src/app/main/content/models/admin/invites.def';
import { of, Subscription } from 'rxjs';
import { EmailsPreviewDialogComponent } from '../../dialogs/emails-preview-dialog/emails-preview-dialog.component';
import { EmailTemplatesService } from 'src/app/main/content/services/admin/invite-templates.service';
import { AccountSelectService } from 'src/app/main/content/services/admin/account-select.service';
import { EmailTemplate } from 'src/app/main/content/models/admin/email-templates.def';
import { FormControl } from '@angular/forms';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { config } from 'src/config';
import { AuthService } from 'src/app/main/content/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ds-send-followup',
  templateUrl: './send-followup.component.html',
  styleUrls: ['./send-followup.component.scss']
})
export class SendFollowupComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  loading = false;
  loadingTemplates = false;

  linkText = 'Change me';
  link = '';

  templates: EmailTemplate[] = [];
  previewTemplate: EmailTemplate = {
    id: 0,
    body: ``,
    created_at: '',
    subject: '',
    title: '',
    type: '',
    updated_at: ''
  };
  templateControl = new FormControl('');
  templateBodyCopy = '';
  disableTextChange = false;
  retakeLoading: boolean = false;

  emailStatuses = EmailInviteStatus;

  emailStatusTranslationMap = emailStatusTranslationMap;

  emails: EmailInvite[] = [];
  /** An array of custom selection of emails */
  customSelection: number[] = [];

  private _col: EmailCollection;

  @Input()
  set col(v: EmailCollection) {
    this._col = v;
  }

  get col() {
    return this._col;
  }

  @Input()
  retakeEmails: { id: number; email: string }[] = null;

  @Input()
  formId: string = '';

  @Input()
  retakeBtnText = null;


  private _emailStatus: EmailInviteStatus = EmailInviteStatus.total;
  @Input()
  set emailStatus(v: EmailInviteStatus) {
    this._emailStatus = v;
    this.getCollectionEmails();
    this.customSelection = [];
  }

  get emailStatus() {
    return this._emailStatus;
  }


  @Output() cancelClick = new EventEmitter();

  constructor(
    private inviteService: InvitesService,
    private errService: ErrorService,
    private helper: HelperService,
    private dialog: MatDialog,
    private inviteTemplateService: EmailTemplatesService,
    private accountSelect: AccountSelectService,
    private auth: AuthService,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.getTemplates();

    this.link = this.createLink('invite');

    this.templateControl.valueChanges.subscribe((id: number) =>
    {
      this.disableTextChange = true;
      if (id) {
        this.previewTemplate = JSON.parse(JSON.stringify(this.templates.find(tem => tem.id === id)));
      } else {
        if (this.previewTemplate) {
          this.previewTemplate.id = 0;
        } else {
          this.previewTemplate = {
            id: 0,
            body: ``,
            created_at: '',
            subject: '',
            title: '',
            type: '',
            updated_at: ''
          };
        }
      }

      console.log('Ch', id);
      this.templateBodyCopy = this.previewTemplate.body;

      setTimeout(() => {
        this.disableTextChange = false;
      }, 0);
    });

  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }


  private createLink(type: 'invite' | 'login'): string {
    const accountIndex = '/' + (this.accountSelect.selectedAccount ? this.accountSelect.selectedAccount.index_name : AccountIndexClass.ACCOUNT_INDEX);


    switch (type) {
      case 'invite':
        return `<a href="${ window.location.protocol + '//%front_app_url%/%account.name_index%/invite/email/%token%' }"><strong>${ this.linkText }</strong></a>`;

      case 'login':
        return `<a href="${ window.location.protocol + '//%front_app_url%/%account.name_index%/login' }"><strong>${ this.linkText }</strong></a>`;

      default:
        return `<a href="${ window.location.protocol + '//%front_app_url%/%account.name_index%/invite/email/%token%' }"><strong>${ this.linkText }</strong></a>`;
    }
  }


  private getCollectionEmails() {
    this.loading = true;

    if (!this.col || !this.col.collection_uuid) {
      console.log('%cNo collection', 'color:red');
      return;
    }

    this.emails = [];


    this.inviteService.getEmailsForCollection(this.col.collection_uuid, this.emailStatus).subscribe(res =>
    {
      console.log('Emails for collection', res);
      this.emails = res;
      this.loading = false;

    }, err =>
    {
      console.log('Error getting emails', err);
      this.loading = false;
      this.errService.handleHttpErrorForLogedInUser(err);
    });

  }

  private getTemplates() {

    this.loadingTemplates = true;

    if(!this.formId.length) {
      this.inviteService.getFollowupFormId$()
      .pipe(
        switchMap((value) => {
          return this.inviteTemplateService.getTemplatesByShareOptions(value)
            .pipe(
              map(
                res => {
                  console.log('FormId: ', this.formId);
                  console.log('Templates:', res);
                  this.templates = res;
                  this.loadingTemplates = false;

                }),
              catchError(err => {
                console.log('Error getting templates', err);
                this.loadingTemplates = false;
                this.errService.handleHttpErrorForLogedInUser(err);
                return of(null);
              })
            )
        })
      )
      .subscribe();

    } else {

      this.inviteTemplateService.getTemplatesByShareOptions(this.formId).subscribe(res => {
        console.log('Templates:', res);
        this.templates = res;
        this.loadingTemplates = false;

      }, err => {
        console.log('Error getting templates', err);
        this.loadingTemplates = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      });

    }

  }

  openPrevewDialog() {
    const emails = this.emails.map(e => ({ id: e.id, email: e.email, status: e.user_assessment_status }));
    console.log('openDialog', this.retakeEmails, emails);
    const dialog_ref = this.dialog.open(EmailsPreviewDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        hideDownloadCsv: this.retakeEmails,
        emails: this.retakeEmails ? this.retakeEmails : emails,
        selectedIDs: this.customSelection,
        enableSelection: true
      }
    });


    dialog_ref.afterClosed().subscribe(dialogRes => {
      if (dialogRes) {
        console.log('dialogRes', dialogRes);
        this.customSelection = dialogRes;
        this._emailStatus = EmailInviteStatus.custom;
      }
    });

  }


  checkOnEmailTextChange() {
    if (!this.disableTextChange) {
      this.templateControl.setValue(0, { emitEvent: false });
      this.previewTemplate.id = 0;
    }
  }

  addInviteLink(type: 'invite' | 'login') {
    this.link = this.createLink(type);

    this.previewTemplate.body = (this.previewTemplate.body || '') + this.link;
  }

  sendFollowUp() {


    if (this.retakeBtnText) {
      this.retakeLoading = true;
      const options = this.auth.httpOptions;
      const url = `${ config.api_url }invites/assessment/email/resend/link/${ this.formId }`;
      let body1: any;

      body1 = {
        email_template_id: this.previewTemplate.id,
        email_body: this.previewTemplate.body,
        email_subject: this.previewTemplate.subject,
        emails: this.retakeEmails.map(item => item.email),
        groups: []
      };


      console.log('body1', body1, this.customSelection, this.previewTemplate);
      this.http.post(url, body1, options).pipe(
        catchError((err) => {
        this.errService.handleHttpErrorForLogedInUser(err);
        return of(null);
      })).subscribe(() => {
        this.retakeLoading = false;
        this.cancelClick.emit();
      });

      return;
    }
    this.loading = true;

    let body: any;
    if (!this.previewTemplate.id) {
      body = {
        invite_subject: this.previewTemplate.subject,
        invite_text: this.previewTemplate.body
      };
    } else {
      body = {
        invite_template_id: this.previewTemplate.id
      };
    }

    if (this.customSelection.length > 0) {
      body.invite_ids = this.customSelection;
    }


    console.log(body, this.customSelection);
    this.inviteService
      .resendInvitesForCollection(this.col.collection_uuid, this.emailStatus, body)
      .subscribe(res => {
        console.log('Response from sending follow-up', res);

        this.helper.showAlert('Follow-up sent.');

        this.cancelClick.emit();

        this.loading = false;

      }, err =>
      {
        console.log('Error sending follow-up', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      });

  }
}
