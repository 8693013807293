import { NgModule } from '@angular/core';
import { DashboardModule } from './dashboard/dashboard.module';
import { AssesmentModule } from './assesment/assesment.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { StgDemoFormComponent } from './quiz-system/stg-demo-form/stg-demo-form.component';
import { RetakeDialogComponent } from './dialogs/retake-dialog/retake-dialog.component';
import { AdminComponentsModule } from 'src/app/main/content/pages/admin/components/admin-components.module';

@NgModule({
  imports: [
    SharedModule,
    DashboardModule,
    AssesmentModule,
    AdminComponentsModule
  ],
  declarations: [
    StgDemoFormComponent,
    RetakeDialogComponent
  ],
  entryComponents: [RetakeDialogComponent]
})
export class SharedPagesModule {
}
