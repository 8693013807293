import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ds-are-you-sure-dialog',
  templateUrl: './are-you-sure-dialog.component.html',
  styleUrls: ['./are-you-sure-dialog.component.scss']
})
export class AreYouSureDialogComponent {
  confirm = false;

  constructor(
    public dialogRef: MatDialogRef<AreYouSureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions = { checkboxConfirm: false, checkboxHTML: 'I understand' }
  ) {
    if (!data) {
      data = { checkboxConfirm: false, checkboxHTML: 'I understand' };
    }
  }


  private static openDialog(dialog: MatDialog, data: DialogOptions): MatDialogRef<AreYouSureDialogComponent, boolean | undefined> {
    return dialog.open(AreYouSureDialogComponent, {
      closeOnNavigation: false,
      restoreFocus: false,
      autoFocus: false,
      disableClose: true,
      data
    });
  }


  public static dialogClose$(translocoService: TranslocoService, dialog: MatDialog, invite_acc_name: string): Observable<boolean> {
    return combineLatest([
      translocoService.selectTranslate('messages.inviteLogoutMsg', { invite_acc_name }),
      translocoService.selectTranslate('global.goBack'),
      translocoService.selectTranslate('global.Proceed'),
      translocoService.selectTranslate('titles.loggingOut')
    ]).pipe(
      first(),
      map(([extraMSG, noText, yesText, title]) => {
        return { extraMSG, noText, yesText, title } as DialogOptions;
      }),
      map(data => AreYouSureDialogComponent.openDialog(dialog, data)),
      mergeMap(
        (dialog_ref) => dialog_ref.afterClosed().pipe(
          map(res => !!res)
        )
      )
    );
  }
}


export interface DialogOptions {
  extraMSG?: string;
  title?: string;
  checkboxConfirm?: boolean;
  checkboxHTML?: string;
  yesText?: string;
  noText?: string;
}
