import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { API } from 'src/app/api-url/api-urls';
import {
  ROLE_ACCOUNT_ADMIN,
  ROLE_ACCOUNT_JUNIOR_ADMIN,
  ROLE_ADMIN,
  ROLE_DEVELOPER,
  ROLE_PLATFORM_ADMIN,
  ROLE_REPORT_ADMIN
} from 'src/app/constants/user.role';
import { ApiDefinition } from '../../models/api/api.def';
import { UserDefinition } from '../../models/user/user.def';
import {
  LoginV2AccountListResponse,
  LoginV2Response,
  SocialLoginAccountListResponse
} from '../../models/auth/auth.model';
import { NavLinksModel } from 'src/app/main/sidenav/models/nav-link.model';
import { ThemingService } from 'src/app/theme/theming.service';
import { AccountIndexClass } from 'src/app/constants/router-params';
import { SidenavService } from 'src/app/main/main-service/sidenav.service';

import {
  AreYouSureDialogComponent
} from '../../pages/shared/dialogs/are-you-sure-dialog/are-you-sure-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import {
  MFALoginResponse,
  SocialMFALoginResponse
} from 'src/app/main/content/models/general/general-api-response.model';
import { combineLatest } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  set firstLogin(v: boolean) {
    this._firstLogin = v;
  }

  get firstLogin() {
    if (this._firstLogin) {
      this._firstLogin = false;
      return true;
    } else return this._firstLogin;
  }

  constructor(private http: HttpClient, private router: Router, private themingService: ThemingService, private navService: SidenavService, private dialog: MatDialog, private translocoService: TranslocoService) {
    this.api = new ApiDefinition();
    this.httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'body'
    };


  }


  public api: ApiDefinition;
  public httpOptions: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    }; observe?: 'body'; params?: HttpParams | {
      [param: string]: string | string[];
    }; reportProgress?: boolean; responseType?: 'json'; withCredentials?: boolean;
  };
  public token = '';
  public refresh_token = '';
  public quiz_token = '';
  public user: UserDefinition;
  isUserAdmin = false;
  adminLevel = '';

  private _firstLogin = true; // For displaying wellcome back only once

  navLinks: NavLinksModel[] = [];

  login = {

    /**
     * Login with email and password
     *
     * POST /login
     */
    login: (email: string, password: string, invite_token: string, accountName: string = null, captchaResponse: string, twoFactorCode?: string) => {
      return this.http.post<LoginV2Response | LoginV2AccountListResponse | MFALoginResponse>(API.APIDOMAIN + `login`, {
        email,
        password,
        invite_token,
        account: accountName,
        'g-recaptcha-response': captchaResponse,
        twoFactorCode
      }, this.httpOptions);
    },
    /**
     * Login with facebook
     *
     * POST /login/facebook
     * @param userInfo
     */
    facebook: (userInfo: {
      first_name: string; last_name: string; email: string; facebook_id: string; access_token: string;
    }, account: string, invite_token?: string, token?: string, checksum?: string, twoFactorCode?: string) => {
      const options = this.httpOptions;
      options.params = { account };
      return this.http.post<LoginV2Response | SocialLoginAccountListResponse | MFALoginResponse>(API.APIDOMAIN + 'login/facebook', {
        ...userInfo,
        token,
        checksum,
        twoFactorCode
      }, options);
    },
    /**
     * Login with google
     *
     * POST /login/google
     * @param userInfo
     */
    google: (userInfo: {
      first_name: string; last_name: string; email: string; google_id: string; access_token: string;
    }, account: string, invite_token?: string, token?: string, checksum?: string, twoFactorCode?: string) => {
      const options = this.httpOptions;
      options.params = { account };
      return this.http.post<LoginV2Response | SocialLoginAccountListResponse | MFALoginResponse>(API.APIDOMAIN + 'login/google', {
        ...userInfo,
        token,
        checksum,
        twoFactorCode
      }, options);
    },
    /**
     * Linkedin route for getting URL
     *
     * GET /login/linkedin
     * @param data
     */
    linkedinURL: (account: string, returnUrl: string) => {
      const options = this.httpOptions;
      options.params = {
        account,
        returnUrl
      };
      return this.http.get<{ url: string; }>(API.APIDOMAIN + 'login/linkedin', options);
    },

    /**
     * Linkedin route for getting user data
     *
     * GET /login/linkedin
     */
    linkedinDATA: (params: { code?: string; state?: string; account?: string; token?: string, checksum?: string; }) => {
      const options = this.httpOptions;
      options.params = params;

      return this.http.get<LoginV2Response | SocialLoginAccountListResponse | SocialMFALoginResponse>(API.APIDOMAIN + 'login/linkedin', options);
    },


    /**
     * Route to confirm MFA token and GA code
     *
     * POST /mfa/token/login
     * @param MFAToken
     * @param twoFactorCode
     */
    mfaLogin: (MFAToken: string, twoFactorCode: string) => {
      return this.http.post<LoginV2Response>(API.APIDOMAIN + `mfa/token/login`, {
        MFAToken,
        twoFactorCode
      }, this.httpOptions);
    }
  };

  register = {
    /**
     * Register with email
     *
     * POST /registration
     */
    email: (data: { email: string, first_name: string, last_name: string, password: string, account?: string } | UserDefinition) => {
      return this.http.post<LoginV2Response>(API.APIDOMAIN + this.api.register, data, this.httpOptions);
    },
    /**
     * Register with linkedin
     *
     * POST /registration/linkedin
     * @param user
     */
    linkedin: (user) => {
      return this.http.post(API.APIDOMAIN + this.api.register + '/linkedin', user, this.httpOptions);
    }
  };


  /**
   * Refresh a user token
   *
   * POST /token/refresh
   */
  refreshToken() {
    return this.http.post<LoginV2Response>(API.APIDOMAIN + `token/refresh`, {
      user_id: this.user.id,
      refresh_token: this.refresh_token
    }, this.httpOptions);
  }

  /**
   * Refresh anon user token
   *
   * POST /token/refresh
   */
  refreshAnonToken(anonId: number, refreshToken: string) {
    return this.http.post<LoginV2Response>(API.APIDOMAIN + `token/refresh`, {
      user_id: anonId,
      refresh_token: refreshToken
    }, this.httpOptions);
  }

  isLoggedIn(): boolean {
    this.getData();
    if (!this.user || !this.user.roles) {
      return false;
    }
    if (this.token === '' || !this.token) {
      return false;
    }

    AccountIndexClass.ACCOUNT_INDEX = this.user.account.index_name;
    AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM = '/' + this.user.account.index_name;

    return true;
  }

  getUserRoles(): string[] | null {
    const user = localStorage.getItem('ds_user');
    if (user) {
      const roles: any = JSON.parse(user).roles;
      return Array.isArray(roles) ? roles as string[] : null;
    }
    return null;
  }


  userHasRole(role: string): boolean {
    if (this.getUserRoles()) {
      return this.getUserRoles().includes(role);
    }

    return false;
  }


  userHasRoles(roles: string[]): boolean {
    if (roles.length === 0) {
      console.error('You have to provide user roles');
    }
    for (const role in roles) {
      if (!this.userHasRole(role)) {
        return false;
      }
    }

    return true;
  }

  getAnonUserId() {
    const anonUser = localStorage.get('anon_user');
    if (anonUser) {
      const id = (JSON.parse(anonUser)).id;
    }
  }

  getData() {
    this.user = JSON.parse(localStorage.getItem('ds_user'));
    if (this.user && this.user.roles && this.user.roles[0] === ROLE_ADMIN) {
      this.adminLevel = this.user.roles[1];
    }
    this.token = localStorage.getItem('access_token');
    this.refresh_token = localStorage.getItem('refresh_token');
    this.quiz_token = localStorage.getItem('quiz_token');

    const headers = {};
    headers['Content-Type'] = 'application/json';

    if (!this.token) {
      this.token = '';
    } else {
      headers['X-TOKEN'] = this.token;
    }

    this.httpOptions = {
      headers: new HttpHeaders(headers)
    };
  }

  setData(user: UserDefinition, quiz_token?: string) {
    if (!localStorage) {
      console.log('NO LOCAL STORAGE');
      console.error('NO LOCAL STORAGE');
      return;
    }
    this.user = user;
    if (this.user && this.user.roles && this.user.roles[0] === ROLE_ADMIN) {
      if (this.user.roles.length < 2) {
        this.user.roles[1] = 'Error no admin role'; // TODO remove hardcode
        if (!this.user.groups) {
          this.user.groups = ['Error groups not recived'];
        }
      }
    }
    this.token = user.token_key;
    this.refresh_token = user.refresh_token;
    localStorage.setItem('ds_user', JSON.stringify(user));
    localStorage.setItem('access_token', this.token);
    localStorage.setItem('refresh_token', this.refresh_token);

    if (quiz_token) {
      localStorage.setItem('quiz_token', quiz_token);
      this.quiz_token = quiz_token;
    }

    this.getData();
    this.makeNavLinks();

    if (this.navService.leftSidenav.mode === 'side' && !this.navService.leftSidenav.opened) this.navService.leftSidenav.open();
    if (this.navService.rightSidenav.mode === 'side' && !this.navService.rightSidenav.opened) this.navService.rightSidenav.open();

    // clean up anon stuff
    localStorage.removeItem('anon_token');
    localStorage.removeItem('anon_user');
    localStorage.removeItem('anon_quiz_token');
    localStorage.removeItem('anon_assessment');
    localStorage.removeItem('anon_refresh_token');
  }

  updateUserData(user: UserDefinition): void {
    localStorage.setItem('ds_user', JSON.stringify(user));
  }
  
  userLoggedIn(): boolean {
    this.getData();
    if (!this.user || !this.user.roles) {
      return false;
    }
    if (this.token === '' || !this.token) {
      return false;
    }

    return true;
  }


  /**
   * Logout a user
   *
   * DELETE /logout
   */
  logout(noRedirect?: boolean) {
    this.http.delete(API.APIDOMAIN + 'logout', { headers: { 'X-TOKEN': this.token } })
      .subscribe(res => console.log('logoout', res));

    this.logoutCleanUp(noRedirect);
  }

  /**
   * Logout a user and show confirmation popup
   *
   * DELETE /logout
   */
  logoutWithPopup(noRedirect?: boolean) {
    const dialog_ref = this.dialog.open(AreYouSureDialogComponent, {
      closeOnNavigation: false,
      restoreFocus: false,
      autoFocus: false,
      disableClose: true,
      data: {
        extraMSG: this.translocoService.translate('messages.confirmLogout'),
        noText: this.translocoService.translate('global.Cancel'),
        yesText: this.translocoService.translate('global.yes'),
        title: this.translocoService.translate('global.logout')
      }
    });

    dialog_ref.afterClosed().subscribe(result => {
      if (result) {
        this.http.delete(API.APIDOMAIN + 'logout', { headers: { 'X-TOKEN': this.token } })
          .subscribe(res => console.log('logoout', res));

        this.logoutCleanUp(noRedirect);
      }
    });
  }

  logoutAll(noRedirect?: boolean) {
    this.http.delete(API.APIDOMAIN + 'logout/all', { headers: { 'X-TOKEN': this.token } })
      .subscribe(res => console.log('logoout', res));

    this.logoutCleanUp(noRedirect);
  }

  getGdprToken() {
    return this.http.get<any>(API.APIDOMAIN + 'user/gdpr/token', this.httpOptions);
  }

  userDeleteData(token: string) {
    return this.http.delete<any>(API.APIDOMAIN + `user/gdpr/delete/${ token }`);
  }

  userMasterDeleteData(token: string) {
    return this.http.delete<any>(API.APIDOMAIN + `user/gdpr/delete/master/${ token }`);
  }

  userGdprConsent(token: string) {
    return this.http.put<any>(API.APIDOMAIN + `user/gdpr/consent/${ token }`, {});
  }

  userDeleteSend(email: string) {
    return this.http.post<any>(API.APIDOMAIN + `user/gdpr/delete/send`, { email: email });
  }

  get quizToken(): string {
    return this.quiz_token;
  }


  /**
   * Cleans up all the local storage items and tokens
   * @param noRedirect
   */
  logoutCleanUp(noRedirect?: boolean) {
    this.token = '';
    this.user = null;
    this.quiz_token = '';
    this.firstLogin = true;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('ds_user');
    localStorage.removeItem('quiz_token');
    localStorage.removeItem('anon_token');

    eraseCookie('user_auth');
    if (!noRedirect) {
      this.router.navigate([`${ AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM }/login`]);
    }

    if (this.navService.leftSidenav.opened) this.navService.leftSidenav.close();
    if (this.navService.rightSidenav.opened) this.navService.rightSidenav.close();
  }


  /**
   * POST request for sending user a new password via email
   *
   * POST /reset-password
   * @param email
   */
  forgotPassword(email: string, account: string) {
    return this.http.post(API.APIDOMAIN + 'reset-password', {
      email,
      account
    }, this.httpOptions);
  }

  makeNavLinks() {
    if (!this.user || !AccountIndexClass.ACCOUNT_INDEX) {
      return;
    }

    this.navLinks = [];

    if (this.user.roles.includes(ROLE_ADMIN)) // Admin nav
    {
      if (this.user.roles.includes(ROLE_PLATFORM_ADMIN)) {
        this.navLinks = [
          {
            text: 'Admin',
            icon: 'security',
            identifier: 'nav-admin-panel',
            sublinks: [
              {
                text: 'Accounts',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/accounts',
                identifier: 'nav-accounts'
              },
              {
                text: 'Assessments',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/results',
                identifier: 'nav-results'
              },
              {
                text: 'Users',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/users',
                identifier: 'nav-users'
              }
            ]
          }
        ];
      } else if (this.user.roles.includes(ROLE_ACCOUNT_ADMIN)) {
        this.navLinks = [
          {
            text: 'Admin',
            icon: 'security',
            identifier: 'nav-admin-panel',
            sublinks: [
              {
                text: 'Accounts',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/accounts',
                identifier: 'nav-accounts'
              },
              {
                text: 'Assessments',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/results',
                identifier: 'nav-results'
              },
              {
                text: 'Users',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/users',
                identifier: 'nav-users'
              }
            ]
          }
        ];
      } else if (this.user.roles.includes(ROLE_ACCOUNT_JUNIOR_ADMIN)) {
        this.navLinks = [
          {
            text: 'Admin',
            icon: 'security',
            identifier: 'nav-admin-panel',
            sublinks: [
              {
                text: 'Accounts',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/accounts',
                identifier: 'nav-accounts'
              },
              {
                text: 'Assessments',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/results',
                identifier: 'nav-results'
              },
              {
                text: 'Users',
                link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/users',
                identifier: 'nav-users'
              }
            ]
          }
        ];
      } else if (this.user.roles.includes(ROLE_REPORT_ADMIN)) {
        this.navLinks = [
          {
            text: 'Assessments',
            link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/admin/results',
            icon: 'security',
            identifier: 'nav-results'
          }
        ];
      }

      if (this.user.roles.includes(ROLE_DEVELOPER)) {
        this.navLinks.push({
          text: 'Developer Corner',
          icon: 'developer_mode',
          identifier: 'developer-corner-panel',
          sublinks: [
            {
              text: 'Chart Templates',
              link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/developer-corner/chart-templates',
              identifier: 'nav-chart-templates'
            },
            {
              text: 'Report Templates',
              link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/developer-corner/report-templates',
              identifier: 'nav-report-templates'
            },
            {
              text: 'Elastic Mappings',
              link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/developer-corner/elastic-mappings',
              identifier: 'nav-elastic-mappings'
            },
            {
              text: 'Report Articles',
              link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/developer-corner/articles',
              identifier: 'nav-articles'
            }
          ]
        });
      }
    }

    combineLatest([
      this.translocoService.selectTranslate<string>('menus.myprofile'),
      this.translocoService.selectTranslate<string>('menus.privacy'),
      this.translocoService.selectTranslate<string>('menus.myAssessments'),
      this.translocoService.selectTranslate<string>('menus.dashboard'),
      this.translocoService.selectTranslate<string>('menus.logout'),
    ]).subscribe((translations) => {

        const [myProfile, privacy, myAssessments, dashboard, logout] = translations;

        this.navLinks = this.navLinks.filter(link => !link.translatable);
        this.navLinks.push({
          text: myAssessments,
          link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/home',
          icon: 'home',
          identifier: 'nav-home',
          translatable: true
        }, {
          text: myProfile,
          link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/user/profile',
          icon: 'account_circle',
          identifier: 'nav-profile',
          translatable: true
        });
  
        if (this.themingService.id === 2) {
          this.navLinks.push({
            text: 'Help',
            icon: 'help',
            identifier: 'nav-help',
            func: () => window.open(this.themingService.helpPageUrl, '_blank')
          }, {
            text: privacy,
            icon: 'info',
            identifier: 'nav-privacy-policy',
            link: AccountIndexClass.ACCOUNT_INDEX_ROUTE_PARAM + '/u/terms',
            translatable: true
          });
        } else {
          if(window.mpgSubdomain === 'gear') {
            this.navLinks.push({
              text: privacy,
              icon: 'info',
              identifier: 'nav-privacy-policy',
              link: '/privacy-policy',
              translatable: true
            });
          } else {
            this.navLinks.push({
              text: privacy,
              icon: 'info',
              identifier: 'nav-privacy-policy',
              func: () => window.open('https://www.manpowergroup.com/privacy-policy', '_blank'),
              translatable: true
            });
          }
        }
  
        this.navLinks.push({
          text: logout,
          icon: 'exit_to_app',
          identifier: 'nav-logout',
          func: () => this.logoutWithPopup(),
          translatable: true
        });

      });

  }
}


/**
 * Gets the  cookie by name
 * @param name
 */
export function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

/**
 * Erase the cookie
 * @param name
 */
export function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
