import {Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private _leftSidenav: MatSidenav;
  private _rightSidenav: MatSidenav;
  private _showHamburgerIcon: boolean = true;

  set showHamburgerIcon(value: boolean) {
    this._showHamburgerIcon = value;
  }

  get showHamburgerIcon(): boolean {
    const ds_user = JSON.parse(localStorage.getItem('ds_user'));
    if (ds_user && ds_user.created_via && ds_user.created_via === 'sso_oauth_token') {
      this.closeLeftSidenav();
      return false;
    }
    return this._showHamburgerIcon;
  }

  get leftSidenav() {
    return this._leftSidenav;
  }

  get rightSidenav() {
    return this._rightSidenav;
  }

  isCollapsed = false;
  canBeCollapsed = true;

  constructor() {
  }

  setLeftSidenav(sidenavInstance: MatSidenav) {
    this._leftSidenav = sidenavInstance;
  }

  setRightSidenav(sidenavInstance: MatSidenav) {
    this._rightSidenav = sidenavInstance;
  }

  getLeftSidenavPosition() {
    return this._leftSidenav.position;
  }

  getRightSidenavPosition() {
    return this._rightSidenav.position;
  }

  /**
   * Trys to get the mode from the local storage first, but if no value, return the default mode
   */
  getDefaultLeftSidenavMode(): 'over' | 'push' | 'side' {
    const mode = JSON.parse(localStorage.getItem('sidenav-modes'));
    if (mode)
      return mode.left;

    else
      return 'side';
  }

  /**
   * Trys to get the mode from the local storage first, but if no value, return the current mode
   */
  getDefaultRightSidenavMode(): 'over' | 'push' | 'side' {
    const mode = JSON.parse(localStorage.getItem('sidenav-modes'));
    if (mode)
      return mode.right;

    else
      return this._rightSidenav.mode;
  }

  openLeftSidenav() {
    this._leftSidenav.open();
  }

  openRightSidenav() {
    this._rightSidenav.open();
  }

  closeLeftSidenav() {
    this._leftSidenav.close();
  }

  closeRightSidenav() {
    this._rightSidenav.close();
  }

  toggleLeftSidenav() {
    this._leftSidenav.toggle();
  }

  toggleRightSidenav() {
    this._rightSidenav.toggle();
  }

  setSidenavMode(sidenav: 'left' | 'right', mode: 'over' | 'push' | 'side') {
    const isSide = mode === 'side';

    if (!isSide) {
      setTimeout(() => {
        this.isCollapsed = false;
      }, 0);
    }

    switch (sidenav) {
      case 'left':
        this._leftSidenav.mode = mode;
        break;
      case 'right':
        this._rightSidenav.mode = mode;
        break;

      default:
        break;
    }

    // Close and open the sidenavs so that the position updates
    if (isSide && sidenav === 'left') {
      this._leftSidenav.close();
      setTimeout(() => {
        this._leftSidenav.open();
      }, 0);
    }
    if (isSide && sidenav === 'right') {
      this._rightSidenav.close();
      setTimeout(() => {
        this._rightSidenav.open();
      }, 0);
    }

    this.saveNewModes();
  }

  /**
   * Switch the left sidenav to be positioned on the right side and vice versa
   */
  switchSidenavPositions() {
    setTimeout(() => {
      this._leftSidenav.position = this._leftSidenav.position === 'start' ? 'end' : 'start';
      this._rightSidenav.position = this._rightSidenav.position === 'start' ? 'end' : 'start';

      // Close and open the sidenavs so that the position updates
      if (this._leftSidenav.mode === 'side' || this._leftSidenav.mode === 'push') {
        this._leftSidenav.close();
        setTimeout(() => {
          this._leftSidenav.open();
        }, 0);
      }
      if (this._rightSidenav.mode === 'side' || this._rightSidenav.mode === 'push') {
        this._rightSidenav.close();
        setTimeout(() => {
          this._rightSidenav.open();
        }, 0);
      }

      this.saveNewPositions();
    }, 0);
  }

  private saveNewPositions() {
    const positions = {
      left: this._leftSidenav.position,
      right: this._rightSidenav.position
    };

    localStorage.setItem('sidenav-positions', JSON.stringify(positions));
  }

  private saveNewModes() {
    const modes = {
      left: this._leftSidenav.mode,
      right: this._rightSidenav.mode
    };

    localStorage.setItem('sidenav-modes', JSON.stringify(modes));
  }

  /**
   * Gets the saved position from local storage and sets them (if it exists)
   */
  getSavedPositions() {
    const position = JSON.parse(localStorage.getItem('sidenav-positions'));

    if (position) {
      this._leftSidenav.position = position.left;
      this._rightSidenav.position = position.right;
    }
  }

  /**
   * Gets the saved modes from local storage and applys them
   */
  getSavedModes() {
    const modes = JSON.parse(localStorage.getItem('sidenav-modes'));

    if (modes) {
      this._leftSidenav.mode = modes.left;
      this._rightSidenav.mode = modes.right;
    }
  }

  openSidenavIfSideMode() {
    if (this._leftSidenav.mode === 'side' && !this._leftSidenav.opened) {
      this._leftSidenav.open();
    }
    if (this._rightSidenav.mode === 'side' && !this._rightSidenav.opened) {
      this._rightSidenav.open();
    }
  }

  /**
   * Opens then closes the sidenav to recalculate the margins of the container
   * @param side
   */
  openCloseSidenav(side: 'left' | 'right') {
    switch (side) {
      case 'left':
        this._leftSidenav.close();
        setTimeout(() => {
          this._leftSidenav.open();
        }, 0);
        break;
      case 'right':
        this._rightSidenav.close();
        setTimeout(() => {
          this._rightSidenav.open();
        }, 0);
        break;
    }
  }
}
