import { Component, Input, OnInit } from '@angular/core';
import { AssessmentResultsComponent } from '../../../../user/assessment-results/assessment-results.component';
import { AnonReportPageComponent } from '../../../../anonymous-quiz/anon-report-page/anon-report-page.component';

@Component({
  selector: 'ds-skills-insight-details-card',
  templateUrl: './skills-insight-details-card.component.html',
  styleUrls: [ './skills-insight-details-card.component.scss' ]
})
export class SkillsInsightDetailsCardComponent implements OnInit {
  @Input() result: any;
  @Input() isAnon: boolean;
  @Input() loading: boolean = false;

  constructor(
    private assessmentResults: AssessmentResultsComponent,
    private anonReport: AnonReportPageComponent
  ) {
  }

  ngOnInit() {
  }

  shareReportToSocialNetwork(provider: 'facebook' | 'twitter' | 'linkedin' | 'plain') {
    !this.isAnon ? this.assessmentResults.createShareImage(provider) : this.anonReport.createShareImage(provider);
  }
}
