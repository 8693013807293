import { Component, OnInit, Input } from '@angular/core';
import { SubmittedAssessment } from 'src/app/main/content/models/admin/results.def';
import { Scale, CoreDriversScore } from 'src/app/main/content/models/user/user-results.def';

@Component({
  selector: 'ds-core-drivers-details-card',
  templateUrl: './core-drivers-details-card.component.html',
  styleUrls: ['./core-drivers-details-card.component.scss']
})
export class CoreDriversDetailsCardComponent implements OnInit
{
  _result: SubmittedAssessment;
  @Input() set result(value: SubmittedAssessment)
  {
    this._result = value;
    console.groupCollapsed('CORE DRIVERS - DETAILS CARD');
    console.log(this.result);
    console.groupEnd();

    if (Array.isArray(this.result.score))
    {
      this.scales = [];
    }
    else
    {
      this.scales = Object.values((<CoreDriversScore>this.result.score).scales);
      this.scales.sort((a, b) => a.scale_name.localeCompare(b.scale_name));
    }
  }
  get result() { return this._result; }

  scales: Scale[] = [];

  constructor()
  { }

  ngOnInit()
  { }
}
