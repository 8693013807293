import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorService } from '../../../services/helpers/error.service';

@Component({
  selector: 'gdpr-action',
  templateUrl: './gdpr-action.component.html',
  styleUrls: ['./gdpr-action.component.scss']
})
export class GdprActionComponent implements OnInit {
  loadingMsg: string = '';
  loadingTitle: string = '';
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private errService: ErrorService
  ) {
    console.log(this.route.snapshot.params.type);
    console.log(this.route.snapshot.params.master);
    console.log(this.route.snapshot.params.token);
  }

  ngOnInit() {
    if (!this.route.snapshot.params.master) {
      if (this.route.snapshot.params.type === 'consent') {
        this.loadingMsg = 'Storing your consent, please wait.';
        this.loadingTitle = 'Consent';

        this.auth.userGdprConsent(this.route.snapshot.params.token).subscribe(result => {
          this.loadingMsg = 'Your consent has been stored.';
          this.loading = false;
        }, error => {
          this.loading = false;
          this.loadingMsg = 'Something went wrong.';
          this.errService.handleHttpErrorForLogedOutUser(error);
        });
      } else if (this.route.snapshot.params.type === 'delete') {
        this.loadingMsg = 'We are working on deleting your data, please wait.';
        this.loadingTitle = 'Delete';

        this.auth.userDeleteData(this.route.snapshot.params.token).subscribe(result => {
          this.loadingMsg = 'Your data was successfully deleted.';
          this.loading = false;
        }, error => {
          this.loading = false;
          this.loadingMsg = 'Something went wrong.';
          this.errService.handleHttpErrorForLogedOutUser(error);
        });
      }
    } else if (this.route.snapshot.params.master === 'master') {
      this.loadingMsg = 'We are working on deleting your data, please wait.';
      this.loadingTitle = 'Delete';

      this.auth.userMasterDeleteData(this.route.snapshot.params.token).subscribe(result => {
        this.loadingMsg = 'Your data was successfully deleted.';
        this.loading = false;
      }, error => {
        this.loading = false;
        this.loadingMsg = 'Something went wrong.';
        this.errService.handleHttpErrorForLogedOutUser(error);
      });
    }
  }

  goToLogin() {
    this.auth.logoutCleanUp();
  }

}
