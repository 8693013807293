import { Directive, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent
{
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}

@Directive({
  selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy
{
  @Input() thresholdPercent = .8;
  @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
  _onDestroy = new Subject();

  constructor(public autoComplete: MatAutocomplete)
  {
    this.autoComplete.opened.pipe(
      tap(() =>
      {
        // When autocomplete emitts opened, panel is not yet created (by Overlay)
        // The panel will be available on next tick
        // The panel wil NOT open if there are no options to display
        setTimeout(() =>
        {
          // remove listner just for safety, in case the close event is skipped.
          this.removeScrollEventListener();
          this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
        });
      }),
      takeUntil(this._onDestroy)).subscribe();

    this.autoComplete.closed.pipe(
      tap(() => this.removeScrollEventListener()),
      takeUntil(this._onDestroy)
    ).subscribe();
  }

  private removeScrollEventListener()
  {
    if (this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement)
    {
      this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
    }
  }

  ngOnDestroy()
  {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.removeScrollEventListener();
  }

  onScroll(event: any)
  {
    if (this.thresholdPercent === undefined)
    {
      this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
    }
    else
    {
      const threshold = this.thresholdPercent * 100 * event.target.scrollHeight / 100;
      const current = event.target.scrollTop + event.target.clientHeight;

      // console.log(`scroll ${current}, threshold: ${threshold}`);
      if (current > threshold)
      {
        this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
      }
    }
  }
}
