import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseHttpTableComponent } from '../base-http-table.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PaginationQueryParams } from 'src/app/main/content/models/params/params.def';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { ProfileModel } from 'src/app/main/content/models/user/user.def';
import { MatTableDataSource } from '@angular/material/table';
import { USER_ROLE_MAP } from 'src/app/constants/user.role';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';

@Component({
  selector: 'ds-http-profiles-table',
  templateUrl: './http-profiles-table.component.html',
  styleUrls: ['./http-profiles-table.component.scss']
})
export class HttpProfilesTableComponent extends BaseHttpTableComponent implements OnInit, AfterViewInit
{
  displayedColumns = ['select', 'user.first_name', 'user.last_name', 'user.groups', 'user.roles', 'user.email', 'user.account.name', 'company', 'country.name', 'user.last_login_at', 'user.active', 'user.created_at'];
  dataSource = new MatTableDataSource<ProfileModel>([]);

  _anon = false;
  _active = false;
  _includeAll = false;

  @Input() set anon(value: boolean)
  {
    this._anon = value;
    this.changeSubject.next();
  }

  @Input() set active(value: boolean)
  {
    this._active = value;
    this.changeSubject.next();
  }  

  @Input() set includeAll(value: boolean)
  {
    this._includeAll = value;
    this.changeSubject.next();
  }

  @Output() rowClick = new EventEmitter<ProfileModel>();
  @Output() totalUsers = new EventEmitter<Number>();


  userRoleMap = USER_ROLE_MAP;

  constructor(
    private userService: UsersService,
    private errService: ErrorService
  )
  {
    super();
  }

  ngOnInit()
  {
    super.ngOnInit();
  }

  ngAfterViewInit()
  {
    super.ngAfterViewInit();
    this.getData();
  }

  private getData()
  {
    merge(this.changeSubject)
    .pipe(
      startWith({}),
      switchMap(() =>
      {
        this.isLoadingResults = true;

        const queryParams: PaginationQueryParams = {
          _sort: this.sort.active,
          _order: <any>this.sort.direction.toUpperCase(),
          _page: this.paginator.pageIndex + 1,
          _size: this.paginator.pageSize,
          _q: this._query,
          anon: this._anon,
          active: this._active,
          includeAll: this._includeAll
        };
        queryParams._filter = JSON.stringify(this._filters);
        if (this._account) queryParams.account = this._account;

        return this.userService.getAllProfiles(queryParams);
      }),
      map(data =>
      {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isError = false;
        this.error = null;
        this.resultsLength = data.total;
        
        this.totalUsers.emit(this.resultsLength);
        this.groups.emit(data.groups);

        return data.items;
      }),
      catchError((err) =>
      {
        console.log('Error:', err);
        this.error = err;
        this.isLoadingResults = false;

        this.isError = true;
        this.errService.handleHttpErrorForLogedInUser(err);
        return observableOf([]);
      })
    ).subscribe(data => this.dataSource.data = data);
  }

  onRowClick(row: ProfileModel)
  {
    this.rowClick.emit(row);
  }
}
