import { Component, OnInit, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { ReportService } from 'src/app/main/content/services/admin/report.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { MatTableDataSource } from '@angular/material/table';
import { ReportUser } from 'src/app/main/content/models/admin/report.def';
import { BaseHttpTableComponent } from '../base-http-table.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UsersReportQueryParams, UsersReportFilters } from 'src/app/main/content/models/params/params.def';
import { ScaleFilter } from '../../../dialogs/scale-filters/scale-filters.component';
import { FormControl } from '@angular/forms';
import { EChartOption } from 'echarts';

@Component({
  selector: 'ds-http-report-users-table',
  templateUrl: './http-report-users-table.component.html',
  styleUrls: ['./http-report-users-table.component.scss']
})
export class HttpReportUsersTableComponent extends BaseHttpTableComponent implements OnInit, AfterViewInit
{
  /** Default columns that the table has, like first_name, last_name etc. */
  defaultColumns = ['select', 'first_name', 'last_name', 'email', 'groups'];
  /** The columns that the table displays */
  displayedColumns = this.defaultColumns;
  /** A list of scales that exist in report user's data */
  _scales: string[] = [];

  /** Output for scale filters (outputs all scales that exist with active set to false) */
  @Output() scales = new EventEmitter<ScaleFilter[]>();

  /** A flag that is set when displayed columns, visibleScales and other scale related things are first initialized */
  scalesInitialized = false;

  dataSource = new MatTableDataSource<ReportUser>([]);

  @Output() rowClick = new EventEmitter<ReportUser>();
  @Output() graph = new EventEmitter<EChartOption[]>();
  @Output() allItems = new EventEmitter<ReportUser[]>();

  @Input() formID: string;

  private _groupFilters = [];
  @Input() set groupsFilter(v: string[])
  {
    this._groupFilters = v;

    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }

  private _rangeFilters = {};
  @Input() set rangeFilters(v)
  {
    this._rangeFilters = v;

    this.paginator.pageIndex = 0;
    this.changeSubject.next();
  }

  /** Form control that detrmains scales that are visible in the table */
  visibleScales: FormControl = new FormControl([]);

  constructor(
    private reportService: ReportService,
    private errService: ErrorService,
  )
  {
    super();
  }


  ngOnInit()
  {
    super.ngOnInit();

    this.visibleScales.valueChanges.subscribe((scales: string[]) =>
    {
      console.log('Selected columns: ', scales);
      if (scales.includes(undefined))
      {
        this.visibleScales.setValue([], { emitEvent: false });
        this.displayedColumns = this.defaultColumns;
      }
      else
      {
        this.displayedColumns = this.defaultColumns;
        this.displayedColumns = this.displayedColumns.concat(scales);
      }
    });
  }

  ngAfterViewInit()
  {
    super.ngAfterViewInit();
    this.getData();
  }

  private getData()
  {
    merge(this.changeSubject)
      .pipe(
        startWith({}),
        switchMap(() =>
        {
          this.isLoadingResults = true;

          const queryParams: UsersReportQueryParams = {
            _sort: this.sort.active,
            _order: this.sort.direction.toUpperCase(),
            _page: this.paginator.pageIndex + 1,
            _size: this.paginator.pageSize,
            _q: this._query
          };

          const filters: UsersReportFilters = {
            groups: this._groupFilters,
            ranges: this._rangeFilters
          };

          return this.reportService.getUsersReport(this.formID, queryParams, filters);
        }),
        map(data =>
        {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isError = false;
          this.error = null;
          this.resultsLength = data.total;

          this.groups.emit(data.groups);
          this.allItems.emit(data.users);

          console.groupCollapsed('Report users');
          console.log(data);
          console.groupEnd();

          if (!this.scalesInitialized)
          {
            // First initialize visible columns to the defaults
            this.displayedColumns = this.defaultColumns;
            this._scales = [];
            // Make columns with extra scale columns from user's results
            if (data.users && data.users.length > 0)
            {
              this._scales = Object.keys(data.users[0].scales);
              this.displayedColumns = this.displayedColumns.concat(data.default_columns);
              this.scales.emit(this._scales.map(s => ({ scaleName: s, range: [0, 100], active: false })));
              this.visibleScales.setValue(data.default_columns, { emitEvent: false });

              console.log('Init scales', this.displayedColumns);
              this.scalesInitialized = true;
            }
          }

          this.graph.emit(data.graph);

          return data.users;
        }),
        catchError((err) =>
        {
          console.log('Error:', err);
          this.error = err;
          this.isLoadingResults = false;

          this.isError = true;
          this.errService.handleHttpErrorForLogedInUser(err);
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);
  }

  onRowClick(row: ReportUser)
  {
    this.rowClick.emit(row);
  }
}

