import { Component, OnInit } from '@angular/core';
import { DesignType } from 'src/app/theme/theme.models';
import { ThemingService } from 'src/app/theme/theming.service';

@Component({
  selector: 'ds-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class FuseRegisterComponent implements OnInit
{
  designTypes = DesignType;

  constructor(
    public themingService: ThemingService
  )
  {}

  ngOnInit()
  {}
}
