import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, pluck} from 'rxjs/operators';
import * as _ from 'lodash';

export class ComponentStore<T> {
  private _subject: BehaviorSubject<T>;

  constructor(initialState: T) {
    this._subject = new BehaviorSubject<T>(initialState);
  }

  select<E>(name: string): Observable<E> {
    return this.state$.pipe(
      pluck(name)
    );
  }

  patch(partial: Partial<T>) {
    this._subject.next({...this.value, ...partial});
  }

  set(state: T) {
    this._subject.next({...state});
  }

  get value() {
    return this._subject.value;
  }

  get state$(): Observable<T> {
    return this._subject.asObservable();
  }
}
