import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiDefinition } from '../../models/api/api.def';
import { API } from 'src/app/api-url/api-urls';
import { AdminAssessmentResults, CSVResponse, QuizResult } from '../../models/admin/results.def';
import { PaginationQueryParams } from '../../models/params/params.def';

@Injectable({
  providedIn: 'root'
})
export class AdminResultsService {
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.api = this.auth.api;
  }

  /**
   * Get a list quiz results
   *
   * GET /results
   * @param account If this is set, the list will be only for that account
   */
  getResults(account?: string) {
    const options = this.auth.httpOptions;
    if (account) options.params = { account };

    return this.http.get<QuizResult[]>(API.APIDOMAIN + this.api.results, options);
  }

  /**
   * GET invites by form id
   * /invites/{ form_id }
   * @param form_id
   */
  getInvitesByFormId(form_id: string) {
    return this.http.get(API.APIDOMAIN + this.api.emailInvites + '/' + form_id, this.auth.httpOptions);
  }

  /**
   * Get the CSV data preview for the
   *
   * POST /assessments/csv/scores/{type}/{form_id}/{download_value}/preview
   */
  getCSVpreview(user_ids: number[], type: 'scales' | 'raw_data', form_id: string, download_value: 'num' | 'raw' | 'text', search: string, selectedGroups: string[], selectedPersonas: string[], range: any[]) {
    let body = {}

    body = {
      user_ids: user_ids,
      q: search,
      groups: selectedGroups,
      personas: selectedPersonas,
      range
    };


    return this.http.post<CSVResponse>(
      API.APIDOMAIN + this.api.csv_scores + `/${ type }/${ form_id }/${ download_value }/preview`, body, this.auth.httpOptions);
  }

  /**
   * Download the CSV data
   *
   * GET /assessments/csv/scores/download/{download_key}
   */
  downloadCSV(download_key: string) {
    const options: any = this.auth.httpOptions;
    options.responseType = 'blob' as 'json';
    options.observe = 'response';

    return this.http.get<any>(API.APIDOMAIN + this.api.csv_scores + `/download/${ download_key }`, options);
  }


  /**
   * Get the results for given form.
   *
   * POST /results/{formId}
   */
  getResultsByFormID(form_id: string, params: PaginationQueryParams, groups: string[], personas: string[], maturity_level?: any) {
    const options = this.auth.httpOptions;
    options.params = <any> params;

    return this.http.post<AdminAssessmentResults>(API.APIDOMAIN + `${ this.api.results }/${ form_id }`, {
      groups,
      personas,
      maturity_level
    }, options);
  }
}
