import { Injectable } from '@angular/core';
import { ApiDefinition } from '../../models/api/api.def';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { API } from 'src/app/api-url/api-urls';
import { LoginV2Response } from '../../models/auth/auth.model';
import { CoreDriversResult } from '../../models/user/user-results.def';
import { UserModel } from '../../models/user/user.def';
import { UserAssessmentModel } from '../../models/admin/assesment.def';

@Injectable({
  providedIn: 'root'
})
export class AnonUserService {
  private api: ApiDefinition = new ApiDefinition();

  private _anonUser: UserModel;
  set anonUser(v: UserModel) {
    this._anonUser = v;
    localStorage.setItem('anon_user', JSON.stringify(this._anonUser));
  }

  get anonUser() {
    const u = localStorage.getItem('anon_user');
    return this._anonUser || (u ? JSON.parse(u) : null);
  }


  private _anonToken: string;
  set anonToken(v: string) {
    this._anonToken = v;
    localStorage.setItem('anon_token', this._anonToken);
  }

  get anonToken() {
    return this._anonToken || localStorage.getItem('anon_token');
  }


  private _anonRefreshToken: string;
  set anonRefreshToken(v: string) {
    this._anonRefreshToken = v;
    localStorage.setItem('anon_refresh_token', this._anonRefreshToken);
  }

  get anonRefreshToken() {
    return this._anonRefreshToken || localStorage.getItem('anon_refresh_token');
  }


  private _anonQuizToken: string;
  set anonQuizToken(v: string) {
    this._anonQuizToken = v;
    localStorage.setItem('anon_quiz_token', this._anonQuizToken);
  }

  get anonQuizToken() {
    return this._anonQuizToken || localStorage.getItem('anon_quiz_token');
  }


  private _assessment: UserAssessmentModel;
  set assessment(v: UserAssessmentModel) {
    this._assessment = v;
    localStorage.setItem('anon_assessment', JSON.stringify(this._assessment));
  }

  get assessment() {
    const a = localStorage.getItem('anon_assessment');
    return this._assessment || (a ? JSON.parse(a) : null);
  }

  private _emailRequired: boolean;
  set emailRequired(v: boolean) {
    this._emailRequired = v;
    localStorage.setItem('email_required', JSON.stringify(this._emailRequired));
  }

  get emailRequired() {
    const a = localStorage.getItem('email_required');
    return this._emailRequired || (a ? JSON.parse(a) : null);
  }


  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
  }


  /**
   * Update anon user email, this action also sends the registration email
   *
   * PUT /user/anon/email
   * @param email
   * @param userToken
   */
  checkAnonEmail(email: string, userToken: string) {
    return this.http.put<{ status: 'OK' | 'EXISTS'; message: string; }>(API.APIDOMAIN + this.api.user_anon + `/email`, {email}, this.getHeaders(userToken));
  }


  /**
   * Login and add user assessment from anon user
   *
   * POST /anon/login
   * @param email
   * @param password
   * @param account
   * @param anonUserToken
   */
  loginAnonUser(email: string, password: string, account: string, anonUserToken: string, overwrite?: boolean) {
    return this.http.post<LoginV2Response>(API.APIDOMAIN + this.api.anon_login, {
      email,
      password,
      account,
      overwrite
    }, this.getHeaders(anonUserToken));
  }


  /**
   * Get results for user by the form id and user id
   *
   * GET /results/{form_id}/{user_id}/score
   *
   * @param form_id
   * @param user_id
   * @param userToken x-token for the anon user
   */
  getAnonUserResult(form_id: string, user_id: number, userToken: string) {
    return this.http.get<CoreDriversResult | any>(API.APIDOMAIN + `${ this.api.results }/${ form_id }/${ user_id }/score`, this.getHeaders(userToken));
  }


  /**
   * PUT request for updating user data
   *
   * PUT /users/{user_id}
   * @param user
   */
  updateAnonUser(user: UserModel) {
    const token = user.token_key || localStorage.getItem('anon_token');
    return this.http.put<UserModel>(API.APIDOMAIN + this.api.users + `/${ user.id }`, user, this.getHeaders(token));
  }


  /**
   * Get an anon user by email token
   *
   * GET /user/anon/verify/{token}
   *
   * @param token token from email link
   */
  getAnonUserByEmailToken(token: string) {
    return this.http.get<LoginV2Response>(API.APIDOMAIN + this.api.user_anon + `/verify/${ token }`, this.auth.httpOptions);
  }


  private getHeaders(xToken: string) {
    const options = this.auth.httpOptions;
    options.headers = {
      'Content-Type': 'application/json',
      'X-TOKEN': xToken
    };

    return options;
  }
}
