import {Component, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {QuizComponent} from './quiz.component';
import {ActivatedRoute, Router} from '@angular/router';
import {HelperService} from 'src/app/main/content/services/helpers/helper.service';
import {QuizSystemService} from 'src/app/main/content/services/quiz/quiz-system.service';
import {AuthService} from 'src/app/main/content/services/auth/auth.service';
import {ThemingService} from 'src/app/theme/theming.service';
import {Platform} from '@angular/cdk/platform';
import {AssesmentService} from 'src/app/main/content/services/user/assesment.service';
import {RouterParamsService} from 'src/app/main/main-service/router-params.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ErrorService} from 'src/app/main/content/services/helpers/error.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AnonUserService} from 'src/app/main/content/services/user/anon-user.service';
import {UsersService} from 'src/app/main/content/services/admin/users.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {TranslocoService} from '@ngneat/transloco';
import {DemographicsService} from 'src/app/main/content/services/user/demographics.service';
import { AppStore } from '../../../../../../app.store';

@Component({
  selector: 'ds-anon-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({opacity: 0}),
        animate('.15s ease', style({opacity: 1})),
      ])
    ])
  ]
})

export class AnonQuizComponent extends QuizComponent implements OnInit {
  @Input() userToken: string;
  @Input() userID: number;
  @Input() formID: string;
  @Input() quizToken: string;
  @Input() emailRequired: boolean;

  isAnonQuiz = true;
  showDemographics = true;
  checkingEmail = false;

  userEmailControl = new FormControl('', Validators.compose([Validators.pattern(HelperService.EMAIL_REGEX), Validators.required]));
  termsControl = new FormControl(false, Validators.requiredTrue);

  stgDemoForm: FormGroup;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected helper: HelperService,
    protected quizService: QuizSystemService,
    protected auth: AuthService,
    public themingService: ThemingService,
    protected platform: Platform,
    protected assessmentService: AssesmentService,
    protected routerParams: RouterParamsService,
    protected breakpointObserver: BreakpointObserver,
    protected errService: ErrorService,
    protected anonService: AnonUserService,
    protected userService: UsersService,
    protected sanitizer: DomSanitizer,
    protected dialog: MatDialog,
    protected translocoService: TranslocoService,
    private fb: FormBuilder,
    protected demoService: DemographicsService,
    public appStore: AppStore
  ) {
    super(router, route, helper, quizService, auth, themingService, platform, assessmentService, routerParams, breakpointObserver, errService, userService, sanitizer, dialog, anonService, translocoService, demoService, appStore);
  }

  ngOnInit() {
    if (!this.userToken || !this.userID || !this.formID || !this.quizToken)
      throw new Error('User token, formID or user ID not provided via input variables to the anon quiz');

    this.createDemoForm();

    super.ngOnInit();
  }

  createDemoForm() {
    this.stgDemoForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.pattern(HelperService.EMAIL_REGEX), Validators.required])],
      country: [null, Validators.compose([Validators.required])],
    });
  }

  saveAnswers() {
    if (this.auth.isLoggedIn())
      super.saveAnswers(null, null, this.formID);
    else
      super.saveAnswers(this.userToken, this.userID, this.formID, this.quizToken);
  }

  submitAnswers() {
    if (this.auth.isLoggedIn())
      super.submitAnswers(null, null, this.formID);
    else
      super.submitAnswers(this.userToken, this.userID, this.formID, this.quizToken);
  }

  fillInTheQuizResults() {
    super.fillInTheQuizResults(this.formID, this.userToken, this.quizToken);
  }


  checkUserEmail() {
    const email = this.userEmailControl.value;
    if (this.quiz.theme === 'stg_assessment-quiz-theme') {
      return this.handleStgDemographics();
    }

    if (!email || this.userEmailControl.invalid || this.termsControl.invalid || this.checkingEmail) {
      return;
    }

    this.checkingEmail = true;

    this.anonService.checkAnonEmail(email, this.userToken)
      .subscribe(res => {
        console.log('Check anon email response: ', res);
        this.showDemographics = false;
        this.checkingEmail = false;
      }, err => {
        console.log('Check anon email error:', err);
        this.checkingEmail = false;
        this.errService.handleHttpErrorForLogedOutUser(err);
      });

  }

  handleStgDemographics() {
    if (!this.stgDemoForm.valid) return;

    const user = {
      id: this.userID,
      first_name: this.stgDemoForm.controls.firstName.value,
      last_name: this.stgDemoForm.controls.lastName.value,
      email: this.stgDemoForm.controls.email.value
    };
    const userP = {
      user_id: this.userID,
      country: this.stgDemoForm.controls.country.value
    };


    this.userService.updateAnonUser(user).subscribe(res => {
      this.userService.updateAnonProfile(this.userID, userP).subscribe(_ => {
        this.showDemographics = false;
        this.checkingEmail = false;
      }, err => {
        this.errService.handleHttpErrorForLogedOutUser(err);
      });
    }, err => {
      this.errService.handleHttpErrorForLogedOutUser(err);
    });
  }


  /**
   * Opens the brazenx T&C
   */
  openTerms() {
    window.open('https://www.manpowergroup.com/terms-of-use');
  }

  openPrivacyPolicy() {
    window.open('https://www.manpowergroup.com/privacy-policy');
  }
}

