import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main.component';
import { HeaderComponent } from './header/header.component';
import { LeftSidenavComponent } from './sidenav/left-sidenav/left-sidenav.component';
import { RightSidenavComponent } from './sidenav/right-sidenav/right-sidenav.component';
import { NavLinksComponent } from './sidenav/nav-links/nav-links.component';
import { ContentComponent } from './content/content.component';
import { RouterModule } from '@angular/router';
import { CustomDatePipe } from './content/pipes/custom-date.pipe';
import { IsControlRequiredPipe } from './content/pipes/is-control-required.pipe';
import { SystemDateFormatPipe } from './content/pipes/system-date-format.pipe';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    LeftSidenavComponent,
    RightSidenavComponent,
    NavLinksComponent,
    ContentComponent,
    IsControlRequiredPipe,
    SystemDateFormatPipe
  ],
    exports: [
        MainComponent,
        IsControlRequiredPipe
    ],
  imports: [
    SharedModule,
    RouterModule,
  ]
})
export class MainModule { }
