import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/main/content/services/admin/users.service';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/main/content/models/user/user.def';
import { AnonUserService } from 'src/app/main/content/services/user/anon-user.service';

@Component({
  selector: 'ds-anon-login-register-dialog',
  templateUrl: './anon-login-register-dialog.component.html',
  styleUrls: [ './anon-login-register-dialog.component.scss' ]
})
export class AnonLoginRegisterDialogComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  loading = false;
  user: UserModel;

  step: 'login' | 'register' = null;

  constructor(
    public dialogRef: MatDialogRef<AnonLoginRegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private userID: number,
    private userService: UsersService,
    private anonService: AnonUserService,
  ) {
  }

  ngOnInit() {
    console.log('User id:', this.userID);

    this.getUserDetails();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private getUserDetails() {
    this.loading = true;


    this.userService.getUserById(this.userID, this.anonService.anonToken).subscribe(res => {
      console.log('Get User details response: ', res);
      this.user = res;
      this.user.token_key = this.anonService.anonToken;
      this.user.refresh_token = this.anonService.anonRefreshToken;

      if (this.user.status === 'NORMAL') {
        this.step = 'login';
      } else {
        this.step = 'register';
      }

      this.loading = false;

    }, err => {
      console.log('Get User error:', err);
      this.loading = false;
    });

  }


  onUserActionCompleted(user: UserModel) {
    this.dialogRef.close(user);
  }

  switchToLogin(email: string) {
    this.step = 'login';
  }

  onSubmit() {
    this.dialogRef.close(this.step);
  }
}
