import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/app/animations/animations';
import { DesignType } from 'src/app/theme/theme.models';
import { Forms, UserAssessment } from '../../../models/admin/assesment.def';
import { ThemingService } from 'src/app/theme/theming.service';
import { AssesmentService } from '../../../services/user/assesment.service';
import { ErrorService } from '../../../services/helpers/error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: fuseAnimations
})
export class DashboardComponent implements OnInit, OnDestroy
{
  designTypes = DesignType;
  activeDesign = DesignType.design1;

  loading = true;
  result: Forms;
  userAssessments: UserAssessment[] = [];

  subs = new Subscription();

  constructor(
    public themingService: ThemingService,
    private assessmentService: AssesmentService,
    private errService: ErrorService
  )
  { }

  ngOnInit()
  {
    this.getUserAssessments();
  }

  ngOnDestroy()
  {
    if (this.subs) { this.subs.unsubscribe(); }
  }

  private getUserAssessments()
  {
    this.loading = true;
    this.subs.add(
      this.assessmentService.getUsersAssessments().subscribe(res =>
      {
        console.log('User\'s assessments: ', res);
        this.userAssessments = res;
        this.chooseDesign();
        this.loading = false;

      }, err =>
      {
        console.log('User\'s assessments error: ', err);
        this.loading = false;
        this.errService.handleHttpErrorForLogedInUser(err);
      })
    );
  }

  private chooseDesign()
  {
    if (!this.userAssessments || this.userAssessments.length < 1)
    {
      this.activeDesign = this.themingService.dashboardDesign;
    }
    else if (this.userAssessments.length >= 2)
    {
      this.activeDesign = DesignType.design1;
    }
    else
    {
      this.activeDesign = this.themingService.dashboardDesign;
    }
  }
}
