import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PaginationQueryParams } from 'src/app/main/content/models/params/params.def';
import { BaseHttpTableComponent } from '../base-http-table.component';
import { SelectionModel } from '@angular/cdk/collections';
import { UserResult } from 'src/app/main/content/models/admin/results.def';
import { AdminResultsService } from 'src/app/main/content/services/admin/admin-results.service';
import { ErrorService } from 'src/app/main/content/services/helpers/error.service';
import { AppStore } from 'src/app/app.store';
import {
  ReportRangeFilterComponent
} from 'src/app/main/content/pages/admin/admin-results/results-overview/reporting-tab/reporting-filters/report-range-filter/report-range-filter.component';

export interface MaturityLevelPayload {
  improvingCultureRetention: string;
  engagingEvolvingTalent: string;
  acquiringHiringTalent: string;
}

@Component({
  selector: 'ds-http-users-table',
  templateUrl: './http-users-table.component.html',
  styleUrls: ['./http-users-table.component.scss']
})
export class HttpUsersTableComponent extends BaseHttpTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['select', 'name', 'email', 'groups', 'summary', 'language', 'started', 'completed_at', 'company'];
  selection = new SelectionModel<UserResult>(true, []);

  @Output() rowClick = new EventEmitter<UserResult>();
  @Output() allData = new EventEmitter<UserResult[]>();
  @Output() csvOptions = new EventEmitter<{ [key: string]: string }>();

  @Input() form_id: string;

  private _filterGroups: string[];
  @Input() set filterGroups(value: string) {
    this._filterGroups = JSON.parse(value ? value : '[]');
    this.changeSubject.next();
  }

  private _acquiringHiring: string;
  @Input()
  set acquiringHiring(value: string) {
    this._acquiringHiring = value;
    this.changeSubject.next();
  }

  _engagingEvolving: string;
  @Input()
  set engagingEvolving(value: string) {
    this._engagingEvolving = value;
    this.changeSubject.next();
  }

  _improvingCulture: string;
  @Input()
  set improvingCulture(value: string) {
    this._improvingCulture = value;
    this.changeSubject.next();
  }


  private _filterPersonas: string[];
  @Input() set filterPersonas(value: string) {
    this._filterPersonas = JSON.parse(value ? value : '[]');
    this.changeSubject.next();
  }

  constructor(
    private adminResults: AdminResultsService,
    private errService: ErrorService,
    private appStore: AppStore
  ) {
    super();
  }


  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.getData();
  }

  private getData() {
    merge(this.changeSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const queryParams: PaginationQueryParams = {
            _sort: this.sort.active,
            _order: <any> this.sort.direction.toUpperCase(),
            _page: this.paginator.pageIndex + 1,
            _size: this.paginator.pageSize,
            _q: this._query
          };


          if (this.start) {
            queryParams._start = ReportRangeFilterComponent.formatStartDate(this.start);
          }

          if (this.end) {
            queryParams._end = ReportRangeFilterComponent.formatEndDate(this.end);
          } else {
            this.end = null;
          }


          queryParams._filter = JSON.stringify(this._filters);
          if (this._account) queryParams.account = this._account;

          const ml: MaturityLevelPayload = {
            improvingCultureRetention: this._improvingCulture,
            engagingEvolvingTalent: this._engagingEvolving,
            acquiringHiringTalent: this._acquiringHiring
          };

          if (ml.improvingCultureRetention === null) {
            delete ml.improvingCultureRetention;
          }

          if (ml.engagingEvolvingTalent === null) {
            delete ml.engagingEvolvingTalent;
          }

          if (ml.acquiringHiringTalent === null) {
            delete ml.acquiringHiringTalent;
          }
          return this.adminResults.getResultsByFormID(this.form_id, queryParams, this._filterGroups, this._filterPersonas, ml);
        }),
        map(data => {
          console.log('%cUsers:', 'font-weight: bold; color:white; background-color:green; padding: 2px 4px;', data);
          // Flip flag to show that loading has finished.

          this.appStore.maturity_level = data.maturity_level ? data.maturity_level : null;
          this.isLoadingResults = false;
          this.isError = false;
          this.error = null;
          this.resultsLength = data.total;
          this.groups.emit(Object.keys(data.groups));
          this.personas.emit(Object.keys(data.personas));
          this.allData.emit(data.items);
          this.csvOptions.emit(data.values);

          return data.items;
        }),
        catchError((err) => {
          console.log('Error:', err);
          this.error = err;
          this.isLoadingResults = false;

          this.isError = true;
          this.errService.handleHttpErrorForLogedInUser(err);
          return observableOf([]);
        })
      ).subscribe((data) => {

      this.dataSource.data = data;

    });
  }

  onRowClick(row: UserResult) {
    console.log('Row clicked; emmit');
    this.rowClick.emit(row);
  }

  isGreyRow(row: any): boolean {
    return row.user_assessment && row.user_assessment.status !== 'completed';

  }
}
