import { UserModel } from '../user/user.def';
import { UserAssessmentModel } from './assesment.def';

export const emailStatusTranslationMap = {
  total: 'admin.all',
  sent: 'admin.sent',
  errors: 'admin.failedErr',
  created: 'admin.created',
  started: 'admin.started',
  completed: 'admin.completed',
  custom: "admin.custom"
} as const;

export interface InviteDataModel {
  invite: InviteModel;
  user: UserModel;
}

export interface InviteModel {
  company: Field;
  country: Field;
  created_at: string;
  email_template: any;
  first_name: string;
  form_id: string;
  id: number;
  last_name: string;
  send_to: string;
  sent_at: string;
  state: Field;
  token: string;
  updated_at: string;
  verified_at: string;
  user_groups: string;
  is_deleted: boolean;
  user_assessment: {
    init_started_at: string;
    started_at: string;
    finished_at: string;
    finished: boolean;
  }[];
  error: boolean;
}

interface Field {
  id: number;
  name: string;
}

/**
 * For use when sending email invite
 */
export interface EmailInviteModel {
  user_invite?: boolean;
  form_id: string;
  email_template_id: number;
  invites: Invite[];
  email_body?: string;
  email_subject?: string;
  game?: string;
}

/**
 * For use when sending email invite
 */
export interface Invite {
  email: string;
  first_name?: string;
  last_name?: string;
  country?: number;
  state?: number;
  company?: string;
  groups?: string[];
  notes?: string;
}

export interface VerifyResponse {
  status: 'OK' | 'Invalid' | 'Login';
  message: string;
  account: string;
  token: string;
  email: string;
}

// Generated by https://quicktype.io

export interface CreateInviteLinkResponse {
  assessment: {
    form_id: string;
    name: string;
    created_at: string;
  };
  token: string;
  created_by: {
    email: string;
  };
  groups: any[];
  hits: number;
  last_hit_at: null;
  enabled: boolean;
  created_at: string;
  anonymous: boolean;
  emailRequired: boolean;
}

export interface FulfillResponse {
  message: string;
  status: 'OK' | 'Invalid';
}

export interface PublicInvite {
  token: string;
  created_by: {
    email: string;
  };
  groups: string[];
  hits: number;
  last_hit_at: string;
  enabled: boolean;
  anonymous: boolean;
  incognito?: boolean;
  emailRequired: boolean;
}

/**
 * Email invite model.
 *
 * Listed in table
 */
export interface EmailInvite {
  collection_uuid: string;
  error: boolean;
  id: number;
  email: string;
  sent: number;
  sent_by: {
    email: string;
  };
  sent_at: string;
  created_by: {
    email: string;
  };
  country: {
    id: number;
    name: string;
    code: string;
  };
  state: {
    id: number;
    name: string;
  };
  company: string;
  first_name: string;
  last_name: string;
  user_assessment_status: EmailInviteStatus;
}

export interface EmailCollection {
  collection_uuid: string;
  created_at: string;
  form_id: string;
  total: number;
  errors: number;
  sent: number;
  created: number;
  completed: number;
  started: number;
  auto_resend: string;
}

export interface SuccessfulResponse {
  status: string;
  message: string;
}

export interface AnonFulfillResponse {
  emailRequired: boolean;
  tokens: AnonTokens;
  userassessment: UserAssessmentModel;
}

export interface AnonTokens {
  token_key: string;
  refresh_token: string;
  user: UserModel;
  quizToken: {
    token: string;
    expires_at: string;
  };
}

export interface FollowUp {
  id: number;
  collection_uuid: string;
  follow_up_type: 'init';
  emails: {
    count: number;
    errors: number;
    addresses: {
      email: string;
      status: EmailInviteStatus;
      invite_id: number;
    }[];
  };
  sent_by: {
    email: string;
  };
  created_at: string;
}

export enum EmailInviteStatus {
  total = 'total',
  errors = 'errors',
  sent = 'sent',
  created = 'created',
  completed = 'completed',
  started = 'started',
  custom = 'custom',
}
